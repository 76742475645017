import React, { FC, ReactNode } from 'react'

import { connect } from 'react-redux'
import { DefaultTheme, ThemeProvider } from 'styled-components'

import defaultTheme from '../../style/theme'

type CustomThemeProviderProps = {
  children: ReactNode
  theme: DefaultTheme
}

const CustomThemeProvider: FC<CustomThemeProviderProps> = ({
  children,
  theme,
}) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

const ConnectedThemeProvider = connect(state => ({
  theme: {
    ...defaultTheme,
    checkoutColors: {
      primary:
        state.storeSettings?.checkoutColor &&
        state.storeSettings?.checkoutColor !== ''
          ? state.storeSettings.checkoutColor
          : '#1879b9',
      primaryButton:
        state.storeSettings?.checkoutButtonColor &&
        state.storeSettings?.checkoutButtonColor !== ''
          ? state.storeSettings.checkoutButtonColor
          : '#1879b9',
    },
  },
}))(CustomThemeProvider)

export default ConnectedThemeProvider
