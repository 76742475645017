import React, { useRef, useEffect } from 'react'

import {
  Field,
  FieldProps,
  ErrorMessage,
  FormikErrors,
  FormikTouched,
} from 'formik'
import styled from 'styled-components'

import { WithValidation } from '../../../@types'
import { Label, Input } from '../../style/components/input'
import { InputWrapper } from '../../style/components/Section'
import { AppBoxProps, PropsOf } from '../Primitives/AppBox'

import CheckIcon from './CheckIcon'
import { StripeFormProps } from './StripeForm'
import TextError from './TextError'

interface InputProps extends Pick<StripeFormProps, 'mode'> {
  label: string
  name: string
  placeholder?: string
  showErrorMessage?: boolean
  errors?: FormikErrors<{
    [field: string]: any
  }>
  touched?: FormikTouched<{
    [field: string]: any
  }>
  validate?: (value: string) => Promise<string> | string
  isValid?: React.Dispatch<React.SetStateAction<boolean>>
  onKeyUp?: () => void

  autofocus?: boolean
  disabled?: boolean
  showCheckIcon?: boolean
  validationIcon?: WithValidation
  setActive?: React.Dispatch<React.SetStateAction<boolean>>
}

const InputContainer = styled.div`
  position: relative;
  & > input:-ms-input-placeholder {
    color: ${props => props.theme.colorblackmainmoona} !important;
    opacity: 0.3 !important;
    font-family: 'Open Sans', sans-serif, Arial !important;
    font-size: 1.4rem !important;
    line-height: 2.4rem !important;
  }
`

export const MoonaInput: React.FC<
  InputProps &
    Omit<AppBoxProps, keyof InputProps> &
    Pick<PropsOf<'input'>, 'maxLength'>
> = ({
  name,
  label,
  placeholder,
  errors,
  touched,
  validate,
  isValid,
  onKeyUp,
  autofocus = false,
  disabled = false,
  validationIcon = { success: true, error: false, neutral: true },
  showErrorMessage = true,
  showCheckIcon = true,
  mode = 'normal',
  setActive,
  maxLength,
  ...props
}) => {
  const isError = errors[name] && touched[name]
  const errorClass = isError ? 'error' : ''

  if (isValid) isValid(!isError)
  const inputRef = useRef<HTMLInputElement>(null)

  // const [show, eventHandlers] = useToggleOnFocus();

  useEffect(() => {
    if (inputRef.current) {
      const ref = inputRef.current
      if (autofocus) {
        ref.focus()
      }
    }
  }, [inputRef, autofocus])

  useEffect(() => {
    const nodeRef = inputRef.current
    const focusListener = () => setActive(true)
    const blurListener = () => setActive(false)

    if (setActive) {
      nodeRef.addEventListener('focus', focusListener)
      nodeRef.addEventListener('blur', blurListener)
    }
    return () => {
      nodeRef.removeEventListener('focus', focusListener)
      nodeRef.removeEventListener('blur', blurListener)
    }
  }, [setActive, name])
  const isIntegrated = mode === 'integrated'

  return (
    <InputWrapper {...props}>
      <Label isIntegrated={isIntegrated} htmlFor={name}>
        {label}
      </Label>

      <Field name={name} validate={validate}>
        {({ meta }: FieldProps) => {
          return (
            <InputContainer>
              <Input
                isIntegrated={isIntegrated}
                innerRef={inputRef}
                className={errorClass}
                id={name}
                name={name}
                placeholder={placeholder || ''}
                validate={validate}
                autoComplete="off"
                onKeyUp={onKeyUp}
                disabled={disabled}
                maxLength={maxLength}
                // {...eventHandlers}
              />
              <span></span>
              {showCheckIcon && (
                <CheckIcon validation={validationIcon} meta={meta} />
              )}
            </InputContainer>
          )
        }}
      </Field>

      {showErrorMessage && <ErrorMessage component={TextError} name={name} />}
    </InputWrapper>
  )
}
