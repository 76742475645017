import { API, graphqlOperation } from 'aws-amplify'
import { takeLatest, call, fork, put, select } from 'redux-saga/effects'

import { getPaymentIntent, getPaymentIntentMain } from 'graphql/queries'
import {
  createMainPaymentIntentError,
  createPaymentIntentError,
  setClientSecret,
} from 'state/features/paymentintent/actions'
import { checkCartId } from 'utils/axios'

import { isPrestashop } from '../../../utils/ecommerce'
import { setHasDiscount } from '../payment/actions'
import { AppState } from '../types'
import { check } from '../users/utils'

import {
  CreatePaymentIntentRequest,
  CREATE_PAYMENTINTENT_REQUEST,
  CREATE_MAIN_PAYMENTINTENT_REQUEST,
  CreateMainPaymentIntentRequest,
} from './types'

function* paymentIntent(action: CreatePaymentIntentRequest) {
  try {
    const {
      moonaId,
      stripeMerchantId,
      price,
      paymentId,
      orderId,
      ecommerceSessionId,
      notifyUrl,
      ecommerce,
      cartId,
      deviceId,
      merchantId,
      email,
      endTrial,
      subscriptionWithoutTrialPeriod,
      payload,
    } = action.payload
    const amount = Math.round(price * 100)
    const currency = yield select(
      (state: AppState) => state.query.params.currency
    )
    if (isPrestashop(ecommerce)) {
      yield call(checkCartId, notifyUrl, {
        email,
        amount: amount + 500,
        cart_id: cartId,
        merchant_id: merchantId,
        device_id: deviceId,
      })
    }
    const paymentIntentResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getPaymentIntent, {
        userId: moonaId,
        stripeMerchantId: stripeMerchantId,
        price: Math.round(price * 100),
        stripePaymentMethod: paymentId,
        currency,
        orderId,
        endTrial,
        subscriptionWithoutTrialPeriod,
        ecommerceSessionId,
        payload,
      })
    )

    const paymentIntentData = check(paymentIntentResult, 'getPaymentIntent')

    yield put(
      setClientSecret({ clientSecret: paymentIntentData.body.client_secret })
    )
  } catch (error) {
    let msg = ''
    switch (error.code) {
      case 'ERROR-MOONA-001':
        msg = 'Incorrect parameters'
        break
      case 'ERROR-STRIPE-001':
        msg = 'An error occurred with our connection to Stripe'
        break
      case 'ERROR-PAYMENT-INTENT-CREATION-002':
        msg = 'Price must be greater than or equal to 1.'
        break
      case 'ERROR-DB-001':
        msg = 'An error occurred in the database'
        break
      case 'ERROR-PAYMENT-FRAUD-001':
        msg = 'ERROR-PAYMENT-FRAUD-001'
        break
      case 'ERROR-PAYMENT-FRAUD-002':
        msg = 'ERROR-PAYMENT-FRAUD-002'
        break
      case 'ERROR-PAYMENT-FRAUD-003':
        msg = 'ERROR-PAYMENT-FRAUD-003'
        break
      case 'ERROR-PRESTASHOP-IPN':
        msg = 'ERROR-PRESTASHOP-IPN'
        break

      default:
        msg = 'ERROR'
        break
    }
    yield put(createPaymentIntentError({ error: msg }))
  }
}

function* mainPaymentIntent(action: CreateMainPaymentIntentRequest) {
  try {
    const {
      moonaId,
      stripeMerchantId,
      price,
      orderId,
      ecommerceSessionId,
      notifyUrl,
      ecommerce,
      cartId,
      deviceId,
      merchantId,
      email,
      stripePaymentMethod,
      discount,
      payload,
    } = action.payload
    const amount = Math.round(price * 100)
    const currency = yield select(
      (state: AppState) => state.query.params.currency
    )
    if (isPrestashop(ecommerce)) {
      yield call(checkCartId, notifyUrl, {
        email,
        amount: amount + 500,
        cart_id: cartId,
        merchant_id: merchantId,
        device_id: deviceId,
      })
    }
    const mainPaymentIntentResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getPaymentIntentMain, {
        userId: moonaId,
        stripeMerchantId: stripeMerchantId,
        price: amount,
        currency,
        stripePaymentMethod,
        orderId,
        ecommerceSessionId,
        discount,
        payload,
      })
    )

    const paymentIntentData = check(
      mainPaymentIntentResult,
      'getPaymentIntentMain'
    )

    yield put(
      setClientSecret({ clientSecret: paymentIntentData.body.client_secret })
    )
    yield put(setHasDiscount({ hasDiscount: discount }))
  } catch (error) {
    let msg = ''
    switch (error.code) {
      case 'ERROR-MOONA-001':
        msg = 'Incorrect parameters'
        break
      case 'ERROR-STRIPE-001':
        msg = 'An error occurred with our connection to Stripe'
        break
      case 'ERROR-PAYMENT-INTENT-MAIN-001':
        msg = 'The customer was not found.'
        break
      case 'ERROR-PAYMENT-INTENT-MAIN-002':
        msg = 'Error while creating the payment.'
        break
      case 'ERROR-DB-001':
        msg = 'An error occurred in the database'
        break
      case 'ERROR-PAYMENT-FRAUD-001':
        msg = 'ERROR-PAYMENT-FRAUD-001'
        break
      case 'ERROR-PAYMENT-FRAUD-002':
        msg = 'ERROR-PAYMENT-FRAUD-002'
        break
      case 'ERROR-PAYMENT-FRAUD-003':
        msg = 'ERROR-PAYMENT-FRAUD-003'
        break
      case 'ERROR-PRESTASHOP-IPN':
        msg = 'ERROR-PRESTASHOP-IPN'
        break

      default:
        msg = 'An error occurred. Please retry again.'
        break
    }
    yield put(createMainPaymentIntentError({ error: msg }))
  }
}

function* watchCreatePaymentIntent() {
  yield takeLatest(CREATE_PAYMENTINTENT_REQUEST, paymentIntent)
}

function* watchCreateMainPaymentIntent() {
  yield takeLatest(CREATE_MAIN_PAYMENTINTENT_REQUEST, mainPaymentIntent)
}

const paymentItentSagas = [
  fork(watchCreatePaymentIntent),
  fork(watchCreateMainPaymentIntent),
]

export default paymentItentSagas
