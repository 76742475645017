import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { StrictMode } from 'react'

import Amplify from 'aws-amplify'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ModalProvider } from 'styled-react-modal'

import ConnectedThemeProvider from 'common/components/ThemeProvider/ConnectedThemeProvider'
import { syncViewHeightCssVariable } from 'common/style/view-height'

import awsExports from './aws-exports'
import { GlobalStyle } from './common/style'
import { ModalBackground } from './common/style/components/modal'
import {
  initAmplitude,
  setAmplitudeUserProperties,
} from './common/utilities/amplitude'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import configureStore from './state'
import './i18n/config'
import './common/style/css/global-styles.css'

initAmplitude()
setAmplitudeUserProperties({ type: 'shopper' })
Amplify.configure(awsExports)

const store = configureStore()
syncViewHeightCssVariable()

ReactDOM.render(
  <StrictMode>
    <ModalProvider backgroundComponent={ModalBackground}>
      <Provider store={store}>
        <ConnectedThemeProvider>
          <GlobalStyle />
          <Routes />
        </ConnectedThemeProvider>
      </Provider>
    </ModalProvider>
  </StrictMode>,
  document.getElementById('root')
)
serviceWorker.unregister()
