import { AppActions } from '../types'

import {
  CREATE_MAIN_PAYMENTINTENT_ERROR,
  CREATE_MAIN_PAYMENTINTENT_FAILURE,
  CREATE_MAIN_PAYMENTINTENT_REQUEST,
  CREATE_MAIN_PAYMENTINTENT_SUCCESS,
  CREATE_PAYMENTINTENT_ERROR,
  CREATE_PAYMENTINTENT_FAILURE,
  CREATE_PAYMENTINTENT_REQUEST,
  CREATE_PAYMENTINTENT_SUCCESS,
  CreateMainPaymentIntentError,
  CreateMainPaymentIntentFailure,
  CreateMainPaymentIntentRequest,
  RESET_MAIN_PAYMENTINTENT,
  RESET_PAYMENTINTENT,
  SET_CLIENT_SECRET,
} from './types'

export const createPaymentIntentRequest = ({
  moonaId,
  stripeMerchantId,
  price,
  paymentId,
  orderId,
  ecommerceSessionId,
  notifyUrl,
  ecommerce,
  merchantId,
  deviceId,
  cartId,
  email,
  endTrial,
  subscriptionWithoutTrialPeriod,
  payload,
}: {
  moonaId: string
  stripeMerchantId: string
  price: number
  paymentId: string
  orderId: string
  ecommerceSessionId: string
  notifyUrl: string
  email: string
  ecommerce: string
  cartId?: string | null
  deviceId: string
  merchantId: string
  endTrial?: boolean
  subscriptionWithoutTrialPeriod?: boolean
  payload?: string | null
}): AppActions => ({
  type: CREATE_PAYMENTINTENT_REQUEST,
  payload: {
    moonaId,
    stripeMerchantId,
    price,
    paymentId,
    orderId,
    ecommerceSessionId,
    notifyUrl,
    ecommerce,
    merchantId,
    deviceId,
    cartId,
    email,
    endTrial,
    subscriptionWithoutTrialPeriod,
    payload,
  },
})

export const createMainPaymentIntentRequest = (
  payload: CreateMainPaymentIntentRequest['payload']
): AppActions => ({
  type: CREATE_MAIN_PAYMENTINTENT_REQUEST,
  payload,
})

export const createPaymentIntentSuccess = (): AppActions => ({
  type: CREATE_PAYMENTINTENT_SUCCESS,
})
export const createMainPaymentIntentSuccess = (): AppActions => ({
  type: CREATE_MAIN_PAYMENTINTENT_SUCCESS,
})
export const createPaymentIntentFailure = ({
  error,
}: {
  error: string
}): AppActions => ({
  type: CREATE_PAYMENTINTENT_FAILURE,
  payload: {
    error,
  },
})
export const createMainPaymentIntentFailure = (
  payload: CreateMainPaymentIntentFailure['payload']
): AppActions => ({
  type: CREATE_MAIN_PAYMENTINTENT_FAILURE,
  payload,
})
export const setClientSecret = ({
  clientSecret,
}: {
  clientSecret: string
}): AppActions => ({
  type: SET_CLIENT_SECRET,
  payload: {
    clientSecret,
  },
})

export const createPaymentIntentError = ({
  error,
}: {
  error: string
}): AppActions => ({
  type: CREATE_PAYMENTINTENT_ERROR,
  payload: {
    error,
  },
})

export const createMainPaymentIntentError = (
  payload: CreateMainPaymentIntentError['payload']
): AppActions => ({
  type: CREATE_MAIN_PAYMENTINTENT_ERROR,
  payload,
})
export const resetPaymentIntent = (): AppActions => ({
  type: RESET_PAYMENTINTENT,
})
export const resetMainPaymentIntent = (): AppActions => ({
  type: RESET_MAIN_PAYMENTINTENT,
})
