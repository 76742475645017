import React, { cloneElement, FC, ReactElement, useState } from 'react'

import { useDisclosure } from '@liinkiing/react-hooks'
import { WithName } from '@types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ArrowLeftIcon } from '../../../assets/illustrations'
import { isPrestashop } from '../../../utils/ecommerce'
import {
  isEmbeddedMode,
  notifyCancelEvent,
  notifyFinishProcessEvent,
} from '../../../utils/events'
import logo from '../../images/logo.svg'
import { ButtonWhiteLink, PrimaryButton } from '../../style/components/buttons'
import { Image, Props as ImageProps } from '../../style/components/image'
import { Loader } from '../../style/components/loader'
import { StyledModal } from '../../style/components/modal'
import { ONE_PAGER_DESKTOP_MAX_WIDTH } from '../../style/components/Section'
import { Spinner } from '../../style/components/spinner'
import { tablet } from '../../style/queries/media-queries'
import { sendAmplitudeData } from '../../utilities/amplitude'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import closeIcon from '../Cancel/close.svg'
import { AppBox, AppBoxProps } from '../Primitives/AppBox'
import Flex from '../Primitives/Flex'
import Text from '../Primitives/Text'
import { OverlayLink } from '../Typography/Links'

const Logo = styled(Image)`
  align-items: center;
  align-self: center;
  width: 7.5rem;
  height: 2rem;
  @media ${tablet} {
    width: 10rem;
    height: 4rem;
  }
`

const HeaderRight: ReturnType<typeof styled.div> & WithName = styled.div``
const RIGHT_PART_NAME = 'HeaderRight' as const
HeaderRight.__name = RIGHT_PART_NAME

const HeaderLeft: ReturnType<typeof styled.div> & WithName = styled.div``
const LEFT_PART_NAME = 'HeaderLeft' as const
HeaderLeft.__name = LEFT_PART_NAME

const HeaderContainer = styled(AppBox).attrs({ as: 'nav' })`
  ${HeaderLeft} {
    display: flex;
  }
  ${Logo} {
    display: flex;
  }
  ${HeaderRight} {
    display: flex;
  }
`

type SubComponents = {
  Left: typeof HeaderLeft
  Right: typeof HeaderRight
}

type Props = AppBoxProps & {
  logoProps?: Partial<ImageProps>
  alternateBackToShop?: boolean
  deleteCookieSlotProductCheckbox?: boolean
  showBreadcrumb?: boolean
  showGoToOrderConfirmation?: boolean
  showBackToShop?: boolean
  srcLogo?: string
  showLogo?: boolean
  updatePopinBackText?: boolean
}

const Modal = styled(StyledModal)`
  border: 0;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  align-items: flex-start;
  padding: 8rem 0;
  @media screen and (min-width: 300px) {
    padding: 8rem 4rem;
  }
  @media ${tablet} {
    border: 1px solid #e8e9ed;
    box-shadow: 0px 20px 58px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    max-width: 68rem;
    height: auto;
  }
`

export const CloseIcon = styled(Image).attrs({ src: closeIcon })`
  &:hover {
    cursor: pointer;
  }
`

const FullPriceButton = styled(ButtonWhiteLink)`
  text-decoration: none;
`

export const Header: FC<Props> & SubComponents = ({
  children,
  logoProps,
  alternateBackToShop,
  deleteCookieSlotProductCheckbox,
  showBreadcrumb: initialShowBreadcrumb = true,
  showBackToShop = true,
  showGoToOrderConfirmation = false,
  showLogo = true,
  srcLogo,
  updatePopinBackText = false,
  ...props
}) => {
  const [t] = useTranslation('app')
  const [isLoading, setIsLoading] = useState(false)
  const hasDiscount = useSelector(state => state.payment.hasDiscount)
  const {
    params: {
      mode,
      returnUrl,
      cancelUrl,
      ecommerce,
      merchantId,
      merchantStripeId,
      merchantSite,
      orderId,
      ecommerceSessionId,
      islogged,
    },
  } = useSelector(state => state.query)

  const isEmbedded = isEmbeddedMode(mode)
  const showBreadcrumb = isEmbedded ? false : initialShowBreadcrumb
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }

  const right = React.Children.toArray(children).find(
    (c: any) => c.type.__name === RIGHT_PART_NAME
  ) as ReactElement | undefined

  const left = React.Children.toArray(children).find(
    (c: any) => c.type.__name === LEFT_PART_NAME
  ) as ReactElement | undefined

  const backToShop = showBackToShop ? (
    <OverlayLink
      ml={alternateBackToShop ? 0 : '2rem'}
      style={
        alternateBackToShop
          ? { textDecoration: 'none', minWidth: '3.3rem', maxWidth: '3.3rem' }
          : undefined
      }
      key="back-to-shop"
      onClick={() => {
        onOpen()
        sendAmplitudeData('Click - Back to shop', {
          merchantID: merchantId,
          merchantStripeID: merchantStripeId,
          merchantSite: merchantSite,
          orderID: orderId,
          moonaSessionID: ecommerceSessionId,
          isLogged: islogged,
        })
      }}
    >
      {
        // 0435627a-f6e1-49d8-b00b-16aae26d16ae RDX UK IN PROD
        // b2b58fb1-514b-4632-b7fa-6e0419b23b70 RDX - courtemanche.ca CAD IN STAGING
        // 1db49449-7452-4bed-90a4-b81cf5a48f7f RDX CAD IN STAGING (acct_1KYxu12QUjvEjxbk)
        // 0435627a-f6e1-49d8-b00b-16aae26d16ae RDX CAD IN PROD (acct_1JS0ey2QcFVxMM97)
        merchantId === '0435627a-f6e1-49d8-b00b-16aae26d16ae' ||
        merchantId === 'b2b58fb1-514b-4632-b7fa-6e0419b23b70' ||
        merchantId === '1db49449-7452-4bed-90a4-b81cf5a48f7f' ||
        merchantId === '0435627a-f6e1-49d8-b00b-16aae26d16ae' ? (
          // <Text fontWeight={700} fontSize="1em">{t('global.backToRDX')}</Text>
          <Image width="1.9em" src={ArrowLeftIcon} />
        ) : alternateBackToShop ? (
          <Image width="1em" src={ArrowLeftIcon} />
        ) : (
          t('global.backToShop')
        )
      }
    </OverlayLink>
  ) : null
  const goToOrderConfirmation =
    isEmbedded && showGoToOrderConfirmation ? (
      <>
        {!isPrestashop(ecommerce) ? (
          <OverlayLink
            ml="2rem"
            key="go-to-confirmation-no-prestashop"
            onClick={() => {
              notifyFinishProcessEvent(
                returnUrl,
                mode,
                hasDiscount,
                '*',
                ecommerce
              )
            }}
          >
            {t('global.goToOrderConfirmation')}
          </OverlayLink>
        ) : returnUrl.includes('id_cart') ? (
          <OverlayLink
            ml="2rem"
            key="go-to-confirmation-prestashop"
            onClick={() => {
              notifyFinishProcessEvent(
                returnUrl,
                mode,
                hasDiscount,
                '*',
                ecommerce
              )
            }}
          >
            {t('global.goToOrderConfirmation')}
          </OverlayLink>
        ) : (
          <Loader key="loader-prestashop" />
        )}
      </>
    ) : null

  return (
    <HeaderContainer
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={[null, ONE_PAGER_DESKTOP_MAX_WIDTH]}
      px="2rem"
      mx="auto"
      height={
        !showBreadcrumb &&
        !showBackToShop &&
        !showGoToOrderConfirmation &&
        !showLogo &&
        !children
          ? '24px'
          : ['60px', '80px']
      }
      {...props}
    >
      {alternateBackToShop ? backToShop : null}
      {left ?? null}
      {showLogo ? (
        srcLogo ? (
          <Logo src={srcLogo} alt="Logo" {...logoProps} />
        ) : (
          <Logo src={logo} alt="Logo" {...logoProps} />
        )
      ) : null}
      {!right && showBreadcrumb ? (
        <Breadcrumb />
      ) : right ? (
        cloneElement(right, {
          children: Array.isArray(right.props.children)
            ? alternateBackToShop
              ? [...right.props.children, goToOrderConfirmation]
              : [...right.props.children, backToShop, goToOrderConfirmation]
            : alternateBackToShop
            ? [right.props.children, goToOrderConfirmation]
            : [right.props.children, backToShop, goToOrderConfirmation],
        })
      ) : alternateBackToShop ? (
        [goToOrderConfirmation]
      ) : (
        [backToShop, goToOrderConfirmation]
      )}
      {alternateBackToShop && !right ? (
        <div style={{ minWidth: '3.3rem', maxWidth: '3.3rem' }} />
      ) : null}

      <Modal
        isOpen={isOpen}
        onBackgroundClick={handleClose}
        onEscapeKeydown={handleClose}
      >
        <CloseIcon
          onClick={handleClose}
          position="absolute"
          top="2rem"
          right="2rem"
        />
        <Flex
          maxWidth="100%"
          direction="column"
          spacing="3rem"
          textAlign="center"
        >
          <Text fontWeight={700} fontSize="3.6rem">
            {updatePopinBackText
              ? t('modals.discountRdx.title')
              : t('modals.discount.title')}
          </Text>
          <Text color="colorgreymain" fontSize="1.6rem">
            {updatePopinBackText
              ? t('modals.discountRdx.subtitle')
              : t('modals.discount.subtitle')}
          </Text>
          <PrimaryButton
            width="100%"
            maxWidth="400px"
            disabled={isLoading}
            onClick={() => {
              sendAmplitudeData('Click - Back to shop - Valid', {
                merchantID: merchantId,
                merchantStripeID: merchantStripeId,
                merchantSite: merchantSite,
                orderID: orderId,
                moonaSessionID: ecommerceSessionId,
                isLogged: islogged,
              })
              if (
                process.env.REACT_APP_STAGING === 'true' &&
                ecommerce === 'FORM'
              ) {
                window.location.replace('/')
              } else {
                setIsLoading(true)
                if (isEmbeddedMode(mode)) {
                  notifyCancelEvent(mode, ecommerce, '*')
                } else {
                  let urlToReturnShopper = cancelUrl
                  if (deleteCookieSlotProductCheckbox) {
                    if (urlToReturnShopper.includes('?'))
                      urlToReturnShopper += '&slpcbx=0'
                    else urlToReturnShopper += '?slpcbx=0'
                  }
                  window.open(urlToReturnShopper, '_self')
                }
              }
            }}
          >
            {isLoading ? (
              <Spinner position="relative" />
            ) : updatePopinBackText ? (
              t('modals.discountRdx.cta')
            ) : (
              t('modals.discount.cta')
            )}
          </PrimaryButton>
          <FullPriceButton
            onClick={() => {
              sendAmplitudeData('Click - Back to shop - Cancel', {
                merchantID: merchantId,
                merchantStripeID: merchantStripeId,
                merchantSite: merchantSite,
                orderID: orderId,
                moonaSessionID: ecommerceSessionId,
                isLogged: islogged,
              })
              onClose()
            }}
            fontWeight={500}
            fontSize="1.4rem"
          >
            {updatePopinBackText
              ? t('modals.discountRdx.cancel')
              : t('modals.discount.cancel')}
          </FullPriceButton>
        </Flex>
      </Modal>
    </HeaderContainer>
  )
}

Header.displayName = 'Header'
Header.Left = HeaderLeft
Header.Right = HeaderRight

export default Header
