import styled from 'styled-components'

export const CollapsibleContent = styled('div')`
  padding: 17px;
  background: #fafafa;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &.without-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  input {
    background: #fff;
    border-radius: 5px;
  }
`
