import React, { ChangeEventHandler, FC } from 'react'

import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import MoonaIcon, { MoonaIconProps } from 'common/components/Icon/MoonaIcon'
import BorderedWrapper from 'common/components/Layout/BorderedWrapper'
import Flex from 'common/components/Primitives/Flex'
import Text from 'common/components/Primitives/Text'
import { Loader } from 'common/style/components/loader'
import {
  CheckoutRadioGroupWrapper,
  CheckoutRadioItem,
} from 'common/style/pages/checkout'
import { currencySelector } from 'state/features/checkoutSession/selector'
import { currencify } from 'utils/string'

export type CheckoutRadioOptionProps = {
  label: string
  details?: string
  privacy?: string
  value: string | number
  iconProps?: MoonaIconProps
  price?: number | string
}

type CheckoutRadioGroupProps = {
  name: string
  value: string
  options?: CheckoutRadioOptionProps[]
  onChange: ChangeEventHandler<HTMLInputElement>
  setFieldValue: (field: string, value: any) => void
  loading?: boolean
}

const BoxShippingloader = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
`

const CheckoutRadioGroup: FC<CheckoutRadioGroupProps> = ({
  name,
  value,
  options = [],
  onChange,
  setFieldValue,
  loading = null,
}) => {
  const {
    checkoutColors: { primary },
  } = useTheme()
  const currency = useSelector(currencySelector)
  return (
    <CheckoutRadioGroupWrapper role="group">
      {loading && (
        <BoxShippingloader>
          <Flex
            spacing="32px"
            align="center"
            direction="column"
            marginTop="12px"
          >
            <Loader key="loader-shopify" />
            <Text>Getting available shipping rates...</Text>
          </Flex>
        </BoxShippingloader>
      )}
      {loading ? null : options.length > 0 ? (
        options.map(option => (
          <CheckoutRadioItem
            key={option.value}
            className={`checkout-radio-option ${
              option.value === value ? 'active' : ''
            }`}
            onClick={() => setFieldValue(name, option.value)}
          >
            <input
              type="radio"
              value={option.value}
              name={name}
              onChange={onChange}
              checked={option.value === value}
            />
            {option.iconProps?.icon && (
              <MoonaIcon
                icon={option.iconProps.icon}
                fill={option.value === value ? primary : undefined}
              />
            )}
            <div style={{ flexGrow: 1 }}>
              <label dangerouslySetInnerHTML={{ __html: option.label }}></label>
              {option.details && (
                <p
                  className="text-details"
                  dangerouslySetInnerHTML={{ __html: option.details }}
                ></p>
              )}
              {option.privacy && (
                <p dangerouslySetInnerHTML={{ __html: option.privacy }}></p>
              )}
            </div>
            {option.price && (
              <p className="text-price">
                {typeof option.price === 'number'
                  ? currencify(option.price, currency)
                  : option.price}{' '}
              </p>
            )}
          </CheckoutRadioItem>
        ))
      ) : (
        <BorderedWrapper style={{ marginTop: 0 }}>
          <Text
            style={{
              fontSize: '14px',
              color: '#737373',
              padding: '0 12.5%',
              textAlign: 'center',
              lineHeight: '1.5',
            }}
          >
            No shipping rates configured for this shipping country. Thanks to
            contact the merchant to inform him.
          </Text>
        </BorderedWrapper>
      )}
    </CheckoutRadioGroupWrapper>
  )
}

export default CheckoutRadioGroup
