import axios from 'axios'

import {
  CleeverWebService,
  GetShopperInfoPayload,
  moonaApi,
} from 'common/hooks/useWebService'

const WebService: (
  secretKeyTest: string,
  secretKeyLive: string
) => Omit<CleeverWebService, 'getShippingSlotInfos'> = (secretKeyTest, secretKeyLive) => {

  // TODO : Update according to StoreSettings > testMode ou pas ?
  const headers = {
    'X-API-Key': (process.env.REACT_APP_BUILD_ENV === 'prod') ? secretKeyLive : secretKeyTest,
  }
  return {
    getShopperInfo: async (payload: GetShopperInfoPayload) => {
      const request = await axios.post(
        moonaApi + '/ecommerce/get_shopper_infos',
        payload,
        {
          headers,
        }
      )
      const data = Object.entries(request)[0][1]

      if (data.statusCode === 200) {
        return data
      } else {
        return {
          body: {},
          statusCode: 200,
        }
      }
    },
  }
}

export default WebService
