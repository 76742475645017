import React, { ChangeEvent, FC, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import { CheckoutSelectInput } from 'common/components/Form/Checkout'
import { UpdateCheckout } from 'state/features/checkout/action'

type AddressSelectorProps = {
  addresses: any[]
  addressValueSelect: string
  setAddressValueSelect: (addressValueSelect: string) => void
  billing?: boolean
  formik: any
}

const formatAdressForHtmlValue = (address: any) => {
  const {
    id,
    address1: addressName,
    address2: addressName2,
    city,
    country,
    zip,
    firstName,
    lastName,
    company,
  } = address

  // TODO: Format is add1, add2, city zipcode, country (firstname lastname, company)
  return {
    value: `${id}`,
    label: `${addressName ? `${addressName}, ` : ''}${
      addressName2 ? `${addressName2}, ` : ''
    }${city ? `${city} ` : ''} ${zip ? `${zip}, ` : ''}${
      country ? `${country} ` : ''
    }(${firstName ? `${firstName} ` : ''}${lastName ? `${lastName}` : ''}${
      company ? `, ${company}` : ''
    })`,
  }
}

const AddressSelector: FC<AddressSelectorProps> = ({
  addresses,
  addressValueSelect,
  setAddressValueSelect,
  billing = false,
  formik,
}) => {
  const dispatch = useDispatch()
  const options = useMemo(() => {
    return [
      ...addresses.map(address => {
        return formatAdressForHtmlValue(address)
      }),
      {
        value: 'new',
        label: 'Use new address',
      },
    ]
  }, [addresses])

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    const emptyAddress = {
      [`id${billing ? 'Billing' : ''}`]: '',
      [`address1${billing ? 'Billing' : ''}`]: '',
      [`address2${billing ? 'Billing' : ''}`]: '',
      [`city${billing ? 'Billing' : ''}`]: '',
      [`company${billing ? 'Billing' : ''}`]: '',
      [`country${billing ? 'Billing' : ''}`]: '',
      [`countryCode${billing ? 'Billing' : ''}`]: '',
      [`countryName${billing ? 'Billing' : ''}`]: '',
      [`customerId${billing ? 'Billing' : ''}`]: '',
      [`firstName${billing ? 'Billing' : ''}`]: '',
      [`lastName${billing ? 'Billing' : ''}`]: '',
      [`name${billing ? 'Billing' : ''}`]: '',
      [`phone${billing ? 'Billing' : ''}`]: '',
      [`province${billing ? 'Billing' : ''}`]: '',
      [`provinceCode${billing ? 'Billing' : ''}`]: '',
      [`zip${billing ? 'Billing' : ''}`]: '',
    }
    if (value === 'new') {
      dispatch(UpdateCheckout(emptyAddress))
      formik.setValues({
        ...formik.values,
        ...emptyAddress,
      })
    } else {
      const selectedAddress = addresses.find(address => address.id === value)

      if (selectedAddress) {
        const address = {
          [`id${billing ? 'Billing' : ''}`]: selectedAddress.id,
          [`address1${billing ? 'Billing' : ''}`]: selectedAddress.address1,
          [`address2${billing ? 'Billing' : ''}`]: selectedAddress.address2,
          [`city${billing ? 'Billing' : ''}`]: selectedAddress.city,
          [`company${billing ? 'Billing' : ''}`]: selectedAddress.company,
          [`country${billing ? 'Billing' : ''}`]: selectedAddress.country,
          [`countryCode${
            billing ? 'Billing' : ''
          }`]: selectedAddress.countryCode,
          [`countryName${
            billing ? 'Billing' : ''
          }`]: selectedAddress.countryName,
          [`customerId${billing ? 'Billing' : ''}`]: selectedAddress.customerId,
          [`firstName${billing ? 'Billing' : ''}`]: selectedAddress.firstName,
          [`lastName${billing ? 'Billing' : ''}`]: selectedAddress.lastName,
          [`name${billing ? 'Billing' : ''}`]: selectedAddress.name,
          [`phone${billing ? 'Billing' : ''}`]: selectedAddress.phone,
          [`province${billing ? 'Billing' : ''}`]: selectedAddress.province,
          [`provinceCode${
            billing ? 'Billing' : ''
          }`]: selectedAddress.provinceCode,
          [`zip${billing ? 'Billing' : ''}`]: selectedAddress.zip,
        }
        dispatch(UpdateCheckout(address))
        formik.setValues({
          ...formik.values,
          ...address,
        })
      } else {
        dispatch(UpdateCheckout(emptyAddress))
        formik.setValues({
          ...formik.values,
          ...emptyAddress,
        })
      }
    }

    setAddressValueSelect(value)
  }

  return (
    <CheckoutSelectInput
      useFormik={false}
      label="Saved adresses"
      onChange={handleChange}
      options={options}
      value={
        addressValueSelect ||
        (addresses.length > 0
          ? addresses.find(address => address.default).id.toString()
          : 'new')
      }
      style={{
        background: billing ? '#fff' : 'none',
      }}
    />
  )
}

export default AddressSelector
