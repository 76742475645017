import { CheckoutSessionState } from './types'

const initialCustomer = {
  id: null,
  firstName: null,
  lastName: null,
  acceptsMarketing: null,
  email: null,
  phone: null,
  addresses: [],

  urlPhoto: null,
}

const initialCart = {
  id: null,
  notes: null,
  attributes: {
    slotProductCheckbox: null,
  },
  itemCount: null,
  requiresShipping: null,
  currency: null,
  items: [],

  currencySymbol: null,
  urlPhoto: null,
}

const initialStore = {
  country: null,
  currency: null,
  language: null,
  myShopifyDomain: null,
  customizer: null,
  appMode: null,
  role: null,
  logoutUrl: null,
  loginUrl: null,
  cancelUrl: null,
  cartUrl: null,

  // VERIF
  merchantId: null,
  stripeMerchantId: null,
  ecommerceSolution: null,
  ecommerceVersion: null,
  website: null,
  apiVersion: null,
  pluginVersion: null,
  notificationUrl: null,
  returnUrl: null,
}

export const initialCheckoutSessionState: CheckoutSessionState = {
  id: null,
  myShopifyDomain: null,
  customer: initialCustomer,
  cart: initialCart,
  store: initialStore,
  checkoutShopifyId: null,
  order: {
    id: null,
  },
  createdAt: null,
  updatedAt: null,
}
