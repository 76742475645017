import React, { ReactNode, CSSProperties } from 'react'

import Skeleton from 'react-loading-skeleton'

import { MarginContainer } from 'common/style/components/Section'

interface MoonaSkeletonProps {}

interface BaseSkeletonProps {
  mx?: string
  my?: string
  mt?: string
  mb?: string
  center?: boolean
  selfcenter?: boolean
  count?: number
  duration?: number
  width?: string | number
  wrapper?: ReactNode
  height?: string | number
  circle?: boolean
  style?: CSSProperties
  className?: string
}
export const BaseSkeleton: React.FC<BaseSkeletonProps> = ({
  mx,
  my,
  mt,
  mb,
  selfcenter = false,
  center = false,
  ...props
}) => (
  <MarginContainer
    mx={mx}
    my={my}
    mt={mt}
    mb={mb}
    center={center}
    selfcenter={selfcenter}
  >
    <Skeleton {...props} />
  </MarginContainer>
)
