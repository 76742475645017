import React, { FC } from 'react'

import { FormikValues } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { CheckoutCheckbox } from 'common/components/Form/Checkout'
import BorderedWrapper from 'common/components/Layout/BorderedWrapper'
import { H3 } from 'common/style/components/Typography'
import { CheckoutFormGroup } from 'common/style/pages/checkout'
import {
  UpdateCheckout,
  UpdateGetDiscount,
} from 'state/features/checkout/action'
import { CheckoutState } from 'state/features/checkout/types'
import { ClearSlotProductCheckbox } from 'state/features/checkoutSession/actions'
import { summarySelector } from 'state/features/checkoutShopify/selector'
import { CheckoutShopifyState } from 'state/features/checkoutShopify/types'
import { ShopperState } from 'state/features/shopper/types'
import { currencify } from 'utils/string'

type DiscountBlocProps = {
  shopper: ShopperState
  checkoutState: CheckoutState
  checkoutShopify: CheckoutShopifyState
  currency: string
  formik: FormikValues
  slotProductCheckbox: string
  hasDiscountSubscription: boolean
}

const DiscountBloc: FC<DiscountBlocProps> = ({
  checkoutState,
  checkoutShopify,
  currency,
  formik,
  slotProductCheckbox,
  hasDiscountSubscription,
}) => {
  const dispatch = useDispatch()
  const { getDiscount } = useSelector((state: any) => state.checkout)
  const { total } = useSelector(summarySelector)

  const handleDiscountCheckbox = e => {
    const { checked } = e.target

    if (checked) {
      formik.setValues({
        ...formik.values,
        paymentMethod: 'MP1',
        getDiscount: true,
      })
      dispatch(UpdateCheckout({ paymentMethod: 'MP1', getDiscount: true }))
    } else {
      formik.setFieldValue('getDiscount', false)
      dispatch(UpdateGetDiscount(false))
    }

    // If the user unchecks the checkbox manually and slotProductCheckbox amount exist, we clear the slotProductCheckbox
    if (slotProductCheckbox && !checked) {
      dispatch(ClearSlotProductCheckbox())
    }
  }

  // TODO : En dur => mettre dans une variable
  if (getDiscount && parseFloat(total) < 10) {
    dispatch(UpdateGetDiscount(false))
    return null
  }

  return (
    !hasDiscountSubscription && (
      <CheckoutFormGroup>
        <H3>Get a discount now!</H3>
        <BorderedWrapper
          style={{
            margin: 0,
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
            gap: 0,
          }}
        >
          <p style={{ fontSize: '14px', marginBottom: '10px' }}>
            Your order is eligible to a{' '}
            {currencify(checkoutState.cleeverDiscount.discount / 100, currency)}{' '}
            discount!
          </p>
          <CheckoutCheckbox
            name="getDiscount"
            label={
              checkoutState.cleeverDiscount[
                formik.values.getDiscount ? 'titleChecked' : 'title'
              ]
            }
            onChange={handleDiscountCheckbox}
            checked={formik.values.getDiscount}
          />
          {formik.values.getDiscount && (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: checkoutState.cleeverDiscount.description,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: checkoutState.cleeverDiscount.privacy,
                }}
              />
            </>
          )}
        </BorderedWrapper>
      </CheckoutFormGroup>
    )
  )
}

export default DiscountBloc
