import React, { useState } from 'react'

import { CardCvcElement } from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardCvcElementOptions,
} from '@stripe/stripe-js'

import MoonaIcon from 'common/components/Icon/MoonaIcon'
import { CheckoutInputStyle, ErrorWrapper } from 'common/style/pages/checkout'

const CardCvcInput = ({ error, setFieldError }) => {
  const [focus, setFocus] = useState<boolean>(false)
  const [empty, setEmpty] = useState<boolean>(true)
  const cardCvcElementOptions: StripeCardCvcElementOptions = {
    placeholder: '',
  }
  const onChange = (event: StripeCardCvcElementChangeEvent): void => {
    if (empty !== event.empty) {
      setEmpty(event.empty)
    }
    if (event.complete) {
      const cardholderName = document.querySelector<HTMLInputElement>(
        'input[name="cardName"]'
      )
      if (cardholderName) {
        cardholderName.focus()
      }
    }
    if (event.error) {
      setFieldError('cardCvc', event.error.message)
    } else {
      setFieldError('cardCvc', null)
    }
  }
  return (
    <ErrorWrapper className={error ? 'error' : ''}>
      <CheckoutInputStyle
        className={`checkout-input ${
          focus ? 'label-active' : empty ? '' : 'label-active'
        }`}
      >
        <label>Security Code</label>
        <CardCvcElement
          className="input"
          onChange={onChange}
          options={cardCvcElementOptions}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <MoonaIcon icon="QuestionFill" />
      </CheckoutInputStyle>
      {error && <p className="text-error">{error}</p>}
    </ErrorWrapper>
  )
}

export default CardCvcInput
