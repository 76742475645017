import React, { FC, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { CheckoutCheckbox } from 'common/components/Form/Checkout'
import Flex from 'common/components/Primitives/Flex'
import InformationSkeleton from 'common/components/Skeleton/Checkout/InformationSkeleton'
import { CheckoutLink } from 'common/style/components/links'
import { H3Shopify } from 'common/style/components/Typography'
import { CheckoutFormGroup } from 'common/style/pages/checkout'
import { StepComponentProps } from 'pages/Checkout/CheckoutFlow'
import apiInstance from 'services/api'
import {
  checkoutEmailUpdateV2,
  checkoutShippingAddressUpdateV2,
} from 'shopify/mutations'
import { AppState } from 'state/features/types'

import BottomForm from '../BottomForm'
import formikInformations from '../formikObject/information'

import AdressForm from './AddressForm'
import CustomerBloc from './CustomerBloc'
import ExpressCheckout from './ExpressCheckout'
import { handleSubmitInformationsStep } from './handleSubmit'
import { scrollToTop } from 'utils/scroll'
import { sendAmplitudeData } from 'common/utilities/amplitude'

const InformationStep: FC<StepComponentProps> = ({
  step,
  steps,
  handleNextStep,
  handlePreviousStep,
  isFirstStep,
  isLastStep,
  loading,
}) => {
  const dispatch = useDispatch()
  const globalState = useSelector((state: AppState) => state)
  const {
    checkout: checkoutState,
    checkoutSession,
    storeSettings: { emailMarketing, merchantId, myShopifyDomain },
    checkoutShopify,
  } = globalState
  const [mode, setMode] = useState<'default' | 'guest' | 'connected'>('guest')

  const [t] = useTranslation('app')
  const informationLabels: any = t('checkout.form.information', {
    returnObjects: true,
  })

  const formik = useFormik(
    formikInformations(
      checkoutState,
      (values, setFieldError, setSubmitting) => {
        handleSubmitInformationsStep({
          checkoutId: checkoutShopify.id,
          values,
          successCallback: () => {
            sendAmplitudeData(
              'Click - Shopify Chkt - Shipping details - Next',
              {
                merchantID: merchantId,
                merchantSite: myShopifyDomain,
                orderID: checkoutSession?.order?.id,
                currency: checkoutSession.store.currency,
                orderTotalValue: checkoutShopify.paymentDue.amount,
                checkoutSessionID: checkoutSession.id,
                slotProductCheckbox:
                  !!checkoutSession.cart.attributes?.slotProductCheckbox,
                isLogged: !!checkoutSession.customer,
              }
            )

            setSubmitting(false)
            handleNextStep()
          },
          dispatch,
          setFieldError,
          setSubmitting,
          globalState,
        })
      }
    )
  )

  const saveFormAndRedirect = async () => {
    const shippingAddressUpdateResult = await apiInstance.post('', {
      query: checkoutShippingAddressUpdateV2,
      variables: {
        checkoutId: checkoutShopify.id,
        shippingAddress: {
          address1: formik.values.address1,
          city: formik.values.city,
          company: formik.values.company,
          country: formik.values.country,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          phone: formik.values.phone,
          province: formik.values.province,
          zip: formik.values.zip,
        },
        country: checkoutShopify.shippingAddress?.countryCodeV2 || 'GB',
      },
    })
    if (shippingAddressUpdateResult) {
      const emailUpdateResult = await apiInstance.post('', {
        query: checkoutEmailUpdateV2,
        variables: {
          checkoutId: checkoutShopify.id,
          email: formik.values.email,
          country: checkoutShopify.shippingAddress?.countryCodeV2 || 'GB',
        },
      })

      if (
        emailUpdateResult &&
        emailUpdateResult?.data?.data?.checkoutEmailUpdateV2?.checkout?.webUrl
      ) {
        window.location.href =
          emailUpdateResult?.data?.data?.checkoutEmailUpdateV2?.checkout?.webUrl
      }
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [loading])

  useEffect(() => {
    if (checkoutState.saveForm) {
      saveFormAndRedirect()
    } else {
      formik.setValues({
        ...formik.values,
        ...checkoutState,
      })
    }
  }, [checkoutState])

  useEffect(() => {
    formik.setFieldValue(
      'requiresShipping',
      checkoutSession.cart.requiresShipping
    )
  }, [checkoutSession.cart.requiresShipping])

  // TMP
  const isLovelox =
    merchantId === 'ea32032d-cd32-44dc-882b-77c28bd99db9' ||
    merchantId === 'd75fac0d-62af-4d39-bc46-9ff5ef56a282'

  return loading ? (
    <>
      <InformationSkeleton />
    </>
  ) : (
    <>
      <ExpressCheckout />
      <form id="information-form" onSubmit={formik.handleSubmit}>
        {/* Hidden values */}
        <input
          type="hidden"
          name="country"
          value={formik.values.country ?? ''}
        />
        <input
          type="hidden"
          name="province"
          value={formik.values.province ?? ''}
        />
        <CheckoutFormGroup>
          <Flex align="end" justify="space-between" sx={{ marginTop: '2rem' }}>
            <H3Shopify>Contact Information</H3Shopify>
            {!isLovelox && !checkoutSession.customer?.id && (
              <p
                style={{
                  fontSize: '1.4rem',
                  lineHeight: '2.1rem',
                  color: '#545454',
                }}
              >
                Already have an account?{' '}
                <CheckoutLink
                  href={checkoutSession.store.loginUrl}
                  style={{ fontSize: '1.4rem !important' }}
                >
                  Log in
                </CheckoutLink>
              </p>
            )}
          </Flex>
          <CustomerBloc
            formik={formik}
            labels={informationLabels}
            mode={mode}
            setMode={setMode}
          />
          {emailMarketing && (
            <CheckoutCheckbox
              name="emailMarketingConsent"
              label={informationLabels.emailMarketingConsent.label}
              onChange={formik.handleChange}
              checked={formik.values.emailMarketingConsent}
            />
          )}
        </CheckoutFormGroup>
        <CheckoutFormGroup>
          <H3Shopify>
            {checkoutSession.cart.requiresShipping ? 'Shipping' : 'Billing'}{' '}
            Address
          </H3Shopify>
          <AdressForm
            formik={formik}
            billing={!checkoutSession.cart.requiresShipping}
            isFirstStep={isFirstStep}
          />
        </CheckoutFormGroup>
        <BottomForm
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          handlePreviousStep={handlePreviousStep}
          backTitle={!isFirstStep && steps[step.id - 1].title}
          nextTitle={!isLastStep && steps[step.id + 1].title}
          formik={formik}
        />
      </form>
    </>
  )
}

export default InformationStep
