import { initialCheckoutState } from './initialStates'
import {
  CheckoutActionTypes,
  CheckoutState,
  START_REDIRECT_LOADING,
  START_SAVE_FORM,
  UPDATE_CHECKOUT,
  UPDATE_GET_DISCOUNT,
} from './types'

const CheckoutReducer = (
  state = initialCheckoutState,
  action: CheckoutActionTypes
): CheckoutState => {
  switch (action.type) {
    case UPDATE_CHECKOUT:
      return { ...state, ...action.payload }
    case UPDATE_GET_DISCOUNT:
      return { ...state, getDiscount: action.payload }
    case START_SAVE_FORM:
      return { ...state, saveForm: true }
    case START_REDIRECT_LOADING:
      return { ...state, redirectLoading: true }
    default:
      return state
  }
}

export default CheckoutReducer
