import { API, graphqlOperation } from 'aws-amplify'
import { takeLatest, call, fork, put } from 'redux-saga/effects'

import { getEcommerceSession } from 'graphql/queries'

import { setAmplitudeUserDevice } from '../../../common/utilities/amplitude'
import {
  setHasSubscription,
  setHasSubscriptionDiscountPostPurchase,
  setHasSubscriptionMBG,
  setHasSubscriptionYCoupon,
  setMoonaId,
  setOnlyEmail,
  setStripeId,
} from '../users/actions'
import { getUserAndEcommerceSessionById } from '../users/api'

import {
  setEcomSession,
  setEcomSessionFailed,
  startMainSessionLoading,
  stopMainSessionLoading,
} from './actions'
import {
  EcomSession,
  LoadEcomSessionAction,
  LOAD_ECOM_SESSION,
  LoadMainEcomSessionAction,
  LOAD_MAIN_ECOM_SESSION,
} from './types'

function* loadUserSessionAction(action: LoadEcomSessionAction) {
  const sessionid = action.payload.id
  try {
    const data = yield call(
      [API, 'graphql'],
      graphqlOperation(getEcommerceSession, { id: sessionid })
    )

    const ecomSession: EcomSession = data.data.getEcommerceSession
    if (ecomSession.deviceId && ecomSession.deviceId !== '') {
      setAmplitudeUserDevice(ecomSession.deviceId)
    }
    yield put(setEcomSession(ecomSession))
  } catch (e) {
    yield put(setEcomSessionFailed(true))
  }
}
function* loadMainEcomSessionAction(action: LoadMainEcomSessionAction) {
  const sessionid = action.payload.id
  try {
    yield put(startMainSessionLoading())
    const info = yield getUserAndEcommerceSessionById(sessionid)
    const ecomSession: EcomSession = info.ecommercesession
    const user = info.user

    if (ecomSession.deviceId && ecomSession.deviceId !== '') {
      setAmplitudeUserDevice(ecomSession.deviceId)
    }

    yield put(setEcomSession(ecomSession))

    if (user) {
      yield put(setOnlyEmail({ email: user.email }))
      if ('id' in user) {
        yield put(setMoonaId({ moonaId: user.id }))
      }
      if ('stripeId' in user) {
        yield put(setStripeId({ stripeId: user.stripeId }))
      }
      if ('hasSubscription' in user) {
        yield put(setHasSubscription({ hasSubscription: user.hasSubscription }))
      }
      if ('hasSubscriptionYCoupon' in user) {
        yield put(
          setHasSubscriptionYCoupon({
            hasSubscriptionYCoupon: user.hasSubscriptionYCoupon,
          })
        )
      }
      if ('hasSubscriptionMBG' in user) {
        yield put(
          setHasSubscriptionMBG({ hasSubscriptionMBG: user.hasSubscriptionMBG })
        )
      }
      if ('hasSubscriptionDiscountPostPurchase' in user) {
        yield put(
          setHasSubscriptionDiscountPostPurchase({ hasSubscriptionDiscountPostPurchase: user.hasSubscriptionDiscountPostPurchase })
        )
      }
    }

    yield put(stopMainSessionLoading())
  } catch (e) {
    yield put(setEcomSessionFailed(true))
  }
}

function* watchLoadUserSessionRequest() {
  yield takeLatest(LOAD_ECOM_SESSION, loadUserSessionAction)
}
function* watchLoadMainEcomSessionRequest() {
  yield takeLatest(LOAD_MAIN_ECOM_SESSION, loadMainEcomSessionAction)
}

const querySagas = [
  fork(watchLoadUserSessionRequest),
  fork(watchLoadMainEcomSessionRequest),
]
export default querySagas
