import React from 'react'

const Tag = props => {
  return (
    <svg
      viewBox="0 0 16 16"
      className="_1fragem1a _1fragem3g _1fragembx _1fragembw"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M15.1 2.3L13.7.9 8 6.6 2.3.9.9 2.3 6.6 8 .9 13.7l1.4 1.4L8 9.4l5.7 5.7 1.4-1.4L9.4 8"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default Tag
