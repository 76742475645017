import axios from 'axios'
import lodash from 'lodash'

import { moonaApi } from 'common/hooks/useWebService'
import { CheckoutState } from 'state/features/checkout/types'
import { CheckoutShopifyState } from 'state/features/checkoutShopify/types'
import { ShopifyPayloadCreateOrder } from 'state/features/query/types'
import { ShopperState } from 'state/features/shopper/types'
import { StoreSettingsState } from 'state/features/storeSettings/types'

import {
  createCleeverAdditionalNotesData,
  createCleeverMetafieldsData,
  createCleeverNotesData,
  createStringifyPayloadForECommerceSession,
} from './creationPayloadUtils'
import { parsePhoneNumber } from 'libphonenumber-js/min'

type CreateOrderOptions = {
  checkoutSessionId: string
  checkoutShopify: CheckoutShopifyState
  checkoutState: CheckoutState
  shopper: ShopperState
  summary: any
  currency: string
  storeSettings: StoreSettingsState
}

type EcommerceSessionOptions = {
  integratedPayload: any
  paymentMethodId: string
  mode: string
} & CreateOrderOptions

export const createOrderService = async (options: CreateOrderOptions) => {
  const payloadCreateOrderRequest = createPayloadForCreateOrder(options)

  try {
    // console.log('WAITING');
    // await new Promise(resolve => setTimeout(resolve, 30000));
    // console.log('END WAIT');
    const requestCreateOrder = await axios.post(
      `${options.storeSettings.serverShopifyUrl}/create`,
      payloadCreateOrderRequest,
      {}
    )
    const payloadCreateOrderResponse = Object.entries(requestCreateOrder)[0][1]

    return payloadCreateOrderResponse.order
  } catch (error) {
    console.log('Error during order creation')
    // throw new Error('Error during order creation')
    return null
  }
}

export const createEcommerceSessionService = async (
  options: EcommerceSessionOptions
) => {
  try {
    const payload = createPayloadForEcommerceSession(options)

    if (options.summary.ceilingError) {
      payload.amount += 1
    }

    // TMP : Digital product => country vide / default value
    if (!payload.country || payload.country === '') {
      console.log('Default country value given')
      payload.country = 'GB'
    }

    // TODO : Update according to StoreSettings > testMode ou pas ?
    const urlRequest = await axios.post(moonaApi, payload, {
      headers: {
        'X-API-Key':
          process.env.REACT_APP_BUILD_ENV === 'prod'
            ? options.storeSettings.secretKeyLive
            : options.storeSettings.secretKeyTest,
      },
    })
    const payloadUrlRequest = Object.entries(urlRequest)[0][1]

    return payloadUrlRequest
  } catch (error) {
    throw new Error('Error during ecommerce session creation')
  }
}

function buildPayloadBillingAddress(billingAddress: CheckoutState) {
  return {
    address1: billingAddress.address1Billing, // mandatory
    address2:
      billingAddress.address2Billing !== ''
        ? billingAddress.address2Billing
        : null,
    city: billingAddress.cityBilling, // mandatory
    // TODO: Add when we will add company input
    company: null, // null if empty
    country:
      billingAddress.countryBilling !== ''
        ? billingAddress.countryBilling
        : null, // mandatory - 'United Kingdom'
    countryCodeV2:
      billingAddress.countryCodeBilling !== ''
        ? billingAddress.countryCodeBilling
        : null, // 'GB'
    firstName: billingAddress.firstNameBilling, // mandatory
    lastName: billingAddress.lastNameBilling, // mandatory
    phone: billingAddress.phoneBilling,
    province:
      billingAddress.provinceBilling !== ''
        ? billingAddress.provinceBilling
        : null, // null if empty - 'England'
    provinceCode:
      billingAddress.provinceCodeBilling !== ''
        ? billingAddress.provinceCodeBilling
        : null, // null if empty - 'ENG'
    zip: billingAddress.zipBilling, // mandatory
  }
}

const createPayloadForEcommerceSession = (options: EcommerceSessionOptions) => {
  const {
    integratedPayload,
    paymentMethodId,
    mode,
    checkoutSessionId,
    checkoutShopify,
    checkoutState,
    shopper,
    summary,
    currency,
    storeSettings,
  } = options

  const stringifyPayload = createStringifyPayloadForECommerceSession({
    isChecked:
      checkoutState.getDiscount || checkoutState.paymentMethod === 'MP2',
    paymentMethodId,
    checkoutState,
    checkoutShopify,
    storeSettings,
    shopper,
    summary,
    currency,
  })

  const payload = {
    ...integratedPayload,
    ...(checkoutState.billingAddressMethod !== 'same' && {
      address:
        `${checkoutState.address1Billing} ${checkoutState.address2Billing}`.trim(),
      city: checkoutState.cityBilling ?? checkoutState.city,
      country: checkoutState.countryCodeBilling ?? checkoutState.countryCode,
      postcode: checkoutState.zipBilling ?? checkoutState.zip,
      firstname: checkoutState.firstNameBilling ?? checkoutState.firstName,
      lastname: checkoutState.lastNameBilling ?? checkoutState.lastName,
      phone: checkoutState.phoneBilling ?? checkoutState.phone + 'merguez',
    }),
    mode,
    payload: stringifyPayload,
    // Usecase MShip => remove reference shipping price and add calculated new discounted shipping price
    amount: Math.round(
      (parseFloat(checkoutShopify.totalPrice.amount) -
        parseFloat(checkoutShopify.shippingLine?.price?.amount ?? '0') +
        parseFloat(summary?.shipping ?? '0')) *
        100
    ),
  }
  const parsedPhone = parsePhoneNumber(payload.phone, payload.country)

  if (parsedPhone) {
    console.log('parsedPhone', parsedPhone)
    payload.phone = parsedPhone.number
  }

  const freeShippingDiscount = checkoutShopify.discountApplications?.nodes.find(
    item => item.value.percentage === 100 && item.targetType === 'SHIPPING_LINE'
  )

  if (freeShippingDiscount) {
    payload.amount = Math.round(
      parseFloat(checkoutShopify.totalPrice.amount) * 100
    )
  }

  if (paymentMethodId) {
    payload.payment_method = paymentMethodId
  }

  payload.shopify_payload_create_order = createPayloadForCreateOrder({
    checkoutSessionId,
    checkoutShopify,
    checkoutState,
    shopper,
    summary,
    currency,
    storeSettings,
  })

  if (
    checkoutState.billingAddressMethod !== 'same' ||
    checkoutState.requiresShipping === false
  ) {
    payload.shopify_payload_create_order.billingAddress =
      buildPayloadBillingAddress(checkoutState)
  }

  return payload
}

const createPayloadForCreateOrder = (
  options: CreateOrderOptions
): ShopifyPayloadCreateOrder => {
  const {
    checkoutSessionId,
    checkoutShopify,
    checkoutState,
    storeSettings,
    summary,
    currency,
  } = options

  const payloadData: ShopifyPayloadCreateOrder = {
    session: checkoutSessionId,
    checkout: {
      ...checkoutShopify,
      customAttributes: checkoutShopify.customAttributes.filter(
        attr =>
          attr.key !== 'cleever_slot_product_checkbox' &&
          attr.key !== 'cleever_slot_discount_above_limit_midpoint' &&
          attr.key !== 'cleever_slot_discount_below_limit_midpoint' &&
          attr.key !== 'cleever_slot_discount_min_amount'
      ),
    },
  }

  if (summary.ceilingError) {
    payloadData.totalPriceCeilDecimal = parseFloat(summary.paymentDue).toFixed(
      2
    )
  }

  // Don't send emailMarketingConsent if it's not checked
  if (checkoutState.emailMarketingConsent === true) {
    payloadData.emailMarketingConsent = true
  }

  if (checkoutState.smsMarketingConsent === true) {
    payloadData.smsMarketingPhone = checkoutState.smsMarketingPhone
    payloadData.smsMarketingConsent = checkoutState.smsMarketingConsent
  }

  const isCleeverShipping = checkoutState.shippingMethod.includes('Cleever-')

  // replace shipping by cleever shipping if it's cleeverShipping selected
  if (isCleeverShipping) {
    // TODO : Attention => payloadData.checkout est une référence à checkoutShopify (virer cette référence later)
    const shippingLineBaseAmount =
      (checkoutState.shippingSlot?.shippingPriceReference ?? 0) / 100
    const newShippingPriceWithDiscount = parseFloat(summary.shipping)
    payloadData.checkout.totalPrice.amount = (
      parseFloat(checkoutShopify.totalPrice.amount) -
      shippingLineBaseAmount +
      newShippingPriceWithDiscount
    ).toFixed(2)
    payloadData.checkout.paymentDue.amount = (
      parseFloat(checkoutShopify.paymentDue.amount) -
      shippingLineBaseAmount +
      newShippingPriceWithDiscount
    ).toFixed(2)
    payloadData.checkout.shippingLine.title = `${checkoutState.shippingSlot.shippingMethodTitle} - via funded shipping premium service`
    payloadData.checkout.shippingLine.price.amount = summary.shipping
  }

  // Init if shipping != billing OR checkoutState.requiresShipping == false for digital product
  if (
    checkoutState.billingAddressMethod !== 'same' ||
    checkoutState.requiresShipping === false
  ) {
    payloadData.billingAddress = buildPayloadBillingAddress(checkoutState)
  }

  const isHexxee = storeSettings.merchantId === '98ec6166-9b60-4664-8b7a-0fb1f12efc30' ||
                    storeSettings.merchantId === '2c72fc30-0a19-4b46-85a4-559c37172632'

  if (
    (checkoutState.shippingSlot ||
    checkoutState.getDiscount ||
    checkoutState.paymentMethod === 'MP2') &&
    !isHexxee
  ) {
    payloadData.cleeverNotes = createCleeverNotesData(
      checkoutState,
      checkoutShopify,
      currency,
      summary
    )
    payloadData.cleeverAdditionalNotes =
      createCleeverAdditionalNotesData(checkoutState)
  }

  const cleeverMetafields = createCleeverMetafieldsData({
    checkoutState,
    storeSettings,
  })

  payloadData.cleeverMetafields = lodash.mapKeys(
    cleeverMetafields,
    (value, key) => lodash.camelCase(key)
  )

  return payloadData
}
