import { API, Auth, graphqlOperation } from 'aws-amplify'
import { call, put, takeEvery, fork } from 'redux-saga/effects'

import { getStripeCards } from 'graphql/queries'

import {
  clearAmplitudeUserId,
  setAmplitudeUserId,
} from '../../../common/utilities/amplitude'
import {
  loadUser,
  clearUser,
  setUserMailAndId,
  setHasSubscription,
  setHasSubscriptionMBG,
  setHasSubscriptionYCoupon,
  setHasSubscriptionDiscountPostPurchase,
} from '../users/actions'
import { getUserInfosByEmail } from '../users/api'

import {
  loggedIn,
  loggableSuccess,
  loginFail,
  createForgotPasswordSuccess,
  createForgotPasswordFailure,
} from './actions'
import {
  LOGIN,
  LOGOUT,
  LOGGABLE,
  LogginAction,
  FORGOT_PASSWORD,
  ForgotPasswordAction,
} from './types'

// Only Moona user can be logged
function* authenticate(action: LogginAction) {
  const { login, password } = action.payload

  try {
    const result = yield call([Auth, 'signIn'], {
      username: login.toLowerCase(),
      password: password,
    })

    const {
      attributes: { sub },
    } = result

    // sub is the cognito id and we will update the moona id after
    yield put(setUserMailAndId({ email: login, moonaId: sub }))

    // If auth.sigIn OK => it's a Moona user
    const user = yield getUserInfosByEmail(login.toLowerCase(), false)
    if (user) {
      let id: string,
        email: string,
        firstname: string,
        lastname: string,
        phone: string,
        stripeId: string
      if ('id' in user) {
        id = user.id
        email = user.email
        firstname = user.firstname
        lastname = user.lastname
        phone = user.phone
      }
      if ('stripeId' in user) {
        stripeId = user.stripeId
      }
      if ('hasSubscription' in user) {
        yield put(setHasSubscription({ hasSubscription: user.hasSubscription }))
      }
      if ('hasSubscriptionYCoupon' in user) {
        yield put(
          setHasSubscriptionYCoupon({
            hasSubscriptionYCoupon: user.hasSubscriptionYCoupon,
          })
        )
      }
      if ('hasSubscriptionMBG' in user) {
        yield put(
          setHasSubscriptionMBG({ hasSubscriptionMBG: user.hasSubscriptionMBG })
        )
      }
      if ('hasSubscriptionDiscountPostPurchase' in user) {
        yield put(
          setHasSubscriptionDiscountPostPurchase({ hasSubscriptionDiscountPostPurchase: user.hasSubscriptionDiscountPostPurchase })
        )
      }

      const cardsData = yield call(
        [API, 'graphql'],
        graphqlOperation(getStripeCards, { stripeUserId: stripeId })
      )
      const stripeCards = JSON.parse(cardsData.data.getStripeCards).body.cards
        .data
      const cards = stripeCards.map(c => ({
        id: c.id,
        brand: c.card.brand,
        number: c.card.last4,
        default: false,
      }))

      yield put(
        loadUser({
          firstName: firstname,
          lastName: lastname,
          email: email,
          phone: phone,
          stripeId,
          paymentId: cards[0].id,
          cards,
          moonaId: id,
        })
      )
      // setAmplitudeUserId(id)
      yield put(loggedIn())
    } else {
      yield put(loginFail())
    }
  } catch (error) {
    if (error.code === 'UserNotFoundException') {
    } else if (error.code === 'UserNotConfirmedException') {
    } else {
    }
    clearAmplitudeUserId()
    yield put(loginFail())
  }
}

function* watchAuth() {
  yield takeEvery(LOGIN, authenticate)
}

function* logout() {
  localStorage.removeItem('token')
  yield put(clearUser())
  clearAmplitudeUserId()
}

function* watchLogout() {
  yield takeEvery(LOGOUT, logout)
}

function* loggable() {
  yield put(loggableSuccess(true))
}

function* watchLoggable() {
  yield takeEvery(LOGGABLE, loggable)
}

function* forgotPasswordGen(action: ForgotPasswordAction) {
  try {
    yield call([Auth, 'forgotPassword'], action.payload.login.toLowerCase())
    yield put(createForgotPasswordSuccess())
  } catch (error) {
    let msg = ''
    if (error.code === 'LimitExceededException') {
      msg = 'LimitExceededException'
    } else {
      msg = 'Account not found. Verify your email or register.'
    }
    yield put(createForgotPasswordFailure({ error: msg }))
  }
}

function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordGen)
}

const authSagas = [
  fork(watchAuth),
  fork(watchLoggable),
  fork(watchLogout),
  fork(watchForgotPassword),
]
export default authSagas
