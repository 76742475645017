import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    :root {
        --color-purple-moona: #9540FF;
        --color-purple-dark:  #6C1FCB;
        --color-purple-light:#ECDDFF;

        --color-blackmain-moona: #3F4045;
        --color-blackmain-moona-02: #292B36;

        --color-grey-main: #87888D;
        --color-grey-light-1: #F5F6FA;
        --color-grey-dark: #F5F6FA;
        --color-grey-link: #C4C4C4;

        --color-success:#31DC9E;
        --color-success-hover: #1BC185;
        --color-success-disable:rgba(49, 220, 158, 0.5);
        --color-success-border: #BAFFE6;

        --color-red-danger: #FF4773;



    }


    * {
        margin:0;
        padding:0;
    }

    *, *::before, *::after {
        box-sizing:inherit;
    }

    html {
        box-sizing:border-box;
        font-size:62.5%
    }
    body {
        font-family: "Open Sans", sans-serif, Arial;
        width: 100vw;
         -webkit-overflow-scrolling: touch;
         overscroll-behavior:none;
    }
`
