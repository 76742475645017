import React, { FC } from 'react'

import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { CheckoutLink } from 'common/style/components/links'
import { Spinner } from 'common/style/components/spinner'
import { cartUrlSelector } from 'state/features/checkoutSession/selector'

import Button from '../Buttons/Button'
import MoonaIcon from '../Icon/MoonaIcon'
import Flex from '../Primitives/Flex'

type BottomFormProps = {
  isFirstStep: boolean
  isLastStep: boolean
  backTitle?: string
  nextTitle?: string
  formik: any
  handlePreviousStep: () => void
  disabled?: boolean
}

const BottomForm: FC<BottomFormProps> = ({
  isFirstStep,
  isLastStep,
  backTitle = '',
  nextTitle = '',
  formik,
  handlePreviousStep,
  disabled = false,
}) => {
  const cartUrl = useSelector(cartUrlSelector)
  const { isMobile } = useSelector((state: any) => state.session)

  const {
    checkoutColors: { primary },
  } = useTheme()

  return (
    <Flex
      direction={isMobile ? 'column' : 'row'}
      align="center"
      justifyContent="space-between"
    >
      {isFirstStep ? (
        <CheckoutLink
          href={cartUrl}
          style={{
            order: isMobile ? 1 : -1,
          }}
        >
          <Flex
            align="center"
            style={{
              gap: '6px',
              width: isMobile ? '100%' : 'auto',
              paddingTop: isMobile ? '21px' : '0',
            }}
          >
            <MoonaIcon
              icon="ChevronRight"
              style={{
                transform: 'rotate(180deg)',
                position: 'relative',
                top: '1px',
              }}
              fill={primary}
            />
            <span>Return to cart</span>
          </Flex>
        </CheckoutLink>
      ) : (
        <Button
          className="simple-link"
          onClick={handlePreviousStep}
          style={{
            fontSize: '1.4rem',
            padding: 0,
            minWidth: 0,
            order: isMobile ? 1 : -1,
          }}
        >
          <Flex align="center" style={{ gap: '6px' }}>
            <MoonaIcon
              icon="ChevronRight"
              style={{
                transform: 'rotate(180deg)',
                position: 'relative',
                top: '1px',
              }}
              fill={primary}
            />
            <span>Return to {backTitle.toLocaleLowerCase()}</span>
          </Flex>
        </Button>
      )}
      <Button
        type="submit"
        disabled={disabled}
        style={{
          fontSize: '1.4rem',
          fontWeight: '500',
          minWidth: '190px',
          width: isMobile ? '100%' : 'auto',
        }}
      >
        {formik.isSubmitting ? (
          <Spinner center />
        ) : isLastStep ? (
          `Pay now`
        ) : (
          `Continue to ${nextTitle.toLowerCase()}`
        )}
      </Button>
    </Flex>
  )
}

export default BottomForm
