import React, { Suspense } from 'react'

import { ResetPasswordSkeleton } from './ResetPasswordSkeleton'

const ResetPasswordPageBase = React.lazy(() => import('./ResetPassword'))

export const ResetPasswordPage = () => (
  <Suspense fallback={<ResetPasswordSkeleton />}>
    <ResetPasswordPageBase />
  </Suspense>
)
