const formatDateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'short',
}
export const getEstimatedDeliveryDate = (
  timestampSooner: string | null,
  timestampLater: string | null,
  methodHandle: string,
  methodTitle: string | null,
  merchantId: string | null,
) => {
  // ONLY5POUNDS
  if (merchantId === '19123ba9-4ffd-44c1-b5e0-28956f8353e6' || merchantId === 'a7a05c7f-4397-46a4-a1c5-6c39bee501c9') {
    return getBetterShippingDescription(methodTitle);
  } else {
    const sooner = parseInt(timestampSooner)
    const later = parseInt(timestampLater)
    if (!sooner && !later) {
      if (
        methodHandle.toLowerCase().includes('shopify') &&
        methodHandle.toLowerCase().includes('express')
      ) {
        return '1 to 3 business days'
      } else if (
        methodHandle.toLowerCase().includes('shopify') &&
        methodHandle.toLowerCase().includes('standard')
      ) {
        return '2 to 4 business days'
      } else {
        return ''
      }
    }

    const minDay = sooner
      ? new Date(sooner * 1000).toLocaleString('default', formatDateOptions)
      : null
    const maxDay = later
      ? new Date(later * 1000).toLocaleString('default', formatDateOptions)
      : null

    if (!minDay || !maxDay) return ''
    return `Estimated delivery ${minDay}-${maxDay}`
  }
}

// BETTER SHIPPING - ONLY5POUNDS
export const getBetterShippingDescription = (methodHandle: string) => {
  const shippingOptions = {
    // UK
    // SINGLE ITEM
    "YODEL STANDARD - SINGLE ITEM": "2-3 Business Days Shipping Time (After Dispatch 48H*)",
    "DPD EXPRESS - SINGLE ITEM": "1-2 Business Days Shipping Time (After Dispatch 48H*)",
    // MULTIPLE ITEMS
    "YODEL STANDARD - UNLIMITED ITEMS": "2-3 Business Days (After Dispatch 48H*)",
    "DPD STANDARD - UNLIMITED ITEMS": "1-2 Business Days (After Dispatch 48H*)",
    "DPD STANDARD SHIPPING": "1-2 Business Days (After Dispatch 48H*)",
    "DPD EXPRESS - UNLIMITED ITEMS": "1-2 Business Days (After Dispatch 24H* When ordered before 12pm, Mon-Thurs)",
    // ISLE OF MAN - NORTHERN IRELAND - SCOTTISH HIGHLANDS
    "NORTHERN IRELAND, ISLE OF MAN (OFF-SHORE)": "2-3 Days (After Dispatch 48H*)",
    "SCOTTISH HIGHLANDS (OFF-SHORE)": "2-3 Days (After Dispatch 48H*)"
  };

  return shippingOptions[methodHandle] || Object.values(shippingOptions)[0];
}
