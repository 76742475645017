export const UPDATE_STORE_SETTINGS = 'UPDATE_STORE_SETTINGS'

export interface Province {
  id: number
  countryId: number
  name: string
  code: string
  taxName: string
  taxType: null | string
  shippingZoneId: null
  tax: number
  taxPercentage: number
}

export interface Country {
  id: number
  name: string
  code: string
  taName: string
  tax: number
  provinces: Province[]
}

export interface StoreSettingsState {
  id: string
  name: string
  publishableKeyLive: string
  publishableKeyTest: string
  secretKeyLive: string
  secretKeyTest: string
  slotMP2: boolean
  logoUrl: string
  checkoutButtonColor: string
  checkoutColor: string
  checkoutUrl: string
  emailMarketing: boolean
  smsMarketing: boolean
  enabled: boolean
  markets: any[]
  countries: Country[]
  paymentMethods: string[]
  fastCheckoutMethods: any[]
  storefrontAccessToken: string
  myShopifyDomain: string
  trackingCode: string
  merchantId: string
  ecommerceSolution: string
  ecommerceVersion: string
  apiVersion: string
  pluginVersion: string
  shippingSlotAssociatedMethod: string
  shippingSlotDisplayAssociatedMethod: boolean
  shippingSlotEnabled: boolean
  shippingSlotLabel: string
  shippingSlotSort: number
  serverShopifyUrl: string
  taxesIncluded: boolean
  testMode: boolean
  appStatus: string
  appBlockProductCheckbox: { slug: string; enabled: boolean }[]
  createdAt: string
  updatedAt: string
  addressAutoCompletionEnabled: boolean
}

export interface UpdateStoreSettings {
  type: typeof UPDATE_STORE_SETTINGS
  payload: StoreSettingsState
}

export type StoreSettingsActionTypes = UpdateStoreSettings
