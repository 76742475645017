import styled from 'styled-components'

import { tablet } from '../queries/media-queries'

export const LogoWrapper = styled('div')`
  margin: 0 auto;
  max-width: 60rem;
  width: 100%;

  @media ${tablet} {
    max-width: 123rem;
  }
`

export const CheckoutWrapper = styled('div')`
  min-height: calc(100vh - 64px);
  background-color: #fff;
  display: flex;
  flex-direction: column;

  &.wrap,
  .wrap {
    margin: 0 auto 0 auto;
    max-width: 60rem;
    width: 100%;
  }

  .container-cart {
    width: 100%;
    height: 67px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    background: #fafafa;
  }
  .container-flow {
    overflow: auto;
    width: 100%;
    padding: 0 1.4rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
  }
  @media ${tablet} {
    display: flex;
    flex-direction: row-reverse;

    &.wrap,
    .wrap {
      max-width: 123rem;
    }

    .container-cart {
      border-top: none;
      border-bottom: none;
      border-left: 1px solid #d9d9d9;
      width: 43%;
      height: auto;
      min-height: 100vh;
      padding: 57px 47px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #fafafa;
        z-index: -1;
        width: 300%;
      }
    }
    .container-flow {
      margin-right: auto;
      padding: 57px 66px;
      width: 57%;
      height: auto;
      min-height: 100vh;
    }
  }
`
export const FlowBreadcrumbList = styled('div')`
  display: flex;
  align-items: center;
  gap: 11px;
  width: 100%;
  height: 30px;
  margin: 0.5rem 0 1.5rem 0;
  font-size: 12px;

  a {
    font-size: inherit;
  }

  @media ${tablet} {
    margin: 0;
    font-size: 14px;
  }
`
export const FlowBreadcrumbItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 11px;
  color: ${props => props.theme.checkoutColors.primary};
  &.disabled {
    color: #737373;
    &.current {
      color: #333333;
    }
  }

  button {
    background: none;
    border: none;
    font-size: inherit;
    outline: none;
    color: inherit;

    &:not(:disabled):hover {
      cursor: pointer;
    }
  }
  span {
    font-size: 14px;
    color: #737373;
  }
`
export const CheckoutFormGroup = styled('div')`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  p,
  a {
    font-size: 12px;
  }
`
export const CheckoutInputStyle = styled('div')`
  position: relative;
  height: 46px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;

  &.error {
    border: 2px solid red;
  }
  input,
  .input {
    position: relative;
    padding: 11px;
    height: 100%;
    width: inherit;
    background: transparent !important;
    border: none;
    outline: none;
    z-index: 1;
  }
  label {
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #737373;
    transition: all 0.2s ease-in-out;
    z-index: 0;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);

    &.start {
      left: 11px;
      right: auto;
    }
  }

  &:focus-within,
  &.focused {
    border: 2px solid ${props => props.theme.checkoutColors.primary};
    &.error {
      border: 2px solid red;
    }
  }

  &.label-active {
    input,
    .input {
      padding: 22px 11px 0 11px;
    }
    label {
      top: 6px;
      font-size: 12px;
      transform: translateY(0);
    }
  }
`
export const CheckoutSelect = styled('div')`
  position: relative;
  height: 46px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;

  &.error {
    border: 2px solid red;
  }

  select {
    padding: 22px 51px 0 11px;
    height: 100%;
    width: inherit;
    background: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-overflow: ellipsis;
  }

  label {
    position: absolute;
    left: 11px;
    color: #737373;
    top: 6px;
    font-size: 12px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 40px;
    height: 20px;
    width: 2px;
    background: #d9d9d9;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #737373;
    transition: transform 0.2s ease-in-out;
  }
`

export const ErrorWrapper = styled('div')`
  width: 100%;
  p.text-error {
    margin-top: 5px;
    color: red;
    font-size: 12px;
  }
`

export const CheckoutRadioGroupWrapper = styled('div')``
export const CheckoutRadioItem = styled('div')`
  padding: 17px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 11px;
  border: 1px solid #d9d9d9;
  color: #737373;
  font-size: 14px;
  position: relative;

  label {
    font-size: 14px;
    line-height: 18.5px;
  }

  .text-details {
    margin-top: 0.25rem;
    color: #737373;
  }

  .text-price {
    margin-left: auto;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    cursor: pointer;
  }

  & input[type='radio'] {
    height: 18px;
    width: 18px;
    position: relative;
    flex-shrink: 0;

    &:checked:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 7px solid ${props => props.theme.checkoutColors.primary};
      background-color: #fff;
    }
  }
  &.active {
    color: ${props => props.theme.checkoutColors.primary};

    label {
      color: #545454;
    }
  }
`

export const BottomCheckoutStyled = styled('div')`
  margin-top: 98px;
  border-top: 1px solid #d9d9d9;
  padding: 17px 0;
  display: flex;
  align-items: center;
  column-gap: 21px;
  row-gap: 8px;
  flex-wrap: wrap;
`

export const ToggleButton = styled('button')`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: ${props => props.theme.checkoutColors.primary};
  display: flex;
  align-items: center;
  padding: 17px 14px;

  svg:not(.arrow) {
    margin-right: 10px;
  }

  svg.arrow {
    margin-left: 8px;
    transform: rotate(90deg);
    transition: all 0.2s ease-out;
  }

  &.active {
    svg.arrow {
      transform: rotate(270deg);
    }
  }
`

export const StyledBorderedWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  padding: 21px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 2rem 0 2.5rem 0;

  button {
    width: 33%;
    height: 54px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 1.5rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${tablet} {
      padding: 1.5rem 3rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  & > label {
    font-size: 14px;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 1.5rem;
    background: #fff;
  }
`

export const IFrameWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
`

export const CustomIFrame = styled('iframe')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  padding: 23px;
  border-radius: 23px;
  z-index: 999;
  border: none;
  min-width: 50%;
  height: 90%;
  width: 100%;
`

export const OutOfStockWrapper = styled('div')`
  font-family: Inter;
  flex-grow: 1;

  .title {
    margin-top: 40px;
    flex-direction: column;
    gap: 20px;

    @media ${tablet} {
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }

    .icon-wrapper svg {
      color: ${props => props.theme.checkoutColors.primary};
    }

    p {
      font-size: 12px;
      color: #727272;
      font-weight: 400;
      line-height: 18.5px;

      @media ${tablet} {
        font-size: 14px;
      }
    }
  }

  .list-items-wrapper {
    margin-top: 20px;
    width: 100%;
  }

  .btn-return-to-cart {
    background-color: ${props => props.theme.checkoutColors.primary};
    width: 100%;
    margin-top: 24px;
    font-size: 14px;

    @media ${tablet} {
      width: auto;
      margin-left: auto;
      display: block;
    }
  }
`
