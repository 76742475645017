import { FALLBACK_CURRENCY } from './actions'
import {
  ECOM_SESSION_FAILED,
  LOAD_ECOM_SESSION,
  LOAD_QUERY,
  QueryActionTypes,
  QueryState,
  SET_ECOM_SESSION,
  SET_QUERY_LOADED,
  START_MAIN_SESSION_LOADING,
  STOP_MAIN_SESSION_LOADING,
} from './types'

export const initialQueryState: QueryState = {
  params: {
    price: 0,
    currency: FALLBACK_CURRENCY,
  },
  mainSessionLoading: true,
  queryLoaded: false,
  userSessionLoaded: false,
  userSessionLoading: false,
  userSessionError: false,
}

export default function users(
  state = initialQueryState,
  action: QueryActionTypes
): QueryState {
  switch (action.type) {
    case LOAD_QUERY:
      return {
        ...state,
        params: action.payload,
        queryLoaded: true,
      }
    case SET_QUERY_LOADED:
      return {
        ...state,
        queryLoaded: action.payload.queryLoaded,
      }
    case LOAD_ECOM_SESSION:
      return {
        ...state,
        userSessionLoading: true,
      }
    case SET_ECOM_SESSION:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
        userSessionLoading: false,
        userSessionLoaded: true,
      }
    case ECOM_SESSION_FAILED:
      return {
        ...state,
        mainSessionLoading: false,
        userSessionLoading: false,
        userSessionLoaded: false,
        userSessionError: true,
      }
    case START_MAIN_SESSION_LOADING:
      return {
        ...state,
        mainSessionLoading: true,
      }
    case STOP_MAIN_SESSION_LOADING:
      return {
        ...state,
        mainSessionLoading: false,
      }

    default:
      return state
  }
}
