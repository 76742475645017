import React from 'react'

import { useTranslation } from 'react-i18next'
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { ActivationRequiredPage } from 'pages/ActivationRequired'
import { CheckoutPage } from 'pages/Checkout'
import { CouponPopin } from 'pages/CouponPopin'
import { LeaveReviewPage } from 'pages/LeaveReview'
import { MoneyBackGuaranteePage } from 'pages/MoneyBackGuarantee'
import { MoneyBackGuaranteeConfirmPage } from 'pages/MoneyBackGuaranteeConfirm'
import { RetargetingPage } from 'pages/Retargeting'

import AuthenticationRoutes from './common/components/AuthenticationRoutes/AuthenticationRoutes'
import Layout from './common/components/Layout'
import { CardFormPage } from './pages/CardForm'
import { CheckoutTestPage } from './pages/CheckoutTest'
import { ForgotPasswordPage } from './pages/ForgotPassword'
import { HomePage } from './pages/Home'
import { LoginPage } from './pages/Login'
import { OrderLimitPage } from './pages/OrderLimit'
import { PayPage } from './pages/Pay'
import { PayMainPage } from './pages/PayMain'
import { ResetPasswordPage } from './pages/ResetPassword'
import { ReviewsPage } from './pages/Reviews'
import { PostPurchaseDiscountPage } from 'pages/PostPurchaseDiscount'

const Routes: React.FC = () => {
  const [t] = useTranslation('app')
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Layout exact path={t('routes.payment')} component={PayPage} />
          <Layout exact path={t('routes.reviews')} component={ReviewsPage} />
          <Layout
            exact
            path={t('routes.retargeting')}
            component={RetargetingPage}
          />
          <AuthenticationRoutes
            exact
            path={t('routes.login')}
            component={LoginPage}
          />
          <Layout exact path={t('routes.home')} component={HomePage} />

          <Layout
            exact
            path={t('routes.checkoutShopify')}
            component={CheckoutPage}
          />

          <Layout
            exact
            path={t('routes.checkouttest')}
            component={CheckoutTestPage}
          />
          <Layout
            exact
            path={t('routes.forgotpassword')}
            component={ForgotPasswordPage}
          />
          <Layout
            exact
            path={t('routes.resetpassword')}
            component={ResetPasswordPage}
          />
          <Layout
            exact
            path={t('routes.orderlimit')}
            component={OrderLimitPage}
          />
          <Layout
            exact
            path={t('routes.activationRequired')}
            component={ActivationRequiredPage}
          />
          <Layout
            exact
            path={t('routes.leaveReview')}
            component={LeaveReviewPage}
          />
          <Layout exact path={t('routes.cardForm')} component={CardFormPage} />
          <Layout exact path={t('routes.payMain')} component={PayMainPage} />
          <Layout
            exact
            path={t('routes.postPurchaseDiscount')}
            component={PostPurchaseDiscountPage}
          />
          <Layout
            exact
            path={t('routes.moneyBackGuarantee')}
            component={MoneyBackGuaranteePage}
          />

          <Layout
            exact
            path={t('routes.moneyBackGuaranteeConfirm')}
            component={MoneyBackGuaranteeConfirmPage}
          />

          <Layout
            exact
            path={t('routes.couponPopin')}
            component={CouponPopin}
          />

          <Redirect
            to={
              t('routes.payment') +
              (typeof window !== 'undefined' ? window.location.search : '')
            }
          />
        </Switch>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default Routes
