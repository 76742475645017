export const MoonaException = (code, message, moonaId) => {
  const error = new Error(message)
  error.code = code
  error.moonaId = moonaId
  return error
}

export const check = (resolvedPromise, operation, moonaId) => {
  if (resolvedPromise.data[operation]) {
    const data = resolvedPromise.data[operation]
    let parsedData = null
    try {
      parsedData = JSON.parse(data)
    } catch {
      throw MoonaException('unknown', 'Could not parse ' + data, moonaId)
    }

    if (parsedData.statusCode !== 200) {
      if (parsedData.error)
        throw MoonaException(
          parsedData.error.code,
          parsedData.error.message,
          moonaId
        )
      throw MoonaException('unknown', 'No error code received', moonaId)
    } else {
      return parsedData
    }
  } else {
    throw MoonaException(
      'unknown',
      'No data fetched for operation ' + operation,
      moonaId
    )
  }
}
