import { ComponentProps, FC } from 'react'

import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

import AppBox, { AppBoxProps } from '../Primitives/AppBox'

export const OverlayLink = styled(AppBox).attrs({ as: 'a' })`
  color: #969696;
  font-family: inherit;
  font-size: 1.4rem;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
` as FC<Omit<AppBoxProps, keyof ComponentProps<'a'>> & ComponentProps<'a'>>

export const OverlayLinkRouter = styled(AppBox).attrs({ as: Link })`
  color: #969696;
  font-family: inherit;
  font-size: 1.4rem;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
` as FC<Omit<AppBoxProps, keyof LinkProps> & LinkProps>
