import { SessionState, SessionActionTypes, SET_DEVICE_TYPE } from './types'

export const initialSessionState: SessionState = {
  isMobile: false,
  isSmallMobile: false,
  isLargeMobile: false,
  isMediumMobile: false,
  mode:
    typeof window !== 'undefined' &&
    process.env.REACT_APP_BUILD_ENV !== 'prod' &&
    window.location.search.includes('mode=demo')
      ? 'demo'
      : 'default'
}

export default function payment(
  state = initialSessionState,
  action: SessionActionTypes
): SessionState {
  switch (action.type) {
    case SET_DEVICE_TYPE:
      return {
        ...state,
        isMobile: action.payload.isMobile,
        isSmallMobile: action.payload.isSmallMobile,
        isLargeMobile: action.payload.isLargeMobile,
        isMediumMobile: action.payload.isMediumMobile
      }

    default:
      return state
  }
}
