import React from 'react'

const Tag = props => {
  return (
    <svg
      viewBox="0 0 18 18"
      className="_1fragem1a _1fragem3g _1fragembx _1fragembw"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M17.78 3.09A1.995 1.995 0 0016 2h-5.165c-.535 0-1.046.214-1.422.593l-6.82 6.89c0 .002 0 .003-.002.003a2.035 2.035 0 00-.5.874L.74 8.055a2 2 0 01.712-2.737L9.823.425A1.99 1.99 0 0111.35.22l4.99 1.337a2 2 0 011.44 1.533z"
        fillOpacity="0.55"
        fill="#3F4045"
      ></path>
      <path
        d="M10.835 2H16a2 2 0 012 2v5.172c0 .53-.21 1.04-.586 1.414l-6.818 6.818a2 2 0 01-2.82.01l-5.166-5.1a2.001 2.001 0 01-.02-2.828c.002 0 .003 0 .003-.002l6.82-6.89A2 2 0 0110.835 2zM13.5 8a1.5 1.5 0 10-.001-3.001A1.5 1.5 0 0013.5 8z"
        fillOpacity="0.9"
        fill="#3F4045"
      ></path>
    </svg>
  )
}

export default Tag
