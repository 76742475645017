import { PoliciesState } from './types'

export const initialPoliciesState: PoliciesState = {
  privacyPolicy: null,
  refundPolicy: null,
  shippingPolicy: null,
  subscriptionPolicy: null,
  termsOfService: null,
  contactInformation: null,
}
