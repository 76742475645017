import React, { FC } from 'react'

type ChevronRightProps = {
  fill?: string
} & any

const ChevronRight: FC<ChevronRightProps> = ({ fill = '#737373', ...rest }) => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M0 1L1 0L5 4L6 5L5 6L1 10L0 9L4 5" fill={fill} />
  </svg>
)

export default ChevronRight
