import React from 'react'

import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import { MoonaPassword } from 'common/components/Form/MoonaPassword/MoonaPassword'
import { yupPasswordValidationRule } from 'common/components/Form/validators'
import { tablet } from 'common/style/queries/media-queries'
import {
  LoginFormContainer,
  LoginFormSection,
  LoginButton,
  FormWrapper,
} from 'pages/Login/components/LoginForm'
import { setFirstPwd, updatePwd } from 'state/features/users/actions'

import { FormBundle } from '../ResetPassword'

interface ResetPasswordFormProps extends FormBundle {
  email: string
  passwordUpdated: boolean
  isFirstPwd: boolean
  isProcessing: boolean
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>
  isSubmitted: boolean
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>
  username?: string
  code?: string
}

const FormContainer = styled(LoginFormContainer)``

const SubmitPassword = styled(LoginButton)`
  width: 28.5rem;
  @media ${tablet} {
    width: 100% !important;
  }
`

const SectionForm = styled(LoginFormSection)``

const initialValues = {
  password: '',
  confirmpassword: '',
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  password,
  confirmpassword,
  button,
  email,

  isFirstPwd,
  isProcessing,
  setProcessing,
  isSubmitted,
  username,
  code,
}) => {
  const dispatch = useDispatch()

  const validationSchema = Yup.object({
    ...yupPasswordValidationRule(password.validation),
    confirmpassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), ''],
        JSON.stringify(confirmpassword.validation)
      )
      .required(JSON.stringify(confirmpassword.validation)),
  })

  const initialErrors = {
    password: '{"label":"","name":""}',
  }

  return (
    <FormContainer>
      <Formik
        initialErrors={initialErrors}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async values => {
          setProcessing(true)
          if (isFirstPwd) {
            dispatch(setFirstPwd({ password: values.password, email }))
          } else {
            dispatch(updatePwd({ password: values.password, username, code }))
          }
        }}
      >
        {({ errors, touched }) => {
          return (
            <FormWrapper autoComplete="off">
              <SectionForm>
                <MoonaPassword
                  name="password"
                  label={password.label}
                  placeholder={password.placeholder}
                  errors={errors}
                  touched={touched}
                  autofocus={true}
                  disabled={isProcessing}
                />
                <MoonaPassword
                  name="confirmpassword"
                  label={confirmpassword.label}
                  placeholder={confirmpassword.placeholder}
                  errors={errors}
                  touched={touched}
                  disabled={isProcessing}
                />

                <SubmitPassword
                  isSubmitting={isProcessing}
                  isSubmitted={isSubmitted}
                  label={button.label}
                  disabled={isProcessing}
                  type="submit"
                  name="set-password-submit"
                />
              </SectionForm>
            </FormWrapper>
          )
        }}
      </Formik>
    </FormContainer>
  )
}
