export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE'

export interface Session {
  isMobile: boolean
  isSmallMobile: boolean
  isLargeMobile: boolean
  isMediumMobile: boolean
  mode: 'default' | 'demo'
}

export interface SetDeviceType {
  type: typeof SET_DEVICE_TYPE
  payload: {
    isMobile: boolean
    isSmallMobile: boolean
    isLargeMobile: boolean
    isMediumMobile: boolean
  }
}

export type SessionActionTypes = SetDeviceType

export interface SessionState extends Session {}
