import { AppState } from './features/types'

const TTL = 10 * 60 * 1000 // 30minutes
type PERSISTED_STATE = Pick<AppState, 'users' | 'auth' | 'payment' | 'query'>

type ITEM = {
  expiry: number
  data: PERSISTED_STATE
}

export const loadState = (): PERSISTED_STATE | undefined => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    const item: ITEM = JSON.parse(serializedState)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return undefined so reducers inititialized themselves on their own
      localStorage.removeItem('state')
      return undefined
    }

    return item.data
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: PERSISTED_STATE) => {
  try {
    const now = new Date()

    const updatedState = {
      auth: {
        ...state.auth,
        loginEmail: '',
        error: '',
        forgotPasswordLoading: false,
        forgotPasswordSuccess: false,
        forgotPasswordFailure: false,
        loginfailure: false
      },
      payment: {
        ...state.payment,
        success: false,
        submitting: false
      },
      query: {
        params: {
          ...state.query.params
        },
        mainSessionLoading: true,
        queryLoaded: false,
        userSessionLoaded: false,
        userSessionLoading: false,
        userSessionError: false
      },
      users: {
        ...state.users,
        isMoonaUser: false,
        password: '',
        userSecret: '',
        error: '',
        hasSubscription: false,
        hasSubscriptionYCoupon: false,
        hasSubscriptionMBG: false,
        hasSubscriptionDiscountPostPurchase: false
      }
    }

    const item: ITEM = {
      data: updatedState,
      expiry: now.getTime() + TTL
    }

    const serializedState = JSON.stringify(item)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}
