import styled from 'styled-components'

export const AutocompleteMenuFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px;

  color: #707070;
  font-size: 12px;
  font-weight: 400;
  background: #f2f2f2;
`
