import type {
  ComponentPropsWithRef,
  ElementType,
  HTMLAttributes,
  JSXElementConstructor,
  RefAttributes,
} from 'react'

import css, { SystemStyleObject } from '@styled-system/css'
import shouldForwardProp from '@styled-system/should-forward-prop'
import styled, { DefaultTheme } from 'styled-components'
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  ResponsiveValue,
  shadow,
  space,
  SpaceProps,
  system,
  TypographyProps,
  typography,
  variant,
  BoxShadowProps,
} from 'styled-system'

type AppBoxHTMLProps = RefAttributes<any> & HTMLAttributes<any>

// TODO(@liinkiing): when having more time, refacto the theme.ts and extract
// automatically TypeScript types from theme to have autocompletion for bg="myThemeColor"
type ColorsProps = ResponsiveValue<string>

type AppColorProps = {
  /**
   * The color utility parses a component's `color` and `bg` props and converts them into CSS declarations.
   * By default the raw value of the prop is returned.
   *
   * Color palettes can be configured with the ThemeProvider to use keys as prop values, with support for dot notation.
   * Array values are converted into responsive values.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/color)
   */
  color?: ColorsProps
  /**
   * The color utility parses a component's `color` and `bg` props and converts them into CSS declarations.
   * By default the raw value of the prop is returned.
   *
   * Color palettes can be configured with the ThemeProvider to use keys as prop values, with support for dot notation.
   * Array values are converted into responsive values.
   *
   * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/CSS/background-color)
   */
  bg?: ColorsProps
  backgroundColor?: ColorsProps
}

type AppCustomStyledProps = {
  minSize?: StyledSystemProps['width']
  maxSize?: StyledSystemProps['width']
}

type StyledSystemProps = ColorProps &
  TypographyProps &
  BorderProps &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  GridProps &
  PositionProps &
  BoxShadowProps

type ModifiedStyledSystemProps = AppCustomStyledProps & AppColorProps

interface CustomAppBoxProps {
  readonly uppercase?: boolean
  readonly disableFocusStyles?: boolean
  readonly css?:
    | ((theme: DefaultTheme) => any)
    | ReturnType<typeof css>
    | Record<string, unknown>
  readonly ref?: any
}

type AppBoxCssStateProps = {
  sx?: SystemStyleObject
  _variants?: Array<typeof variant> | typeof variant
  _hover?: SystemStyleObject
  _selected?: SystemStyleObject
  _active?: SystemStyleObject
  _focus?: SystemStyleObject
  _disabled?: SystemStyleObject
}

export type AppBoxProps = AppBoxHTMLProps &
  CustomAppBoxProps &
  StyledSystemProps &
  ModifiedStyledSystemProps &
  AppBoxCssStateProps

export type PropsOf<
  E extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<E, ComponentPropsWithRef<E>>

export interface AppBoxOwnProps<E extends ElementType = ElementType>
  extends AppBoxProps {
  as?: E
}

export type PolymorphicAppBoxProps<E extends ElementType> = AppBoxOwnProps<E> &
  Omit<PropsOf<E>, keyof AppBoxOwnProps>

export type PolymorphicComponentProps<E extends ElementType, P> = P &
  PolymorphicAppBoxProps<E>

const defaultElement = 'div'

export const AppBox = styled('div').withConfig<AppBoxProps>({
  shouldForwardProp: prop => {
    return shouldForwardProp(prop)
  },
})(
  props => ({
    textTransform: props.uppercase ? 'uppercase' : undefined,
  }),
  ({
    sx,
    _hover,
    _active,
    _focus,
    _disabled,
    _selected,
    disableFocusStyles,
  }: AppBoxProps) =>
    css({
      ...(sx ?? {}),
      '&:hover': _hover ?? {},
      '&:active': _active ?? {},
      '&[aria-selected="true"]': _selected ?? {},
      '&:focus': disableFocusStyles
        ? { ..._focus, outline: 'none', boxShadow: 'none' }
        : _focus ?? {},
      '&:disabled': _disabled ?? {},
    }),
  ({ _variants }) =>
    Array.isArray(_variants) ? _variants.map(v => v) : _variants ?? {},
  compose(
    system({
      gap: {
        properties: ['gap'],
        scale: 'sizes',
      },
      minSize: {
        properties: ['minWidth', 'minHeight'],
        scale: 'sizes',
      },
      maxSize: {
        properties: ['maxWidth', 'maxHeight'],
        scale: 'sizes',
      },
    }),
    shadow,
    color,
    space,
    layout,
    flexbox,
    grid,
    border,
    typography,
    position
  )
)

AppBox.displayName = 'AppBox'

export type PolymorphicAppBox = <E extends ElementType = typeof defaultElement>(
  props: PolymorphicAppBoxProps<E>
) => JSX.Element

export type PolymorphicComponent<P> = <
  E extends ElementType = typeof defaultElement
>(
  props: PolymorphicComponentProps<E, P>
) => JSX.Element

export default AppBox
