import { AppActions } from '../types'

import {
  SET_FAILURE,
  SET_HAS_DISCOUNT,
  SET_PRICE,
  SET_SUCCESS,
  SetHasDiscount,
  START_SUBMITTING,
  STOP_SUBMITTING,
} from './types'

export const setPrice = (price: number, discount: number): AppActions => {
  return {
    type: SET_PRICE,
    payload: {
      price: price,
      finalPrice: price - discount,
      discount,
    },
  }
}
export const setSuccess = (): AppActions => {
  return {
    type: SET_SUCCESS,
  }
}

export const setFailure = (): AppActions => {
  return {
    type: SET_FAILURE,
  }
}

export const startSubmitting = (): AppActions => {
  return {
    type: START_SUBMITTING,
  }
}

export const stopSubmitting = (): AppActions => {
  return {
    type: STOP_SUBMITTING,
  }
}

export const setHasDiscount = (
  payload: SetHasDiscount['payload']
): AppActions => {
  return {
    type: SET_HAS_DISCOUNT,
    payload,
  }
}
