export const UPDATE_POLICIES = 'UPDATE_POLICIES'

export interface Policy {
  body: string
  handle: string
  id: string
  title: string
  url: string
}
export interface PoliciesState {
  privacyPolicy: Policy
  refundPolicy: Policy
  shippingPolicy: Policy
  subscriptionPolicy: Policy
  termsOfService: Policy
  contactInformation: Omit<Policy, 'id' | 'handle'>
}

export interface UpdatePoliciesAction {
  type: typeof UPDATE_POLICIES
  payload: PoliciesState
}

export type PoliciesActionTypes = UpdatePoliciesAction
