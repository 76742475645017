import { AppState } from '../types'

export const currencySelector = (state: AppState) => {
  const { checkoutSession, checkoutShopify } = state
  return (
    checkoutShopify.subtotalPrice.currencyCode ??
    checkoutSession.cart.currency
  )
}

export const cartUrlSelector = (state: AppState) => {
  const { checkoutSession } = state
  const {
    store: { cartUrl },
    cart: { attributes },
  } = checkoutSession

  return (
    cartUrl +
    `${
      attributes.slotProductCheckbox === undefined
        ? ''
        : `?slpcbx=${attributes.slotProductCheckbox ? '1' : '0'}`
    }`
  )
}
