import { CheckoutState } from './types'

export const initialCheckoutInformationState = {
  email: '',
  emailMarketingConsent: false,
  deliveryMethod: 'ship',
  requiresProvince: false,
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  countryCode: '',
  province: '',
  provinceCode: '',
  zip: '',
  phone: '',
  smsMarketingConsent: false,
  smsMarketingPhone: '',
  saveInformationForNextTime: true,
  cleeverDiscount: {
    title:
      '<span style="color: #000;font-size: 14px;"><b>Apply £5 discount now</b></span>',
    titleChecked:
      '<span style="color: #000;font-size: 14px;"><b>£5 discount applied!</b></span>',
    discount: 500,
    description:
      "<p style='margin-top:10px;font-size: 14px;'> • Get £5 off now<br/> • 15% cashback anywhere online<br/> • Best price promise<br/><p style='margin-top:5px; font-size: 14px;'>YCoupon: <b>7-day FREE trial</b>, then <b>£29.99 a month. Cancel anytime.</b></p></p>",
    privacy: `<p style="color: #737373; margin-top:10px;" >I agree to YCoupon's <a href="https://ykards.com/terms-and-conditions/" style="color: grey" target="_blank">T&amp;C</a>, <a href="https://ykards.com/privacy-policy/" style="color: grey" target="_blank" >privacy policy</a> and receive offers via sms/email.</p>`,
  },
}

export const initialCheckoutShippingState = {
  shippingMethod: '',
  shippingTitle: '',
  paymentMethod: 'MP1',
}
export const initialCheckoutPaymentState = {
  getDiscount: false,
  rememberMe: false,
  paymentMethod: 'MP1',
  billingAddressMethod: 'same',
  requiresProvinceBilling: false,
  firstNameBilling: '',
  lastNameBilling: '',
  address1Billing: '',
  address2Billing: '',
  cityBilling: '',
  countryBilling: '',
  countryCodeBilling: '',
  zipBilling: '',
  phoneBilling: '',
  provinceBilling: '',
  provinceCodeBilling: '',
}

export const initialCheckoutState: CheckoutState = {
  ...initialCheckoutInformationState,
  ...initialCheckoutShippingState,
  ...initialCheckoutPaymentState,
  saveForm: false,
  redirectLoading: false,
}
