/* eslint-disable no-case-declarations */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { FormikValues, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { CustomIFrame, IFrameWrapper } from 'common/style/pages/checkout'
import { redirectFromPaymentEvent } from 'common/utilities/redirectFromPaymentEvent'
import { StepComponentProps } from 'pages/Checkout/CheckoutFlow'
import { integratedContentSelector } from 'state/customSelectors'
import {
  UpdateCheckout,
  UpdateGetDiscount,
} from 'state/features/checkout/action'
import { ClearSlotProductCheckbox } from 'state/features/checkoutSession/actions'
import { currencySelector } from 'state/features/checkoutSession/selector'
import { summarySelector } from 'state/features/checkoutShopify/selector'
import { AppState } from 'state/features/types'
import { currencify } from 'utils/string'

import BottomForm from '../BottomForm'
import formikPayment from '../formikObject/payment'
import InformationsBloc from '../ShippingStep/InformationsBloc'

import BillingAddressBloc from './Form/BillingAddressBloc'
import DiscountBloc from './Form/DiscountBloc'
import PaymentBloc from './Form/PaymentBloc'
import { createEcommerceSessionService } from './paymentService'
import InformationSkeleton from 'common/components/Skeleton/Checkout/InformationSkeleton'
import { toastError } from 'common/components/Toast/Toast'
import { UpdateCheckoutShopify } from 'state/features/checkoutShopify/action'
import { sendAmplitudeData } from 'common/utilities/amplitude'
import { scrollToTop } from 'utils/scroll'
import { doNotdisplayDiscountBlocInPaymentPage } from 'utils/features'

export const PaymentStep: FC<StepComponentProps> = ({
  step,
  steps,
  handlePreviousStep,
  isFirstStep,
  isLastStep,
  setCurrentStep,
  loading,
}) => {
  const {
    checkout: checkoutState,
    storeSettings,
    checkoutShopify,
    checkoutSession,
    shopper,
  } = useSelector((state: AppState) => {
    return state
  })
  const { id: checkoutSessionId, cart } = checkoutSession

  const currency = useSelector(currencySelector)
  const summary = useSelector(summarySelector)
  const integratedPayload = useSelector(integratedContentSelector)
  const slotProductCheckbox = useSelector(
    state => state.checkoutSession.cart.attributes?.slotProductCheckbox ?? null
  )

  const dispatch = useDispatch()

  const elements = useElements()

  const stripe = useStripe()
  const [paymentMethodId, setPaymentMethodId] = useState<string>(null)
  const [iFrameUrl, setIFrameUrl] = useState<string>(null)
  const [discountBlocDisplay, setDiscountBlocDisplay] = useState<boolean>(true)

  const infos = useMemo(() => {
    const baseArray = [
      {
        label: 'contact',
        value: checkoutState.email,
        changeCallback: () => setCurrentStep(0),
      },
    ]

    if (cart.requiresShipping) {
      baseArray.push({
        label: 'shipTo',
        value: `${checkoutState.address1}, ${checkoutState.zip} ${checkoutState.city}`,
        changeCallback: () => setCurrentStep(0),
      })
    }

    if (checkoutState.shippingMethod && checkoutState.shippingMethod !== '') {
      // Need to keep it for the split
      /* eslint-disable @typescript-eslint/no-unused-vars */
      // Format could be :
      // shopify-title-price
      // handle_name-title-price-timestampSooner-timestampLater
      const shippingMethodDecoded = decodeURI(checkoutState.shippingMethod)
        .replace('Cleever-', '')
        .replace('Better Shipping App-', '')
      const shippingMethodLastPart = shippingMethodDecoded.slice(
        shippingMethodDecoded.lastIndexOf('-') + 1
      )
      const parts = shippingMethodDecoded.split('-')

      let name: string
      let title: string
      let price: string
      let timestampSooner: string
      let timestampLater: string

      // If it's a timestamp => 5 parts with the 2 last as timestamp
      if (/[0-9]{10,}/.test(shippingMethodLastPart)) {
        name = parts.shift()
        // Don't move the order
        timestampLater = parts.pop()
        timestampSooner = parts.pop()
        price = parts.pop()
        title = parts.join('-')
      }
      // If it's a number => 3 parts
      else {
        name = parts.shift()
        price = parts.pop()
        title = parts.join('-')
      }

      // TODO : Check si on peut le récupérer à la place de le calculer à nouveau
      // Calculate the given shipping price
      // shopify-UK%203-5%20working%20days-4.94
      // shopify-UK%203-5%20working%20days-4.94-121213323-121213323
      let shippingPrice = price
      const checkoutShippingInfo = checkoutState.shippingSlot
      if (checkoutShippingInfo) {
        const shippingPriceDiscounted = parseFloat(
          (parseFloat(price) - checkoutShippingInfo.discount / 100).toFixed(2)
        )
        if (shippingPriceDiscounted >= 0) {
          shippingPrice = shippingPriceDiscounted.toString()
        } else {
          shippingPrice = '0'
        }
      }

      baseArray.push({
        label: 'method',
        value: `${checkoutState.shippingTitle} · <strong>${currencify(
          shippingPrice,
          currency
        )}</strong>`,
        changeCallback: () => setCurrentStep(1),
      })
    }

    return baseArray
  }, [checkoutState, currency, setCurrentStep])

  const hasDiscountSubscription = useMemo(
    () => !(shopper === null || shopper.id === null || shopper.sbm === 0),
    [shopper]
  )

  // 1st step : dispacth the checkout to the store and check if the payment method is MP1 OR MP2
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickPayButton = async (
    values: FormikValues,
    setFieldError,
    setSubmitting
  ) => {
    sendAmplitudeData(`Click - Shopify Chkt - Payment - Pay`, {
      merchantID: storeSettings.merchantId,
      merchantSite: storeSettings.myShopifyDomain,
      orderID: checkoutSession?.order?.id,
      currency: checkoutSession.store.currency,
      orderTotalValue: checkoutShopify.paymentDue.amount,
      checkoutSessionID: checkoutSession.id,
      slotProductCheckbox:
        !!checkoutSession.cart.attributes?.slotProductCheckbox,
      isLogged: !!checkoutSession.customer,
      shippingMethodID: checkoutState.shippingMethod,
      shippingMethodLabel: checkoutState.shippingTitle,
      billingAddressDifferent: !(formik.values.billingAddressMethod === 'same'),
    })
    if (
      checkoutShopify.shippingLine === null &&
      checkoutState.requiresShipping
    ) {
      const shippingPrice =
        parseFloat(checkoutShopify.paymentDue.amount) -
        parseFloat(checkoutShopify.subtotalPrice.amount)

      const selectedShippingMethod =
        checkoutShopify.availableShippingRates.shippingRates.find(
          rate => parseFloat(rate.price.amount) === shippingPrice
        )

      if (selectedShippingMethod) {
        try {
          sendAmplitudeData('Log - Shopify - ShippingLine null', {
            merchantID: storeSettings.merchantId,
            checkoutSessionID: checkoutSessionId,
            merchantName: storeSettings.name,
            email: checkoutShopify.email,
            message: 'Update shipping line manually',
          })
        } catch (e) {
          console.error(e)
        }

        await dispatch(
          UpdateCheckoutShopify({
            ...checkoutShopify,
            shippingLine: selectedShippingMethod,
          })
        )
      } else {
        try {
          sendAmplitudeData('Log - Shopify - ShippingLine null', {
            merchantID: storeSettings.merchantId,
            checkoutSessionID: checkoutSessionId,
            merchantName: storeSettings.name,
            email: checkoutShopify.email,
            message: 'Not possible to update the shipping line manually',
          })
        } catch (e) {
          console.error(e)
        }

        toastError(
          { msg: 'Please select a shipping method' },
          'Error before create ecommerce session'
        )
        handlePreviousStep()
        return
      }
    }

    // TODO : Pb => Pour MP2, le checkoutState n'a pas le temps de s'update en fonction des infos du formik
    // Il faut l'update avant de pouvoir lancer les flows de payement
    // Grosse refacto à faire pour gérer en REDUX
    try {
      await dispatch(
        UpdateCheckout({
          ...checkoutState,
          ...values,
        })
      )

      // Copy billing value if needed
      if (checkoutState.billingAddressMethod !== 'same') {
        checkoutState.address1Billing = values.address1Billing
        checkoutState.address2Billing = values.address2Billing
        checkoutState.cityBilling = values.cityBilling
        checkoutState.countryBilling = values.countryBilling
        checkoutState.countryCodeBilling = values.countryCodeBilling
        checkoutState.firstNameBilling = values.firstNameBilling
        checkoutState.lastNameBilling = values.lastNameBilling
        checkoutState.zipBilling = values.zipBilling
        checkoutState.phoneBilling = values.phoneBilling
        checkoutState.provinceBilling = values.provinceBilling
        checkoutState.provinceCodeBilling = values.provinceCodeBilling
      }

      // TODO : Remove de 'paid' et uncomment here to do the checkout creation before the payment
      // await createOrder()

      if (values.paymentMethod === 'MP1') {
        MP1PaymentFlowStart(values, setFieldError, setSubmitting)
      } else if (values.paymentMethod === 'MP2') {
        MP2PaymentFlowStart()
      }
    } catch (error) {
      console.log('UpdateCheckout error ', error)
    }
  }

  const formik = useFormik(formikPayment(checkoutState, handleClickPayButton))

  const displayStripeErrors = (error, setFieldError) => {
    switch (error.code) {
      case 'incomplete_number':
        setFieldError('cardNumber', error.message)
        break
      case 'incomplete_expiry':
        setFieldError('cardExpiry', error.message)
        break
      case 'incomplete_cvc':
        setFieldError('cardCvc', error.message)
        break
    }
  }

  const createECommerceSession = async mode => {
    try {
      const payloadUrlRequest = await createEcommerceSessionService({
        integratedPayload,
        paymentMethodId,
        mode,
        checkoutSessionId,
        checkoutShopify,
        checkoutState,
        shopper,
        summary,
        currency,
        storeSettings,
      })

      if (payloadUrlRequest.redirectionMode === 'redirection') {
        window.location.href = payloadUrlRequest.url
      } else {
        const url = new URL(payloadUrlRequest.url)
        // add shopper infos to url
        url.searchParams.append('si', JSON.stringify(shopper))
        // add shopName to url
        url.searchParams.append('sn', storeSettings.name)
        setIFrameUrl(url.toString())
      }
    } catch (error) {
      // TODO: Alert system via slack - email - sentry
      console.log('Error on create ecommercesession')
      window.location.href = checkoutShopify.webUrl
    }
  }

  // 1. Create a PaymentMethod
  // 2. Create a EcommerceSession with paymentMethodId etc...
  // 3. Create a reponse je reçois l'url de l'iFrame
  // 4. Je l'affiche & je l'écoute
  // 5. Quand je reçois paid je fais le createOrder
  // 6. Update de l'email
  // 7. Update le return url + EComSession
  const MP1PaymentFlowStart = async (values, setFieldError, setSubmitting) => {
    if (!values?.cardName) {
      setFieldError('cardName', 'Please fill in the card name')
      setSubmitting(false)
      return
    }

    // 1. Create a PaymentMethod
    const paymentMethodResponse = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (paymentMethodResponse.error) {
      displayStripeErrors(paymentMethodResponse.error, setFieldError)
      setSubmitting(false)
    } else if (paymentMethodResponse?.paymentMethod.id) {
      setPaymentMethodId(paymentMethodResponse.paymentMethod.id ?? null)
    }
  }

  const MP2PaymentFlowStart = () => {
    createECommerceSession('redirection')
  }

  const execEvent = useCallback(
    event => {
      const data = event.data
      // console.log('LOG => ' + JSON.stringify(data))
      switch (data.action) {
        case 'paid':
          // NOTHING TO DO
          break
        case 'open-new-tab':
          window.open(data.url, '_blank')
          break
        case 'redirect':
          redirectFromPaymentEvent(event, data.url)
          break
        case 'finish-moona-process':
          if (data.url) {
            window.location.href = data.url
          } else {
            window.location.href = `https://${
              storeSettings.myShopifyDomain
            }/apps/cleever-api/redirect-clear-cart?return_to=${encodeURIComponent(
              storeSettings.myShopifyDomain
            )}`
          }
          break
        case 'go-to-cart':
          redirectFromPaymentEvent(
            event,
            `https://${storeSettings.myShopifyDomain}/cart`
          )
          break
        case 'go-to-checkout':
          redirectFromPaymentEvent(
            event,
            `https://${storeSettings.myShopifyDomain}/checkout`
          )
          break
        case 'cancel':
          redirectFromPaymentEvent(
            event,
            `https://${storeSettings.myShopifyDomain}/cart`
          )
          break
        default:
          console.log('There is no response of Cleever discount API')
      }
    },
    [storeSettings.myShopifyDomain]
  )

  const iFrameEventListener = useCallback(
    (event: any) => {
      // if (
      //   !event.origin.includes('staging.secure-payment-ecommerce.com') &&
      //   !event.origin.includes('staging.secure-payment.moona.com') &&
      //   !event.origin.includes('secure-payment-ecommerce.com') &&
      //   !event.origin.includes('secure-payment.moona.com') &&
      //   !event.origin.includes(
      //     'feature-payment-shopify.d1laipzguht15.amplifyapp.com'
      //   ) &&
      //   !event.origin.includes(
      //     'feature-shopify.d1laipzguht15.amplifyapp.com'
      //   ) &&
      //   !event.origin.includes('127.0.0.1:3000')
      // ) {
      //   return
      // }

      var data = event.data
      if (data.source !== 'Moona') {
        console.log('Cleever discount: Wrong source')
      } else {
        execEvent(event)
      }
    },
    [execEvent]
  )

  useEffect(() => {
    sendAmplitudeData(`View - Shopify Chkt - Payment`, {
      merchantID: storeSettings.merchantId,
      merchantSite: storeSettings.myShopifyDomain,
      orderID: checkoutSession?.order?.id,
      currency: checkoutSession.store.currency,
      orderTotalValue: checkoutShopify.paymentDue.amount,
      checkoutSessionID: checkoutSession.id,
      slotProductCheckbox:
        !!checkoutSession.cart.attributes?.slotProductCheckbox,
      isLogged: !!checkoutSession.customer,
      shippingMethodID: checkoutState.shippingMethod,
      shippingMethodLabel: checkoutState.shippingTitle,
    })
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [loading])

  // After MP1 => setPaymentMethodId
  useEffect(() => {
    if (paymentMethodId) {
      createECommerceSession('integrated')
    }
  }, [paymentMethodId])

  // After createEcommerceSession => setIFrameUrl
  useEffect(() => {
    if (iFrameUrl) {
      window.addEventListener('message', iFrameEventListener, false)
    } else {
      return () => {
        window.removeEventListener('message', iFrameEventListener)
      }
    }
  }, [iFrameUrl])

  useEffect(() => {
    if (shopper.sbm > 0) {
      formik.setFieldValue('getDiscount', false)
      dispatch(UpdateGetDiscount(false))
      dispatch(ClearSlotProductCheckbox())
    }
  }, [shopper])

  useEffect(() => {
    if (formik.values.billingAddressMethod) {
      checkoutState.billingAddressMethod = formik.values.billingAddressMethod

      sendAmplitudeData(`Click - Shopify Chkt - Payment - Billing address`, {
        merchantID: storeSettings.merchantId,
        merchantSite: storeSettings.myShopifyDomain,
        orderID: checkoutSession?.order?.id,
        currency: checkoutSession.store.currency,
        orderTotalValue: checkoutShopify.paymentDue.amount,
        checkoutSessionID: checkoutSession.id,
        slotProductCheckbox:
          !!checkoutSession.cart.attributes?.slotProductCheckbox,
        isLogged: !!checkoutSession.customer,
        shippingMethodID: checkoutState.shippingMethod,
        shippingMethodLabel: checkoutState.shippingTitle,
        billingAddressDifferent: !(
          formik.values.billingAddressMethod === 'same'
        ),
      })
    }
  }, [formik.values.billingAddressMethod])

  useEffect(() => {
    if (discountBlocDisplay && parseFloat(summary.total) < 10) {
      setDiscountBlocDisplay(false)
      formik.setFieldValue('getDiscount', false)
      dispatch(UpdateGetDiscount(false))
      // dispatch(ClearSlotProductCheckbox())
    }
    if (!discountBlocDisplay && parseFloat(summary.total) >= 10) {
      setDiscountBlocDisplay(true)
    }

    // Allow to display the discount checkbox bloc in the payment page
    if (doNotdisplayDiscountBlocInPaymentPage(storeSettings.merchantId)) {
      setDiscountBlocDisplay(false)
    }
  }, [summary.total])

  useEffect(() => {
    if (checkoutState.saveForm && checkoutShopify.webUrl) {
      window.location.href = checkoutShopify.webUrl
    }
  }, [checkoutState.saveForm])

  return loading ? (
    <InformationSkeleton />
  ) : (
    <>
      <InformationsBloc infos={infos} />
      <form onSubmit={formik.handleSubmit}>
        {discountBlocDisplay && (
          <DiscountBloc
            shopper={shopper}
            checkoutState={checkoutState}
            checkoutShopify={checkoutShopify}
            currency={currency}
            formik={formik}
            slotProductCheckbox={slotProductCheckbox}
            hasDiscountSubscription={hasDiscountSubscription}
          />
        )}

        <PaymentBloc
          formik={formik}
          shopper={shopper}
          slotMP2={storeSettings.slotMP2}
          currency={currency}
          slotProductCheckbox={slotProductCheckbox}
          hasDiscountSubscription={hasDiscountSubscription}
          totalPrice={parseFloat(summary.total)}
        />

        {cart.requiresShipping && <BillingAddressBloc formik={formik} />}

        {/* <CheckoutFormGroup>
          <H3>Remember me</H3>
          <BorderedWrapper
            style={{
              marginTop: 0,
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              gap: 21,
              padding: 17,
              minHeight: 55,
            }}
          >
            <CheckoutCheckbox
              name="rememberMe"
              label="Save my information for a faster checkout"
              onChange={formik.handleChange}
              checked={formik.values.rememberMe}
            />
          </BorderedWrapper>
        </CheckoutFormGroup> */}

        <BottomForm
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          handlePreviousStep={handlePreviousStep}
          backTitle={!isFirstStep && steps[step.id - 1].title}
          nextTitle={!isLastStep && steps[step.id + 1].title}
          formik={formik}
        />
      </form>
      {iFrameUrl && (
        <IFrameWrapper>
          {/* temporaire */}
          <CustomIFrame
            src={
              window.location.hostname.includes('localhost')
                ? iFrameUrl.replace(
                    'https://staging.secure-payment.moona.com',
                    'http://localhost:3000'
                  )
                : iFrameUrl
            }
            // src={ iFrameUrl }
          ></CustomIFrame>
          {/* temporaire */}
        </IFrameWrapper>
      )}
    </>
  )
}

export default PaymentStep
