import { initialShopperState } from './initialStates'
import { UPDATE_SHOPPER, ShopperActionTypes } from './types'

const ShopperReducer = (
  state = initialShopperState,
  action: ShopperActionTypes
) => {
  switch (action.type) {
    case UPDATE_SHOPPER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default ShopperReducer
