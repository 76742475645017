import React from 'react'

import { ToastContainer, ToastContent, toast } from 'react-toastify'
import styled from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'
import { Image } from 'common/style/components/image'
import { Loader } from 'common/style/components/loader'
import { NameableSpan } from 'common/style/components/Section'
import { tablet } from 'common/style/queries/media-queries'

import check from './check.svg'
import close from './close.svg'
import stop from './stop.svg'

const TopToastContainer = styled(ToastContainer)`
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 0 1.76rem;

  @media ${tablet} {
    padding: 0;
    justify-content: center;
    max-width: 85.5rem;
  }

  & > .Toastify__toast {
    width: 100%;
    margin-bottom: 1rem;
    height: 6.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #ffffff;
    &:first-of-type {
      border-radius: 0 0 12px 12px;
    }
    border-radius: 12px;

    &--success {
      background: ${props => props.theme.colors.colorsuccess};
    }

    &--error {
      background: ${props => props.theme.colors.colorreddanger};
    }

    &--info {
      background: ${props => props.theme.colors.colororangepending};
      border-radius: 12px !important;
      color: white;
    }
  }
`

const Close = styled(Image)``
const Check = styled(Image)`
  margin-right: 1.6rem;
`
const Stop = styled(Image)`
  margin-right: 1.6rem;
`

export const MoonaCenterTopToastContainer: React.FC<{
  closeButton?: boolean
}> = ({ closeButton = true }) => {
  return (
    <TopToastContainer
      position="top-center"
      className="top-toast-container"
      autoClose={1000 * 5}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      closeButton={closeButton ? <Close src={close} /> : <></>}
    />
  )
}

const ToastMessageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const topSuccess = (msg: string, name: string) => {
  const content: ToastContent = (
    <ToastMessageContainer>
      <Check src={check} />
      <NameableSpan name={name}>{msg}</NameableSpan>
    </ToastMessageContainer>
  )
  return toast.success(content, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

const ToastErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 2.4rem;
`

interface ToastMessage {
  msg: string
}

const ErrorMessage = styled(NameableSpan)`
  @media ${tablet} {
    max-width: 50rem;
  }
`
export const toastError = (
  { msg }: ToastMessage,
  name: string,
  onClose?: () => void
) => {
  const content: ToastContent = (
    <ToastErrorMessageContainer>
      <Stop src={stop} />
      <ErrorMessage name={name}>{msg}</ErrorMessage>
    </ToastErrorMessageContainer>
  )
  return toast.error(content, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: () => onClose,
  })
}

export const toastPending = (msg: string, name: string) => {
  const content: ToastContent = (
    <ToastMessageContainer>
      <Loader
        color="white"
        mr="1.7rem"
        mt="9px"
        height="4px !important"
        width="4px !important"
        fontSize="3px !important"
      />
      <NameableSpan name={name}>{msg}</NameableSpan>
    </ToastMessageContainer>
  )
  return toast.info(content, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    closeButton: false,
  })
}
