import React, { FC } from 'react'

import styled from 'styled-components'

import * as Icons from './icons'

export type MoonaIconProps = {
  icon: string
  className?: string
  style?: any
  onClick?: () => void
} & any

const CustomIconBox = styled.span`
  position: relative;
  height: auto;
  &:hover {
    cursor: pointer;
  }
`

const MoonaIcon: FC<MoonaIconProps> = ({
  icon,
  className = '',
  onClick,
  ...rest
}) => {
  const { ...icons } = Icons
  const CustomIcon = icons[icon]

  if (!CustomIcon) {
    console.log(`Icon ${icon} not found in`, Icons)
    return null
  }

  return onClick ? (
    <CustomIconBox onClick={onClick}>
      <CustomIcon className={`moona-icon ${className}`} {...rest} />
    </CustomIconBox>
  ) : (
    <CustomIcon className={`moona-icon ${className}`} {...rest} />
  )
}

export default MoonaIcon
