import React from 'react'

import { useSelector } from 'react-redux'

import Flex from '../Primitives/Flex'

import CardCvcInput from './CardInputShopify/CardCvcInput'
import CardExpiryInput from './CardInputShopify/CardExpiryInput'
import CardNumberInput from './CardInputShopify/CardNumberInput'
import { CheckoutInput } from './Checkout'

const StripeFormShopify = ({ formik }) => {
  const { isMobile } = useSelector((state: any) => state.session)
  return (
    <Flex direction="column" spacing="17px">
      <CardNumberInput
        error={formik?.errors?.cardNumber ?? null}
        setFieldError={formik.setFieldError}
      />
      <Flex
        align="flex-start"
        direction={isMobile ? 'column' : 'row'}
        spacing="17px"
      >
        <CardExpiryInput
          error={formik?.errors?.cardExpiry ?? null}
          setFieldError={formik.setFieldError}
        />
        <CardCvcInput
          error={formik?.errors?.cardCvc ?? null}
          setFieldError={formik.setFieldError}
        />
      </Flex>

      <CheckoutInput
        label="Cardholder name"
        name="cardName"
        value={formik.values.cardName}
        onChange={formik.handleChange}
        errors={formik.errors}
      />
    </Flex>
  )
}

export default StripeFormShopify
