import React, { FC, useEffect, useMemo } from 'react'

import { useFormik } from 'formik'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'

import MoonaIcon from 'common/components/Icon/MoonaIcon'
import { H3 } from 'common/style/components/Typography'
import {
  CheckoutFormGroup,
  CheckoutRadioGroupWrapper,
  CheckoutRadioItem,
} from 'common/style/pages/checkout'
import { StepComponentProps } from 'pages/Checkout/CheckoutFlow'
import { UpdateCheckout } from 'state/features/checkout/action'
import { currencySelector } from 'state/features/checkoutSession/selector'
import { summarySelector } from 'state/features/checkoutShopify/selector'
import { AppState } from 'state/features/types'
import { currencify } from 'utils/string'

import BottomForm from '../BottomForm'
import formikShipping from '../formikObject/shipping'

import InformationsBloc from './InformationsBloc'
import ShippingDisplayer from './ShippingDisplayer'
import InformationSkeleton from 'common/components/Skeleton/Checkout/InformationSkeleton'
import { scrollToTop } from 'utils/scroll'
import { sendAmplitudeData } from 'common/utilities/amplitude'

type OtherPaymentMethodObjectKeys = 'paypal' | 'klarna' | 'clearpay' | 'revolut'

type OtherPaymentMethodObject = {
  [key in OtherPaymentMethodObjectKeys]: {
    displayName: string
    iconKey: string
  }
}

const ShippingStep: FC<StepComponentProps> = ({
  step,
  steps,
  handleNextStep,
  handlePreviousStep,
  isFirstStep,
  isLastStep,
  setCurrentStep,
  loading,
}) => {
  const currency = useSelector(currencySelector)
  const {
    checkout: checkoutState,
    checkoutShopify,
    storeSettings,
    checkoutSession,
    shopper,
  } = useSelector((state: AppState) => state)
  const { total } = useSelector(summarySelector)
  const dispatch = useDispatch()
  const [paymentMethodsLoading, setPaymentMethodsLoading] = React.useState(true)
  const {
    cart: { attributes, requiresShipping },
  } = checkoutSession

  const hasDiscountSubscription = useMemo(
    () => !(shopper === null || shopper.id === null || shopper.sbm === 0),
    [shopper]
  )

  const otherPaymentMethods: OtherPaymentMethodObject = {
    paypal: {
      displayName: 'Paypal',
      iconKey: 'Paypal',
    },
    clearpay: {
      displayName: 'ClearPay',
      iconKey: 'Clearpay',
    },
    klarna: {
      displayName: 'Klarna - Flexible payments',
      iconKey: 'Klarna',
    },
    revolut: {
      displayName: 'Revolut payment',
      iconKey: 'Revolut',
    },
  }

  const formik = useFormik(
    formikShipping(checkoutState, async values => {
      let checkout = { ...values }

      if (values.paymentMethod === 'MP2') {
        checkout = Object.assign(checkout, {
          getDiscount: true,
          paymentMethod: 'MP1',
        })
      }

      dispatch(UpdateCheckout(checkout))

      let paymentMethodLabel = ''
      if (values.paymentMethod === 'MP1') {
        paymentMethodLabel = 'Credit Card'
      } else if (values.paymentMethod === 'MP2') {
        paymentMethodLabel = `CARD - ${currencify(
          5,
          currency
        )} OFF with YCoupon`
      } else {
        paymentMethodLabel =
          otherPaymentMethods[values.paymentMethod].displayName
      }

      await sendAmplitudeData(`Click - Shopify Chkt - Shipping Select - Next`, {
        merchantID: storeSettings.merchantId,
        merchantSite: storeSettings.myShopifyDomain,
        paymentMethodID: formik.values.paymentMethod,
        paymentMethodLabel,
        orderID: checkoutSession?.order?.id,
        currency: checkoutSession.store.currency,
        checkoutSessionID: checkoutSession.id,
        slotProductCheckbox:
          !!checkoutSession.cart.attributes?.slotProductCheckbox,
        shippingMethodID: checkoutState.shippingMethod,
        shippingMethodLabel: checkoutState.shippingTitle,
      })
      if (
        storeSettings.paymentMethods.length > 0 &&
        storeSettings.paymentMethods.includes(checkout.paymentMethod)
      ) {
        window.location.href = checkoutShopify.webUrl
      } else {
        handleNextStep()
      }
    })
  )

  const infos = useMemo(() => {
    const baseArray = [
      {
        label: 'contact',
        value: checkoutState.email,
        changeCallback: () => setCurrentStep(0),
      },
    ]

    if (requiresShipping) {
      baseArray.push({
        label: 'shipTo',
        value: `${checkoutState.address1}, ${checkoutState.zip} ${checkoutState.city}`,
        changeCallback: () => setCurrentStep(0),
      })
    }

    return baseArray
  }, [checkoutState, setCurrentStep])

  useEffect(() => {
    scrollToTop()
  }, [loading])

  useEffect(() => {
    if (!formik.isSubmitting) {
      let hasDiscountCheck = false

      if (formik.values.paymentMethod === 'MP1') {
        if (hasDiscountSubscription === false) {
          if (attributes?.slotProductCheckbox) {
            hasDiscountCheck = true
          } else {
            hasDiscountCheck = checkoutState.getDiscount
          }
        }
      }

      formik.setFieldValue('getDiscount', hasDiscountCheck)
      dispatch(
        UpdateCheckout({
          getDiscount: hasDiscountCheck,
          paymentMethod: formik.values.paymentMethod,
        })
      )
    }
  }, [formik.values.paymentMethod])

  useEffect(() => {
    if (!requiresShipping) {
      setPaymentMethodsLoading(false)
    }
  }, [requiresShipping])

  // verify if payment selected is available otherwise set MP1
  useEffect(() => {
    if (formik.values.paymentMethod === 'MP2') {
      if (
        (storeSettings.slotMP2 &&
          !hasDiscountSubscription &&
          parseFloat(total) > 10) === false
      ) {
        formik.setFieldValue('paymentMethod', 'MP1')
      }
    }
    if (['MP1', 'MP2'].includes(formik.values.paymentMethod) === false) {
      if (checkoutState?.cleeverShippingPrice) {
        formik.setFieldValue('paymentMethod', 'MP1')
      }
    }
  }, [
    paymentMethodsLoading,
    formik.values.paymentMethod,
    hasDiscountSubscription,
    storeSettings.slotMP2,
    total,
    checkoutState?.cleeverShippingPrice,
  ])

  useEffect(() => {
    if (checkoutState.saveForm && checkoutShopify.webUrl) {
      window.location.href = checkoutShopify.webUrl
    }
  }, [checkoutState.saveForm])

  return loading ? (
    <InformationSkeleton />
  ) : (
    <>
      <InformationsBloc infos={infos} />
      <form onSubmit={formik.handleSubmit}>
        {/* TODO : check later for use React.cloneElement in return of
        ShippingDisplayer */}
        {/* <ShippingDisplayer>
          {({ shippings }) =>
            shippings.length > 0 && (
              <CheckoutFormGroup>
                <H3>Shipping Method</H3>
                <CheckoutRadioGroup
                  name="shippingMethod"
                  onChange={formik.handleChange}
                  setFieldValue={formik.setFieldValue}
                  value={formik.values.shippingMethod}
                  options={shippings}
                />
              </CheckoutFormGroup>
            )
          }
        </ShippingDisplayer> */}
        {requiresShipping && (
          <ShippingDisplayer
            formik={formik}
            paymentMethodsLoading={paymentMethodsLoading}
            setPaymentMethodsLoading={setPaymentMethodsLoading}
            hasDiscountSubscription={hasDiscountSubscription}
            totalAmount={total}
            otherPaymentMethods={otherPaymentMethods}
          />
        )}

        {(storeSettings.paymentMethods.length > 0 || storeSettings.slotMP2) && (
          <CheckoutFormGroup>
            <H3>Payment Method</H3>
            {paymentMethodsLoading ? (
              <Skeleton height="60px" />
            ) : (
              <CheckoutRadioGroupWrapper>
                {/* SLOT MP1 */}
                <CheckoutRadioItem
                  className={`checkout-radio-option ${
                    formik.values.paymentMethod === 'MP1' ? 'active' : ''
                  }`}
                  onClick={() => formik.setFieldValue('paymentMethod', 'MP1')}
                >
                  <input
                    type="radio"
                    readOnly
                    value="MP1"
                    name="paymentMethod"
                    checked={formik.values.paymentMethod === 'MP1'}
                  />
                  <label>Credit card</label>
                  <div
                    style={{
                      marginLeft: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                    }}
                  >
                    <MoonaIcon icon="VisaCard" />
                    <MoonaIcon icon="MasterCard" />
                    <MoonaIcon icon="AmexCard" />
                    <MoonaIcon icon="UnknowCard" />
                    <p>and more...</p>
                  </div>
                </CheckoutRadioItem>
                {/* SLOT MP2 */}
                {storeSettings.slotMP2 &&
                  !hasDiscountSubscription &&
                  parseFloat(total) > 10 && (
                    <CheckoutRadioItem
                      className={`checkout-radio-option ${
                        formik.values.paymentMethod === 'MP2' ? 'active' : ''
                      }`}
                      onClick={() =>
                        formik.setFieldValue('paymentMethod', 'MP2')
                      }
                    >
                      <input
                        type="radio"
                        readOnly
                        value="MP2"
                        name="paymentMethod"
                        checked={formik.values.paymentMethod === 'MP2'}
                      />
                      <label>
                        CARD - {currencify(5, currency)} OFF with YCoupon
                      </label>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 'auto',
                        }}
                      >
                        <MoonaIcon icon="YCoupon" />
                      </div>
                    </CheckoutRadioItem>
                  )}
                {!checkoutState?.cleeverShippingPrice &&
                  !checkoutState.getDiscount &&
                  storeSettings.paymentMethods.map((method, index) => (
                    <CheckoutRadioItem
                      key={index}
                      className={`checkout-radio-option ${
                        formik.values.paymentMethod === method ? 'active' : ''
                      }`}
                      onClick={() =>
                        formik.setFieldValue('paymentMethod', method)
                      }
                    >
                      <input
                        type="radio"
                        readOnly
                        value={method}
                        name="paymentMethod"
                        checked={formik.values.paymentMethod === method}
                      />
                      <label>
                        {checkoutState?.cleeverShippingPrice}
                        {otherPaymentMethods[method].displayName}
                      </label>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 'auto',
                          height: '24px',
                        }}
                      >
                        <MoonaIcon
                          icon={otherPaymentMethods[method].iconKey}
                          height="20px"
                        />
                      </div>
                    </CheckoutRadioItem>
                  ))}
              </CheckoutRadioGroupWrapper>
            )}
          </CheckoutFormGroup>
        )}
        <BottomForm
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          handlePreviousStep={handlePreviousStep}
          backTitle={!isFirstStep && steps[step.id - 1].title}
          nextTitle={!isLastStep && steps[step.id + 1].title}
          formik={formik}
          disabled={
            checkoutShopify.availableShippingRates?.shippingRates?.length < 1
          }
        />
      </form>
    </>
  )
}

export default ShippingStep
