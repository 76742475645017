import React, { ChangeEventHandler, FC } from 'react'

import styled from 'styled-components'

import Flex from '../../Primitives/Flex'

type CheckoutCheckboxProps = {
  name: string
  label: string
  labelProps?: any
  onChange: ChangeEventHandler<HTMLInputElement>
  checked: boolean
}

const StyledCheckbox = styled('input')`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  height: 18px;
  width: 18px;
  border: 1px solid;
  border-color: #d9d9d9;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;

  &:focus {
    border-color: ${props => props.theme.checkoutColors.primary};
  }

  &:hover {
    cursor: pointer;
  }

  &:checked {
    border-color: ${props => props.theme.checkoutColors.primary};
    background: ${props => props.theme.checkoutColors.primary};
    margin: 0;
    position: relative;

    &:before {
      content: '✓';
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      padding-bottom: 2px;
      transform: translate(-50%, -50%);
    }
  }
`

const CheckoutCheckbox: FC<CheckoutCheckboxProps> = ({
  label,
  labelProps = {},
  ...rest
}) => {
  return (
    <Flex
      className="checkout-checkbox"
      spacing="9px"
      align="center"
      style={{
        height: '18px',
        fontSize: '14px',
        color: '#545454',
      }}
    >
      <StyledCheckbox type="checkbox" {...rest} />
      <label {...labelProps} dangerouslySetInnerHTML={{ __html: label }} />
    </Flex>
  )
}

export default CheckoutCheckbox
