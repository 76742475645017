import InformationStep from 'common/components/Checkout/InformationStep/InformationStep'
import PaymentStep from 'common/components/Checkout/PaymentStep/PaymentStep'
import ShippingStep from 'common/components/Checkout/ShippingStep/ShippingStep'

import { CheckoutFlowStepsState } from './types'

export const initialCheckoutFlowStepsState: CheckoutFlowStepsState = [
  {
    id: 0,
    title: 'Information',
    component: InformationStep,
    validate: false,
  },
  {
    id: 1,
    title: 'Shipping',
    component: ShippingStep,
    validate: false,
  },
  {
    id: 2,
    title: 'Payment',
    component: PaymentStep,
    validate: false,
  },
]
export const checkoutFlowStepsStateWithoutShipping: CheckoutFlowStepsState = [
  {
    id: 0,
    title: 'Information',
    component: InformationStep,
    validate: false,
  },
  {
    id: 1,
    title: 'Payment',
    component: PaymentStep,
    validate: false,
  },
]
