import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Flex from 'common/components/Primitives/Flex'
import { tablet } from 'common/style/queries/media-queries'

type InfoProps = {
  label: string
  value: string
  changeCallback: () => void
}

type InformationsBlocProps = {
  infos?: InfoProps[]
}

const InformationsBlocStyled = styled('div')`
  padding: 6px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 17px;
  margin-bottom: 34px;

  .flex-item {
    padding: 12px 0;
    &:not(:first-child) {
      border-top: 1px solid #d9d9d9;
    }

    p:first-child {
      color: #737373;
    }

    button:last-child {
      margin-left: auto;
      border: none;
      outline: none;
      background: none;
      color: ${props => props.theme.checkoutColors.primary};

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media ${tablet} {
    font-size: 14px;
  }
`

const InformationsBloc: FC<InformationsBlocProps> = ({ infos }) => {
  const [t] = useTranslation('app')
  const informationsBlocLabels: any = t('checkout.informationsBloc', {
    returnObjects: true,
  })

  return (
    <>
      <InformationsBlocStyled>
        {infos.map(info => {
          const { label, value, changeCallback } = info
          return (
            <Flex
              key={label}
              className="flex-item"
              align="center"
              spacing="14px"
            >
              <p>{informationsBlocLabels[label]}</p>
              <p dangerouslySetInnerHTML={{ __html: value }}></p>
              <button type="button" onClick={changeCallback}>
                {informationsBlocLabels.change}
              </button>
            </Flex>
          )
        })}
      </InformationsBlocStyled>
    </>
  )
}

export default InformationsBloc
