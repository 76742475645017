import { FormikValues } from 'formik'
import * as Yup from 'yup'

import { initialCheckoutPaymentState } from 'state/features/checkout/initialStates'

const formikPayment = (
  checkoutState,
  callback: (
    values: FormikValues,
    setFieldError: (field: string, value: string) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void
) => {
  const initialState = initialCheckoutPaymentState
  Object.keys(initialState).forEach(key => {
    initialState[key] = checkoutState[key]
  })

  const makeRequired = (field: string) =>
    Yup.string().when('billingAddressMethod', {
      is: val => val !== 'same',
      then: Yup.string().required(`${field} is required`),
    })

  return {
    initialValues: { ...initialState },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values: FormikValues, { setFieldError, setSubmitting }) => {
      callback(values, setFieldError, setSubmitting)
    },
    validationSchema: Yup.object().shape({
      getDiscount: Yup.boolean(),
      rememberMe: Yup.boolean(),
      paymentMethod: Yup.string(),
      billingAddressMethod: Yup.string(),
      firstNameBilling: makeRequired('Firstname'),
      lastNameBilling: makeRequired('Lastname'),
      address1Billing: makeRequired('Address'),
      address2Billing: Yup.string(),
      cityBilling: makeRequired('City'),
      countryCodeBilling: makeRequired('City'),
      provinceCodeBilling: Yup.string()
        .nullable()
        .when('requiresProvinceBilling', {
          is: val => val === true,
          then: Yup.string().required(`Province is required`),
        }),
      zipBilling: makeRequired('Zip'),
      phoneBilling: makeRequired('Phone'),
    }),
  }
}

export default formikPayment
