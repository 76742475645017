import React, { ChangeEventHandler, CSSProperties, FC } from 'react'

import { FormikErrors } from 'formik'
import { select } from 'redux-saga/effects'

import { CheckoutSelect, ErrorWrapper } from 'common/style/pages/checkout'

type CheckoutSelectInputProps = {
  name?: string
  label: string
  value: string
  errors?: FormikErrors<any>
  options: Array<{ value: string; label: string }>
  onChange: ChangeEventHandler<HTMLSelectElement>
  style?: CSSProperties
  useFormik?: boolean
}

const CheckoutSelectInput: FC<CheckoutSelectInputProps> = ({
  name,
  label,
  value,
  options,
  errors,
  onChange,
  style = {},
  useFormik = true,
}) => {
  const error = (errors && errors[name]) ?? null

  return useFormik ? (
    <ErrorWrapper className={error ? 'error' : ''}>
      <CheckoutSelect className={error ? 'error' : ''} style={style}>
        <select name={name} value={value} onChange={onChange}>
          {options.map((option, index) => (
            // suppressContentEditableWarning={true}
            // <option key={option.value ?? 'default'} value={option.value}>
            <option key={option.value ? `${option.value}_${index}` : 'default'} value={option.value} disabled={option.label === '---'} >
              {option.label}
            </option>
          ))}
        </select>
        <label>{label}</label>
      </CheckoutSelect>
      {error && <p className="text-error">{error}</p>}
    </ErrorWrapper>
  ) : (
    <CheckoutSelect style={style}>
      <select value={value} onChange={onChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label>{label}</label>
    </CheckoutSelect>
  )
}

export default CheckoutSelectInput
