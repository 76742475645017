import {
  checkoutFlowStepsStateWithoutShipping,
  initialCheckoutFlowStepsState,
} from './initialStates'
import {
  CheckoutFlowStepsActionTypes,
  CheckoutFlowStepsState,
  VALIDATE_CHECKOUT_FLOW_STEP,
  UNVALIDATE_CHECKOUT_FLOW_STEP,
  REMOVE_SHIPPING_STEP,
} from './types'

const CheckoutFlowStepsReducer = (
  state = initialCheckoutFlowStepsState,
  action: CheckoutFlowStepsActionTypes
): CheckoutFlowStepsState => {
  switch (action.type) {
    case VALIDATE_CHECKOUT_FLOW_STEP:
      return state.map(step => {
        if (step.id === action.payload) {
          return { ...step, validate: true }
        }
        return step
      })
    case UNVALIDATE_CHECKOUT_FLOW_STEP:
      return state.map(step => {
        if (step.id === action.payload) {
          return { ...step, validate: false }
        }
        return step
      })
    case REMOVE_SHIPPING_STEP:
      return checkoutFlowStepsStateWithoutShipping
    default:
      return state
  }
}

export default CheckoutFlowStepsReducer
