import { GetShopperInfoPayload } from 'common/hooks/useWebService'
import { parsePhoneNumber } from 'react-phone-number-input'
import apiInstance from 'services/api'
import WebService from 'services/webService'
import {
  checkoutEmailUpdateV2,
  checkoutShippingAddressUpdateV2,
} from 'shopify/mutations'
import {
  UpdateCheckout,
  UpdateGetDiscount,
} from 'state/features/checkout/action'
import { ClearSlotProductCheckbox } from 'state/features/checkoutSession/actions'
import { UpdateCheckoutShopify } from 'state/features/checkoutShopify/action'
import { UpdateShopper } from 'state/features/shopper/action'
import { initialShopperState } from 'state/features/shopper/initialStates'

export const handleSubmitInformationsStep = async ({
  checkoutId,
  values,
  successCallback,
  dispatch,
  setFieldError,
  setSubmitting,
  globalState,
}) => {
  const { getShopperInfo } = WebService(
    globalState.storeSettings.secretKeyTest,
    globalState.storeSettings.secretKeyLive
  )
  const requiresShipping = values.requiresShipping

  let checkoutUpdated = null

  let errorsEmail = []
  let errorsAddress = []
  let errorsPhone = []

  // Update Checkout shipping address
  if (requiresShipping) {
    let availableShippingRatesReady = false

    while (!availableShippingRatesReady) {
      const resultShippingAddressUpdateV2 = await apiInstance.post('', {
        query: checkoutShippingAddressUpdateV2,
        variables: {
          // responseEmailUpdate?.checkoutEmailUpdateV2?.checkout?.id,
          // globalState.checkoutShopify.id
          checkoutId: globalState.checkoutSession.checkoutShopifyId,
          shippingAddress: {
            address1: values.address1,
            city: values.city,
            company: values.company,
            country: values.country,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            province: values.province,
            zip: values.zip,
          },
          country: values.countryCode || 'GB',
        },
      })
      const payloadShippingAddressUpdateV2: any = Object.entries(
        resultShippingAddressUpdateV2.data
      )[0][1]
      const errorsShippingAddressUpdateV2 =
        payloadShippingAddressUpdateV2.checkoutShippingAddressUpdateV2
          ?.checkoutUserErrors

      if (errorsShippingAddressUpdateV2?.length > 0) {
        if (errorsShippingAddressUpdateV2[0].code === 'INVALID_FOR_COUNTRY') {
          setFieldError('zip', errorsShippingAddressUpdateV2[0].message)
          setSubmitting(false)
          return
        }
      }
      if (
        payloadShippingAddressUpdateV2.checkoutShippingAddressUpdateV2 &&
        payloadShippingAddressUpdateV2.checkoutShippingAddressUpdateV2
          .checkoutUserErrors?.length > 0
      ) {
        errorsAddress =
          payloadShippingAddressUpdateV2.checkoutShippingAddressUpdateV2
            .checkoutUserErrors
      }

      if (payloadShippingAddressUpdateV2.checkoutShippingAddressUpdateV2) {
        checkoutUpdated =
          payloadShippingAddressUpdateV2.checkoutShippingAddressUpdateV2
            .checkout
        availableShippingRatesReady =
          checkoutUpdated.availableShippingRates?.ready === true
      }

      // Attendre une seconde avant de relancer la requête
      await new Promise(resolve => setTimeout(resolve, 1000))
    }

    // if (values.phone) {
    //   console.log('values.phone 1', values.phone)
    //   console.log('values.phone 2', parsePhoneNumber(values.phone))
    //   const phoneValidated = parsePhoneNumber(values.phone)
    //   if (!phoneValidated || !phoneValidated.country) {
    //     errorsPhone.push({
    //       field: ['phone'],
    //       message: 'Enter a valid phone number to use this delivery method',
    //     })
    //   }
    // }

    if (globalState.storeSettings.smsMarketing && values.smsMarketingConsent) {
      if (
        !values.smsMarketingPhone ||
        parsePhoneNumber(values.smsMarketingPhone) === undefined ||
        parsePhoneNumber(values.smsMarketingPhone).country === undefined
      ) {
        errorsPhone.push({
          field: ['smsMarketingPhone'],
          message: 'Enter a valid phone number',
        })
      }
    }
  }

  if (values.email !== globalState.checkout.email || !requiresShipping) {
    // Update Checkout Email
    const resultEmailUpdate = await apiInstance.post('', {
      query: checkoutEmailUpdateV2,
      variables: {
        checkoutId: checkoutId,
        email: values.email,
        country: values.countryCode || 'GB',
      },
    })
    // const responseEmailUpdate: any = Object.entries(resultEmailUpdate.data)[0][1]
    const payloadEmailUpdate: any = Object.entries(resultEmailUpdate.data)[0][1]

    if (
      payloadEmailUpdate.checkoutEmailUpdateV2 &&
      payloadEmailUpdate.checkoutEmailUpdateV2.checkoutUserErrors?.length > 0
    ) {
      errorsEmail = payloadEmailUpdate.checkoutEmailUpdateV2.checkoutUserErrors
    }

    if (payloadEmailUpdate.checkoutEmailUpdateV2) {
      checkoutUpdated = payloadEmailUpdate.checkoutEmailUpdateV2.checkout
    }
  }

  const errors = [...errorsEmail, ...errorsAddress, ...errorsPhone]
  if (errors?.length > 0) {
    errors.forEach(error => {
      const fieldIndex = error.field?.length - 1
      setFieldError(error.field[fieldIndex], error.message)
    })
    setSubmitting(false)
  } else {
    dispatch(UpdateCheckoutShopify(checkoutUpdated))
    dispatch(
      UpdateCheckout({
        ...values,
      })
    )

    if (
      values.email !== globalState.checkout.email ||
      globalState.shopper.id === null
    ) {
      const getShopperInfoPayload: GetShopperInfoPayload = {
        email: values.email,
        ecommerce_solution: globalState.storeSettings.ecommerceSolution,
        ecommerce_version: globalState.storeSettings.ecommerceVersion,
        plugin_version: globalState.storeSettings.pluginVersion,
        api_version: globalState.storeSettings.apiVersion,
        merchant_id: globalState.storeSettings.merchantId,
      }
      try {
        const getShopperInfoData = await getShopperInfo(getShopperInfoPayload)

        console.log('getShopperInfoData', getShopperInfoData.body)
        if (Object.keys(getShopperInfoData.body).length === 0) {
          dispatch(UpdateShopper(initialShopperState))
        } else {
          dispatch(UpdateShopper(getShopperInfoData.body))
          if (getShopperInfoData.body.sbm !== 0) {
            dispatch(ClearSlotProductCheckbox())
            dispatch(UpdateGetDiscount(false))
          }
        }
      } catch (error) {
        dispatch(UpdateShopper(initialShopperState))
        console.log(error)
      }
    }

    if (values.countryCode !== 'GB') {
      window.location.href = checkoutUpdated.webUrl
    }

    // Redirect to the Shopify chekcout if the currency is not GBP
    else if (checkoutUpdated.subtotalPrice?.currencyCode !== 'GBP') {
      window.location.href = checkoutUpdated.webUrl
    } else {
      successCallback()
    }
  }
}
