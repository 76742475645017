import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

export const Tooltip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    &[class*='place-'] {
      padding: 11px;
      background: #333;
      color: #fff;
      max-width: 200px;
      text-align: center;
    }
    &.place-top {
      &::after {
        border-top-color: #333;
      }
    }
    &.place-right {
      &::after {
        border-right-color: #333;
      }
    }
    &.place-bottom {
      &::after {
        border-bottom-color: #333;
      }
    }
    &.place-left {
      &::after {
        border-left-color: #333;
      }
    }
  }
`
