import React, { FC } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { AppState } from '../../../state/features/types'
import { AppBox, AppBoxProps } from '../Primitives/AppBox'
import Flex from '../Primitives/Flex'
import Text from '../Primitives/Text'

type BreadcrumbItemProps = AppBoxProps & { active: boolean }

const BreadcrumbItem = styled(AppBox).attrs<BreadcrumbItemProps>(props => ({
  as: 'li',
  display: 'flex',
  alignItems: 'center',
  fontWeight: props.active ? 700 : 400,
  sx: {
    '& svg': {
      opacity: props.active ? 1 : 0.4,
    },
  },
}))<BreadcrumbItemProps>`` as FC<BreadcrumbItemProps>

const ANONYMOUS_ITEMS = ['Password', 'More Discounts']
const LOGGED_IN_ITEMS = ['More Discounts']

const URL_MAPPING = {
  Password: '/payment-successful',
  'More Discounts': '/congratulations',
}

export const Breadcrumb: FC = () => {
  const { location } = useHistory()
  const { logged } = useSelector((state: AppState) => {
    return state.auth
  })
  const items = logged ? LOGGED_IN_ITEMS : ANONYMOUS_ITEMS
  return (
    <AppBox as="nav" aria-label="breadcrumb" fontSize={['1.1rem', '1.4rem']}>
      <Flex as="ol" sx={{ listStyle: 'none' }} spacing="1rem">
        {items.map((item, i) => (
          <BreadcrumbItem
            active={location.pathname.includes(URL_MAPPING[item])}
            key={`.0${i}`}
          >
            <Text mr={i < items.length - 1 ? '1rem' : undefined}>{item}</Text>
            {i < items.length - 1 && (
              <svg
                role="presentation"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 4 7"
                width="0.8em"
                height="0.8em"
              >
                <path
                  d="M3.83 3.14L.98.64A.62.62 0 00.58.5C.43.5.28.55.18.65.08.74.02.86.01 1c0 .13.06.25.16.35l2.45 2.14L.17 5.64a.5.5 0 00-.12.16.45.45 0 000 .39.5.5 0 00.12.16c.05.05.11.09.18.11a.64.64 0 00.63-.11l2.85-2.5c.11-.1.17-.22.17-.36a.47.47 0 00-.17-.35z"
                  fill="currentColor"
                />
              </svg>
            )}
          </BreadcrumbItem>
        ))}
      </Flex>
    </AppBox>
  )
}
