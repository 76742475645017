import React, { FC } from 'react'

import { FormikValues } from 'formik'
import Collapsible from 'react-collapsible'
import { useDispatch } from 'react-redux'

import StripeFormShopify from 'common/components/Form/StripeFormShopify'
import MoonaIcon from 'common/components/Icon/MoonaIcon'
import { H3 } from 'common/style/components/Typography'
import {
  CheckoutFormGroup,
  CheckoutRadioGroupWrapper,
  CheckoutRadioItem,
} from 'common/style/pages/checkout'
import { UpdateCheckout } from 'state/features/checkout/action'
import { ShopperState } from 'state/features/shopper/types'

import { CollapsibleContent } from './style'

type PaymentBlocProps = {
  formik: FormikValues
  shopper: ShopperState
  slotMP2: boolean
  currency: string
  slotProductCheckbox: string
  hasDiscountSubscription: boolean
  totalPrice: number
}

const PaymentBloc: FC<PaymentBlocProps> = ({
  formik,
  slotMP2,
  currency,
  slotProductCheckbox,
  hasDiscountSubscription,
  totalPrice,
}) => {
  const dispatch = useDispatch()

  const onClick = (value: 'MP1' | 'MP2') => {
    const isAutoCheck = !!(value === 'MP1' && slotProductCheckbox)
    formik.setFieldValue('getDiscount', !!isAutoCheck)
    formik.setFieldValue('paymentMethod', value)
    dispatch(
      UpdateCheckout({ paymentMethod: value, getDiscount: !!isAutoCheck })
    )
  }
  return (
    <CheckoutFormGroup>
      <div>
        <H3>Payment</H3>
        <p>All transactions are secure and encrypted.</p>
      </div>
      <CheckoutRadioGroupWrapper role="group">
        <CheckoutRadioItem
          className={`checkout-radio-option ${
            formik.values.paymentMethod === 'MP1' ? 'active' : ''
          }`}
          style={{ padding: 0 }}
        >
          <Collapsible
            containerElementProps={{ style: { width: '100%' } }}
            triggerDisabled
            trigger={
              <CheckoutRadioItem
                style={{ padding: 0, width: '100%', border: 'none' }}
                onClick={() => onClick('MP1')}
              >
                <input
                  type="radio"
                  readOnly
                  value="MP1"
                  name="paymentMethod"
                  checked={formik.values.paymentMethod === 'MP1'}
                />
                <label>Credit card</label>
                <div
                  style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                  }}
                >
                  <MoonaIcon icon="VisaCard" />
                  <MoonaIcon icon="MasterCard" />
                  <MoonaIcon icon="AmexCard" />
                  <MoonaIcon icon="UnknowCard" />
                  <p>and more...</p>
                </div>
              </CheckoutRadioItem>
            }
            triggerStyle={{
              display: 'flex',
              alignItems: 'center',
              gap: 11,
              width: '100%',
              padding: 17,
            }}
            open={formik.values.paymentMethod === 'MP1'}
          >
            <CollapsibleContent className="without-radius">
              <StripeFormShopify formik={formik} />
            </CollapsibleContent>
          </Collapsible>
        </CheckoutRadioItem>
      </CheckoutRadioGroupWrapper>
    </CheckoutFormGroup>
  )
}

export default PaymentBloc
