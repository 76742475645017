/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react'

import axios from 'axios'
import { useSelector } from 'react-redux'

import { ShopperState } from 'state/features/shopper/types'
import { AppState } from 'state/features/types'

export interface CleeverWebService {
  getShopperInfo: (
    payload: GetShopperInfoPayload
  ) => Promise<GetShopperInfoResponse>
  getShippingSlotInfos: (
    payload: GetShippingSlotInfosPayload
  ) => Promise<GetShippingSlotInfosResponse>
}

export interface GetShopperInfoPayload {
  email: string
  ecommerce_solution: string
  ecommerce_version: string
  plugin_version: string
  api_version: string
  merchant_id: string
}

export interface GetShippingSlotInfosPayload {
  lang: string
  currency_code: string
  currency_symbol: string
  shipping_label: string
  shipping_method_title: string
  shipping_carrier_title: string
  shipping_price_reference: number
  shipping_reference: string
  cart_price: number
  payload?: {
    user_infos?: ShopperState
  }
  email: string
  ecommerce_solution: string
  ecommerce_version: string
  plugin_version: string
  api_version: string
  merchant_id: string
}

export interface ShippingOffer {
  title: string
  title_free: string
  min_amount_to_display: number
  discount: number
  description: string
  description_free: string
  display_slot: boolean
}

export interface GetShippingSlotInfosResponse {
  statusCode: number
  body: {
    call_on_update_shipping: boolean
    new_user: ShippingOffer
    returning_user: ShippingOffer
  }
}

export interface GetShopperInfoResponse {
  statusCode: number
  body: ShopperState
}

export const defaultValuesShippingSlotInfos = {
  call_on_update_shipping: false,
  data_privacy: '<p>I agree to MShip\'s <a target="_blank" href="https://fshipp.com/terms-and-conditions/" style="color: grey">T&amp;C</a>, <a target="_blank" href="https://fshipp.com/privacy-policy/" style="color: grey">privacy policy</a> and receive offers via sms/email.</p>',
  new_user: {
    title: 'FREE SHIPPING up to £5',
    title_free: 'FREE SHIPPING',
    min_amount_to_display: 0,
    discount: 500,
    description:
      'FREE SHIPPING up to £5. <b>15-day free trial</b> then <b>£29.99 per month. Cancel anytime.</b>',
    description_free:
      'FREE SHIPPING up to £5. <b>15-day free trial</b> then <b>£29.99 per month. Cancel anytime.</b>',
    display_slot: false,
  },
  returning_user: {
    title: 'FREE SHIPPING up to £5',
    title_free: 'FREE SHIPPING',
    min_amount_to_display: 6000,
    discount: 500,
    description: 'This order is eligible to £5 rebate on shipping fees.',
    description_free: 'This order is eligible to free shipping',
    display_slot: false,
  },
}

// TODO : Update according to StoreSettings > testMode ou pas ?
export const moonaApi =
  process.env.REACT_APP_BUILD_ENV === 'prod'
    ? 'https://api.moona.com'
    : 'https://staging.api.moona.com'

const useWebService: () => Omit<CleeverWebService, 'getShopperInfo'> = () => {
  const state = useSelector((state: AppState) => state)
  const secretKeyTest = state.storeSettings.secretKeyTest
  const secretKeyLive = state.storeSettings.secretKeyLive

  // TODO : Update according to StoreSettings > testMode ou pas ?
  const headers = useMemo(
    () => ({
      'X-API-Key': (process.env.REACT_APP_BUILD_ENV === 'prod') ? secretKeyLive : secretKeyTest,
    }),
    [secretKeyTest, secretKeyLive]
  )
  const getShippingSlotInfos = useCallback(
    async (payload: GetShippingSlotInfosPayload) => {
      const request = await axios.post(
        moonaApi + '/ecommerce/get_shipping_slot_infos',
        payload,
        {
          headers,
        }
      )
      const data = Object.entries(request)[0][1]

      if (data.statusCode === 200) {
        return data
      } else {
        return {
          body: defaultValuesShippingSlotInfos,
        }
      }
    },
    [headers]
  )

  return {
    getShippingSlotInfos,
  }
}

export default useWebService
