import React, { useState } from 'react'

import {
  CardCvcElement,
  CardExpiryElement,
  useElements,
} from '@stripe/react-stripe-js'
import {
  StripeCardExpiryElementChangeEvent,
  StripeCardExpiryElementOptions,
} from '@stripe/stripe-js'

import { CheckoutInputStyle, ErrorWrapper } from 'common/style/pages/checkout'

const CardExpiryInput = ({ error, setFieldError }) => {
  const elements = useElements()
  const [focus, setFocus] = useState<boolean>(false)
  const [empty, setEmpty] = useState<boolean>(true)
  const cardExpiryElementOptions: StripeCardExpiryElementOptions = {
    placeholder: '',
  }
  const onChange = (event: StripeCardExpiryElementChangeEvent): void => {
    if (empty !== event.empty) {
      setEmpty(event.empty)
    }
    if (event.complete) {
      const cvc = elements.getElement(CardCvcElement)

      if (cvc) {
        cvc.focus()
      }
    }
    if (event.error) {
      setFieldError('cardExpiry', event.error.message)
    } else {
      setFieldError('cardExpiry', null)
    }
  }
  return (
    <ErrorWrapper className={error ? 'error' : ''}>
      <CheckoutInputStyle
        className={`checkout-input ${
          focus ? 'label-active' : empty ? '' : 'label-active'
        }`}
      >
        <label>Expiration date {empty && '(MM / YY)'}</label>
        <CardExpiryElement
          className="input"
          onChange={onChange}
          options={cardExpiryElementOptions}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </CheckoutInputStyle>
      {error && <p className="text-error">{error}</p>}
    </ErrorWrapper>
  )
}

export default CardExpiryInput
