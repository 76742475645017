import { initialCheckoutSessionState } from './initialStates'
import {
  UPDATE_CHECKOUT_SESSION,
  CLEAR_SLOT_PRODUCT_CHECKBOX,
  CheckoutSessionActionTypes,
} from './types'

const CheckoutSessionReducer = (
  state = initialCheckoutSessionState,
  action: CheckoutSessionActionTypes
) => {
  switch (action.type) {
    case UPDATE_CHECKOUT_SESSION:
      return { ...state, ...action.payload }
    case CLEAR_SLOT_PRODUCT_CHECKBOX:
      return {
        ...state,
        cart: { ...state.cart, attributes: { slotProductCheckbox: null } },
      }
    default:
      return state
  }
}

export default CheckoutSessionReducer
