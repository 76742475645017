import React, { FC, useEffect } from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch } from 'react-redux'
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import { Metadata } from 'common/components/SEO/Metadata'
import { useScrollToTop } from 'common/hooks/useScollToTop'
import { useDeviceType } from 'common/style/queries/useDeviceType'
import { setDeviceType } from 'state/features/session/actions'

import AppBox, { AppBoxProps } from '../Primitives/AppBox'
export interface ComponentProps extends RouteComponentProps {}
const stripeOptions = {
  locale: 'en' as const,
}
const stripePromise = loadStripe(
  process.env.REACT_APP_PUBLISHABLE_KEY || '',
  stripeOptions
)
interface Props extends RouteProps {
  component: React.FC<ComponentProps>
}

type ContentContainerProps = AppBoxProps & { desktopMaxWidth?: string | number }

export const ContentContainer: FC<ContentContainerProps> = styled(AppBox).attrs<
  ContentContainerProps
>(props => ({
  mx: 'auto',
  maxWidth: props.maxWidth ?? ['340px', props.desktopMaxWidth ?? '400px'],
}))``

const Layout: React.FC<Props> = ({
  component: Component,
  location,
  ...rest
}) => {
  const dispatch = useDispatch()
  const deviceType = useDeviceType()
  useEffect(() => {
    dispatch(setDeviceType(deviceType))
  }, [dispatch, deviceType])

  return (
    <Route
      {...rest}
      location={location}
      render={matchProps => (
        <div>
          <RunHook />
          <Metadata location={location?.pathname} />
          <Elements stripe={stripePromise} options={stripeOptions}>
            <Component {...matchProps} />
          </Elements>
        </div>
      )}
    />
  )
}

const RunHook = () => {
  useScrollToTop()

  return null
}
export default Layout
