import { ShopperState } from 'state/features/shopper/types'

export const displayPostPurchaseDiscountPopup = (
  merchantId: string,
  shopper: ShopperState
) => {
  if (shopper.sbdt === 1 || shopper.sbdt === 2) return false

  return [
    '15491ed2-4c91-4c13-a62b-422aeb04076d', // STAGING : Site test dev-store-10
    '01d81502-a62c-480c-9e34-a5601ebe2614', // STAGING: Sassy
    '879ca477-9c5b-4d10-8975-2d241330701d', // STAGING: Devonwick
    'b92dd6e0-65a2-4880-8b69-7c112e3528d4', // STAGING: PoundAMeter
    '06423c76-ffa4-4738-9c0b-33c0b82fa2b2', // STAGING: Sugaholics
    'ed6dd0c4-4162-49a0-9ecd-31b54a6bb142', // STAGING: Magento - Test - pt-serv.com
    '1e4a1c8d-2f56-4df9-a326-547665abd533', // STAGING: WooCommerce - Test - ka.shops.moona.com
    '49e8382c-96c0-4e36-9d66-b2951ba588c3', // STAGING: CastleCombeCircuit
    '02c3728f-e5e9-4e6c-866c-5dc0f49330a1', // STAGING: Shirley Aquatics
    '3273ffc4-872b-46a4-aa94-a47dfb9f8310', // STAGING: Papootz
    '62e84eac-9791-4ab0-a5cb-f4c9e737054a', // STAGING: splashparkbarking.co.uk
    '863eb9e1-e8d7-4df4-acc1-26dcf83bf8fc', // PROD: Magento - Test - pt-serv.com
    '3821e0c1-5309-4b0b-b8ce-f1e6f24f5ccd', // PROD: WooCommerce - Test
    '493c10f8-3074-466d-b063-475d8aef4c50', // PROD: Sassy,
    '4b23fc03-9613-498f-90b2-626d94808c2e', // PROD: Devonwick
    '7bf278ed-e447-420a-879f-db15aaba556a', // PROD: PoundAMeter
    '3cb32803-5727-418d-b482-1a643a80246c', // PROD: Sugaholics
    '4ce23e41-2729-4ba8-8bc6-49c647c216c0', // PROD: CastleCombeCircuit
    '9616d723-95dc-4e3d-8384-4214a77245cf', // PROD: Shirley Aquatics
    '91786f60-ae8c-40af-af42-36f7ab4c229d', // PROD: Papootz
    '824c1540-af94-48fb-8b30-28563befcf79', // PROD: splashparkbarking.co.uk
  ].includes(merchantId)
}

export const doNotdisplayDiscountBlocInPaymentPage = (
  merchantId: string
) => {
  return [
    'a3995120-961c-44bd-a735-51bf94a70ae8', // STAGING: RedCandy
    '98ec6166-9b60-4664-8b7a-0fb1f12efc30', // STAGING: Hexxee
    '01d81502-a62c-480c-9e34-a5601ebe2614', // STAGING: Sassy
    '879ca477-9c5b-4d10-8975-2d241330701d', // STAGING: Devonwick
    'b92dd6e0-65a2-4880-8b69-7c112e3528d4', // STAGING: PoundAMeter
    '06423c76-ffa4-4738-9c0b-33c0b82fa2b2', // STAGING: Sugaholics
    'ed6dd0c4-4162-49a0-9ecd-31b54a6bb142', // STAGING: Magento - Test - pt-serv.com
    '1e4a1c8d-2f56-4df9-a326-547665abd533', // STAGING: WooCommerce - Test - ka.shops.moona.com
    '49e8382c-96c0-4e36-9d66-b2951ba588c3', // STAGING: CastleCombeCircuit
    '02c3728f-e5e9-4e6c-866c-5dc0f49330a1', // STAGING: Shirley Aquatics
    '3273ffc4-872b-46a4-aa94-a47dfb9f8310', // STAGING: Papootz
    '62e84eac-9791-4ab0-a5cb-f4c9e737054a', // STAGING: splashparkbarking.co.uk
    '863eb9e1-e8d7-4df4-acc1-26dcf83bf8fc', // PROD: Magento - Test - pt-serv.com
    '3821e0c1-5309-4b0b-b8ce-f1e6f24f5ccd', // PROD: WooCommerce - Test - ka.shops.moona.com
    '70332e82-7b7b-41b7-9b2c-4b6b2b823018', // PROD: RedCandy
    '2c72fc30-0a19-4b46-85a4-559c37172632', // PROD: Hexxee
    '493c10f8-3074-466d-b063-475d8aef4c50', // PROD: Sassy,
    '4b23fc03-9613-498f-90b2-626d94808c2e', // PROD: Devonwick
    '7bf278ed-e447-420a-879f-db15aaba556a', // PROD: PoundAMeter
    '3cb32803-5727-418d-b482-1a643a80246c', // PROD: Sugaholics
    '4ce23e41-2729-4ba8-8bc6-49c647c216c0', // PROD: CastleCombeCircuit
    '9616d723-95dc-4e3d-8384-4214a77245cf', // PROD: Shirley Aquatics
    '91786f60-ae8c-40af-af42-36f7ab4c229d', // PROD: Papootz
    '824c1540-af94-48fb-8b30-28563befcf79', // PROD: splashparkbarking.co.uk
  ].includes(merchantId)
}
