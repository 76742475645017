import React, { Suspense } from 'react'

import { StringParam, useQueryParam } from 'use-query-params'

const CardFormBase = React.lazy(() => import('./CardForm'))
const CardFormFull = React.lazy(() => import('./CardFormFull'))

export const CardFormPage = () => {
  const [sessionId] = useQueryParam('sessionid', StringParam)

  return (
    <Suspense fallback={null}>
      {sessionId ? <CardFormFull /> : <CardFormBase />}
    </Suspense>
  )
}
