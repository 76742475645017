import React from 'react'

import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { BaseSkeleton } from 'common/components/BaseSkeleton/BaseSkeleton'
import { MarginContainer, LoginSection } from 'common/style/components/Section'
import { AppState } from 'state/features/types'

import { ResetPasswordWrapper, ResetPasswordMain, Intro } from './ResetPassword'
interface ResetPasswordSkeletonProps {}

export const FormSkeletonContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const ResetPasswordSkeleton: React.FC<ResetPasswordSkeletonProps> = () => {
  const { isMobile } = useSelector((state: AppState) => state.session)
  return (
    <LoginSection>
      <ResetPasswordWrapper>
        {isMobile && (
          <BaseSkeleton mb="4rem" mt="3.6rem" width={101} height={22} center />
        )}
        <ResetPasswordMain>
          {!isMobile && <BaseSkeleton mb="4rem" width={101} height={22} />}

          <BaseSkeleton width={229} height={22} />
          <Intro>{<Skeleton count={2} width={200} />}</Intro>
          <FormSkeletonContainer>
            <MarginContainer mb="2.4rem" column>
              <Skeleton width={63} height={20} />
              <Skeleton width={324} height={33} />
            </MarginContainer>

            <MarginContainer mb="1.8rem" column>
              <Skeleton width={63} height={20} />
              <Skeleton width={324} height={33} />
            </MarginContainer>

            <Skeleton width={324} height={33} />
          </FormSkeletonContainer>
        </ResetPasswordMain>
      </ResetPasswordWrapper>
    </LoginSection>
  )
}
