import { initialStoreSettingsState } from './initialStates'
import { UpdateStoreSettings, UPDATE_STORE_SETTINGS } from './types'

const StoreSettingsReducer = (
  state = initialStoreSettingsState,
  action: UpdateStoreSettings
) => {
  switch (action.type) {
    case UPDATE_STORE_SETTINGS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default StoreSettingsReducer
