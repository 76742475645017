import React, { FC } from 'react'

type PickUpProps = {
  fill?: string
} & any

const PickUp: FC<PickUpProps> = ({ fill = '#737373', ...rest }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.85624 1.00624C2.01152 0.695543 2.32413 0.5 2.66555 0.5H15.156C15.4952 0.5 15.8062 0.69307 15.9625 1.00071L17.7468 4.51272C17.8147 4.6463 17.8498 4.79473 17.849 4.94523C17.8427 6.23396 17.6032 7.36873 16.9855 8.19811C16.7209 8.55335 16.4057 8.82821 16.0509 9.02956V17.5228C16.0509 18.0357 15.644 18.4514 15.1421 18.4514H2.66579C2.16391 18.4514 1.75706 18.0357 1.75706 17.5228V9.0334C1.39723 8.83154 1.07846 8.55454 0.813012 8.19422C0.195685 7.35625 -0.0220397 6.21284 0.0017373 4.92307C0.00433454 4.78218 0.0382791 4.64374 0.100999 4.51825L1.85624 1.00624ZM3.57453 9.46271V16.5943H6.25927V12.2107C6.25927 11.6979 6.66612 11.2822 7.168 11.2822H10.6558C11.1576 11.2822 11.5645 11.6979 11.5645 12.2107V16.5943H14.2334V9.46264C13.155 9.45569 12.2149 9.07796 11.5645 8.23567C10.9182 9.08232 9.98114 9.46274 8.90364 9.46274C7.83603 9.46274 6.90634 9.08935 6.25927 8.26035C5.60631 9.09434 4.65727 9.45898 3.57453 9.46271ZM7.22045 5.86882C7.31106 6.435 7.47125 6.82186 7.65884 7.07439C7.90001 7.39905 8.263 7.60566 8.90364 7.60566C9.54436 7.60566 9.90554 7.39909 10.1447 7.07582C10.3314 6.8235 10.4905 6.43631 10.5794 5.86882H7.22045ZM12.543 5.86882H15.9672C15.8834 6.43744 15.7256 6.82316 15.5392 7.07346C15.3006 7.39376 14.9309 7.60566 14.2638 7.60566C13.5969 7.60566 13.2229 7.39372 12.9798 7.07018C12.7912 6.81935 12.631 6.43438 12.543 5.86882ZM15.9625 4.51825L14.6038 2.35708H3.22185L1.86013 4.51825H15.9625ZM1.86013 5.86882C1.9309 6.44449 2.08295 6.83015 2.26506 7.07736C2.4945 7.3888 2.8646 7.60566 3.55772 7.60566C4.2505 7.60566 4.62566 7.3888 4.8605 7.07348C5.04513 6.82557 5.20002 6.44078 5.27584 5.86882H1.86013ZM9.74704 16.5943V13.1393H8.07674V16.5943H9.74704Z"
      fill={fill}
    />
  </svg>
)

export default PickUp
