import React, { FC, ReactNode } from 'react'

import { StyledBorderedWrapper } from 'common/style/pages/checkout'

type BorderedWrapperProps = {
  label?: string
  children?: ReactNode
  style?: any
}

const BorderedWrapper: FC<BorderedWrapperProps> = ({
  label,
  style = {},
  children,
}) => {
  return (
    <StyledBorderedWrapper style={style}>
      {label && <label>{label}</label>}
      {children}
    </StyledBorderedWrapper>
  )
}

export default BorderedWrapper
