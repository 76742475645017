import React, { Suspense } from 'react'

const PostPurchaseDiscountBase = React.lazy(
  () => import('./PostPurchaseDiscount')
)

export const PostPurchaseDiscountPage = () => (
  <Suspense fallback={null}>
    <PostPurchaseDiscountBase />
  </Suspense>
)
