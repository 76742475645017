import React, { ChangeEvent } from 'react'

import { FormikErrors } from 'formik'

import MoonaIcon from 'common/components/Icon/MoonaIcon'
import { Tooltip } from 'common/style/components/tooltip'
import { CheckoutInputStyle, ErrorWrapper } from 'common/style/pages/checkout'

type CheckoutInputProps = {
  name: string
  label: string
  type?: string
  value: string
  helperText?: string
  errors?: FormikErrors<any>
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: any) => void
  regexp?: RegExp
  disabled?: boolean
}

const CheckoutInput = React.forwardRef(
  (
    {
      name,
      label,
      value = '',
      type = 'text',
      helperText = null,
      errors,
      onChange,
      regexp,
      ...rest
    }: CheckoutInputProps,
    ref
  ) => {
    const error = (errors && errors[name]) ?? null

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (regexp && !regexp.test(e.target.value)) {
        return
      }
      onChange(e)
    }

    return (
      <ErrorWrapper className={error ? 'error' : ''}>
        <CheckoutInputStyle
          className={`checkout-input ${
            value?.length > 0 ? 'label-active' : ''
          } ${error ? 'error' : ''}`}
        >
          <input
            // @ts-ignore
            ref={ref}
            type={type}
            value={value}
            name={name}
            onChange={handleChange}
            placeholder=""
            {...rest}
          />
          <label>{label}</label>
          {helperText && (
            <MoonaIcon
              icon="QuestionFill"
              data-tip
              data-for="questionTip"
              style={{ zIndex: 1 }}
            />
          )}
        </CheckoutInputStyle>
        {helperText && (
          <Tooltip id="questionTip" type="info" effect="solid">
            {helperText}
          </Tooltip>
        )}
        {error && <p className="text-error">{error}</p>}
      </ErrorWrapper>
    )
  }
)

CheckoutInput.displayName = 'CheckoutInput'

export default CheckoutInput
