import { initialPoliciesState } from './initialState'
import { PoliciesActionTypes, PoliciesState, UPDATE_POLICIES } from './types'

const PoliciesReducer = (
  state = initialPoliciesState,
  action: PoliciesActionTypes
): PoliciesState => {
  switch (action.type) {
    case UPDATE_POLICIES:
      return Object.assign(state, action.payload)
    default:
      return state
  }
}

export default PoliciesReducer
