import { CheckoutShopifyState } from './types'

export const initialCheckoutShopifyState: CheckoutShopifyState = {
  id: '',
  email: '',
  webUrl: '',
  orderStatusUrl: '',
  customAttributes: [],
  discountApplications: {
    nodes: [],
  },
  availableShippingRates: {
    ready: false,
    shippingRates: [],
  },
  shippingLine: {
    handle: '',
    title: '',
    price: {
      amount: '',
      currencyCode: '',
    },
  },
  lineItems: {
    nodes: [],
  },
  lineItemsSubtotalPrice: {
    amount: '',
    currencyCode: '',
  },
  paymentDue: {
    amount: '',
    currencyCode: '',
  },
  subtotalPrice: {
    amount: '',
    currencyCode: '',
  },
  totalDuties: {
    amount: '',
    currencyCode: '',
  },
  totalPrice: {
    amount: '',
    currencyCode: '',
  },
  totalTax: {
    amount: '',
    currencyCode: '',
  },
}
