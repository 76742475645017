import * as Yup from 'yup'

import { initialCheckoutShippingState } from 'state/features/checkout/initialStates'

const formikShipping = (
  checkoutState,
  callback: (checkout: any, setSubmitting: any) => void
) => {
  const initialState = initialCheckoutShippingState
  Object.keys(initialCheckoutShippingState).forEach(key => {
    initialState[key] = checkoutState[key]
  })

  return {
    initialValues: initialState,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting }) => {
      callback(values, setSubmitting)
    },
    validationSchema: Yup.object().shape({
      shippingMethod: Yup.string(),
    }),
  }
}

export default formikShipping
