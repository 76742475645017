export const CREATE_PAYMENTINTENT_REQUEST = 'CREATE_PAYMENTINTENT_REQUEST'
export const CREATE_PAYMENTINTENT_SUCCESS = 'CREATE_PAYMENTINTENT_SUCCESS'
export const CREATE_PAYMENTINTENT_FAILURE = 'CREATE_PAYMENTINTENT_FAILURE'
export const CREATE_PAYMENTINTENT_ERROR = 'CREATE_PAYMENTINTENT_ERROR'
export const RESET_PAYMENTINTENT = 'RESET_PAYMENTINTENT'

export const CREATE_MAIN_PAYMENTINTENT_REQUEST =
  'CREATE_MAIN_PAYMENTINTENT_REQUEST'
export const CREATE_MAIN_PAYMENTINTENT_SUCCESS =
  'CREATE_MAIN_PAYMENTINTENT_SUCCESS'
export const CREATE_MAIN_PAYMENTINTENT_FAILURE =
  'CREATE_MAIN_PAYMENTINTENT_FAILURE'
export const CREATE_MAIN_PAYMENTINTENT_ERROR = 'CREATE_MAIN_PAYMENTINTENT_ERROR'
export const RESET_MAIN_PAYMENTINTENT = 'RESET_MAIN_PAYMENTINTENT'

export const SET_CLIENT_SECRET = 'SET_CLIENT_SECRET'

export interface CreatePaymentIntentRequest {
  type: typeof CREATE_PAYMENTINTENT_REQUEST
  payload: {
    moonaId: string
    stripeMerchantId: string
    price: number
    paymentId: string
    orderId: string
    ecommerceSessionId: string
    email: string
    notifyUrl: string
    ecommerce: string
    cartId?: string | null
    deviceId: string
    merchantId: string
    endTrial?: boolean
    subscriptionWithoutTrialPeriod?: boolean
    payload?: string | null
  }
}

export interface CreateMainPaymentIntentRequest {
  type: typeof CREATE_MAIN_PAYMENTINTENT_REQUEST
  payload: {
    moonaId: string
    stripeMerchantId: string
    price: number
    stripePaymentMethod: string
    orderId: string
    ecommerceSessionId: string
    email: string
    notifyUrl: string
    ecommerce: string
    cartId?: string | null
    deviceId: string
    merchantId: string
    discount?: boolean
    payload?: string | null
  }
}

export interface CreatePaymentIntentSuccess {
  type: typeof CREATE_PAYMENTINTENT_SUCCESS
}

export interface CreateMainPaymentIntentSuccess {
  type: typeof CREATE_MAIN_PAYMENTINTENT_SUCCESS
}

export interface SetClientSecret {
  type: typeof SET_CLIENT_SECRET
  payload: {
    clientSecret: string
  }
}
export interface CreatePaymentIntentFailure {
  type: typeof CREATE_PAYMENTINTENT_FAILURE
  payload: {
    error: string
  }
}

export interface CreateMainPaymentIntentFailure {
  type: typeof CREATE_MAIN_PAYMENTINTENT_FAILURE
  payload: {
    error: string
  }
}

export interface CreatePaymentIntentError {
  type: typeof CREATE_PAYMENTINTENT_ERROR
  payload: {
    error: string
  }
}

export interface CreateMainPaymentIntentError {
  type: typeof CREATE_MAIN_PAYMENTINTENT_ERROR
  payload: {
    error: string
  }
}

export interface ResetPaymentIntent {
  type: typeof RESET_PAYMENTINTENT
}

export interface ResetMainPaymentIntent {
  type: typeof RESET_MAIN_PAYMENTINTENT
}

export interface PaymentIntentState {
  loading: boolean
  success: boolean
  failure: boolean
  error: string
  amount: number
  moonaId: string
  stripeMerchantId: string
  clientSecret: string
}

export type PaymentIntentActionTypes =
  | CreatePaymentIntentRequest
  | CreatePaymentIntentError
  | CreatePaymentIntentSuccess
  | CreatePaymentIntentFailure
  | ResetPaymentIntent
  | CreateMainPaymentIntentRequest
  | CreateMainPaymentIntentError
  | CreateMainPaymentIntentSuccess
  | CreateMainPaymentIntentFailure
  | ResetMainPaymentIntent
  | SetClientSecret
