import { initialCheckoutShopifyState } from './initialStates'
import {
  CheckoutShopifyActionTypes,
  CheckoutShopifyState,
  UPDATE_CHECKOUT_SHOPIFY,
} from './types'

const CheckoutShopifyReducer = (
  state = initialCheckoutShopifyState,
  action: CheckoutShopifyActionTypes
): CheckoutShopifyState => {
  switch (action.type) {
    case UPDATE_CHECKOUT_SHOPIFY:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default CheckoutShopifyReducer
