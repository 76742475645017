import React, { useState } from 'react'

import {
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js'
import {
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js'

import MoonaIcon from 'common/components/Icon/MoonaIcon'
import { CheckoutInputStyle, ErrorWrapper } from 'common/style/pages/checkout'

const CardNumberInput = ({ error, setFieldError }) => {
  const elements = useElements()
  const [focus, setFocus] = useState<boolean>(false)
  const [empty, setEmpty] = useState<boolean>(true)
  const cardNumberElementOptions: StripeCardNumberElementOptions = {
    placeholder: '',
  }
  const onChange = (event: StripeCardNumberElementChangeEvent): void => {
    if (empty !== event.empty) {
      setEmpty(event.empty)
    }
    if (event.complete) {
      const expiry = elements.getElement(CardExpiryElement)

      if (expiry) {
        expiry.focus()
      }
    }
    if (event.error) {
      setFieldError('cardNumber', event.error.message)
    } else {
      setFieldError('cardNumber', null)
    }
  }
  return (
    <ErrorWrapper className={error ? 'error' : ''}>
      <CheckoutInputStyle
        className={`checkout-input ${
          focus ? 'label-active focused' : empty ? '' : 'label-active'
        }`}
      >
        <label>Card Number</label>
        <CardNumberElement
          className="input"
          onChange={onChange}
          options={cardNumberElementOptions}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <MoonaIcon icon="QuestionFill" />
      </CheckoutInputStyle>
      {error && <p className="text-error">{error}</p>}
    </ErrorWrapper>
  )
}

export default CardNumberInput
