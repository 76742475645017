import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'

import AuthReducer from './auth/reducers'
import authSagas from './auth/sagas'
import CheckoutReducer from './checkout/reducers'
import CheckoutFlowStepsReducer from './checkoutFlowSteps/reducers'
import CheckoutSessionReducer from './checkoutSession/reducers'
import CheckoutShopifyReducer from './checkoutShopify/reducers'
import FormReducer from './form/reducers'
import PaymentReducer from './payment/reducers'
import PaymentIntentReducer from './paymentintent/reducers'
import paymentIntentSagas from './paymentintent/sagas'
import PoliciesReducer from './policies/reducer'
import QueryReducer from './query/reducers'
import querySagas from './query/sagas'
import SessionReducer from './session/reducers'
import ShopperReducer from './shopper/reducers'
import StoreSettingsReducer from './storeSettings/reducers'
import UsersReducer from './users/reducers'
import usersSagas from './users/sagas'

export const rootReducer = combineReducers({
  users: UsersReducer,
  auth: AuthReducer,
  query: QueryReducer,
  payment: PaymentReducer,
  session: SessionReducer,
  form: FormReducer,
  paymentIntent: PaymentIntentReducer,
  checkout: CheckoutReducer,
  storeSettings: StoreSettingsReducer,
  checkoutSession: CheckoutSessionReducer,
  checkoutFlowSteps: CheckoutFlowStepsReducer,
  checkoutShopify: CheckoutShopifyReducer,
  shopper: ShopperReducer,
  policies: PoliciesReducer,
})

export function* rootSaga() {
  yield all([...usersSagas, ...authSagas, ...paymentIntentSagas, ...querySagas])
}
