import { FC } from 'react'

import styled, { keyframes } from 'styled-components'

import AppBox, { AppBoxProps } from '../../components/Primitives/AppBox'

type SpinnerProps = AppBoxProps & {
  center?: boolean
}

const rotationCenter = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
`

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`
export const Spinner = styled(AppBox)<SpinnerProps>`
  height: 24px;
  width: 24px;

  position: ${props => props.position ?? 'absolute'};

  ${props =>
    props.center
      ? `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  `
      : ''}

  @media all and (-ms-high-contrast: none) {
    margin: -12px 0 0 -12px;
  }

  animation: ${props => (props.center ? rotationCenter : rotation)} 1s infinite
    linear;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 100%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    top: -2px;
    height: 100%;
    width: 100%;
    border-top: 2px solid #fff;
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 100%;
  }
` as FC<SpinnerProps>
