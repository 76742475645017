import React, { Suspense } from 'react'

const MoneyBackGuaranteeConfirmBase = React.lazy(
  () => import('./MoneyBackGuaranteeConfirm')
)

export const MoneyBackGuaranteeConfirmPage = () => (
  <Suspense fallback={null}>
    <MoneyBackGuaranteeConfirmBase />
  </Suspense>
)
