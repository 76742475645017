import React, { Suspense } from 'react'

const ActivationRequiredPageBase = React.lazy(
  () => import('./ActivationRequired')
)

export const ActivationRequiredPage = () => (
  <Suspense fallback={null}>
    <ActivationRequiredPageBase />
  </Suspense>
)
