import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'

import { FormikErrors } from 'formik'
import { CountryCode } from 'libphonenumber-js/types'
import {
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isSupportedCountry,
  parsePhoneNumber,
} from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import labels from 'react-phone-number-input/locale/en.json'
import styled from 'styled-components'

import MoonaIcon from 'common/components/Icon/MoonaIcon'
import { CheckoutInputStyle, ErrorWrapper } from 'common/style/pages/checkout'

type CheckoutNumberInputProps = {
  name: string
  label: string
  type?: string
  value: string
  helperText?: string
  errors?: FormikErrors<any>
  onChange: (value: any) => void
  onFocus?: (e: any) => void
  pattern?: string
  defaultCountry?: CountryCode
  defaultValue?: string
  formik?: any
}

const StyledFlagWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  border-left: 1px solid #bebebe;
  z-index: 2;

  .flag {
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
    width: 27px;
  }
`

const CheckoutNumberInput: FC<CheckoutNumberInputProps> = ({
  name,
  label,
  value = '',
  type = 'text',
  errors,
  onChange,
  defaultCountry = 'GB',
  defaultValue,
  formik,
}) => {
  const error = (errors && errors[name]) ?? null
  const [countrySelected, setCountrySelected] = useState<CountryCode>(
    defaultCountry as CountryCode
  )

  const options = useMemo(() => {
    return Object.keys(flags)
      .filter(key => isSupportedCountry(key as CountryCode))
      .map(key => {
        return {
          value: key,
          label: `${labels[key]} (+${getCountryCallingCode(
            key as CountryCode
          )})`,
        }
      })
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (/^[0-9\s()+-]*$/.test(value)) {
      const numberParsed = parsePhoneNumber(value)

      if (numberParsed) {
        if (numberParsed.country) {
          setCountrySelected(numberParsed.country as CountryCode)
        }
        if (formatPhoneNumberIntl(value)) {
          onChange(formatPhoneNumberIntl(value))
        } else {
          onChange(value)
        }
      } else {
        // si la valeur de l'input n'est plus parsable alors on essaie de le parser avec le calling code du pays sélectionné
        const testParsing = parsePhoneNumber(
          `+${getCountryCallingCode(countrySelected)}${value}`
        )
        if (testParsing) {
          if (testParsing.country) {
            setCountrySelected(testParsing.country as CountryCode)
          }
          if (formatPhoneNumberIntl(testParsing.number)) {
            onChange(formatPhoneNumberIntl(testParsing.number))
          } else {
            onChange(testParsing.number)
          }
        } else {
          onChange(value)
        }
      }
    }
  }

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value: selectValue } = e.target
    const oldCallingCode = getCountryCallingCode(countrySelected)
    const newCallingCode = getCountryCallingCode(selectValue as CountryCode)
    let newNumber = ''
    if (value.includes(oldCallingCode)) {
      newNumber = value.replace(oldCallingCode, newCallingCode)
    } else {
      newNumber = `+${newCallingCode}${value}`
    }
    setCountrySelected(selectValue as CountryCode)
    if (formatPhoneNumberIntl(newNumber)) {
      onChange(formatPhoneNumberIntl(newNumber))
    } else {
      onChange(newNumber)
    }
  }

  const displayFlag = (key: string) => {
    const Flag = flags[key]
    return <Flag className="flag" />
  }

  useEffect(() => {
    // TODO: mettre en place le système de default value via l'input phone de l'address

    // if (!value && defaultValue) {
    //   const numberParsed = parsePhoneNumber(defaultValue)
    //   if (numberParsed) {
    //     if (numberParsed.country) {
    //       setCountrySelected(numberParsed.country as CountryCode)
    //     }
    //     if (formatPhoneNumberIntl(defaultValue)) {
    //       onChange(formatPhoneNumberIntl(defaultValue))
    //     } else {
    //       onChange(defaultValue)
    //     }
    //   } else {
    //     const testParsing = parsePhoneNumber(
    //       `+${getCountryCallingCode(countrySelected)}${defaultValue}`
    //     )
    //     if (testParsing) {
    //       if (testParsing.country) {
    //         setCountrySelected(testParsing.country as CountryCode)
    //       }
    //       if (formatPhoneNumberIntl(testParsing.number)) {
    //         onChange(formatPhoneNumberIntl(testParsing.number))
    //       } else {
    //         onChange(testParsing.number)
    //       }
    //     } else {
    //       onChange(`+${getCountryCallingCode(countrySelected)}`)
    //     }
    //   }
    // } else if (value) {
    //   const numberParsed = parsePhoneNumber(value)
    //   if (numberParsed) {
    //     setCountrySelected(numberParsed.country as CountryCode)
    //   }
    // } else {
    const defaultValue = `+${getCountryCallingCode(countrySelected)}`
    onChange(defaultValue)
    // }
  }, [])

  return (
    <ErrorWrapper className={error ? 'error' : ''}>
      <CheckoutInputStyle
        className={`checkout-input ${value ? 'label-active' : ''} ${
          error ? 'error' : ''
        }`}
        style={{
          paddingLeft: '27px',
        }}
      >
        <MoonaIcon icon="Phone" className="start" />
        <input
          type={type}
          value={value}
          name={name}
          onChange={handleChange}
          pattern="[0-9\s()+-]*"
        />
        <label
          style={{
            paddingLeft: '27px',
          }}
        >
          {label}
        </label>

        {countrySelected && (
          <StyledFlagWrapper>
            {displayFlag(countrySelected)}
            <MoonaIcon
              icon="ChevronRight"
              width="10px"
              height="10px"
              style={{
                transform: 'translateY(-50%) rotate(90deg)',
              }}
            />
            <select
              style={{
                width: '100%',
                height: '100%',
                opacity: 0,
              }}
              value={countrySelected}
              onChange={handleSelectChange}
            >
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </StyledFlagWrapper>
        )}
      </CheckoutInputStyle>
      {error && <p className="text-error">{error}</p>}
    </ErrorWrapper>
  )
}

export default CheckoutNumberInput
