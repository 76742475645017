export const SET_FIRST_NAME = 'SET_FIRST_NAME'
export const SET_LAST_NAME = 'SET_LAST_NAME'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const SET_BRAND = 'SET_BRAND'

export interface Form {
  firstName: string
  lastName: string
  email: string
  phonenumber: string
  brand: string
}

export interface SetFirstName {
  type: typeof SET_FIRST_NAME
  payload: {
    firstName: string
  }
}
export interface SetLastName {
  type: typeof SET_LAST_NAME
  payload: {
    lastName: string
  }
}
export interface SetEmail {
  type: typeof SET_EMAIL
  payload: {
    email: string
  }
}

export interface SetPhoneNumber {
  type: typeof SET_PHONE_NUMBER
  payload: {
    phonenumber: string
  }
}
export interface SetBrand {
  type: typeof SET_BRAND
  payload: {
    brand: string
  }
}

export type FormActionTypes =
  | SetFirstName
  | SetLastName
  | SetEmail
  | SetPhoneNumber
  | SetBrand

export interface FormState extends Form {}
