export const checkoutType = `
  checkout {
    id
    email
    webUrl
    orderStatusUrl
    customAttributes {
      key
      value
    }
    shippingAddress {
      address1
      address2
      city
      company
      country
      countryCodeV2
      firstName
      lastName
      phone
      province
      provinceCode
      zip
    }
    availableShippingRates {
      ready
      shippingRates {
        handle
        title
        price {
          amount
          currencyCode
        }
      }
    }
    discountApplications(first: 250) {
      nodes {
        ... on DiscountCodeApplication {
          code
        }
        ... on AutomaticDiscountApplication {
          title
        }
        allocationMethod
        targetSelection
        targetType
        value {
          __typename
          ... on MoneyV2 {
            amount
            currencyCode
          }
          ... on PricingPercentageValue {
            percentage
          }
        }
      }
    }
    shippingLine {
      handle
      title
      price {
        amount
        currencyCode
      }
    }
    lineItems (first:250) {
      nodes {
        id
        quantity
        title
        customAttributes {
          key
          value
        }
        discountAllocations {
          discountApplication {
              ... on DiscountCodeApplication {
                code
              }
              ... on AutomaticDiscountApplication {
                title
              }
              allocationMethod
              targetSelection
              targetType
              value {
                  __typename
                  ... on MoneyV2 {
                  amount
                  currencyCode
                  }
                  ... on PricingPercentageValue {
                  percentage
                  }
              }
          }
          allocatedAmount {
              amount
              currencyCode
          }
        }
        unitPrice {
          amount
          currencyCode
        }
        variant {
          id
          price {
            amount
            currencyCode
          }
          image {
            id
            altText
            url
            height
            width
          }
          requiresShipping
          sku
          title
          unitPrice {
            amount
            currencyCode
          }
          weight
          weightUnit
          selectedOptions {
            name
            value
          }
          availableForSale
          currentlyNotInStock
          # product{}
        }
      }
    }
    paymentDue {
      amount
      currencyCode
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    totalDuties {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    totalTax {
      amount
      currencyCode
    }
  }
`

export const checkoutCreate = /* GraphQL */ `
  mutation checkoutCreate(
    $input: CheckoutCreateInput!
    $country: CountryCode!
    ) @inContext(country: $country) {
    checkoutCreate(input: $input) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        field
        code
        message
      }
      queueToken
    }
  }
`

export const checkoutAttributesUpdateV2 = /* GraphQL */ `
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
    $country: CountryCode!
    ) @inContext(country: $country) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        field
        code
        message
      }
    }
  }
`

export const checkoutEmailUpdateV2 = /* GraphQL */ `
  mutation checkoutEmailUpdateV2(
    $checkoutId: ID!
    $email: String!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutEmailUpdateV2(
      checkoutId: $checkoutId
      email: $email
    ) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        field
        code
        message
      }
    }
  }
`
export const checkoutShippingAddressUpdateV2 = /* GraphQL */ `
  mutation checkoutShippingAddressUpdateV2(
    $checkoutId: ID!
    $shippingAddress: MailingAddressInput!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutShippingAddressUpdateV2(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
    ) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        field
        code
        message
      }
    }
  }
`
export const checkoutShippingLineUpdate = /* GraphQL */ `
  mutation checkoutShippingLineUpdate(
    $checkoutId: ID!
    $shippingRateHandle: String!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutShippingLineUpdate(
      checkoutId: $checkoutId
      shippingRateHandle: $shippingRateHandle
    ) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        field
        code
      }
    }
  }
`
export const checkoutDiscountCodeApplyV2 = /* GraphQL */ `
  mutation checkoutDiscountCodeApplyV2(
    $checkoutId: ID!
    $discountCode: String!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutDiscountCodeApplyV2(
      checkoutId: $checkoutId
      discountCode: $discountCode
    ) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        field
        code
        message
      }
    }
  }
`
export const checkoutDiscountCodeRemove = /* GraphQL */ `
  mutation checkoutDiscountCodeRemove(
    $checkoutId: ID!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        field
        code
        message
      }
    }
  }
`
export const checkoutCompleteFree = /* GraphQL */ `
  mutation checkoutCompleteFree($checkoutId: ID!) {
    checkoutCompleteFree(checkoutId: $checkoutId) {
      ${checkoutType}
      checkoutUserErrors {
        # CheckoutUserError fields
        code
        field
        message
      }
    }
  }
`
