import React, { FC } from 'react'

import { FormikValues } from 'formik'
import Collapsible from 'react-collapsible'

import { H3 } from 'common/style/components/Typography'
import {
  CheckoutFormGroup,
  CheckoutRadioGroupWrapper,
  CheckoutRadioItem,
} from 'common/style/pages/checkout'

import AddressForm from '../../InformationStep/AddressForm'

import { CollapsibleContent } from './style'

type BillingAddressBlocProps = {
  formik: FormikValues
}

const BillingAddressBloc: FC<BillingAddressBlocProps> = ({ formik }) => {
  return (
    <CheckoutFormGroup>
      <div>
        <H3>Billing Address</H3>
        <p>Select the address that matches your card or payment method.</p>
      </div>
      <CheckoutRadioGroupWrapper>
        <CheckoutRadioItem
          className={`checkout-radio-option ${
            formik.values.billingAddressMethod === 'same' ? 'active' : ''
          }`}
          onClick={() => formik.setFieldValue('billingAddressMethod', 'same')}
        >
          <input
            type="radio"
            readOnly
            value="same"
            name="billingAddressMethod"
            checked={formik.values.billingAddressMethod === 'same'}
          />
          <label>Same as shipping address</label>
        </CheckoutRadioItem>
        <CheckoutRadioItem
          className={`checkout-radio-option ${
            formik.values.billingAddressMethod === 'different' ? 'active' : ''
          }`}
          style={{ padding: 0 }}
          onClick={() =>
            formik.setFieldValue('billingAddressMethod', 'different')
          }
        >
          <Collapsible
            containerElementProps={{ style: { width: '100%' } }}
            triggerDisabled
            trigger={
              <>
                <input
                  type="radio"
                  readOnly
                  value="different"
                  name="billingAddressMethod"
                  checked={formik.values.billingAddressMethod === 'different'}
                />
                <label>Use a different billing address</label>
              </>
            }
            triggerStyle={{
              display: 'flex',
              alignItems: 'center',
              gap: 11,
              width: '100%',
              padding: 17,
            }}
            open={formik.values.billingAddressMethod === 'different'}
          >
            <CollapsibleContent className="without-radius">
              <CheckoutFormGroup style={{ marginBottom: 0 }}>
                {formik.values.billingAddressMethod === 'different' && (
                  <AddressForm formik={formik} billing={true} />
                )}
              </CheckoutFormGroup>
            </CollapsibleContent>
          </Collapsible>
        </CheckoutRadioItem>
      </CheckoutRadioGroupWrapper>
    </CheckoutFormGroup>
  )
}

export default BillingAddressBloc
