import {
  AuthActionTypes,
  AuthState,
  CLEAR_LOGIN_EMAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS,
  LOGGABLE,
  LOGGABLE_SUCESS,
  LOGGED_IN,
  LOGIN,
  LOGIN_FAIL,
  LOGOUT,
  SET_LOGIN_EMAIL
} from './types'

export const initialAuthState: AuthState = {
  loading: false,
  logged: false,
  registered: false,
  loginfailure: false,
  error: '',
  forgotPasswordLoading: false,
  forgotPasswordSuccess: false,
  forgotPasswordFailure: false,
  email: '',
  loginEmail: ''
}

const AuthReducer = (
  state = initialAuthState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case SET_LOGIN_EMAIL:
      return {
        ...state,
        loginEmail: action.payload
      }
    case CLEAR_LOGIN_EMAIL:
      return {
        ...state,
        loginEmail: ''
      }
    case LOGIN:
      return {
        ...state,
        loading: true,
        logged: false,
        loginfailure: false,
        email: action.payload.login
      }

    case LOGGED_IN:
      return {
        ...state,
        loading: false,
        logged: true,
        loginfailure: false
      }
    case LOGOUT:
      return {
        ...state,
        loading: false,
        logged: false,
        loginfailure: false,
        email: ''
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        logged: false,
        loginfailure: true
      }

    case LOGGABLE:
      return {
        ...state,
        loading: true,
        registered: false
      }

    case LOGGABLE_SUCESS:
      return {
        ...state,
        loading: false,
        registered: action.payload.loggable
      }

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordLoading: true
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordSuccess: true,
        forgotPasswordFailure: false,
        error: ''
      }
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordSuccess: false,
        forgotPasswordFailure: true,
        error: action.payload.error
      }

    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload.error
      }

    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordSuccess: false,
        forgotPasswordFailure: false
      }

    default:
      return state
  }
}

export default AuthReducer
