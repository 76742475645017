import React, { FC } from 'react'

const UnknowCard: FC = props => (
  <svg
    width="38"
    height="24"
    viewBox="0 0 38 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.07"
      d="M35 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.4 24 3 24H35C36.7 24 38 22.7 38 21V3C38 1.3 36.6 0 35 0Z"
      fill="black"
    />
    <path
      d="M35 1C36.1 1 37 1.9 37 3V21C37 22.1 36.1 23 35 23H3C1.9 23 1 22.1 1 21V3C1 1.9 1.9 1 3 1H35Z"
      fill="white"
    />
    <path
      d="M37 16.95V21C37 22.1 36.1 23 35 23H23.228C31.124 21.185 35.271 18.399 37 16.95Z"
      fill="#EDA024"
    />
    <path d="M9 11H29V13H9V11Z" fill="#494949" />
    <path
      d="M22 12C22 13.7 20.7 15 19 15C17.3 15 16 13.6 16 12C16 10.4 17.4 9 19 9C20.7 9 22 10.3 22 12Z"
      fill="#EDA024"
    />
  </svg>
)

export default UnknowCard
