// import { MutationFunctionOptions } from '@apollo/client'
export const PROCESS_ORDER_REQUEST = 'PROCESS_ORDER_REQUEST'
export const PROCESS_MAIN_ORDER_REQUEST = 'PROCESS_MAIN_ORDER_REQUEST'
export const PROCESS_ORDER_DOUBLE_AUTH_REQUEST =
  'PROCESS_ORDER_DOUBLE_AUTH_REQUEST'
export const PROCESS_SUBSCRIBE_TO_MONEY_BACK_GUARANTEE_REQUEST =
  'PROCESS_SUBSCRIBE_TO_MONEY_BACK_GUARANTEE_REQUEST'
export const LOAD_USER = 'LOAD_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const DELETE_FIRST_USER = 'DELETE_FIRST_USER'
export const USER_ERROR = 'USER_ERROR'
export const UPDATE_PWD = 'UPDATE_PWD'
export const SET_FIRST_PWD = 'SET_FIRST_PWD'
export const SET_PWD_SUCCESS = 'SET_PWD_SUCCESS'
export const GET_MAIL = 'GET_MAIL'
export const GET_MAIL_SUCCESS = 'GET_MAIL_SUCCESS'
export const CREATE_MOONA_USER = 'CREATE_MOONA_USER'
export const SET_USER_SECRET = 'SET_USER_SECRET'
export const SET_EMAIL_MOONAID_SECRET = 'SET_EMAIL_MOONAID_SECRET'
export const SET_EMAIL = 'SET_EMAIL'
export const IS_MOONA_USER = 'IS_MOONA_USER'
export const ADD_CARD = 'ADD_CARD'
export const CHANGE_CARD = 'CHANGE_CARD'
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const SET_ONLY_EMAIL = 'SET_ONLY_EMAIL'
export const SET_MOONA_ID = 'SET_IS_MOONA_USER'
export const SET_STRIPE_ID = 'SET_STRIPE_ID'
export const SET_HAS_SUBSCRIPTION = 'SET_HAS_SUBSCRIPTION'
export const SET_HAS_SUBSCRIPTION_YCOUPON = 'SET_HAS_SUBSCRIPTION_YCOUPON'
export const SET_HAS_SUBSCRIPTION_MBG = 'SET_HAS_SUBSCRIPTION_MBG'
export const SET_HAS_SUBSCRIPTION_DISCOUNT_POST_PURCHASE = 'SET_HAS_SUBSCRIPTION_DISCOUNT_POST_PURCHASE'

export interface Card {
  brand?: string
  id?: string
  number?: string
  default: boolean
}
export interface User {
  email: string
  moonaId?: string
  stripeId?: string
  paymentId?: string
  phone?: string
  firstName?: string
  lastName?: string
  cards?: Card[]
  password?: string
  userSecret?: string
}

export interface ProcessOrderRequestAction {
  type: typeof PROCESS_ORDER_REQUEST
  payload: {
    email: string
    firstname: string
    lastname: string
    phone: string
    price: number
    merchantStripeId: string
    orderId: string
    ecommerceSessionId: string
    postcode: string
    city: string
    country: string
    address: string
    notifyUrl: string
    ecommerce: string
    cartId?: string | null
    deviceId: string
    merchantId: string
    payload?: string | null
  }
}
export interface ProcessMainOrderRequestAction {
  type: typeof PROCESS_MAIN_ORDER_REQUEST
  payload: {
    email: string
    firstname: string
    lastname: string
    phone: string
    price: number
    merchantStripeId: string
    orderId: string
    ecommerceSessionId: string
    postcode: string
    city: string
    country: string
    address: string
    notifyUrl: string
    ecommerce: string
    cartId?: string | null
    deviceId: string
    merchantId: string
    stripePaymentMethod: string
    discount?: boolean
    payload?: string | null
  }
}

export interface ProcessSubscribeToMoneyBackGuaranteeRequestioAction {
  type: typeof PROCESS_SUBSCRIBE_TO_MONEY_BACK_GUARANTEE_REQUEST
  payload: {
    stripeUserId: string
  }
}

export interface LoadUser {
  type: typeof LOAD_USER
  payload: User
}

export interface StartLoading {
  type: typeof START_LOADING
}
export interface StopLoading {
  type: typeof STOP_LOADING
}
export interface IsMoonaUserByEmail {
  type: typeof IS_MOONA_USER
  payload: {
    email: string
  }
}

export interface SetOnlyEmail {
  type: typeof SET_ONLY_EMAIL
  payload: {
    email: string
  }
}
export interface SetMoonaId {
  type: typeof SET_MOONA_ID
  payload: {
    moonaId: string
  }
}
export interface SetStripeId {
  type: typeof SET_STRIPE_ID
  payload: {
    stripeId: string
  }
}
export interface SetHasSubscription {
  type: typeof SET_HAS_SUBSCRIPTION
  payload: {
    hasSubscription: boolean
  }
}

export interface SetHasSubscriptionYCoupon {
  type: typeof SET_HAS_SUBSCRIPTION_YCOUPON
  payload: {
    hasSubscriptionYCoupon: boolean
  }
}

export interface SetHasSubscriptionMBG {
  type: typeof SET_HAS_SUBSCRIPTION_MBG
  payload: {
    hasSubscriptionMBG: boolean
  }
}

export interface SetHasSubscriptionDiscountPostPurchase {
  type: typeof SET_HAS_SUBSCRIPTION_DISCOUNT_POST_PURCHASE
  payload: {
    hasSubscriptionDiscountPostPurchase: boolean
  }
}

export interface AddCard {
  type: typeof ADD_CARD
  payload: {
    id: string
    last4: string
    brand: string
  }
}

export interface ChangeCard {
  type: typeof CHANGE_CARD
  payload: {
    id: string
  }
}

export interface SetEmail {
  type: typeof SET_EMAIL
  payload: {
    email: string
  }
}
export interface ClearUser {
  type: typeof CLEAR_USER
}

export interface CreateMoonaUserRequestAction {
  type: typeof CREATE_MOONA_USER
}

export interface SetFirstPwdAction {
  type: typeof SET_FIRST_PWD
  payload: {
    email: string
    password: string
  }
}
export interface UpdatePwdAction {
  type: typeof UPDATE_PWD
  payload: {
    username: string
    code: string
    password: string
  }
}

export interface DeleteFirstUserAction {
  type: typeof DELETE_FIRST_USER
  payload: {
    moonaId: string
  }
}
export interface SetUserSecretAction {
  type: typeof SET_USER_SECRET
  payload: {
    userSecret: string
  }
}
export interface SetPwdSuccessAction {
  type: typeof SET_PWD_SUCCESS
  payload: {
    password: string
  }
}

export interface SetUserMailAndId {
  type: typeof SET_EMAIL_MOONAID_SECRET
  payload: {
    email: string
    moonaId: string
  }
}

export interface GetMailAction {
  type: typeof GET_MAIL
  payload: {
    id: string
  }
}
export interface GetMailSuccessAction {
  type: typeof GET_MAIL_SUCCESS
  payload: {
    email: string
  }
}

export interface UserErrorAction {
  type: typeof USER_ERROR
  payload: {
    error: string
  }
}

export type UsersActionTypes =
  | SetFirstPwdAction
  | UpdatePwdAction
  | SetPwdSuccessAction
  | GetMailAction
  | GetMailSuccessAction
  | UserErrorAction
  | ProcessOrderRequestAction
  | ProcessMainOrderRequestAction
  | ProcessSubscribeToMoneyBackGuaranteeRequestioAction
  | CreateMoonaUserRequestAction
  | LoadUser
  | ClearUser
  | SetUserSecretAction
  | SetUserMailAndId
  | DeleteFirstUserAction
  | IsMoonaUserByEmail
  | SetEmail
  | AddCard
  | ChangeCard
  | StartLoading
  | StopLoading
  | SetOnlyEmail
  | SetMoonaId
  | SetStripeId
  | SetHasSubscription
  | SetHasSubscriptionYCoupon
  | SetHasSubscriptionMBG
  | SetHasSubscriptionDiscountPostPurchase

export interface UsersState extends User {
  loading: boolean
  passwordUpdated: boolean
  isMoonaUser: boolean
  stripeId: string
  hasSubscription: boolean
  hasSubscriptionYCoupon: boolean
  hasSubscriptionMBG: boolean
  hasSubscriptionDiscountPostPurchase: boolean
  userloaded: boolean
  error: string
}
