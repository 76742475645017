import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input'
import * as Yup from 'yup'

import { initialCheckoutInformationState } from 'state/features/checkout/initialStates'

const formikInformations = (
  checkoutState,
  callback: (
    values: any,
    setFieldError: (field: string, value: string | undefined) => void,
    setSubmitting: (value: boolean) => void
  ) => void
) => {
  const initialState = initialCheckoutInformationState
  Object.keys(initialState).forEach(key => {
    initialState[key] = checkoutState[key]
  })

  const makeRequired = (field: string, isBillingValue: boolean) => {
    if (isBillingValue === true) {
      return Yup.string().when('requiresShipping', {
        is: val => !val,
        then: Yup.string().required(`${field} is required`),
      })
    } else {
      return Yup.string().when('requiresShipping', {
        is: val => val,
        then: Yup.string().required(`${field} is required`),
      })
    }
  }

  return {
    initialValues: initialState,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      callback(values, setFieldError, setSubmitting)
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')
        .required('Email is required'),
      emailMarketingConsent: Yup.boolean(),
      deliveryMethod: Yup.string(),
      saveInformationForNextTime: Yup.boolean(),
      firstName: makeRequired('Firstname', false),
      lastName: makeRequired('Lastname', false),
      address1: makeRequired('Address', false),
      address2: Yup.string(),
      city: makeRequired('City', false),
      countryCode: makeRequired('Country', false),
      provinceCode: Yup.string()
        .nullable()
        .when('requiresProvince', {
          is: val => val === true,
          then: Yup.string().required(`Province is required`),
        }),
      zip: makeRequired('Zip', false),
      phone: makeRequired('Phone', false),
      firstNameBilling: makeRequired('Firstname', true),
      lastNameBilling: makeRequired('Lastname', true),
      address1Billing: makeRequired('Address', true),
      address2Billing: Yup.string(),
      cityBilling: makeRequired('City', true),
      countryCodeBilling: makeRequired('Country', true),
      provinceCodeBilling: Yup.string()
        .nullable()
        .when('requiresProvinceBilling', {
          is: val => val === true,
          then: Yup.string().required(`Province is required`),
        }),
      zipBilling: makeRequired('Zip', true),
      phoneBilling: makeRequired('Phone', true),
      smsMarketingConsent: Yup.boolean(),
      smsMarketingPhone: Yup.string().when('smsMarketingConsent', {
        is: val => !!val,
        then: Yup.string().test(
          'phone-validation',
          'Invalid phone number',
          value =>
            typeof value === 'string' &&
            isPossiblePhoneNumber(value) &&
            isValidPhoneNumber(value)
        ),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
  }
}

export default formikInformations
