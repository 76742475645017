import { FC } from 'react'

import { StepComponentProps } from 'pages/Checkout/CheckoutFlow'

export const VALIDATE_CHECKOUT_FLOW_STEP = 'VALIDATE_CHECKOUT_FLOW_STEP'
export const UNVALIDATE_CHECKOUT_FLOW_STEP = 'UNVALIDATE_CHECKOUT_FLOW_STEP'
export const REMOVE_SHIPPING_STEP = 'REMOVE_SHIPPING_STEP'

export type StepProps = {
  id: number
  title: string
  component: FC<StepComponentProps>
  validate: boolean
}

export type CheckoutFlowStepsState = StepProps[]

export interface ValidateCheckoutFlowSteps {
  type: typeof VALIDATE_CHECKOUT_FLOW_STEP
  payload: number
}

export interface UnValidateCheckoutFlowSteps {
  type: typeof UNVALIDATE_CHECKOUT_FLOW_STEP
  payload: number
}

export interface RemoveShippingStep {
  type: typeof REMOVE_SHIPPING_STEP
}

export type CheckoutFlowStepsActionTypes =
  | ValidateCheckoutFlowSteps
  | UnValidateCheckoutFlowSteps
  | RemoveShippingStep
