import {
  CREATE_MAIN_PAYMENTINTENT_ERROR,
  CREATE_MAIN_PAYMENTINTENT_FAILURE,
  CREATE_MAIN_PAYMENTINTENT_REQUEST,
  CREATE_MAIN_PAYMENTINTENT_SUCCESS,
  CREATE_PAYMENTINTENT_ERROR,
  CREATE_PAYMENTINTENT_FAILURE,
  CREATE_PAYMENTINTENT_REQUEST,
  CREATE_PAYMENTINTENT_SUCCESS,
  PaymentIntentActionTypes,
  PaymentIntentState,
  RESET_MAIN_PAYMENTINTENT,
  RESET_PAYMENTINTENT,
  SET_CLIENT_SECRET
} from './types'

export const initialPaymentIntentState: PaymentIntentState = {
  loading: false,
  success: false,
  failure: false,
  error: '',
  amount: 0,
  moonaId: '',
  stripeMerchantId: '',
  clientSecret: ''
}

export default function payout(
  state = initialPaymentIntentState,
  action: PaymentIntentActionTypes
): PaymentIntentState {
  switch (action.type) {
    case CREATE_PAYMENTINTENT_REQUEST:
      return {
        ...state,
        loading: true,
        amount: action.payload.price,
        moonaId: action.payload.moonaId,
        stripeMerchantId: action.payload.stripeMerchantId
      }
    case CREATE_PAYMENTINTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
        error: ''
      }
    case CREATE_PAYMENTINTENT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true
      }
    case CREATE_PAYMENTINTENT_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case CREATE_MAIN_PAYMENTINTENT_REQUEST:
      return {
        ...state,
        loading: true,
        amount: action.payload.price,
        moonaId: action.payload.moonaId,
        stripeMerchantId: action.payload.stripeMerchantId
      }
    case CREATE_MAIN_PAYMENTINTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
        error: ''
      }
    case CREATE_MAIN_PAYMENTINTENT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true
      }
    case CREATE_MAIN_PAYMENTINTENT_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case SET_CLIENT_SECRET:
      return {
        ...state,
        clientSecret: action.payload.clientSecret
      }
    case RESET_PAYMENTINTENT:
      return {
        ...initialPaymentIntentState
      }
    case RESET_MAIN_PAYMENTINTENT:
      return {
        ...initialPaymentIntentState
      }

    default:
      return state
  }
}
