/* eslint-disable camelcase */
import { isPrestashop, isWooCommerce } from './ecommerce'
import { getTargetOrigin } from './url'

type PaidEventPayload = {
  has_moona_discount: boolean
  order_id: string
}

export type IframeEvents =
  | {
      action: 'cancel'
    }
  | {
      action: 'paid'
      payload: PaidEventPayload
    }
  | {
      action: 'finish-moona-process'
      url: string
    }
  | {
      action: 'open-new-tab'
      url: string
    }
  | {
      action: 'close-iframe'
    }

export const isEmbeddedMode = (
  mode?: string
): mode is 'iframe' | 'integrated' =>
  mode?.toLowerCase() === 'iframe' || mode?.toLowerCase() === 'integrated'

export const notifyParentWindow = (
  data: IframeEvents,
  mode: string = 'redirection',
  targetOrigin: string = '*'
) => {
  // console.log('notifyParentWindow', data, mode, targetOrigin);
  if (isEmbeddedMode(mode)) {
    if (window.parent) {
      const message = { ...data, source: 'Moona' }
      const targetOriginUrl =
        targetOrigin === '*' ? targetOrigin : getTargetOrigin(targetOrigin)

      // console.log('Sending message to parent window', message, targetOriginUrl);

      window.parent.postMessage(message, targetOriginUrl)
    }
  }
}

export const notifyPaidEvent = (
  payload: PaidEventPayload,
  mode: string = 'redirection',
  targetOrigin: string = '*',
  ecommerceSessionId?: string
) => {
  return notifyParentWindow(
    {
      action: 'paid',
      payload,
      ...(ecommerceSessionId ? { ecommerceSessionId } : {}),
    },
    mode,
    targetOrigin
  )
}

export const notifyCancelEvent = (
  mode: string = 'redirection',
  ecommerce: string,
  targetOrigin: string = '*'
) => {
  if (isPrestashop(ecommerce)) {
    notifyParentWindow(
      {
        action: 'close-iframe',
      },
      mode,
      targetOrigin
    )
  } else {
    notifyParentWindow(
      {
        action: 'cancel',
      },
      mode,
      targetOrigin
    )
  }
}

export const notifyNewTabEvent = (
  url: string,
  mode: string = 'redirection',
  targetOrigin: string = '*'
) => {
  notifyParentWindow(
    {
      action: 'open-new-tab',
      url: url,
    },
    mode,
    targetOrigin
  )
}

export const notifyFinishProcessEvent = (
  returnUrl: string,
  mode: string = 'redirection',
  hasDiscount: boolean = false,
  targetOrigin: string = returnUrl,
  ecommerce: string
) => {
  // console.log('notifyFinishProcessEvent', returnUrl, mode, hasDiscount, targetOrigin, ecommerce);
  notifyParentWindow(
    {
      action: 'finish-moona-process',
      url: `${returnUrl}${
        isWooCommerce(ecommerce) && hasDiscount ? '&disc=1' : ''
      }`,
    },
    mode,
    targetOrigin
  )
}

export const openNewTabServices = (
  mode: string = 'redirection',
  isShippingEnabled: boolean = false,
  urlShipping: string,
  isDiscountEnabled: boolean = false,
  urlDiscount: string
) => {
  // Open new tab if needed
  if (isEmbeddedMode(mode)) {
    if (isShippingEnabled) {
      notifyNewTabEvent(urlShipping, mode)
    }
    if (isDiscountEnabled) {
      notifyNewTabEvent(urlDiscount, mode)
    }
  } else {
    if (isShippingEnabled) {
      window.open(urlShipping, '_blank')
    }
    if (isDiscountEnabled) {
      window.open(urlDiscount, '_blank')
    }
  }
}
