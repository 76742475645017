import { StoreSettingsState } from './types'

export const initialStoreSettingsState: StoreSettingsState = {
  id: null,
  name: null,
  publishableKeyLive: null,
  publishableKeyTest: null,
  secretKeyLive: null,
  secretKeyTest: null,
  slotMP2: null,
  logoUrl: null,
  checkoutButtonColor: '',
  checkoutColor: '',
  checkoutUrl: '',
  emailMarketing: null,
  smsMarketing: null,
  enabled: null,
  markets: [],
  countries: [],
  paymentMethods: [],
  fastCheckoutMethods: [],
  storefrontAccessToken: null,
  myShopifyDomain: null,
  trackingCode: null,
  merchantId: null,
  ecommerceSolution: null,
  ecommerceVersion: null,
  apiVersion: null,
  pluginVersion: null,
  shippingSlotAssociatedMethod: null,
  shippingSlotDisplayAssociatedMethod: null,
  shippingSlotEnabled: null,
  shippingSlotLabel: null,
  shippingSlotSort: null,
  serverShopifyUrl: null,
  taxesIncluded: null,
  testMode: null,
  appStatus: null,
  appBlockProductCheckbox: [],
  createdAt: null,
  updatedAt: null,
  addressAutoCompletionEnabled: null,
}
