import qh from 'query-string'

import { AppActions } from '../types'

import {
  ECOM_SESSION_FAILED,
  EcomSession,
  LOAD_ECOM_SESSION,
  LOAD_MAIN_ECOM_SESSION,
  LOAD_QUERY,
  LoadMainEcomSessionAction,
  SET_ECOM_SESSION,
  SET_QUERY_LOADED,
  START_MAIN_SESSION_LOADING,
  STOP_MAIN_SESSION_LOADING,
} from './types'

export type SupportedCurrencies = 'GBP' | 'CAD'

export const FALLBACK_CURRENCY: SupportedCurrencies = 'GBP' as const

export const formatWebsiteUrl = (websiteUrl: string): string => {
  const https = new RegExp(/(https:\/\/www.)|(https:\/\/)/gi)
  const http = new RegExp(/(http:\/\/www.)|(http:\/\/)/gi)
  const www = new RegExp(/(www.)/gi)
  const website = websiteUrl
    .replace(https, '')
    .replace(http, '')
    .replace(www, '')

  return website
}

export const loadQuery = (queryString: string): AppActions => {
  const {
    email,
    firstname,
    lastname,
    phone,
    cardnumber,
    expiry,
    cvc,
    price,
    ecommerce,
    islogged,
    url,
    returnUrl,
    merchantSite,
    merchantStripeId,
    merchantId,
    sessionid,
    notificationUrl,
    orderId,
    ecommerceSessionId,
    deviceId,
    enableIframeMode,
    currency,
    pluginVersion,
  } = qh.parse(queryString)

  const normalizedMarchantSite = formatWebsiteUrl(
    (merchantSite as string) || ''
  )

  const amount = parseFloat('' + price)
  return {
    type: LOAD_QUERY,
    payload: {
      email: (email as string) || '',
      firstname: (firstname as string) || '',
      lastname: (lastname as string) || '',
      phone: (phone as string) || '',
      cardnumber: (cardnumber as string) || '',
      expiry: (expiry as string) || '',
      cvc: (cvc as string) || '',
      mode: enableIframeMode === 'true' ? 'iframe' : 'redirection',
      price: amount,
      ecommerce: (ecommerce as string) || '',
      islogged: (islogged as string) || '',
      cancelUrl: (url as string) || '',
      returnUrl: (returnUrl as string) || '',
      merchantSite: normalizedMarchantSite,
      merchantStripeId: (merchantStripeId as string) || '',
      merchantId: (merchantId as string) || '',
      sessionid: (sessionid as string) || '',
      notificationUrl: (notificationUrl as string) || '',
      orderId: (orderId as string) || '',
      ecommerceSessionId: (ecommerceSessionId as string) || '',
      deviceId: (deviceId as string) || '',
      currency: (currency as SupportedCurrencies) || FALLBACK_CURRENCY,
      pluginVersion: (pluginVersion as string) || '',
    },
  }
}

export const loadEcomSession = ({ id }: { id: string }): AppActions => {
  return {
    type: LOAD_ECOM_SESSION,
    payload: {
      id,
    },
  }
}

export const loadMainEcomSession = ({
  id,
}: LoadMainEcomSessionAction['payload']): AppActions => {
  return {
    type: LOAD_MAIN_ECOM_SESSION,
    payload: {
      id,
    },
  }
}

export const setEcomSession = (ecomSession: EcomSession): AppActions => {
  const {
    email,
    firstname,
    lastname,
    phone,
    amount,
    website,
    ecommerceSolution,
    stripeMerchantId,
    merchantId,
    cancelUrl,
    returnUrl,
    notificationUrl,
    orderId,
    id,
    postcode,
    address,
    city,
    country,
    deviceId,
    mode,
    cartId,
    currency,
    payload,
    paymentMethod,
    pluginVersion,
    shopifyPayloadCreateOrder,
    shopifyDomain,
  } = ecomSession

  const normalizedMarchantSite = formatWebsiteUrl((website as string) || '')

  return {
    type: SET_ECOM_SESSION,
    payload: {
      email,
      firstname,
      lastname,
      phone,
      postcode,
      address,
      city,
      currency: currency || FALLBACK_CURRENCY,
      country,
      price: +(+amount / 100).toFixed(2),
      ecommerce: ecommerceSolution,
      cancelUrl,
      returnUrl,
      merchantSite: normalizedMarchantSite,
      merchantStripeId: stripeMerchantId,
      merchantId: merchantId,
      mode,
      notificationUrl,
      cartId,
      orderId,
      ecommerceSessionId: id,
      deviceId,
      stripePaymentMethod: paymentMethod,
      payload: payload ? JSON.parse(payload) : null,
      pluginVersion: pluginVersion,
      shopifyPayloadCreateOrder,
      shopifyDomain,
    },
  }
}

export const setQueryLoaded = (queryLoaded: boolean): AppActions => {
  return {
    type: SET_QUERY_LOADED,
    payload: {
      queryLoaded,
    },
  }
}
export const setEcomSessionFailed = (userSessionError: boolean): AppActions => {
  return {
    type: ECOM_SESSION_FAILED,
    payload: {
      userSessionError,
    },
  }
}

export const startMainSessionLoading = (): AppActions => {
  return {
    type: START_MAIN_SESSION_LOADING,
  }
}

export const stopMainSessionLoading = (): AppActions => {
  return {
    type: STOP_MAIN_SESSION_LOADING,
  }
}
