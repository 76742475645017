import { AppActions } from '../types'

import {
  CLEAR_LOGIN_EMAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS,
  LOGGABLE,
  LOGGABLE_SUCESS,
  LOGGED_IN,
  LOGIN,
  LOGIN_FAIL,
  LOGOUT,
  SET_LOGIN_EMAIL,
} from './types'

export const loginRequest = (login: string, password: string): AppActions => {
  return {
    type: LOGIN,
    payload: {
      login,
      password,
    },
  }
}

export const logoutRequest = (login: string): AppActions => {
  return {
    type: LOGOUT,
    payload: {
      login,
    },
  }
}
export const forgotPasswordRequest = (login: string): AppActions => {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      login,
    },
  }
}

export const loggedIn = (): AppActions => {
  return {
    type: LOGGED_IN,
  }
}
export const loginFail = (): AppActions => {
  return {
    type: LOGIN_FAIL,
  }
}

export const loggable = (userId: string): AppActions => {
  return {
    type: LOGGABLE,
    payload: {
      userId,
    },
  }
}

export const loggableSuccess = (loggable: boolean): AppActions => {
  return {
    type: LOGGABLE_SUCESS,
    payload: {
      loggable,
    },
  }
}

export const createForgotPasswordSuccess = (): AppActions => ({
  type: FORGOT_PASSWORD_SUCCESS,
})
export const createForgotPasswordFailure = ({
  error,
}: {
  error: string
}): AppActions => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: {
    error,
  },
})

export const createForgotPasswordError = ({
  error,
}: {
  error: string
}): AppActions => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: {
    error,
  },
})
export const resetForgotPassword = (): AppActions => ({
  type: FORGOT_PASSWORD_RESET,
})

export const createForgotPasswordRequest = ({
  login,
}: {
  login: string
}): AppActions => ({
  type: FORGOT_PASSWORD,
  payload: {
    login,
  },
})

export const setLoginEmail = (value: string): AppActions => ({
  type: SET_LOGIN_EMAIL,
  payload: value,
})

export const clearLoginEmail = (): AppActions => ({
  type: CLEAR_LOGIN_EMAIL,
})
