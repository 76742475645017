import React from 'react'

const Revolut = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="24"
      fill="none"
      aria-labelledby="pi-revolut"
      {...props}
    >
      <path
        d="M1 3a2 2 0 012-2h32a2 2 0 012 2v18a2 2 0 01-2 2H3a2 2 0 01-2-2V3z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0h32a3 3 0 013 3v18a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm0 1a2 2 0 00-2 2v18a2 2 0 002 2h32a2 2 0 002-2V3a2 2 0 00-2-2H3z"
        fill="#000"
        fillOpacity=".07"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.815 6.5A2.444 2.444 0 004.37 8.944v6.112A2.444 2.444 0 006.815 17.5h6.11a2.444 2.444 0 002.445-2.444V8.944A2.444 2.444 0 0012.926 6.5H6.815zm5.378 1.86c-.532-.3-1.04-.396-1.847-.438a11.17 11.17 0 00-1.83.061l-.366.039-.167.024c-.056.01-.106.02-.156.033a.866.866 0 00-.398.206l-.04.037-.064.064a.503.503 0 00-.13.248l-.027.12-.052.263a4 4 0 010 .449l-.038.388-.072.609-.06.42s-.072.486-.12.796c-.047.303-.123.776-.123.776l-.358 2.096h.003s-.231 1.6 1.774 1.529c.319-.012.577-.077.789-.136l.116-.037.11-.04c.218-.087.403-.228.533-.392.054-.067.161-.073.21-.003.308.394.758.646 1.318.617 1.354-.073 1.76-.386 1.96-.617.269-.307.23-.634 0-.972l-.072-.112-.088-.139c-.225-.376-.414-.8-.616-1.373l-.055-.158.08-.065a3.13 3.13 0 00.57-.63 2.442 2.442 0 00.412-1.19 2.943 2.943 0 00-.023-.708 2.338 2.338 0 00-.222-.749 2.4 2.4 0 00-.951-1.016zm-3.603.52c-.184.017-.578.054-.577.09.01.177.009.36 0 .536-.006.145-.063.632-.096.913v.001l-.02.165a140.97 140.97 0 01-.26 1.734c-.083.52-.176 1.038-.268 1.557l-.031.176-.03.151c-.05.247-.107.528.018.747.132.232.471.26.709.237.861-.085.933-.258.933-.258 0-.612.063-1.167.215-2.139h.465c.088 0 .167.056.196.14l.593 1.715s.08.25.286.412a.618.618 0 00.427.136c.673-.036 1.16-.182 1.232-.264-.454-.87-.793-1.53-1.115-2.57.156-.091.308-.19.45-.302.195-.152.376-.327.515-.534a1.548 1.548 0 00.25-.658l.013-.107a2.082 2.082 0 00-.017-.495 1.589 1.589 0 00-.132-.474 1.52 1.52 0 00-.593-.646c-.444-.25-.956-.297-1.455-.323a10.25 10.25 0 00-1.679.056l-.029.003zm1.06.845a25.876 25.876 0 01-.327 2.16l.135.001c.442.007 1.198.02 1.378-1.102.166-1.037-.538-1.165-1.186-1.059z"
        fill="#191C1F"
      />
      <path
        d="M19.04 15.5v-2.45h1.67c1.41 0 2.31-.96 2.31-2.3 0-1.3-.88-2.25-2.31-2.25h-2.84v7h1.17zm0-5.95h1.47c.81 0 1.31.43 1.31 1.2 0 .81-.47 1.24-1.31 1.24h-1.47V9.55zm6.287 6.04c.69 0 1.22-.26 1.52-.7h.04l.08.61h1v-3.44c0-1.02-.77-1.81-2.13-1.81-1.3 0-2.07.73-2.07 1.71h1.08c.01-.42.34-.77.97-.77.72 0 1.02.4 1.02.88v.48c-.27-.08-.73-.17-1.2-.17-1.48 0-2.11.7-2.11 1.6 0 .91.66 1.61 1.8 1.61zm.26-.91c-.62 0-.92-.32-.92-.74 0-.46.36-.74 1.05-.74.38 0 .74.05 1.12.18v.17c0 .61-.49 1.13-1.25 1.13zm5.181 3.11l2.74-7.35v-.1h-1.21l-1.34 3.88h-.04l-1.34-3.88h-1.19v.1l1.88 5.03.12.01-.82 2.21v.1h1.2z"
        fill="#191C1F"
      />
    </svg>
  )
}

export default Revolut
