import useOutsideClick from 'common/hooks/useOutsideClick'
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { AutocompleteMenuHeader } from './components/AutocompleteMenuHeader'
import { AutocompleteMenuOption } from './components/AutocompleteMenuOption'
import { AutocompleteMenuFooter } from './components/AutocompleteMenuFooter'
import styled from 'styled-components'
import MoonaIcon from 'common/components/Icon/MoonaIcon'

const CloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  width: 16px;
  height: 16px;

  &:hover {
    border: none;
    outline: none;
    cursor: pointer;
  }
`

const Menu = ({ wrapperStyle, data, handleClickOnPlace, setOpen }) => {
  return (
    <div
      className="custom-autocomplete-menu"
      style={{
        position: 'absolute',
        top: `${wrapperStyle.top + 5}px`,
        left: `${wrapperStyle.left}px`,
        width: `${wrapperStyle.width}px`,
        zIndex: 9999,
        background: '#FFF',
        borderRadius: '4px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        minHeight: '200px',
      }}
    >
      <AutocompleteMenuHeader>
        <p>SUGGESTIONS</p>
        <CloseButton onClick={() => setOpen(false)}>
          <MoonaIcon icon="Close" fill="#707070" />
        </CloseButton>
      </AutocompleteMenuHeader>
      <ul>
        {data.map((item, index) => {
          if (
            item?.structured_formatting?.main_text_matched_substrings?.length >
            0
          ) {
            const mainText = item.structured_formatting.main_text
            const matchingSubstrings =
              item.structured_formatting.main_text_matched_substrings[0]
            const { length, offset } = matchingSubstrings

            const firstPart = mainText.slice(0, offset)
            const secondPart = mainText.slice(offset + length)
            const lastPart = item.structured_formatting.secondary_text ?? ''

            return (
              <AutocompleteMenuOption
                key={index}
                onClick={() => handleClickOnPlace(item.place_id)}
              >
                {firstPart}
                <strong>{mainText.slice(offset, offset + length)}</strong>
                {secondPart}
                {', ' + lastPart}
              </AutocompleteMenuOption>
            )
          }
          return (
            <AutocompleteMenuOption
              key={index}
              onClick={() => handleClickOnPlace(item.place_id)}
            >
              {item.description}
            </AutocompleteMenuOption>
          )
        })}
      </ul>

      <AutocompleteMenuFooter>
        <p>
          powered by <strong>Google</strong>
        </p>
      </AutocompleteMenuFooter>
    </div>
  )
}

const AutocompleteMenu = ({
  element,
  open,
  setOpen,
  data,
  handleClickOnPlace,
}) => {
  const elRef = useRef<HTMLDivElement>(null)

  useOutsideClick(elRef, () => {
    setOpen(false)
  })

  useEffect(() => {
    if (open && element?.parentElement) {
      const rect = element.parentElement.getBoundingClientRect()
      const el = document.createElement('div')

      document.body.appendChild(el)
      elRef.current = el

      setTimeout(() => {
        ReactDOM.render(
          <Menu
            data={data}
            wrapperStyle={{
              top: rect.top + window.scrollY + rect.height,
              left: rect.left,
              width: rect.width,
            }}
            handleClickOnPlace={handleClickOnPlace}
            setOpen={setOpen}
          />,
          el
        )
      }, 1)
    } else {
      if (elRef.current) {
        ReactDOM.unmountComponentAtNode(elRef.current)
        document.body.removeChild(elRef.current)
        elRef.current = null
      }
    }

    return () => {
      if (elRef.current) {
        ReactDOM.unmountComponentAtNode(elRef.current)
        document.body.removeChild(elRef.current)
        elRef.current = null
      }
    }
  }, [open, data])

  return null
}

export default AutocompleteMenu
