import {
  PaymentActionTypes,
  PaymentState,
  SET_FAILURE,
  SET_HAS_DISCOUNT,
  SET_PRICE,
  SET_SUCCESS,
  START_SUBMITTING,
  STOP_SUBMITTING
} from './types'

export const initialPaymentState: PaymentState = {
  price: 0,
  finalPrice: 0,
  discount: 0,
  hasDiscount: false,
  success: false,
  submitting: false
}

export default function payment(
  state = initialPaymentState,
  action: PaymentActionTypes
): PaymentState {
  switch (action.type) {
    case SET_PRICE:
      return {
        ...state,
        price: action.payload.price,
        finalPrice: action.payload.finalPrice,
        discount: action.payload.discount
      }
    case SET_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true
      }
    case SET_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false
      }
    case START_SUBMITTING:
      return {
        ...state,
        submitting: true
      }
    case STOP_SUBMITTING:
      return {
        ...state,
        submitting: false
      }

    case SET_HAS_DISCOUNT:
      return {
        ...state,
        hasDiscount: action.payload.hasDiscount
      }

    default:
      return state
  }
}
