import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { CheckoutInput } from 'common/components/Form/Checkout'
import Flex from 'common/components/Primitives/Flex'
import { CheckoutLink } from 'common/style/components/links'

const CustomerAvatar = styled('div')`
  background: #c5c4c4;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  padding: 2px;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`

const CustomerBloc = ({ formik, labels, mode, setMode }) => {
  const {
    customer,
    store: { logoutUrl },
  } = useSelector(state => state.checkoutSession)

  useEffect(() => {
    if (customer?.id) {
      setMode('connected')
    }
  }, [customer, setMode])

  return mode === 'connected' ? (
    <Flex spacing="14px" align="center">
      <CustomerAvatar>
        <img src={customer.urlPhoto ?? '/defaultAvatar.webp'} alt="customer" />
      </CustomerAvatar>
      <Flex direction="column" style={{ gap: '.5rem' }}>
        <p
          style={{ fontSize: '1.4rem', color: '#545454' }}
        >{`${customer.firstName} ${customer.lastName} (${customer.email})`}</p>
        <CheckoutLink href={logoutUrl ?? ''}>Log out</CheckoutLink>
      </Flex>
    </Flex>
  ) : (
    <>
      <CheckoutInput
        name="email"
        label={labels.email.label}
        onChange={formik.handleChange}
        value={formik.values.email}
        errors={formik.errors}
      />
    </>
  )
}

export default CustomerBloc
