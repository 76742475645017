import { ShopperState } from './types'

export const initialShopperState: ShopperState = {
  id: null,
  sbdt: null, // discount code
  sbfs: null, // freeshipping subscription
  sbm: null, // Moona subscription
  sbmbg: null, // MBG subscription
  stripeId: null,
}
