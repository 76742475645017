import React, { useState, useEffect } from 'react'

import qh from 'query-string'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { BaseBundleMessage } from 'common/components/Form/validators'
import {
  MoonaCenterTopToastContainer,
  toastError,
} from 'common/components/Toast/Toast'
import logo from 'common/images/logo.svg'
import { H4 } from 'common/style/components/Typography'
import { tablet } from 'common/style/queries/media-queries'
import { sendAmplitudeData } from 'common/utilities/amplitude'
import {
  LoginCenteredMain,
  LoginImage,
  LoginTitle,
  LoginWrapper,
} from 'pages/Login/Login'
import { AppState } from 'state/features/types'
import { userError } from 'state/features/users/actions'

import { LoginSection } from '../../common/style/components/Section'

import { ResetPasswordForm } from './components/ResetPasswordForm'

export interface FormBundle {
  password: {
    label: string
    placeholder: string
    validation: BaseBundleMessage
  }
  confirmpassword: {
    label: string
    placeholder: string
    validation: BaseBundleMessage
  }
  button: { label: string }
  validation: { label: string; links: { label: string; target: string }[] }
}
interface PageBundle {
  title: string
  intro: string
  form: FormBundle
}

export const ResetPasswordMain = styled(LoginCenteredMain)`
  @media ${tablet} {
    min-height: 51.6rem;
  }
`

export const Logo = styled(LoginImage)``
export const Title = styled(LoginTitle)``
export const Intro = styled(H4)`
  margin-top: 1.2rem;
  max-width: 32.6rem;
  text-align: center;
  color: ${props => props.theme.colorgreymain};
`

export const ResetPasswordWrapper = styled(LoginWrapper)``

const ResetPassword: React.FC = () => {
  const [t] = useTranslation('app')
  const location = useLocation()
  const [isProcessing, setProcessing] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const query = qh.parse(location.search)
  let userEmail = ''
  const users = useSelector((state: AppState) => {
    return state.users
  })
  const {
    params: {
      merchantId,
      merchantStripeId,
      merchantSite,
      orderId,
      mode,
      ecommerceSessionId,
      islogged,
    },
  } = useSelector((state: AppState) => {
    return state.query
  })
  const { isMobile } = useSelector((state: AppState) => state.session)

  useEffect(() => {
    if (users.passwordUpdated) {
      setProcessing(false)
      history.push(t('routes.login' + history.location.search))
    }
    if (users.error !== '') {
      toastError({ msg: users.error }, 'error-updatingpwd', () =>
        dispatch(userError({ error: '' }))
      )
      setProcessing(false)
    }
  }, [users, dispatch, history, t])

  useEffect(() => {
    sendAmplitudeData('View - Reset password', {
      mode,
      merchantID: merchantId,
      merchantStripeID: merchantStripeId,
      merchantSite: merchantSite,
      orderID: orderId,
      moonaSessionID: ecommerceSessionId,
      isLogged: islogged,
    })
  }, [
    mode,
    merchantId,
    merchantStripeId,
    merchantSite,
    orderId,
    ecommerceSessionId,
    islogged,
  ])

  if (query.email) {
    userEmail = query.email as string

    if (query.username) {
    } else {
    }
  } else {
    return <Redirect to={t('routes.login' + history.location.search)} />
  }

  const {
    title,
    intro,
    form: { password, confirmpassword, button, validation },
  }: PageBundle = t('resetpassword', {
    mail: userEmail,
    returnObjects: true,
  })

  return (
    <LoginSection>
      {/* {loading ? (
        <ResetPasswordSkeleton />
      ) : ( */}
      <ResetPasswordWrapper>
        {isMobile && <Logo src={logo} />}
        <ResetPasswordMain>
          {!isMobile && <Logo src={logo} />}
          <Title content={title} />
          <Intro>{intro}</Intro>
          <ResetPasswordForm
            password={password}
            passwordUpdated={users.passwordUpdated}
            confirmpassword={confirmpassword}
            button={button}
            validation={validation}
            email={users.email}
            isFirstPwd={query.username === undefined}
            isProcessing={isProcessing}
            setProcessing={setProcessing}
            isSubmitted={isSubmitted}
            setSubmitted={setSubmitted}
            username={(query.username as string) || ('' as string)}
            code={(query.code as string) || ('' as string)}
          />
        </ResetPasswordMain>
      </ResetPasswordWrapper>
      <MoonaCenterTopToastContainer />
    </LoginSection>
  )
}

export default ResetPassword
