import React from 'react'

import AppBox, {
  AppBoxProps,
  PropsOf,
} from '../../components/Primitives/AppBox'

import { NameProps } from './buttons'

export interface Props
  extends Omit<AppBoxProps, keyof PropsOf<'img'>>,
    PropsOf<'img'> {}

export const Image: React.FC<Props & Partial<NameProps>> = ({
  className,
  src,
  alt,
  ...props
}) => {
  const name = src.split('/').pop()?.split('.').shift() || 'image'
  return (
    <AppBox
      as="img"
      className={className}
      {...props}
      src={src}
      alt={alt || ''}
      {...{ name }}
    />
  )
}
