import { getAmplitudeUserDeviceId } from 'common/utilities/amplitude'

import { AppState } from './features/types'
export const integratedContentSelector = (state: AppState) => {
  const { checkoutSession, checkoutShopify, storeSettings, checkout } = state
  return {
    plugin_version: storeSettings.pluginVersion ?? 'SP_1.0.0',
    api_version: storeSettings.apiVersion,
    ecommerce_solution: storeSettings.ecommerceSolution,
    currency: 'GBP',
    email: checkout.email,
    firstname: checkout.firstName,
    lastname: checkout.lastName,
    phone: checkout.phone,
    address: checkout.address1,
    postcode: checkout.zip,
    city: checkout.city,
    country: checkout.countryCode,
    language: 'en',
    return_url: `https://${checkoutSession.myShopifyDomain}`,
    cancel_url: checkoutSession.store.cancelUrl,
    notification_url: `${storeSettings.serverShopifyUrl}/ipn`,
    server_shopify_url: `${storeSettings.serverShopifyUrl}`,
    device_id: getAmplitudeUserDeviceId(),
    website: storeSettings.myShopifyDomain,
    shopify_checkout_session_id: checkoutSession.id,
    order_id: checkoutShopify.id, // Temporary.. must to replace by the real orderId after it's created
    shopify_domain: storeSettings.myShopifyDomain,
    ...(storeSettings.merchantId
      ? {
          merchant_id: storeSettings.merchantId,
        }
      : {}),
  }
}
