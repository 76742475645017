import React, { FC } from 'react'

import Parser from 'html-react-parser'
import styled, { css } from 'styled-components'

import AppBox, { AppBoxProps } from '../../components/Primitives/AppBox'
import Text from '../../components/Primitives/Text'
import { tablet } from '../queries/media-queries'

export const baseTitleCss = css`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colorblackmainmoona02};
`

export const H1: FC<AppBoxProps> = styled(AppBox).attrs(props => ({
  as: 'h1',
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'column',
  fontWeight: 'bold',
  fontSize: ['2.4rem', '3.2rem'],
  lineHeight: ['4rem'],
  ...props,
}))`
  ${baseTitleCss}
`

export const H1ForMBG: FC<AppBoxProps> = styled(AppBox).attrs(props => ({
  as: 'h1',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: ['2.4rem', '3.2rem'],
  lineHeight: ['4rem'],
  ...props,
}))``

export const H2: FC<AppBoxProps> = styled(AppBox).attrs<AppBoxProps>(props => ({
  as: 'h2',
  fontSize: props.fontSize ?? '1.8rem',
  fontWeight: props.fontWeight ?? 'bold',
}))`
  line-height: 3rem;
  ${baseTitleCss}
`
export const H3: FC<AppBoxProps> = styled(AppBox).attrs({ as: 'h3' })`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${baseTitleCss};
  color: ${props => props.color ?? props.theme.colorblackmainmoona02};
`

export const H3Shopify = styled(H3)`
  font-size: 1.8rem;
`

export const OnePagerTitle: FC<AppBoxProps> = styled(AppBox).attrs<AppBoxProps>(
  props => ({
    as: 'h1',
    fontSize: props.fontSize ?? ['1.4rem', '1.8rem'],
  })
)`
  font-weight: 500;
  line-height: 2.4rem;
  ${baseTitleCss}
`

export const OnePagerSubtitle: FC<AppBoxProps> = styled(AppBox).attrs<
  AppBoxProps & { as: any }
>(props => ({
  as: props.as ?? 'p',
  fontWeight: props.fontWeight ?? 400,
  color: props.color ?? '#696464',
  fontSize: props.fontSize ?? ['1.1rem', '1.4rem'],
  lineHeight: props.lineHeight ?? '2rem',
}))`
  ${OnePagerTitle as any} + & {
    margin-top: 1rem;
  }
`

export const H4: FC<AppBoxProps> = styled(AppBox).attrs({ as: 'h4' })`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  ${baseTitleCss}
`

const baseTextCss = css`
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colorgreymain};
`

export const baseFooterTextCss = css`
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: ${props => props.theme.colorgreymain};
`

export const BodyText = styled.div`
  ${baseTextCss}
  color: #000000;
  @media ${tablet} {
    color: ${props => props.theme.colorgreymain};
  }
`
export const Body = styled.p`
  color: ${props => props.theme.colorgreymain};
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  text-align: center;
  @media ${tablet} {
    ${baseTextCss}
  }
`

export const Sup = styled.sup`
  font-size: 1.2rem;
  font-color: black;
  font-weight: 400;
  left: 0px !important;
  top: 0px !important;
  width: auto !important;
`

interface TitleProps {
  title: string
  className?: string
}

export const Title: React.FC<TitleProps> = ({ title, className }) => {
  return (
    <H1 className={className}>
      {title.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </H1>
  )
}

interface MdProps {
  content: string
  className?: string
}

export const MdBody: React.FC<MdProps> = ({ content, className }) => {
  return (
    <Body className={className}>
      {content.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </Body>
  )
}

export const MdH1: React.FC<MdProps & Omit<AppBoxProps, keyof MdProps>> = ({
  content,
  className,
  ...props
}) => {
  return (
    <H1
      fontSize={['2.4rem', '3.6rem']}
      lineHeight={['3.6rem', '4.1rem']}
      className={className}
      {...props}
    >
      {content.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </H1>
  )
}
export const MdH3: React.FC<MdProps> = ({ content, className }) => {
  return (
    <H3 className={className}>
      {content.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </H3>
  )
}
export const MdTitle2: React.FC<MdProps> = ({ content, className }) => {
  return (
    <h2 className={className}>
      {content.split('  ').map((token, i) => (
        <span key={i}>{Parser(token)}</span>
      ))}
    </h2>
  )
}

export const LightSubtitle = styled(Text).attrs({
  color: 'colorgreymain',
})`
  text-align: center;
  font-size: 1rem;
  line-height: 1.4rem;
  & a {
    color: inherit;
  }
`

export const DarkLightSubtitle = styled(Text).attrs({
  color: '#272727',
})`
  text-align: center;
  font-size: 1rem;
  line-height: 1.4rem;
  & a {
    color: inherit;
  }
`

export const DarkLightTowerLondonSubtitle = styled(Text).attrs({
  color: '#272727',
})`
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.7rem;
  & a {
    color: inherit;
  }
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const ListItem = styled.li``
