export const UPDATE_CHECKOUT_SESSION = 'UPDATE_CHECKOUT_SESSION'
export const CLEAR_SLOT_PRODUCT_CHECKBOX = 'CLEAR_SLOT_PRODUCT_CHECKBOX'

type Address = {
  id: string
  customerId: string
  firstName: string
  lastName: string
  company: string
  address1: string
  address2?: string
  city: string
  province: string
  country: string
  zip: string
  phone: string
  name: string
  provinceCode: string
  countryCode: string
  countryName: string
  default: boolean
}

type Customer = {
  id: string
  firstName: string
  lastName: string
  acceptsMarketing: boolean
  email: string
  phone: string
  addresses: Address[]

  // Custom values
  urlPhoto: string
  // VERIF
  // language: string
}

type Cart = {
  id: string
  notes: string
  attributes: CartAttributes
  itemCount: number
  requiresShipping: boolean
  currency: string
  items: any[]

  // VERIF
  currencySymbol: string
  urlPhoto: string
}

type CartAttributes = {
  slotProductCheckbox: string
}

type Store = {
  country: string
  currency: string
  language: string
  myShopifyDomain: string
  customizer: boolean
  appMode: string
  role: string

  // Custom values
  logoutUrl: string
  loginUrl: string
  cancelUrl: string
  cartUrl: string
}

type Order = {
  id: string
}

export interface CheckoutSessionState {
  id: string
  myShopifyDomain: string
  customer: Customer
  cart: Cart
  store: Store
  order: Order
  createdAt: number
  updatedAt: number
  checkoutShopifyId: string
}

export interface UpdateCheckoutSession {
  type: typeof UPDATE_CHECKOUT_SESSION
  payload: CheckoutSessionState
}
export interface ClearSlotProductCheckbox {
  type: typeof CLEAR_SLOT_PRODUCT_CHECKBOX
}

export type CheckoutSessionActionTypes =
  | UpdateCheckoutSession
  | ClearSlotProductCheckbox
