import { UPDATE_CHECKOUT_SESSION, CLEAR_SLOT_PRODUCT_CHECKBOX } from './types'

export const UpdateCheckoutSession = payload => {
  return {
    type: UPDATE_CHECKOUT_SESSION,
    payload,
  }
}

export const ClearSlotProductCheckbox = () => {
  return {
    type: CLEAR_SLOT_PRODUCT_CHECKBOX,
  }
}
