import React, { FC } from 'react'

type QuestionFillProps = {
  fill?: string
} & any

const QuestionFill: FC<QuestionFillProps> = ({ fill = '#737373', ...rest }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9.7875 14.625H7.65V12.375H9.7875V14.625ZM12.7125 6.6375C12.7125 8.6625 11.25 9.5625 9.5625 9.7875L9.45 11.025H8.2125L7.875 8.4375L7.9875 8.325C10.0125 8.2125 10.9125 7.65 10.9125 6.525C10.9125 5.625 10.2375 5.0625 9.1125 5.0625C8.1 5.0625 7.3125 5.5125 6.525 6.3L5.2875 5.0625C6.1875 4.05 7.425 3.2625 9.1125 3.2625C11.25 3.375 12.7125 4.6125 12.7125 6.6375Z"
      fill={fill}
    />
  </svg>
)

export default QuestionFill
