import { CheckoutShopifyState } from '../checkoutShopify/types'

import { SupportedCurrencies } from './actions'

export const LOAD_QUERY = 'LOAD_QUERY'

export const LOAD_ECOM_SESSION = 'LOAD_ECOM_SESSION'
export const START_MAIN_SESSION_LOADING = 'START_MAIN_SESSION_LOADING'
export const STOP_MAIN_SESSION_LOADING = 'STOP_MAIN_SESSION_LOADING'
export const LOAD_MAIN_ECOM_SESSION = 'LOAD_MAIN_ECOM_SESSION'
export const SET_ECOM_SESSION = 'SET_ECOM_SESSION'
export const SET_QUERY_LOADED = 'SET_QUERY_LOADED'
export const ECOM_SESSION_FAILED = 'ECOM_SESSION_FAILED'

export interface EcomSession {
  id: string
  merchantId: string
  stripeMerchantId: string
  ecommerceSolution: string
  website: string
  apiVersion: string
  pluginVersion: string
  amount: string
  currency: SupportedCurrencies

  email: string
  firstname: string
  lastname: string
  phone: string

  address: string
  postcode: string
  city: string
  country: string
  language: string

  mode: 'redirection' | 'iframe' | 'integrated' | 'main-payment-redirection'
  returnUrl: string
  cancelUrl: string
  notificationUrl: string
  orderId: string
  deviceId: string
  cartId?: string | null
  paymentMethod?: string | null
  payload?: string | null

  shopifyPayloadCreateOrder?: ShopifyPayloadCreateOrder
  shopifyDomain?: string
}
export interface Query {
  price: number
  email?: string
  firstname?: string
  lastname?: string
  phone?: string
  cardnumber?: string
  expiry?: string
  cvc?: string
  ecommerce?: string
  islogged?: string
  cancelUrl?: string
  returnUrl?: string
  merchantSite?: string
  merchantStripeId?: string
  merchantId?: string
  sessionid?: string
  notificationUrl?: string
  serverShopifyUrl?: string
  orderId?: string
  ecommerceSessionId?: string
  deviceId?: string
  postcode?: string
  address?: string
  city?: string
  country?: string
  mode?: 'redirection' | 'iframe' | 'integrated' | 'main-payment-redirection'
  cartId?: string | null
  currency: SupportedCurrencies
  stripePaymentMethod?: string | null
  payload?: Record<string, unknown> | null
  pluginVersion?: string
  shopifyPayloadCreateOrder?: ShopifyPayloadCreateOrder
  shopifyDomain?: string
}

export interface ShopifyPayloadCreateOrder {
  session: string
  checkout: CheckoutShopifyState
  totalPriceCeilDecimal?: string
  emailMarketingConsent?: boolean
  smsMarketingConsent?: boolean
  smsMarketingPhone?: string
  billingAddress?: {
    address1: string
    address2: string
    city: string
    company: string
    country: string
    countryCodeV2: string
    firstName: string
    lastName: string
    phone: string
    province: string
    provinceCode: string
    zip: string
  }
  cleeverNotes?: string[]
  cleeverAdditionalNotes?: { name: string; value: string }[]
  cleeverMetafields?: {
    cleeverShippingAmount?: string
    cleeverDiscountAmount?: string
  }
}

export interface LoadQueryAction {
  type: typeof LOAD_QUERY
  payload: Query
}

export interface LoadEcomSessionAction {
  type: typeof LOAD_ECOM_SESSION
  payload: {
    id: string
  }
}
export interface LoadMainEcomSessionAction {
  type: typeof LOAD_MAIN_ECOM_SESSION
  payload: {
    id: string
  }
}
export interface StartMainSessionLoading {
  type: typeof START_MAIN_SESSION_LOADING
}
export interface StopMainSessionLoading {
  type: typeof STOP_MAIN_SESSION_LOADING
}
export interface SetEcomSessionAction {
  type: typeof SET_ECOM_SESSION
  payload: {
    email: string
    firstname: string
    lastname: string
    phone: string
    price: number
    ecommerce: string
    cancelUrl: string
    returnUrl: string
    merchantSite: string
    merchantStripeId: string
    merchantId: string
    notificationUrl: string
    orderId: string
    ecommerceSessionId: string
    deviceId: string
    address: string
    postcode: string
    city: string
    mode: 'redirection' | 'iframe' | 'integrated' | 'main-payment-redirection'
    country: string
    currency: SupportedCurrencies
    cartId?: string | null
    stripePaymentMethod?: string | null
    payload?: Record<string, unknown> | null
    pluginVersion: string
    shopifyPayloadCreateOrder?: ShopifyPayloadCreateOrder
    shopifyDomain?: string
  }
}

export interface SetQueryLoadedAction {
  type: typeof SET_QUERY_LOADED
  payload: {
    queryLoaded: boolean
  }
}
export interface SetEcomSessionFailed {
  type: typeof ECOM_SESSION_FAILED
  payload: {
    userSessionError: boolean
  }
}

export type QueryActionTypes =
  | LoadQueryAction
  | LoadEcomSessionAction
  | SetEcomSessionAction
  | SetQueryLoadedAction
  | SetEcomSessionFailed
  | LoadMainEcomSessionAction
  | StartMainSessionLoading
  | StopMainSessionLoading

export interface QueryState {
  params: Query
  queryLoaded: boolean
  mainSessionLoading: boolean
  userSessionLoaded: boolean
  userSessionLoading: boolean
  userSessionError: boolean
}
