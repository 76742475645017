import React, { FC } from 'react'

import { FieldMetaProps } from 'formik'
import styled from 'styled-components'

import check from 'common/images/check.svg'
import checkko from 'common/images/checkko.svg'
import checkok from 'common/images/checkok.svg'
import { Image } from 'common/style/components/image'

import { WithValidation } from '../../../@types'
export const Icon = styled(Image).attrs({ className: 'status-icon' })`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
`
const CheckIcon: FC<{
  validation?: WithValidation
  meta: Partial<Pick<FieldMetaProps<any>, 'touched' | 'value' | 'error'>>
}> = ({ meta, validation }) => {
  let state: 'neutral' | 'empty' | 'error' | 'success' = 'empty'
  if (!meta.touched && meta.value === 'invalid_phone_number') {
    state = 'neutral'
  } else {
    if (meta.touched && (meta.error || meta.value === 'invalid_phone_number')) {
      state = 'error'
    } else if (meta.value !== '' && !meta.error) {
      state = 'success'
    } else {
      state = 'neutral'
    }
  }
  if ((validation.success ?? true) && state === 'success')
    return <Icon src={checkok} />
  if ((validation.error ?? true) && state === 'error')
    return <Icon src={checkko} />
  if ((validation.neutral ?? true) && state === 'neutral')
    return <Icon src={check} />
  return null
}

export default CheckIcon
