import throttle from 'lodash/throttle'
import { applyMiddleware, createStore, Store, compose } from 'redux'

import { rootReducer, rootSaga } from './features'
import { AppState } from './features/types'
import { loadState, saveState } from './localStorage'
import sagaMiddleware from './middlewares/sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(): Store<AppState> {
  const persistedState = loadState()
  const middlewares = applyMiddleware(sagaMiddleware)
  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(middlewares)
  )
  sagaMiddleware.run(rootSaga)

  store.subscribe(
    throttle(() => {
      saveState({
        users: store.getState().users,
        auth: store.getState().auth,
        query: store.getState().query,
        payment: store.getState().payment,
        // session: store.getState().session,
        // form: store.getState().form,
        // paymentIntent: store.getState().paymentIntent
      })
    }, 1000)
  )

  return store
}
