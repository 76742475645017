import React from 'react'

import Skeleton, { SkeletonProps } from 'react-loading-skeleton'

export const InputSkeleton = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Skeleton> &
    Readonly<SkeletonProps> &
    Readonly<{ children?: React.ReactNode }>
) => <Skeleton height="44px" {...props} />

export const CheckboxSkeleton = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Skeleton> &
    Readonly<SkeletonProps> &
    Readonly<{ children?: React.ReactNode }>
) => <Skeleton height="18px" {...props} />
