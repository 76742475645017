import {
  ADD_SHIPPING_SLOT,
  START_REDIRECT_LOADING,
  START_SAVE_FORM,
  ShippingSlot,
  UPDATE_CHECKOUT,
  UPDATE_GET_DISCOUNT,
} from './types'

export const UpdateCheckout = payload => {
  return {
    type: UPDATE_CHECKOUT,
    payload,
  }
}

export const UpdateGetDiscount = (payload: boolean) => {
  return {
    type: UPDATE_GET_DISCOUNT,
    payload,
  }
}

export const AddShippingSlot = (payload: ShippingSlot) => {
  return {
    type: ADD_SHIPPING_SLOT,
    payload,
  }
}

export const StartSaveForm = () => {
  return {
    type: START_SAVE_FORM,
  }
}

export const StartRedirectLoading = () => {
  return {
    type: START_REDIRECT_LOADING,
  }
}
