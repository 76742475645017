import React from 'react'

import i18next from 'i18next'
import { isIOS } from 'react-device-detect'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
declare global {
  interface Document {
    documentMode?: any
  }
}

export const Metadata: React.FC<{ location?: string }> = ({ location }) => {
  const [t, i18n] = useTranslation('app')
  React.useEffect(() => {
    i18next.changeLanguage('en')
  }, [])

  const key = location
    ? location === '/'
      ? ''
      : location.endsWith('/')
      ? location?.substring(1, location.length - 1) + '.'
      : location?.substring(1) + '.'
    : ''

  const titleKey = 'meta.' + key + 'title'
  return (
    <HelmetProvider>
      <Helmet>
        <html lang={i18n.language} />
        <title>{t(titleKey)}</title>
        {/* {
          <script
            src="https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js"
            type="text/javascript"
          />
        } */}
        <meta name="description" content={t('meta.description')} />
        {isIOS && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        )}
        <link
          href={window.location.href.replace('www', '').replace('.html', '')}
          rel="canonical"
        />
        <meta property="og:image" content={t('meta.image')} />
      </Helmet>
    </HelmetProvider>
  )
}
