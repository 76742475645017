import { css } from 'styled-components'

export const INTEGRATED_INPUT_CSS = css`
  height: 2.5rem;
  line-height: 1rem;
  font-size: 1.2rem;
  line-height: 1rem;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  & .status-icon {
    width: 10px;
    height: 10px;
  }
  & ~ .status-icon {
    width: 10px;
    height: 10px;
  }
  & [name='help'] {
    width: 10px;
    height: 10px;
  }
  &:active {
    border: none;
  }
`
