import React from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import { AppState } from 'state/features/types'

import Layout from '../Layout'
interface Props {
  component: React.FC
  path: string
  exact?: boolean
}
const AuthenticationRoutes: React.FC<Props> = ({
  component: Component,
  path,
}) => {
  const [t] = useTranslation('app')
  const history = useHistory()
  const { logged } = useSelector((state: AppState) => {
    return state.auth
  })

  if (logged) {
    return <Redirect to={t('routes.payment') + history.location.search} />
  }

  return <Layout exact path={path} component={Component} />
}

export default AuthenticationRoutes
