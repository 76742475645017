import React, { useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import MoonaIcon from 'common/components/Icon/MoonaIcon'
import BorderedWrapper from 'common/components/Layout/BorderedWrapper'
import {
  StartRedirectLoading,
  StartSaveForm,
} from 'state/features/checkout/action'

const Divider = () => {
  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          width: '100%',
          height: '1px',
          transform: 'translateY(-50%)',
          background: '#d9d9d9',
        }}
      ></div>
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '1.4rem',
          color: '#545454',
          background: '#fff',
          padding: '0 .75rem',
        }}
      >
        OR
      </span>
    </div>
  )
}

const FastCheckoutCustomButton = styled.button<{
  bgColor: string
}>`
  background: ${props => props.bgColor};
  height: 38px !important;
  min-width: 120px;
`

const ExpressCheckout = () => {
  const globalState = useSelector(state => state)
  const {
    storeSettings: { fastCheckoutMethods, myShopifyDomain, merchantId },
    checkoutShopify,
  } = globalState

  const dispatch = useDispatch()

  const isSkinnyFood = useMemo(
    () =>
      merchantId === 'b793288d-f296-41f0-8a4f-a2aba6d365a3' ||
      merchantId === '50557fa4-fc8c-4dce-b792-f5dc3cd87d66',
    [merchantId]
  )

  const { isMobile } = useSelector((state: any) => state.session)

  const expressCheckoutElement = {
    shoppay: {
      id: 0,
      url: `https://${myShopifyDomain}/checkout?fc=`,
      bgColor: '#5329EB',
      iconName: 'ShopPay',
      boxSvgStyle: { height: isMobile ? '21px' : '18px' },
      iconProps: {
        width: '60px',
      },
    },
    gpay: {
      id: 1,
      url: `https://${myShopifyDomain}/checkout?fc=`,
      bgColor: '#000',
      iconName: 'GPay',
      boxSvgStyle: {
        padding: '7px',
        height: '34px',
      },
      iconProps: {
        fill: '#fff',
        width: '60px',
      },
    },
    paypal: {
      id: 2,
      url: `https://${myShopifyDomain}/checkout?fc=`,
      bgColor: '#FFC33A',
      iconName: 'Paypal',
      boxSvgStyle: { height: isMobile ? '25px' : '23px' },
      iconProps: {
        width: '60px',
      },
    },
    applepay: {
      id: 2,
      url: `https://${myShopifyDomain}/checkout?fc=`,
      bgColor: '#000',
      iconName: 'ApplePay',
      boxSvgStyle: { height: '34px' },
      iconProps: {
        width: '60px',
      },
    },
  }

  return (
    fastCheckoutMethods.length > 0 && (
      <>
        <BorderedWrapper
          label="Express checkout"
          style={{
            border: 'none',
            paddingBottom: '0',
            ...(isMobile ? { flexWrap: 'wrap' } : {}),
          }}
        >
          {fastCheckoutMethods.map((fastCheckoutMethod, index) => {
            const { bgColor, iconName, iconProps, boxSvgStyle } =
              expressCheckoutElement[fastCheckoutMethod]
            const redirect = async () => {
              if (isSkinnyFood) {
                dispatch(StartRedirectLoading())
                dispatch(StartSaveForm())
              } else {
                window.location.href = `https://${myShopifyDomain}/checkout?fc=${fastCheckoutMethod}`
              }
            }
            return (
              <FastCheckoutCustomButton
                key={index}
                bgColor={bgColor}
                onClick={redirect}
                style={{
                  width:
                    fastCheckoutMethods.length > 3
                      ? `calc(${
                          100 / (fastCheckoutMethods.length / 2)
                        }% - 12px)`
                      : `calc(${100 / fastCheckoutMethods.length}% - 12px)`,
                }}
              >
                <div
                  style={{
                    ...boxSvgStyle,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MoonaIcon icon={iconName} {...iconProps} />
                </div>
              </FastCheckoutCustomButton>
            )
          })}
        </BorderedWrapper>
        <Divider />
      </>
    )
  )
}

export default ExpressCheckout
