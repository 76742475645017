import { API, graphqlOperation, Auth } from 'aws-amplify'
import { takeLatest, take, call, fork, put, select } from 'redux-saga/effects'

import {
  createMoonaUser,
  setUserFirstPassword,
  getPaymentIntent,
  deleteFirstUser,
  createMoonaUserAndPaymentIntentMain,
  subscribeToMoneyBackGuarantee,
} from 'graphql/queries'

import {
  clearAmplitudeUserId,
  setAmplitudeUserId,
} from '../../../common/utilities/amplitude'
import { checkCartId } from '../../../utils/axios'
import { isPrestashop } from '../../../utils/ecommerce'
import {
  setFailure,
  setHasDiscount,
  startSubmitting,
  stopSubmitting,
} from '../payment/actions'
import { AppState } from '../types'

import {
  setPwdSuccess,
  setUserMailAndId,
  userError,
  setUserSecret,
  deleteFirstUserAction,
  startLoading,
  stopLoading,
  setOnlyEmail,
  setMoonaId,
  setStripeId,
  setHasSubscription,
  setHasSubscriptionMBG,
  setHasSubscriptionYCoupon,
  setHasSubscriptionDiscountPostPurchase,
} from './actions'
import { getUserInfosByEmail } from './api'
import {
  SET_FIRST_PWD,
  UPDATE_PWD,
  PROCESS_ORDER_REQUEST,
  DELETE_FIRST_USER,
  IS_MOONA_USER,
  IsMoonaUserByEmail,
  SetFirstPwdAction,
  UpdatePwdAction,
  ProcessOrderRequestAction,
  PROCESS_MAIN_ORDER_REQUEST,
  ProcessMainOrderRequestAction,
  PROCESS_SUBSCRIBE_TO_MONEY_BACK_GUARANTEE_REQUEST,
  ProcessSubscribeToMoneyBackGuaranteeRequestioAction,
} from './types'
import { check } from './utils.js'

function* setFirstPwd(action: SetFirstPwdAction) {
  const { email, password } = action.payload
  try {
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(setUserFirstPassword, {
        email: email.toLowerCase(),
        newPassword: password,
      })
    )
    check(result, 'setUserFirstPassword')
    yield put(setPwdSuccess({ password: action.payload.password }))
  } catch (e) {
    let msg = ''
    switch (e.code) {
      case 'ERROR-MOONA-EXPIRED-LINK':
        msg =
          'An error occurred when trying to set your password: ERROR-MOONA-EXPIRED-LINK'
        break
    }
    yield put(
      userError({
        error: msg || 'An error occurred when trying to set your password',
      })
    )
  }
}
function* updatePwd(action: UpdatePwdAction) {
  const { username, code, password } = action.payload
  try {
    yield call(
      [Auth, 'forgotPasswordSubmit'],
      username.toLowerCase(),
      code,
      password
    )

    yield put(setPwdSuccess({ password: action.payload.password }))
  } catch (e) {
    yield put(
      userError({
        error: 'An error occurred when trying to set your password',
      })
    )
  }
}

function* watchSetFirstPwdRequest() {
  yield takeLatest(SET_FIRST_PWD, setFirstPwd)
}
function* watchUpdatePwdRequest() {
  yield takeLatest(UPDATE_PWD, updatePwd)
}

function* isMoonaUser(action: IsMoonaUserByEmail) {
  try {
    yield put(startLoading())
    const user = yield getUserInfosByEmail(action.payload.email.toLowerCase())
    if (user) {
      yield put(setOnlyEmail({ email: user.email }))
      if ('id' in user) {
        yield put(setMoonaId({ moonaId: user.id }))
      }
      if ('stripeId' in user) {
        yield put(setStripeId({ stripeId: user.stripeId }))
      }
      if ('hasSubscription' in user) {
        yield put(setHasSubscription({ hasSubscription: user.hasSubscription }))
      }
      if ('hasSubscriptionYCoupon' in user) {
        yield put(
          setHasSubscriptionYCoupon({
            hasSubscriptionYCoupon: user.hasSubscriptionYCoupon,
          })
        )
      }
      if ('hasSubscriptionMBG' in user) {
        yield put(
          setHasSubscriptionMBG({ hasSubscriptionMBG: user.hasSubscriptionMBG })
        )
      }
      if ('hasSubscriptionDiscountPostPurchase' in user) {
        yield put(
          setHasSubscriptionDiscountPostPurchase({ hasSubscriptionDiscountPostPurchase: user.hasSubscriptionDiscountPostPurchase })
        )
      }
    }
    yield put(stopLoading())
  } catch (e) {
    yield put(stopLoading())
  }
}

function* watchIsMoonaUserByEmail() {
  yield takeLatest(IS_MOONA_USER, isMoonaUser)
}

export const sleep = (time: number) =>
  new Promise(resolve => setTimeout(resolve, time))

function* processOrder(action: ProcessOrderRequestAction) {
  try {
    const {
      firstname,
      lastname,
      phone,
      email,
      price,
      merchantStripeId,
      orderId,
      address,
      city,
      country,
      postcode,
      cartId,
      merchantId,
      deviceId,
      notifyUrl,
      ecommerce,
      ecommerceSessionId,
      payload,
    } = action.payload

    const amount = Math.round(price * 100)
    const currency = yield select(
      (state: AppState) => state.query.params.currency
    )
    yield put(startSubmitting())
    if (isPrestashop(ecommerce)) {
      yield call(checkCartId, notifyUrl, {
        email,
        amount: amount + 500,
        cart_id: cartId,
        merchant_id: merchantId,
        device_id: deviceId,
      })
    }

    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(createMoonaUser, {
        firstname,
        lastname,
        phone,
        address,
        city,
        country,
        postcode,
        email: email.toLowerCase(),
      })
    )

    const createUserData = check(result, 'createMoonaUser')

    const {
      body: {
        user: { id, stripeId },
      },
    } = createUserData

    yield put(setStripeId({ stripeId: stripeId }))
    yield put(setUserMailAndId({ email: action.payload.email, moonaId: id }))

    const paymentIntentResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getPaymentIntent, {
        userId: id,
        stripeMerchantId: merchantStripeId,
        price: Math.round(price * 100),
        currency,
        orderId,
        ecommerceSessionId,
        payload,
      })
    )

    const paymentIntentData = check(paymentIntentResult, 'getPaymentIntent', id)

    const userSecret = paymentIntentData.body.client_secret

    yield put(setUserSecret({ userSecret: userSecret }))
    yield put(stopSubmitting())
    // setAmplitudeUserId(id)
  } catch (error) {
    let msg = ''

    switch (error.code) {
      case 'UsernameExistsException':
        msg = 'Email already registered'
        break
      case 'InvalidParameterException':
        msg = 'Email and password does not match'
        break
      case 'ERROR-MOONA-001':
        msg = 'Incorrect parameters'
        break
      case 'ERROR-STRIPE-001':
        msg = 'An error occurred with our connection to Stripe'
        break
      case 'ERROR-PAYMENT-INTENT-CREATION-002':
        msg = 'Price must be greater than or equal to 1.'
        break
      case 'ERROR-DB-001':
        msg = 'An error occurred in the database'
        break
      case 'ERROR-PRESTASHOP-IPN':
        msg = 'ERROR-PRESTASHOP-IPN'
        break
      default:
        msg = 'An error occurred with the payment. Please, try again later.'
        break
    }

    if (error.moonaId) {
      yield put(deleteFirstUserAction({ moonaId: error.moonaId }))
      clearAmplitudeUserId()
    }
    yield put(setFailure())
    yield put(
      userError({
        error: msg,
      })
    )
    yield put(stopSubmitting())
  }
}
function* processMainOrderRequest(action: ProcessMainOrderRequestAction) {
  try {
    const {
      firstname,
      lastname,
      phone,
      email,
      price,
      merchantStripeId,
      orderId,
      address,
      city,
      country,
      postcode,
      cartId,
      merchantId,
      deviceId,
      notifyUrl,
      ecommerce,
      ecommerceSessionId,
      stripePaymentMethod,
      discount,
      payload,
    } = action.payload

    const amount = Math.round(price * 100)
    const currency = yield select(
      (state: AppState) => state.query.params.currency
    )
    const isMoonaUser = yield select(
      (state: AppState) => state.users.isMoonaUser
    )
    yield put(startSubmitting())
    if (isPrestashop(ecommerce)) {
      yield call(checkCartId, notifyUrl, {
        email,
        amount: amount + 500,
        cart_id: cartId,
        merchant_id: merchantId,
        device_id: deviceId,
      })
    }

    const createUserOnlyOnStripe = !isMoonaUser && !discount

    const paramUser = {
      firstname,
      lastname,
      phone,
      address,
      city,
      country,
      postcode,
      stripeOnly: createUserOnlyOnStripe,
      email: email.toLowerCase(),
    }

    const paramPayment = {
      stripeMerchantId: merchantStripeId,
      price: amount,
      currency,
      stripePaymentMethod,
      orderId,
      ecommerceSessionId,
      discount,
      payload: payload,
    }

    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(createMoonaUserAndPaymentIntentMain, {
        user: paramUser,
        payment: paramPayment,
      })
    )
    const data = JSON.parse(result.data.createMoonaUserAndPaymentIntentMain)

    let userIdToCheck
    if (data.statusCode === 200) {
      if (data.body && data.body.user) {
        userIdToCheck = data.body.user.id
      }
    } else if (data.statusCode === 400) {
      if (data.error && data.error.moonaId) {
        userIdToCheck = data.error.moonaId
      }
    }
    const createMoonaUserAndPaymentIntentMainCheck = check(
      result,
      'createMoonaUserAndPaymentIntentMain',
      userIdToCheck
    )

    const {
      body: { user, clientSecret },
    } = createMoonaUserAndPaymentIntentMainCheck
    if (user) {
      yield put(setStripeId({ stripeId: user.stripeId }))
      yield put(setOnlyEmail({ email: user.email }))
    }

    yield put(setUserSecret({ userSecret: clientSecret }))
    yield put(stopSubmitting())
    yield put(setHasDiscount({ hasDiscount: discount }))
    // setAmplitudeUserId(user.id)
  } catch (error) {
    let msg = ''

    switch (error.code) {
      case 'UsernameExistsException':
        msg = 'Email already registered'
        break
      case 'InvalidParameterException':
        msg = 'Email and password does not match'
        break

      case 'ERROR-MOONA-001':
        msg = 'Incorrect parameters'
        break
      case 'ERROR-PAYMENT-INTENT-MAIN-001':
        msg = 'The customer was not found.'
        break
      case 'ERROR-PAYMENT-INTENT-MAIN-002':
        msg = 'Error while creating the payment.'
        break
      case 'ERROR-STRIPE-001':
        msg = 'An error occurred with our connection to Stripe'
        break
      case 'ERROR-PAYMENT-INTENT-CREATION-002':
        msg = 'Price must be greater than or equal to 1.'
        break
      case 'ERROR-DB-001':
        msg = 'An error occurred in the database'
        break
      case 'ERROR-PRESTASHOP-IPN':
        msg = 'ERROR-PRESTASHOP-IPN'
        break

      default:
        msg = 'An error occurred. Please retry again.'
        break
    }
    if (error.moonaId) {
      yield put(deleteFirstUserAction({ moonaId: error.moonaId }))
      clearAmplitudeUserId()
    }
    yield put(setFailure())
    yield put(
      userError({
        error: msg,
      })
    )
    yield put(stopSubmitting())
  }
}

function* processSubscribeToMoneyBackGuarantee(
  action: ProcessSubscribeToMoneyBackGuaranteeRequestioAction
) {
  try {
    const { stripeUserId } = action.payload

    yield put(startSubmitting())
    const mbgResult = yield call(
      [API, 'graphql'],
      graphqlOperation(subscribeToMoneyBackGuarantee, {
        stripeUserId: stripeUserId,
      })
    )
    check(mbgResult, 'subscribeToMoneyBackGuarantee')
    yield put(stopSubmitting())
  } catch (error) {
    let msg = ''

    switch (error.code) {
      case 'ERROR-MOONA-001':
        msg = 'Incorrect parameters'
        break
      case 'ERROR-SUBSCRIBE-MBG-001':
        msg = 'You are already subscribed'
        break
      case 'ERROR-SUBSCRIBE-MBG-002':
        msg = 'No payment card. Impossible to have the guarantee.'
        break
      case 'ERROR-SUBSCRIBE-MBG-003':
        msg = 'Error to create the MBG subscription'
        break

      default:
        msg = 'ERROR'
        break
    }
    yield put(setFailure())
    yield put(
      userError({
        error: msg,
      })
    )
    yield put(stopSubmitting())
  }
}

function* watchCreateUserRequest() {
  yield takeLatest(PROCESS_ORDER_REQUEST, processOrder)
}

function* watchProcessMainOrderRequest() {
  yield takeLatest(PROCESS_MAIN_ORDER_REQUEST, processMainOrderRequest)
}

function* watchSubscribeToMoneyBackGuaranteeRequest() {
  yield takeLatest(
    PROCESS_SUBSCRIBE_TO_MONEY_BACK_GUARANTEE_REQUEST,
    processSubscribeToMoneyBackGuarantee
  )
}

function* deleteUser({ moonaId }: { moonaId: string }) {
  try {
    yield call(
      [API, 'graphql'],
      graphqlOperation(deleteFirstUser, {
        moonaId,
      })
    )
  } catch (e) {
    yield put(
      userError({
        error: 'An error occurred, please contact moona',
      })
    )
  }
}

function* watchDeleteUserRequest() {
  while (true) {
    const action = yield take(DELETE_FIRST_USER)
    yield call(deleteUser, { moonaId: action.payload.moonaId })
  }
}

const usersSagas = [
  fork(watchSetFirstPwdRequest),
  fork(watchUpdatePwdRequest),
  fork(watchCreateUserRequest),
  fork(watchDeleteUserRequest),
  fork(watchIsMoonaUserByEmail),
  fork(watchProcessMainOrderRequest),
  fork(watchSubscribeToMoneyBackGuaranteeRequest),
]
export default usersSagas
