export const allCountries: { value: string; label: string, primary: boolean }[] = [
  { value: 'AF', label: 'Afghanistan', primary: false },
  { value: 'AX', label: 'Åland Islands', primary: false },
  { value: 'AL', label: 'Albania', primary: false },
  { value: 'DZ', label: 'Algeria', primary: false },
  { value: 'AD', label: 'Andorra', primary: false },
  { value: 'AO', label: 'Angola', primary: false },
  { value: 'AI', label: 'Anguilla', primary: false },
  { value: 'AG', label: 'Antigua & Barbuda', primary: false },
  { value: 'AR', label: 'Argentina', primary: false },
  { value: 'AM', label: 'Armenia', primary: false },
  { value: 'AW', label: 'Aruba', primary: false },
  { value: 'AC', label: 'Ascension Island', primary: false },
  { value: 'AU', label: 'Australia', primary: false },
  { value: 'AT', label: 'Austria', primary: false },
  { value: 'AZ', label: 'Azerbaijan', primary: false },
  { value: 'BS', label: 'Bahamas', primary: false },
  { value: 'BH', label: 'Bahrain', primary: false },
  { value: 'BD', label: 'Bangladesh', primary: false },
  { value: 'BB', label: 'Barbados', primary: false },
  { value: 'BY', label: 'Belarus', primary: false },
  { value: 'BE', label: 'Belgium', primary: false },
  { value: 'BZ', label: 'Belize', primary: false },
  { value: 'BJ', label: 'Benin', primary: false },
  { value: 'BM', label: 'Bermuda', primary: false },
  { value: 'BT', label: 'Bhutan', primary: false },
  { value: 'BO', label: 'Bolivia', primary: false },
  { value: 'BA', label: 'Bosnia & Herzegovina', primary: false },
  { value: 'BW', label: 'Botswana', primary: false },
  { value: 'BR', label: 'Brazil', primary: false },
  { value: 'IO', label: 'British Indian Ocean Territory', primary: false },
  { value: 'VG', label: 'British Virgin Islands', primary: false },
  { value: 'BN', label: 'Brunei', primary: false },
  { value: 'BG', label: 'Bulgaria', primary: false },
  { value: 'BF', label: 'Burkina Faso', primary: false },
  { value: 'BI', label: 'Burundi', primary: false },
  { value: 'KH', label: 'Cambodia', primary: false },
  { value: 'CM', label: 'Cameroon', primary: false },
  { value: 'CA', label: 'Canada', primary: false },
  { value: 'CV', label: 'Cape Verde', primary: false },
  { value: 'BQ', label: 'Caribbean Netherlands', primary: false },
  { value: 'KY', label: 'Cayman Islands', primary: false },
  { value: 'CF', label: 'Central African Republic', primary: false },
  { value: 'TD', label: 'Chad', primary: false },
  { value: 'CL', label: 'Chile', primary: false },
  { value: 'CN', label: 'China', primary: false },
  { value: 'CX', label: 'Christmas Island', primary: false },
  { value: 'CC', label: 'Cocos (Keeling) Islands', primary: false },
  { value: 'CO', label: 'Colombia', primary: false },
  { value: 'KM', label: 'Comoros', primary: false },
  { value: 'CG', label: 'Congo - Brazzaville', primary: false },
  { value: 'CD', label: 'Congo - Kinshasa', primary: false },
  { value: 'CK', label: 'Cook Islands', primary: false },
  { value: 'CR', label: 'Costa Rica', primary: false },
  { value: 'CI', label: 'Côte d’Ivoire', primary: false },
  { value: 'HR', label: 'Croatia', primary: false },
  { value: 'CW', label: 'Curaçao', primary: false },
  { value: 'CY', label: 'Cyprus', primary: false },
  { value: 'CZ', label: 'Czechia', primary: false },
  { value: 'DK', label: 'Denmark', primary: false },
  { value: 'DJ', label: 'Djibouti', primary: false },
  { value: 'DM', label: 'Dominica', primary: false },
  { value: 'DO', label: 'Dominican Republic', primary: false },
  { value: 'EC', label: 'Ecuador', primary: false },
  { value: 'EG', label: 'Egypt', primary: false },
  { value: 'SV', label: 'El Salvador', primary: false },
  { value: 'GQ', label: 'Equatorial Guinea', primary: false },
  { value: 'ER', label: 'Eritrea', primary: false },
  { value: 'EE', label: 'Estonia', primary: false },
  { value: 'SZ', label: 'Eswatini', primary: false },
  { value: 'ET', label: 'Ethiopia', primary: false },
  { value: 'FK', label: 'Falkland Islands', primary: false },
  { value: 'FO', label: 'Faroe Islands', primary: false },
  { value: 'FJ', label: 'Fiji', primary: false },
  { value: 'FI', label: 'Finland', primary: false },
  { value: 'FR', label: 'France', primary: false },
  { value: 'GF', label: 'French Guiana', primary: false },
  { value: 'PF', label: 'French Polynesia', primary: false },
  { value: 'TF', label: 'French Southern Territories', primary: false },
  { value: 'GA', label: 'Gabon', primary: false },
  { value: 'GM', label: 'Gambia', primary: false },
  { value: 'GE', label: 'Georgia', primary: false },
  { value: 'DE', label: 'Germany', primary: false },
  { value: 'GH', label: 'Ghana', primary: false },
  { value: 'GI', label: 'Gibraltar', primary: false },
  { value: 'GR', label: 'Greece', primary: false },
  { value: 'GL', label: 'Greenland', primary: false },
  { value: 'GD', label: 'Grenada', primary: false },
  { value: 'GP', label: 'Guadeloupe', primary: false },
  { value: 'GT', label: 'Guatemala', primary: false },
  { value: 'GG', label: 'Guernsey', primary: false },
  { value: 'GN', label: 'Guinea', primary: false },
  { value: 'GW', label: 'Guinea-Bissau', primary: false },
  { value: 'GY', label: 'Guyana', primary: false },
  { value: 'HT', label: 'Haiti', primary: false },
  { value: 'HN', label: 'Honduras', primary: false },
  { value: 'HK', label: 'Hong Kong SAR', primary: false },
  { value: 'HU', label: 'Hungary', primary: false },
  { value: 'IS', label: 'Iceland', primary: false },
  { value: 'IN', label: 'India', primary: false },
  { value: 'ID', label: 'Indonesia', primary: false },
  { value: 'IQ', label: 'Iraq', primary: false },
  { value: 'IE', label: 'Ireland', primary: false },
  { value: 'IM', label: 'Isle of Man', primary: false },
  { value: 'IL', label: 'Israel', primary: false },
  { value: 'IT', label: 'Italy', primary: false },
  { value: 'JM', label: 'Jamaica', primary: false },
  { value: 'JP', label: 'Japan', primary: false },
  { value: 'JE', label: 'Jersey', primary: false },
  { value: 'JO', label: 'Jordan', primary: false },
  { value: 'KZ', label: 'Kazakhstan', primary: false },
  { value: 'KE', label: 'Kenya', primary: false },
  { value: 'KI', label: 'Kiribati', primary: false },
  { value: 'XK', label: 'Kosovo', primary: false },
  { value: 'KW', label: 'Kuwait', primary: false },
  { value: 'KG', label: 'Kyrgyzstan', primary: false },
  { value: 'LA', label: 'Laos', primary: false },
  { value: 'LV', label: 'Latvia', primary: false },
  { value: 'LB', label: 'Lebanon', primary: false },
  { value: 'LS', label: 'Lesotho', primary: false },
  { value: 'LR', label: 'Liberia', primary: false },
  { value: 'LY', label: 'Libya', primary: false },
  { value: 'LI', label: 'Liechtenstein', primary: false },
  { value: 'LT', label: 'Lithuania', primary: false },
  { value: 'LU', label: 'Luxembourg', primary: false },
  { value: 'MO', label: 'Macao SAR', primary: false },
  { value: 'MG', label: 'Madagascar', primary: false },
  { value: 'MW', label: 'Malawi', primary: false },
  { value: 'MY', label: 'Malaysia', primary: false },
  { value: 'MV', label: 'Maldives', primary: false },
  { value: 'ML', label: 'Mali', primary: false },
  { value: 'MT', label: 'Malta', primary: false },
  { value: 'MQ', label: 'Martinique', primary: false },
  { value: 'MR', label: 'Mauritania', primary: false },
  { value: 'MU', label: 'Mauritius', primary: false },
  { value: 'YT', label: 'Mayotte', primary: false },
  { value: 'MX', label: 'Mexico', primary: false },
  { value: 'MD', label: 'Moldova', primary: false },
  { value: 'MC', label: 'Monaco', primary: false },
  { value: 'MN', label: 'Mongolia', primary: false },
  { value: 'ME', label: 'Montenegro', primary: false },
  { value: 'MS', label: 'Montserrat', primary: false },
  { value: 'MA', label: 'Morocco', primary: false },
  { value: 'MZ', label: 'Mozambique', primary: false },
  { value: 'MM', label: 'Myanmar (Burma)', primary: false },
  { value: 'NA', label: 'Namibia', primary: false },
  { value: 'NR', label: 'Nauru', primary: false },
  { value: 'NP', label: 'Nepal', primary: false },
  { value: 'NL', label: 'Netherlands', primary: false },
  { value: 'NC', label: 'New Caledonia', primary: false },
  { value: 'NZ', label: 'New Zealand', primary: false },
  { value: 'NI', label: 'Nicaragua', primary: false },
  { value: 'NE', label: 'Niger', primary: false },
  { value: 'NG', label: 'Nigeria', primary: false },
  { value: 'NU', label: 'Niue', primary: false },
  { value: 'NF', label: 'Norfolk Island', primary: false },
  { value: 'MK', label: 'North Macedonia', primary: false },
  { value: 'NO', label: 'Norway', primary: false },
  { value: 'OM', label: 'Oman', primary: false },
  { value: 'PK', label: 'Pakistan', primary: false },
  { value: 'PS', label: 'Palestinian Territories', primary: false },
  { value: 'PA', label: 'Panama', primary: false },
  { value: 'PG', label: 'Papua New Guinea', primary: false },
  { value: 'PY', label: 'Paraguay', primary: false },
  { value: 'PE', label: 'Peru', primary: false },
  { value: 'PH', label: 'Philippines', primary: false },
  { value: 'PN', label: 'Pitcairn Islands', primary: false },
  { value: 'PL', label: 'Poland', primary: false },
  { value: 'PT', label: 'Portugal', primary: false },
  { value: 'QA', label: 'Qatar', primary: false },
  { value: 'RE', label: 'Réunion', primary: false },
  { value: 'RO', label: 'Romania', primary: false },
  { value: 'RU', label: 'Russia', primary: false },
  { value: 'RW', label: 'Rwanda', primary: false },
  { value: 'WS', label: 'Samoa', primary: false },
  { value: 'SM', label: 'San Marino', primary: false },
  { value: 'ST', label: 'São Tomé & Príncipe', primary: false },
  { value: 'SA', label: 'Saudi Arabia', primary: false },
  { value: 'SN', label: 'Senegal', primary: false },
  { value: 'RS', label: 'Serbia', primary: false },
  { value: 'SC', label: 'Seychelles', primary: false },
  { value: 'SL', label: 'Sierra Leone', primary: false },
  { value: 'SG', label: 'Singapore', primary: false },
  { value: 'SX', label: 'Sint Maarten', primary: false },
  { value: 'SK', label: 'Slovakia', primary: false },
  { value: 'SI', label: 'Slovenia', primary: false },
  { value: 'SB', label: 'Solomon Islands', primary: false },
  { value: 'SO', label: 'Somalia', primary: false },
  { value: 'ZA', label: 'South Africa', primary: false },
  { value: 'GS', label: 'South Georgia & South Sandwich Islands', primary: false },
  { value: 'KR', label: 'South Korea', primary: false },
  { value: 'SS', label: 'South Sudan', primary: false },
  { value: 'ES', label: 'Spain', primary: false },
  { value: 'LK', label: 'Sri Lanka', primary: false },
  { value: 'BL', label: 'St Barthélemy', primary: false },
  { value: 'SH', label: 'St Helena', primary: false },
  { value: 'KN', label: 'St Kitts & Nevis', primary: false },
  { value: 'LC', label: 'St Lucia', primary: false },
  { value: 'MF', label: 'St Martin', primary: false },
  { value: 'PM', label: 'St Pierre & Miquelon', primary: false },
  { value: 'VC', label: 'St Vincent & the Grenadines', primary: false },
  { value: 'SD', label: 'Sudan', primary: false },
  { value: 'SR', label: 'Suriname', primary: false },
  { value: 'SJ', label: 'Svalbard & Jan Mayen', primary: false },
  { value: 'SE', label: 'Sweden', primary: false },
  { value: 'CH', label: 'Switzerland', primary: false },
  { value: 'TW', label: 'Taiwan', primary: false },
  { value: 'TJ', label: 'Tajikistan', primary: false },
  { value: 'TZ', label: 'Tanzania', primary: false },
  { value: 'TH', label: 'Thailand', primary: false },
  { value: 'TL', label: 'Timor-Leste', primary: false },
  { value: 'TG', label: 'Togo', primary: false },
  { value: 'TK', label: 'Tokelau', primary: false },
  { value: 'TO', label: 'Tonga', primary: false },
  { value: 'TT', label: 'Trinidad & Tobago', primary: false },
  { value: 'TA', label: 'Tristan da Cunha', primary: false },
  { value: 'TN', label: 'Tunisia', primary: false },
  { value: 'TR', label: 'Turkey', primary: false },
  { value: 'TM', label: 'Turkmenistan', primary: false },
  { value: 'TC', label: 'Turks & Caicos Islands', primary: false },
  { value: 'TV', label: 'Tuvalu', primary: false },
  { value: 'UM', label: 'US Outlying Islands', primary: false },
  { value: 'UG', label: 'Uganda', primary: false },
  { value: 'UA', label: 'Ukraine', primary: false },
  { value: 'AE', label: 'United Arab Emirates', primary: false },
  { value: 'GB', label: 'United Kingdom', primary: false },
  { value: 'US', label: 'United States', primary: false },
  { value: 'UY', label: 'Uruguay', primary: false },
  { value: 'UZ', label: 'Uzbekistan', primary: false },
  { value: 'VU', label: 'Vanuatu', primary: false },
  { value: 'VA', label: 'Vatican City', primary: false },
  { value: 'VE', label: 'Venezuela', primary: false },
  { value: 'VN', label: 'Vietnam', primary: false },
  { value: 'WF', label: 'Wallis & Futuna', primary: false },
  { value: 'EH', label: 'Western Sahara', primary: false },
  { value: 'YE', label: 'Yemen', primary: false },
  { value: 'ZM', label: 'Zambia', primary: false },
  { value: 'ZW', label: 'Zimbabwe', primary: false },
]
