export const normalizeUrl = (url: string): string | null => {
  const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i)
  if (matches) {
    return url
  }

  return `https://${url}`
}

export const getTargetOrigin = (url: string): string => {
  const { origin } = new URL(url)

  return origin
}

export const extractHostnameFromUrl = (
  url: string,
  options?: { includeSubdomain?: boolean }
): string | null => {
  try {
    const hostname = new URL(normalizeUrl(url)).hostname
    if (options?.includeSubdomain) {
      return hostname
    }
    const rootDomain = hostname.replace('www.', '')
    const [domain] = rootDomain.split('.')

    return domain
  } catch {
    return url
  }
}
