import React from 'react'

import jsxInnerText from 'common/utilities/jsx'

import AppBox, { AppBoxOwnProps, PolymorphicComponent } from './AppBox'

interface Props extends AppBoxOwnProps {
  readonly truncate?: number
}

const Text = React.forwardRef<HTMLElement, Props>(
  ({ children, truncate, ...props }, ref) => {
    let content = children
    const innerText = jsxInnerText(content)
    if (truncate && innerText.length > truncate) {
      content = `${innerText.slice(0, truncate)}…`
    }
    return (
      <AppBox
        ref={ref}
        as="p"
        {...(truncate ? { title: innerText } : {})}
        {...props}
      >
        {content}
      </AppBox>
    )
  }
)

Text.displayName = 'Text'

export default Text as PolymorphicComponent<Props>
