/* eslint-disable camelcase */

import axios from 'axios'

import { MoonaException } from 'state/features/users/utils'

const CHECK_CART_ID_ERROR_CODES = [
  'ERROR-PRESTASHOP-IPN-001',
  'ERROR-PRESTASHOP-IPN-002',
  'ERROR-PRESTASHOP-IPN-003',
  'ERROR-PRESTASHOP-IPN-004',
  'ERROR-PRESTASHOP-IPN-005',
  'ERROR-PRESTASHOP-IPN-006',
  'ERROR-PRESTASHOP-IPN-007',
  'ERROR-PRESTASHOP-IPN-008',
  'ERROR-PRESTASHOP-IPN-009',
] as const

export const checkCartId = async (
  notifyUrl: string,
  options: {
    amount: number
    email: string
    cart_id: string
    device_id: string
    merchant_id: string
  }
): Promise<{
  code: typeof CHECK_CART_ID_ERROR_CODES[number]
  message: string
}> => {
  const response = await axios.post(notifyUrl, {
    type: 'cart-verification',
    ...options,
  })
  if (response.status >= 400) {
    throw MoonaException('ERROR-PRESTASHOP-IPN', 'ERROR', 'checkCartId')
  }
  if (
    response.data &&
    'code' in response.data &&
    CHECK_CART_ID_ERROR_CODES.includes(response.data.code)
  ) {
    throw MoonaException(
      'ERROR-PRESTASHOP-IPN',
      response.data.message ?? 'ERROR',
      'checkCartId'
    )
  }

  return response.data
}
