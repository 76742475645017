import React from 'react'

const WarningShopify = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      role="img"
      className="_1fragem1a _1fragem3g _1fragembp _1fragembo"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.167 12.407V6.469h1.667v5.938H9.167Z"
      ></path>
      <path d="M10 16.183a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.33 14.536 12.738 2.99C11.223.334 8.774.34 7.261 2.989L.67 14.536C-.842 17.19.291 19.334 3.2 19.334h13.6c2.909 0 4.042-2.148 2.53-4.798ZM8.761 4.08c.839-1.467 1.637-1.468 2.476 0l6.316 11.066c.86 1.506.49 2.209-1.036 2.209H3.483c-1.525 0-1.894-.701-1.038-2.209L8.76 4.08Z"
      ></path>
    </svg>
  )
}

export default WarningShopify
