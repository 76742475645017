import * as React from 'react'

import { NameProps } from '../../style/components/buttons'
import AppBox, { AppBoxProps } from '../Primitives/AppBox'

export const Card = React.forwardRef<
  HTMLElement,
  AppBoxProps & Partial<NameProps>
>(({ ...props }, ref) => (
  <AppBox
    boxShadow="1px 20px 20px 0px rgba(0, 0, 0, 0.04)"
    borderRadius="16px"
    border="1px solid #e8e9ed"
    p="3.2rem 2.2rem"
    bg="white"
    fontSize="1.4rem"
    lineHeight="2.1rem"
    ref={ref}
    {...props}
  />
))

Card.displayName = 'Card'

export default Card
