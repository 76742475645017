import {
  getAmplitudeEventOptions,
  getAmplitudeUserDeviceId,
} from 'common/utilities/amplitude'
import { CheckoutState } from 'state/features/checkout/types'
import { CheckoutShopifyState } from 'state/features/checkoutShopify/types'
import { ShopperState } from 'state/features/shopper/types'
import { StoreSettingsState } from 'state/features/storeSettings/types'
import { currencify } from 'utils/string'

export interface CleeverAmplitudeEventOptions {
  platform?: string
  os_name?: string
  device_model?: string
  country?: string
  region?: string
  city?: string
}

/* eslint-disable camelcase */
export interface CleeverMetafields {
  cleever_shipping_amount?: string
  cleever_discount_amount?: string
  cleever_payload?: string
  cleever_payment_method?: string
  cleever_payment_method_label?: string
  cleever_merchant_id?: string
  cleever_amplitude_user_id?: string
  cleever_amplitude_device_id?: string
  cleever_amplitude_event_options?: string
}

/* eslint-disable camelcase */
export interface StringifyPayloadForECommerceSession {
  is_checked: boolean
  payment_method?: string
  user_infos?: ShopperState
  slot_shipping?: {
    shipping_price_reference: number
    shipping_method_title: string
    shipping_carrier_title: string
    shipping_reference: string
    shipping_label: string
    discount: number
    min_amount_to_display: number
    title: string
    description: string
    subscription_status: 0 | 1 | 2
  }
  slot_order_discount?: {
    title: string
    amount: string
    description: string
  }
  cleever_notes?: string[]
  cleever_additional_notes?: {
    name: string
    value: string
  }[]
  cleever_metafields?: CleeverMetafields
}

export type createStringifyPayloadForECommerceSessionParams = {
  isChecked?: boolean
  paymentMethodId?: string
  checkoutState: CheckoutState
  checkoutShopify: CheckoutShopifyState
  storeSettings: StoreSettingsState
  shopper: ShopperState
  summary: any
  currency: string
}

export function createStringifyPayloadForECommerceSession({
  isChecked,
  paymentMethodId,
  checkoutState,
  checkoutShopify,
  storeSettings,
  shopper,
  summary,
  currency,
}): string {
  const returningPayload: StringifyPayloadForECommerceSession = {
    is_checked: false,
  }

  if (isChecked) {
    returningPayload.is_checked = isChecked
  }

  if (paymentMethodId) {
    returningPayload.payment_method = paymentMethodId
  }

  if (shopper.id) {
    returningPayload.user_infos = shopper
  }

  if (checkoutState.shippingSlot) {
    returningPayload.slot_shipping = createShippingSlotData(
      checkoutState.shippingSlot,
      shopper
    )
  }

  if (checkoutState.getDiscount || checkoutState.paymentMethod === 'MP2') {
    returningPayload.slot_order_discount = createOrderDiscountData(
      checkoutState.cleeverDiscount
    )
  }

  // if (checkoutState.shippingSlot || (checkoutState.getDiscount || checkoutState.paymentMethod === 'MP2')) {
  //   returningPayload.cleever_notes = createCleeverNotesData(
  //     checkoutState,
  //     checkoutShopify,
  //     currency
  //   )
  //   returningPayload.cleever_additional_notes = createCleeverAdditionalNotesData(
  //     checkoutState
  //   )
  // }

  // returningPayload.cleever_metafields = createCleeverMetafieldsData({
  //   checkoutState, storeSettings
  // })

  return JSON.stringify(returningPayload)
}

export function createShippingSlotData(
  slot,
  shopper
): StringifyPayloadForECommerceSession['slot_shipping'] {
  return {
    shipping_price_reference: slot.shippingPriceReference,
    shipping_method_title: slot.shippingMethodTitle,
    shipping_carrier_title: slot.shippingCarrierTitle,
    shipping_reference: slot.shippingReference,
    shipping_label: slot.shippingLabel,
    discount: slot.discount,
    min_amount_to_display: slot.minAmountToDisplay,
    title: slot.title,
    description: slot.description,
    subscription_status: shopper.sbfs ?? 0,
  }
}

export function createOrderDiscountData(
  discount
): StringifyPayloadForECommerceSession['slot_order_discount'] {
  return {
    title: discount.title,
    amount: discount.discount,
    description: discount.description,
  }
}

export function createCleeverNotesData(
  checkoutState,
  checkoutShopify,
  currency,
  summary
): StringifyPayloadForECommerceSession['cleever_notes'] {
  const cleeverNotes = []

  const isCleeverShipping = checkoutState.shippingMethod.includes('Cleever-')
  const isDiscountCheckedOrMP2 =
    checkoutState.getDiscount || checkoutState.paymentMethod === 'MP2'

  if (isCleeverShipping || isDiscountCheckedOrMP2) {
    let generalNotes: string
    let discountNotes: string
    let shippingNotes: string
    // Include the shipping price (ths discounted one) in order total amount (and without the discount)
    let orderTotalAmount = parseInt(
      (parseFloat(checkoutShopify.totalPrice?.amount) * 100).toFixed(0)
    )
    let orderCustomerAmount = parseInt(
      (parseFloat(checkoutShopify.totalPrice?.amount) * 100).toFixed(0)
    )

    if (summary.ceilingError) {
      orderTotalAmount += 1
      orderCustomerAmount += 1
    }

    if (isDiscountCheckedOrMP2) {
      orderCustomerAmount -= checkoutState.cleeverDiscount.discount
      discountNotes = `\n• Discount premium service funded ${currencify(
        checkoutState.cleeverDiscount.discount / 100,
        currency
      )} (included in order total)`
    }

    if (isCleeverShipping) {
      let shippingGivenDiscount = checkoutState.shippingSlot.discount
      if (
        checkoutState.shippingSlot.discount >
        checkoutState.shippingSlot.shippingPriceReference
      ) {
        shippingGivenDiscount =
          checkoutState.shippingSlot.shippingPriceReference
      }
      orderTotalAmount += shippingGivenDiscount
      // orderCustomerAmount -= shippingGivenDiscount
      shippingNotes = `\n• Shipping premium service funded an additional ${currencify(
        shippingGivenDiscount / 100,
        currency
      )} (directly paid to merchant on behalf of the customer)`
    }

    generalNotes = `• Order total value is ${currencify(
      orderTotalAmount / 100,
      currency
    )}`
    generalNotes += `\n• Customer paid ${currencify(
      orderCustomerAmount / 100,
      currency
    )}`
    if (discountNotes) generalNotes += `${discountNotes}`
    if (shippingNotes) generalNotes += `${shippingNotes}`
    cleeverNotes.push(generalNotes)
  }

  return cleeverNotes
}

export function createCleeverAdditionalNotesData(
  checkoutState
): StringifyPayloadForECommerceSession['cleever_additional_notes'] {
  const cleeverAdditionalNotes = []

  const isCleeverShipping = checkoutState.shippingMethod.includes('Cleever-')
  const isDiscountCheckedOrMP2 =
    checkoutState.getDiscount || checkoutState.paymentMethod === 'MP2'

  if (isCleeverShipping) {
    let shippingGivenDiscount = checkoutState.shippingSlot.discount
    if (
      checkoutState.shippingSlot.discount >
      checkoutState.shippingSlot.shippingPriceReference
    ) {
      shippingGivenDiscount = checkoutState.shippingSlot.shippingPriceReference
    }

    cleeverAdditionalNotes.push({
      name: 'funded_shipping',
      value: shippingGivenDiscount.toString(),
    })
  }

  if (isDiscountCheckedOrMP2) {
    cleeverAdditionalNotes.push({
      name: 'funded_discount',
      value: checkoutState.cleeverDiscount.discount.toString(),
    })
  }

  return cleeverAdditionalNotes
}

export function createCleeverMetafieldsData({
  checkoutState,
  storeSettings,
}: {
  checkoutState: CheckoutState
  storeSettings: StoreSettingsState
}): CleeverMetafields {
  const cleeverMetafields: CleeverMetafields = {}

  if (
    checkoutState.shippingSlot ||
    checkoutState.getDiscount ||
    checkoutState.paymentMethod === 'MP2'
  ) {
    const isCleeverShipping = checkoutState.shippingMethod.includes('Cleever-')
    const isDiscountCheckedOrMP2 =
      checkoutState.getDiscount || checkoutState.paymentMethod === 'MP2'

    if (isCleeverShipping) {
      let shippingGivenDiscount = checkoutState.shippingSlot.discount
      if (
        checkoutState.shippingSlot.discount >
        checkoutState.shippingSlot.shippingPriceReference
      ) {
        shippingGivenDiscount =
          checkoutState.shippingSlot.shippingPriceReference
      }

      cleeverMetafields.cleever_shipping_amount =
        shippingGivenDiscount.toString()
    }

    if (isDiscountCheckedOrMP2) {
      cleeverMetafields.cleever_discount_amount =
        checkoutState.cleeverDiscount.discount.toString()
    }
  }

  cleeverMetafields.cleever_merchant_id = storeSettings.merchantId
  cleeverMetafields.cleever_payment_method = checkoutState.paymentMethod
  cleeverMetafields.cleever_payment_method_label = checkoutState.paymentMethod

  const deviceId = getAmplitudeUserDeviceId()
  if (deviceId) {
    cleeverMetafields.cleever_amplitude_device_id = deviceId
  }

  // TODO : TO OPTIMIZE
  const userId = checkoutState.email
  if (userId) {
    cleeverMetafields.cleever_amplitude_user_id = userId
  }

  cleeverMetafields.cleever_payload = JSON.stringify(cleeverMetafields)
  cleeverMetafields.cleever_amplitude_event_options = JSON.stringify(
    getAmplitudeEventOptions()
  )

  return cleeverMetafields
}
