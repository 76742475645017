import {
  ADD_CARD,
  CHANGE_CARD,
  CLEAR_USER,
  GET_MAIL_SUCCESS,
  LOAD_USER,
  SET_EMAIL,
  SET_EMAIL_MOONAID_SECRET,
  SET_FIRST_PWD,
  SET_HAS_SUBSCRIPTION,
  SET_HAS_SUBSCRIPTION_YCOUPON,
  SET_HAS_SUBSCRIPTION_MBG,
  SET_HAS_SUBSCRIPTION_DISCOUNT_POST_PURCHASE,
  SET_MOONA_ID,
  SET_ONLY_EMAIL,
  SET_PWD_SUCCESS,
  SET_STRIPE_ID,
  SET_USER_SECRET,
  START_LOADING,
  STOP_LOADING,
  UPDATE_PWD,
  USER_ERROR,
  UsersActionTypes,
  UsersState
} from './types'

export const initialUserState: UsersState = {
  password: '',
  isMoonaUser: false,
  passwordUpdated: false,
  email: '',
  loading: false,
  userloaded: false,
  error: '',
  moonaId: '',
  phone: '',
  firstName: '',
  lastName: '',
  cards: [],
  userSecret: '',
  stripeId: '',
  hasSubscription: false,
  hasSubscriptionYCoupon: false,
  hasSubscriptionMBG: false,
  hasSubscriptionDiscountPostPurchase: false
}

export default function users(
  state = initialUserState,
  action: UsersActionTypes
): UsersState {
  switch (action.type) {
    case SET_FIRST_PWD:
      return {
        ...state,
        loading: true,
        passwordUpdated: false
      }
    case UPDATE_PWD:
      return {
        ...state,
        loading: true,
        passwordUpdated: false
      }
    case SET_PWD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordUpdated: true,
        error: '',
        password: action.payload.password
      }
    case GET_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        email: action.payload.email
      }
    case SET_EMAIL:
      return {
        ...state,
        isMoonaUser: true,
        email: action.payload.email
      }
    case LOAD_USER:
      return {
        ...state,
        ...action.payload,
        userloaded: true
      }
    case CLEAR_USER:
      return {
        ...initialUserState
      }
    case SET_USER_SECRET:
      return {
        ...state,
        userSecret: action.payload.userSecret
      }
    case SET_EMAIL_MOONAID_SECRET:
      return {
        ...state,
        email: action.payload.email,
        moonaId: action.payload.moonaId
      }
    case USER_ERROR:
      return {
        ...state,
        error: action.payload.error
      }

    case ADD_CARD:
      return {
        ...state,
        paymentId: action.payload.id,
        cards: [
          ...state.cards,
          {
            default: false,
            id: action.payload.id,
            brand: action.payload.brand,
            number: action.payload.last4
          }
        ]
      }

    case CHANGE_CARD:
      return {
        ...state,
        paymentId: action.payload.id
      }
    case START_LOADING:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case SET_ONLY_EMAIL:
      return {
        ...state,
        email: action.payload.email
      }
    case SET_MOONA_ID:
      return {
        ...state,
        moonaId: action.payload.moonaId,
        isMoonaUser: true
      }
    case SET_STRIPE_ID:
      return {
        ...state,
        stripeId: action.payload.stripeId
      }
    case SET_HAS_SUBSCRIPTION:
      return {
        ...state,
        hasSubscription: action.payload.hasSubscription
      }
    case SET_HAS_SUBSCRIPTION_YCOUPON:
      return {
        ...state,
        hasSubscriptionYCoupon: action.payload.hasSubscriptionYCoupon
      }
    case SET_HAS_SUBSCRIPTION_MBG:
      return {
        ...state,
        hasSubscriptionMBG: action.payload.hasSubscriptionMBG
      }
    case SET_HAS_SUBSCRIPTION_DISCOUNT_POST_PURCHASE:
      return {
        ...state,
        hasSubscriptionDiscountPostPurchase: action.payload.hasSubscriptionDiscountPostPurchase
      }
    default:
      return state
  }
}
