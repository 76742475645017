import React, { useState, useRef, useEffect } from 'react'

import { ErrorMessage, FormikErrors, FormikTouched } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AppBoxProps } from 'common/components/Primitives/AppBox'
import { BaseButton } from 'common/style/components/buttons'
import { Image } from 'common/style/components/image'
import { Label, Input } from 'common/style/components/input'
import { InputWrapper } from 'common/style/components/Section'
import { MdBody } from 'common/style/components/Typography'

import TextError from '../TextError'

import hide from './hide.svg'
import lock from './lock.svg'
import show from './show.svg'

const ERROR_MESSAGES_BLACKLIST = ['required field']

interface InputProps {
  label: string
  name: string
  placeholder?: string
  errors: FormikErrors<{
    [field: string]: any
  }>
  touched: FormikTouched<{
    [field: string]: any
  }>
  validate?: (value: string) => string
  autofocus?: boolean
  disabled?: boolean
}

const PwdInput = styled(Input)`
  padding-left: 3.2rem;
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
`

const InputContainer = styled.div`
  position: relative;
  & input:-ms-input-placeholder {
    color: ${props => props.theme.colorblackmainmoona} !important;
    opacity: 0.3 !important;
    font-family: 'Open Sans', sans-serif, Arial !important;
    font-size: 1.4rem !important;
    line-height: 2.4rem !important;
  }
`
const ShowPasswordContainer = styled.div`
  position: absolute;
  top: 0;
  right: 1.5rem;
  height: 100%;

  display: flex;
  align-items: center;
`
const LockContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 1.2rem;
  height: 100%;
  width: 1.5rem;
  display: flex;
  align-items: center;
`

const Lock = styled(Image)<{
  onMouseEnter: () => void
  onMouseLeave: () => void
}>``
const ShowPassword = styled(BaseButton)<{ icon: string }>`
  background: 100% no-repeat url(${props => props.icon});
  width: 2rem;
  height: 2rem;
`
const Helper = styled(MdBody)<{
  hovered: boolean
}>`
  display: ${({ hovered }) => (hovered ? 'flex' : 'none')};
  background: rgba(41, 43, 54, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 8px;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #ffffff;
  padding: 0.8rem 1.2rem;
  white-space: nowrap;
  align-items: flex-start;
  position: absolute;
  top: 3.5rem;
`

export const MoonaPassword: React.FC<
  InputProps & Omit<AppBoxProps, keyof InputProps>
> = ({
  name,
  label,
  placeholder,
  errors,
  touched,
  validate,
  autofocus = false,
  disabled = false,
  ...props
}) => {
  const [hidden, setHidden] = useState(true)
  const isBlacklistedError = ERROR_MESSAGES_BLACKLIST.some(e =>
    errors[name]?.toString().includes(e)
  )
  const [t] = useTranslation('app')
  const [hovered, setHovered] = useState(false)
  const isError = errors[name] && touched[name] && !isBlacklistedError
  const toggleShow = () => setHidden(!hidden)
  const errorClass = isError ? 'error' : ''
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      const ref = inputRef.current

      if (autofocus) {
        ref.focus()
      }
    }
  }, [inputRef, autofocus])
  return (
    <InputWrapper {...props}>
      <Label htmlFor={name}>{label}</Label>
      <InputContainer>
        <LockContainer>
          <Helper content={t('general.helper.password')} hovered={hovered} />

          <Lock
            src={lock}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </LockContainer>
        <PwdInput
          className={errorClass}
          type={hidden ? 'password' : 'text'}
          innerRef={inputRef}
          id={name}
          name={name}
          placeholder={placeholder || ''}
          validate={validate}
          autoComplete="off"
          disabled={disabled}
        />
        <span></span>
        <ShowPasswordContainer>
          <ShowPassword
            onClick={toggleShow}
            icon={hidden ? hide : show}
            name="showpassword"
          />
        </ShowPasswordContainer>
      </InputContainer>
      {isError && <ErrorMessage component={TextError} name={name} />}
    </InputWrapper>
  )
}
