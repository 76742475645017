export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const LOGGED_IN = 'LOGGED_IN'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGGABLE = 'LOGGABLE'
export const LOGGABLE_SUCESS = 'LOGGABLE_SUCCESS'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET'

export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL'
export const CLEAR_LOGIN_EMAIL = 'CLEAR_LOGIN_EMAIL'

export interface Credentials {
  login: string
  password: string
}

export interface LogginAction {
  type: typeof LOGIN
  payload: Credentials
}

export interface Logout {
  type: typeof LOGOUT
  payload: {
    login: string
  }
}

export interface LoggedInAction {
  type: typeof LOGGED_IN
}

export interface LoginFailAction {
  type: typeof LOGIN_FAIL
}

export interface LoggableAction {
  type: typeof LOGGABLE
  payload: {
    userId: string
  }
}
export interface ForgotPasswordAction {
  type: typeof FORGOT_PASSWORD
  payload: {
    login: string
  }
}

export interface ForgotPasswordSuccess {
  type: typeof FORGOT_PASSWORD_SUCCESS
}

export interface ForgotPasswordFailure {
  type: typeof FORGOT_PASSWORD_FAILURE
  payload: {
    error: string
  }
}

export interface ForgotPasswordError {
  type: typeof FORGOT_PASSWORD_ERROR
  payload: {
    error: string
  }
}
export interface ForgotPasswordReset {
  type: typeof FORGOT_PASSWORD_RESET
}

export interface LoggableSucessAction {
  type: typeof LOGGABLE_SUCESS
  payload: {
    loggable: boolean
  }
}

export interface SetLoginEmail {
  type: typeof SET_LOGIN_EMAIL
  payload: string
}

export interface ClearLoginEmail {
  type: typeof CLEAR_LOGIN_EMAIL
}

export type AuthActionTypes =
  | LogginAction
  | LoggedInAction
  | LoginFailAction
  | LoggableAction
  | LoggableSucessAction
  | Logout
  | ForgotPasswordAction
  | ForgotPasswordSuccess
  | ForgotPasswordFailure
  | ForgotPasswordError
  | ForgotPasswordReset
  | SetLoginEmail
  | ClearLoginEmail

export interface AuthState {
  loading: boolean
  email: string
  logged: boolean
  loginfailure: boolean
  loginEmail: string
  registered: boolean
  error: string
  forgotPasswordLoading: boolean
  forgotPasswordSuccess: boolean
  forgotPasswordFailure: boolean
}
