import React, { FC } from 'react'

type ShipmentProps = {
  fill?: string
} & any

const Shipment: FC<ShipmentProps> = ({ fill = '#737373', ...rest }) => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.27777C0 0.848221 0.338199 0.5 0.755389 0.5H9.90371C10.3209 0.5 10.6591 0.848221 10.6591 1.27777V1.38909H14.4701C14.7274 1.38909 14.9669 1.5239 15.1059 1.74684L17.8804 6.19905C17.9585 6.32438 18 6.47016 18 6.61907V11.9445C18 12.1508 17.9204 12.3487 17.7787 12.4945C17.6371 12.6404 17.4449 12.7223 17.2446 12.7223L16.0211 12.7223C15.6834 13.7739 14.6796 14.5 13.554 14.5C12.434 14.5 11.4345 13.7811 11.092 12.7379L6.85491 12.7377C6.51244 13.781 5.51297 14.5 4.39287 14.5C3.26723 14.5 2.26341 13.7739 1.92579 12.7222L0.755332 12.7221C0.338165 12.7221 0 12.3739 0 11.9443V1.27777ZM1.92582 11.1666C2.26347 10.115 3.26726 9.38894 4.39287 9.38894C5.52402 9.38894 6.53215 10.1222 6.86486 11.1822H9.14831L9.14823 6.63028C9.14818 6.62648 9.14815 6.62267 9.14815 6.61886C9.14815 6.61506 9.14818 6.61127 9.14823 6.60748L9.14815 2.16688C9.14815 2.16128 9.14821 2.1557 9.14832 2.15012V2.05555H1.51078V11.1666L1.92582 11.1666ZM10.6591 11.1823V7.39664H16.4892V11.1667L16.0211 11.1667C15.6835 10.1151 14.6797 9.38894 13.554 9.38894C12.4228 9.38894 11.4147 10.1223 11.082 11.1823L10.6591 11.1823ZM15.8623 5.84109L14.0573 2.94463H10.6591V5.84109H15.8623ZM4.39287 10.9445C3.75519 10.9445 3.31596 11.4351 3.31596 11.9445C3.31596 12.4538 3.75519 12.9445 4.39287 12.9445C5.03056 12.9445 5.46979 12.4538 5.46979 11.9445C5.46979 11.4351 5.03056 10.9445 4.39287 10.9445ZM13.554 10.9445C12.9163 10.9445 12.4771 11.4351 12.4771 11.9445C12.4771 12.4538 12.9163 12.9445 13.554 12.9445C14.1917 12.9445 14.6309 12.4538 14.6309 11.9445C14.6309 11.4351 14.1917 10.9445 13.554 10.9445Z"
      fill={fill}
    />
  </svg>
)

export default Shipment
