import React from 'react'

const Close = props => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path d="M15.1 2.3L13.7.9 8 6.6 2.3.9.9 2.3 6.6 8 .9 13.7l1.4 1.4L8 9.4l5.7 5.7 1.4-1.4L9.4 8"></path>
    </svg>
  )
}

export default Close
