import styled from 'styled-components'

export const AutocompleteMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px;

  color: #707070;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;

  strong {
    font-weight: 400;
    font-size: 14px;
  }
`
