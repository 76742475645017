/* eslint-disable no-debugger */
import React, { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CheckoutRadioGroup } from 'common/components/Form/Checkout'
import useWebService, {
  GetShippingSlotInfosPayload,
  defaultValuesShippingSlotInfos,
} from 'common/hooks/useWebService'
import { H3 } from 'common/style/components/Typography'
import { CheckoutFormGroup } from 'common/style/pages/checkout'
import apiInstance from 'services/api'
import { checkoutShippingLineUpdate } from 'shopify/mutations'
import { UpdateCheckout } from 'state/features/checkout/action'
import { UpdateCheckoutShopify } from 'state/features/checkoutShopify/action'
import { AppState } from 'state/features/types'

import { getEstimatedDeliveryDate } from './utils'
import { sendAmplitudeData } from 'common/utilities/amplitude'

const ShippingDisplayer = ({
  formik,
  paymentMethodsLoading,
  setPaymentMethodsLoading,
  hasDiscountSubscription,
  totalAmount,
  otherPaymentMethods,
}) => {
  const {
    checkoutShopify,
    checkoutSession,
    checkout: checkoutState,
    storeSettings,
    shopper,
  } = useSelector((state: AppState) => state)
  const dispatch = useDispatch()

  const shippingRates =
    checkoutShopify.availableShippingRates?.shippingRates ?? null
  const [callOnUpdate, setCallOnUpdate] = React.useState(false)
  const [shippings, setShippings] = React.useState([])
  const [shippingSlot, setShippingSlot] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  const [firstTrackingSended, setFirstTrackingSended] = React.useState(false)

  const { getShippingSlotInfos } = useWebService()

  const getShippingSlotInfosPayload: GetShippingSlotInfosPayload = useMemo(
    () => ({
      lang: checkoutSession.store.language,
      currency_code: checkoutSession.store.currency,
      currency_symbol: '£', // TODO : get currency symbol from store or other
      shipping_label: storeSettings.shippingSlotLabel,
      shipping_method_title: null,
      shipping_carrier_title: '',
      shipping_price_reference: null,
      shipping_reference: null,
      cart_price: parseInt(
        (parseFloat(checkoutShopify.totalPrice.amount) * 100).toFixed(0)
      ),
      ...(shopper.id && {
        payload: {
          user_infos: shopper,
        },
      }),
      email: checkoutState.email,
      ecommerce_solution: storeSettings.ecommerceSolution,
      ecommerce_version: storeSettings.ecommerceVersion,
      plugin_version: storeSettings.pluginVersion,
      api_version: storeSettings.apiVersion,
      merchant_id: storeSettings.merchantId,
    }),
    [shopper, storeSettings, checkoutShopify]
  )

  const defaultShippingOptions = useMemo(() => {
    // Need to keep it for the split
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const options =
      shippingRates?.map(method => {
        const shippingMethodDecoded = decodeURI(method.handle)
          .replace('Cleever-', '')
          .replace('Better Shipping App-', '')
        const shippingMethodLastPart = shippingMethodDecoded.slice(
          shippingMethodDecoded.lastIndexOf('-') + 1
        )
        const parts = shippingMethodDecoded.split('-')

        let name: string
        let title: string
        let price: string
        let timestampSooner: string
        let timestampLater: string

        // If it's a timestamp => 5 parts with the 2 last as timestamp
        if (/[0-9]{10,}/.test(shippingMethodLastPart)) {
          name = parts.shift()
          // Don't move the order
          timestampLater = parts.pop()
          timestampSooner = parts.pop()
          price = parts.pop()
          title = parts.join('-')
        }
        // If it's a number => 3 parts
        else {
          name = parts.shift()
          price = parts.pop()
          title = parts.join('-')
        }

        return {
          label: method.title,
          details: getEstimatedDeliveryDate(
            timestampSooner,
            timestampLater,
            method.handle,
            method.title,
            storeSettings.merchantId
          ),
          value: method.handle,
          price:
            parseFloat(method.price.amount) === 0
              ? 'Free'
              : parseFloat(method.price.amount),
        }
      }) ?? []
    /* eslint-disable @typescript-eslint/no-unused-vars */

    return options
  }, [shippingRates])

  const createShippingOptions = (
    shippingSlot = null,
    shippingSlotAssociated = null,
    shippingSlotPosition = null,
    defaultOptions = null
  ) => {
    const options = defaultOptions ?? defaultShippingOptions

    if (
      shippingSlot !== null &&
      shippingSlotAssociated !== null &&
      shippingSlotPosition !== null
    ) {
      if (!storeSettings.shippingSlotDisplayAssociatedMethod) {
        options.splice(
          options.findIndex(
            item => item.label === shippingSlotAssociated.title
          ),
          1
        )
      }
      options.splice(shippingSlotPosition - 1, 0, shippingSlot)
    }

    setShippings(options)
  }
  const setupShippingSlot = async (
    shippingSlotAssociated,
    shippingSlotSort,
    defaultOptions = null
  ) => {
    try {
      const shippingMethodDecoded = decodeURI(shippingSlotAssociated.handle)
        .replace('Cleever-', '')
        .replace('Better Shipping App-', '')
      const shippingMethodLastPart = shippingMethodDecoded.slice(
        shippingMethodDecoded.lastIndexOf('-') + 1
      )
      const parts = shippingMethodDecoded.split('-')

      let name: string
      let title: string
      let price: string
      let timestampSooner: string
      let timestampLater: string

      // If it's a timestamp => 5 parts with the 2 last as timestamp
      if (/[0-9]{10,}/.test(shippingMethodLastPart)) {
        name = parts.shift()
        // Don't move the order
        timestampLater = parts.pop()
        timestampSooner = parts.pop()
        price = parts.pop()
        title = parts.join('-')
      }
      // If it's a number => 3 parts
      else {
        name = parts.shift()
        price = parts.pop()
        title = parts.join('-')
      }

      const estimatedDate = getEstimatedDeliveryDate(
        timestampSooner,
        timestampLater,
        shippingSlotAssociated.handle,
        title,
        storeSettings.merchantId
      )

      let shippingSlotInfos
      try {
        shippingSlotInfos = await getShippingSlotInfos({
          ...getShippingSlotInfosPayload,
          shipping_method_title: shippingSlotAssociated.title,
          shipping_reference: shippingSlotAssociated.handle,
          shipping_price_reference: parseInt(
            (parseFloat(shippingSlotAssociated.price.amount) * 100).toFixed(0)
          ),
          shipping_label: storeSettings.shippingSlotLabel,
        })

        if (!shippingSlotInfos.body) {
          shippingSlotInfos.body = defaultValuesShippingSlotInfos
        }
      } catch (error) {
        shippingSlotInfos = { body: defaultValuesShippingSlotInfos }
      }

      setCallOnUpdate(shippingSlotInfos.body.call_on_update_shipping)

      const data =
        shippingSlotInfos.body[
          shopper.sbfs !== null && shopper.sbfs !== 0
            ? 'returning_user'
            : 'new_user'
        ]

      if (
        parseInt(
          (parseFloat(checkoutShopify.totalPrice.amount) * 100).toFixed(0)
        ) < data.min_amount_to_display ||
        !data.display_slot
      ) {
        createShippingOptions()
        return
      }

      const shippingPriceDiscounted = parseFloat(
        (
          parseFloat(shippingSlotAssociated.price.amount) -
          data.discount / 100
        ).toFixed(2)
      )
      const isFree = shippingPriceDiscounted <= 0

      const slot = {
        label:
          data[`title${isFree ? '_free' : ''}`] +
          ' ' +
          data[`description${isFree ? '_free' : ''}`],
        details: estimatedDate,
        value: 'Cleever-' + shippingSlotAssociated.handle,
        price: isFree ? 'Free' : shippingPriceDiscounted,
        privacy: shippingSlotInfos.body.data_privacy,
      }

      setShippingSlot({
        shippingPriceReference: parseInt(
          (parseFloat(shippingSlotAssociated.price.amount) * 100).toFixed(0)
        ),
        shippingMethodTitle: shippingSlotAssociated.title,
        shippingCarrierTitle: '',
        shippingReference: shippingSlotAssociated.handle,
        shippingLabel: storeSettings.shippingSlotLabel,
        discount: data.discount,
        minAmountToDisplay: data.min_amount_to_display,
        title: data[`title${isFree ? '_free' : ''}`],
        description: data[`description${isFree ? '_free' : ''}`],
      })
      createShippingOptions(
        slot,
        shippingSlotAssociated,
        shippingSlotSort,
        defaultOptions
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleShippingSlot = async () => {
    if (!shippingRates) {
      createShippingOptions()
      return
    }
    if (!storeSettings.shippingSlotEnabled) {
      createShippingOptions()
      return
    }

    const { shippingSlotAssociatedMethod, shippingSlotSort } = storeSettings

    const shippingSlotAssociated = shippingRates.find(item => {
      return (
        item.title.toLowerCase() === shippingSlotAssociatedMethod.toLowerCase()
      )
    })

    if (!shippingSlotAssociated) {
      createShippingOptions()
    } else {
      setupShippingSlot(shippingSlotAssociated, shippingSlotSort)
    }
  }

  const reloadShippingRatesForInvalidCode = newShippingRates => {
    const newOptions =
      newShippingRates?.map(method => {
        const shippingMethodDecoded = decodeURI(method.handle)
          .replace('Cleever-', '')
          .replace('Better Shipping App-', '')
        const shippingMethodLastPart = shippingMethodDecoded.slice(
          shippingMethodDecoded.lastIndexOf('-') + 1
        )
        const parts = shippingMethodDecoded.split('-')

        let name: string
        let title: string
        let price: string
        let timestampSooner: string
        let timestampLater: string

        // If it's a timestamp => 5 parts with the 2 last as timestamp
        if (/[0-9]{10,}/.test(shippingMethodLastPart)) {
          name = parts.shift()
          // Don't move the order
          timestampLater = parts.pop()
          timestampSooner = parts.pop()
          price = parts.pop()
          title = parts.join('-')
        }
        // If it's a number => 3 parts
        else {
          name = parts.shift()
          price = parts.pop()
          title = parts.join('-')
        }

        return {
          label: method.title,
          details: getEstimatedDeliveryDate(
            timestampSooner,
            timestampLater,
            method.handle,
            method.title,
            storeSettings.merchantId
          ),
          value: method.handle,
          price:
            parseFloat(method.price.amount) === 0
              ? 'Free'
              : parseFloat(method.price.amount),
        }
      }) ?? []

    const { shippingSlotAssociatedMethod, shippingSlotSort } = storeSettings

    const shippingSlotAssociated = newShippingRates.find(item => {
      return (
        item.title.toLowerCase() === shippingSlotAssociatedMethod.toLowerCase()
      )
    })

    if (shippingSlotAssociated) {
      setupShippingSlot(shippingSlotAssociated, shippingSlotSort, newOptions)
    } else {
      createShippingOptions(null, null, null, newOptions)
    }
    // 1. Mettre à jour les newShippingRates
    // 2. Voir si le shippingSlot peut y être intégré et l'y intégrer
    // 3. Sélectionner le nouveau shippingRates et mettre à jour les shippings
  }

  const shopifyLineUpdate = async shippingMethodSelected => {
    formik.setSubmitting(true)
    setPaymentMethodsLoading(true)

    const isCleeverMethod = shippingMethodSelected?.value.includes('Cleever-')

    // TODO : Wait fix with bad shipping value
    if (shippingMethodSelected) {
      const result = await apiInstance.post('', {
        query: checkoutShippingLineUpdate,
        variables: {
          checkoutId: checkoutShopify.id,
          shippingRateHandle: shippingMethodSelected?.value.replace(
            'Cleever-',
            ''
          ),
          country: checkoutShopify.shippingAddress?.countryCodeV2 || 'GB',
        },
      })

      const {
        checkoutShippingLineUpdate: { checkout, checkoutUserErrors },
      }: any = Object.entries(result.data)[0][1]

      if (checkoutUserErrors?.length > 0) {
        if (checkoutUserErrors[0].code === 'INVALID') {
          const firstShippingMethod =
            checkout.availableShippingRates.shippingRates[0]
          if (firstShippingMethod) {
            const shippingMethodDecoded = decodeURI(firstShippingMethod.handle)
              .replace('Cleever-', '')
              .replace('Better Shipping App-', '')
            const shippingMethodLastPart = shippingMethodDecoded.slice(
              shippingMethodDecoded.lastIndexOf('-') + 1
            )
            const parts = shippingMethodDecoded.split('-')

            let name: string
            let title: string
            let price: string
            let timestampSooner: string
            let timestampLater: string

            // If it's a timestamp => 5 parts with the 2 last as timestamp
            if (/[0-9]{10,}/.test(shippingMethodLastPart)) {
              name = parts.shift()
              // Don't move the order
              timestampLater = parts.pop()
              timestampSooner = parts.pop()
              price = parts.pop()
              title = parts.join('-')
            }
            // If it's a number => 3 parts
            else {
              name = parts.shift()
              price = parts.pop()
              title = parts.join('-')
            }

            dispatch(UpdateCheckoutShopify(checkout))
            dispatch(
              UpdateCheckout({
                shippingMethod: firstShippingMethod.handle,
                shippingTitle: title,
                cleeverShippingPrice: null,
                shippingSlot: null,
              })
            )
            setLoading(true)
          }
          reloadShippingRatesForInvalidCode(
            checkout.availableShippingRates.shippingRates
          )
        }
      } else {
        dispatch(
          UpdateCheckoutShopify({
            ...checkout,
            shippingLine: {
              ...checkout.shippingLine,
              title: shippingMethodSelected?.label,
            },
          })
        )
        dispatch(
          UpdateCheckout({
            shippingMethod: shippingMethodSelected?.value,
            shippingTitle: shippingMethodSelected?.label,
            cleeverShippingPrice: isCleeverMethod
              ? shippingSlot.discount
              : null,
            shippingSlot: isCleeverMethod ? shippingSlot : null,
          })
        )
        setLoading(false)

        // update payment methods
      }

      formik.setFieldValue('shippingTitle', shippingMethodSelected?.label)
    }

    formik.setSubmitting(false)
    setPaymentMethodsLoading(false)
  }

  useEffect(() => {
    if (checkoutSession.cart.requiresShipping) {
      handleShippingSlot()
    }
  }, [storeSettings, shopper, checkoutSession.cart.requiresShipping])

  // Select the shipping method according to the checkout state
  useEffect(() => {
    if (shippings.length > 0) {
      let methodSelected = null
      if (checkoutState.shippingMethod !== '') {
        methodSelected = shippings.find(item => {
          return checkoutState.shippingMethod === item.value
        })
      } else {
        methodSelected = shippings[0]
      }

      if (methodSelected) {
        // Check if the shipping method is already selected
        if (methodSelected.value === formik.values.shippingMethod) {
          shopifyLineUpdate(methodSelected)
        } else {
          formik.setFieldValue('shippingMethod', methodSelected.value)
        }
      } else {
        formik.setFieldValue('shippingMethod', shippings[0].value)
      }
    }
  }, [shippings])

  // Listen formik.values.shippingMethod to start shopifyLineUpdate
  useEffect(() => {
    if (shippings.length === 0) return
    const shippingMethodSelected = shippings.find(item => {
      return formik.values.shippingMethod === item.value
    })
    if (shippingMethodSelected) {
      shopifyLineUpdate(shippingMethodSelected)
    }
  }, [formik.values.shippingMethod])

  useEffect(() => {
    if (!loading && !paymentMethodsLoading) {
      const method = shippings.find(item => {
        return item.value === formik.values.shippingMethod
      })

      if (!firstTrackingSended) {
        sendAmplitudeData('View - Shopify Chkt - Shipping Select', {
          merchantID: storeSettings.merchantId,
          merchantSite: storeSettings.myShopifyDomain,
          orderID: checkoutSession?.order?.id,
          currency: checkoutSession.store.currency,
          orderTotalValue: checkoutShopify.paymentDue.amount,
          checkoutSessionID: checkoutSession.id,
          slotProductCheckbox:
            !!checkoutSession.cart.attributes?.slotProductCheckbox,
          isLogged: !!checkoutSession.customer,
          shippingMethodDescription: '',
          shippingMethodID: method?.value ?? '',
          shippingMethodLabel: method?.label ?? '',
          paymentMethodID: formik.values.paymentMethod,
          paymentMethodLabel:
            formik.values.paymentMethod !== 'MP1'
              ? otherPaymentMethods[formik.values.paymentMethod]?.displayName
              : 'Credit Card',
        })
        setFirstTrackingSended(true)
      }
    }
  }, [loading, paymentMethodsLoading, formik.values.shippingMethod])

  useEffect(() => {
    if (firstTrackingSended) {
      const method = shippings.find(item => {
        return item.value === formik.values.shippingMethod
      })
      sendAmplitudeData(
        'Click - Shopify Chkt - Shipping Select - Change shipping method',
        {
          merchantID: storeSettings.merchantId,
          merchantSite: storeSettings.myShopifyDomain,
          orderID: checkoutSession?.order?.id,
          currency: checkoutSession.store.currency,
          orderTotalValue: checkoutShopify.paymentDue.amount,
          checkoutSessionID: checkoutSession.id,
          slotProductCheckbox:
            !!checkoutSession.cart.attributes?.slotProductCheckbox,
          isLogged: !!checkoutSession.customer,
          shippingMethodDescription: '',
          shippingMethodID: method?.value ?? '',
          shippingMethodLabel: method?.label ?? '',
          paymentMethodID: formik.values.paymentMethod,
          paymentMethodLabel:
            formik.values.paymentMethod !== 'MP1'
              ? otherPaymentMethods[formik.values.paymentMethod]?.displayName
              : 'Credit Card',
        }
      )
    }
  }, [formik.values.shippingMethod])

  useEffect(() => {
    if (firstTrackingSended) {
      const method = shippings.find(item => {
        return item.value === formik.values.shippingMethod
      })
      sendAmplitudeData(
        'Click - Shopify Chkt - Shipping Select - Change payment method',
        {
          merchantID: storeSettings.merchantId,
          merchantSite: storeSettings.myShopifyDomain,
          orderID: checkoutSession?.order?.id,
          currency: checkoutSession.store.currency,
          orderTotalValue: checkoutShopify.paymentDue.amount,
          checkoutSessionID: checkoutSession.id,
          slotProductCheckbox:
            !!checkoutSession.cart.attributes?.slotProductCheckbox,
          isLogged: !!checkoutSession.customer,
          shippingMethodDescription: '',
          shippingMethodID: method?.value ?? '',
          shippingMethodLabel: method?.label ?? '',
          paymentMethodID: formik.values.paymentMethod,
          paymentMethodLabel:
            formik.values.paymentMethod !== 'MP1'
              ? otherPaymentMethods[formik.values.paymentMethod]?.displayName
              : 'Credit Card',
        }
      )
    }
  }, [formik.values.paymentMethod])

  //   return React.cloneElement(children, { shippings }) TODO check later

  return (
    <CheckoutFormGroup>
      <H3>Shipping Method</H3>

      <CheckoutRadioGroup
        name="shippingMethod"
        loading={loading}
        onChange={e => {
          dispatch(
            UpdateCheckoutShopify({
              shippingLine: null,
            })
          )
          formik.handleChange(e)
        }}
        setFieldValue={(name, value) => {
          dispatch(
            UpdateCheckoutShopify({
              shippingLine: null,
            })
          )
          formik.setFieldValue(name, value)
        }}
        value={formik.values.shippingMethod}
        options={shippings}
      />
    </CheckoutFormGroup>
  )
}

export default ShippingDisplayer
