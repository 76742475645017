import React from 'react'

import { Formik, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import { MoonaInput } from 'common/components/Form/MoonaInput'
import { MoonaPassword } from 'common/components/Form/MoonaPassword/MoonaPassword'
import TextError from 'common/components/Form/TextError'
import { SubmittablePrimaryButton } from 'common/style/components/buttons'
import { Section } from 'common/style/components/Section'
import { loginRequest, setLoginEmail } from 'state/features/auth/actions'
import { AppState } from 'state/features/types'

import { FormBundle } from '../Login'
interface LoginFormProps extends FormBundle {
  loginfailure: boolean
  isProcessing: boolean
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>
  isSubmitted: boolean
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

export const LoginFormContainer = styled(Section)`
  margin-top: 3.2rem;
`

export const FormWrapper = styled(Form)`
  width: 100%;
`

export const LoginButton = styled(SubmittablePrimaryButton)`
  margin-top: 0rem;
  &:focus:before {
    z-index: 10;
    background-color: transparent;
  }
`

export const LoginFormSection = styled(Section)`
  margin: 0;
  width: 100%;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 1.6rem;
  }
`

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format'),
  password: Yup.string().min(8),
})

export const LoginForm: React.FC<LoginFormProps> = ({
  email,
  password,
  button,
  validation,
  loginfailure,
  isProcessing,
  setProcessing,
  isSubmitted,
}) => {
  const dispatch = useDispatch()
  const users = useSelector((state: AppState) => {
    return state.users
  })
  const { loginEmail } = useSelector(state => state.auth)

  const initialValues = {
    password: '',
    email: loginEmail !== '' ? loginEmail : users.email,
  }

  return (
    <Section mt="1rem" width="100%">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async values => {
          dispatch(setLoginEmail(values.email))
          setProcessing(true)
          dispatch(loginRequest(values.email.toLowerCase(), values.password))
        }}
      >
        {({ errors, touched, values }) => {
          return (
            <FormWrapper autoComplete="off">
              <LoginFormSection className={'login-form-section'}>
                {loginfailure && (
                  <TextError>{JSON.stringify(validation)}</TextError>
                )}
                <MoonaInput
                  name="email"
                  label={email.label}
                  placeholder={email.placeholder}
                  onBlur={event => {
                    dispatch(setLoginEmail(event.target.value))
                  }}
                  errors={errors}
                  touched={touched}
                  autofocus={true}
                  disabled={isProcessing}
                />
                <MoonaPassword
                  name="password"
                  label={password.label}
                  placeholder={password.placeholder}
                  errors={errors}
                  touched={touched}
                  disabled={isProcessing}
                />

                <LoginButton
                  isSubmitting={isProcessing}
                  isSubmitted={isSubmitted && !loginfailure}
                  label={button.label}
                  type="submit"
                  disabled={
                    values.email === '' ||
                    values.password === '' ||
                    isProcessing
                  }
                  name="set-password-submit"
                />
              </LoginFormSection>
            </FormWrapper>
          )
        }}
      </Formik>
    </Section>
  )
}
