// TODO(@liinkiing): when having more time, merge root values into colors and
// update usage in all places. For now, it will allow us to use directly the colors
// theme in styled props

const baseTheme = {
  colors: {
    colorpurplemoona: '#9540FF',
    colorpurpledark: '#6C1FCB',
    colorpurplelight: '#ECDDFF',
    colorblackmainmoona: '#3F4045',
    colorblackmainmoona02: '#292B36',

    colorpinkycoupon: '#FF54B0',

    colorpinkpricediscounted: '#FF54B0',

    colorgreymain: '#87888D',
    colorgreylight1: '#F5F6FA',
    colorgreydark: '#F5F6FA',
    colorgreylink: '#C4C4C4',

    colorsuccess: '#31DC9E',
    colorsuccesshover: '#1BC185',
    colorsuccessdisable: 'rgba(49, 220, 158, 0.5)',
    colorsuccessborder: '#BAFFE6',

    colorreddanger: '#FF4773',
    colororangepending: '#FFB951',

    lightGray: '#E8E9ED',
  },
  breakpoints: ['1104px'],
  colorpurplemoona: '#9540FF',
  colorpurpledark: '#6C1FCB',
  colorpurplelight: '#ECDDFF',

  colorblackmainmoona: '#3F4045',
  colorblackmainmoona02: '#292B36',

  colorgreymain: '#87888D',
  colorgreylight1: '#F5F6FA',
  colorgreydark: '#F5F6FA',
  colorgreylink: '#C4C4C4',

  colorsuccess: '#31DC9E',
  colorsuccesshover: '#1BC185',
  colorsuccessdisable: 'rgba(49, 220, 158, 0.5)',
  colorsuccessborder: '#BAFFE6',

  colorreddanger: '#FF4773',
  colororangepending: '#FFB951',
} as const

const checkoutTheme = {
  checkoutColors: {
    primary: '',
    primaryButton: '',
  },
}

export const theme = { ...baseTheme, ...checkoutTheme }

export default theme
