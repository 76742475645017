export const isPrestashop = (ecommerce: string): ecommerce is 'Prestashop' => {
  return ecommerce === 'Prestashop'
}
export const isShopify = (ecommerce: string): ecommerce is 'Shopify' => {
  return ecommerce === 'Shopify'
}

export const isWooCommerce = (
  ecommerce: string
): ecommerce is 'WooCommerce' => {
  return ecommerce === 'WooCommerce'
}

// 2.1.1 for test then 2.2.0
export const isMagentoNotifyOrderPaid = (
  ecommerce: string,
  pluginVersion: string,
  merchantId: string
): ecommerce is 'Magento2' => {
  return (
    ecommerce === 'Magento2' &&
    pluginVersion.startsWith('M2_2.2') &&
    merchantId !== '8182cf8d-2f05-40ff-ae2c-442378d4fa70'
  )
}

export const displayShoppingProtectionPlus = (
  merchantId: string
): boolean => {
  return merchantId && MERCHANT_ALLOWED.includes(merchantId);
}

export const NEEDED_ECOMMERCE_REFRESH = ['Prestashop', 'Shopify']
export const ECOMMERCE_MAX_RETRIES = 3
export const ECOMMERCE_POLLING_INTERVAL = 2000
export const MERCHANT_ALLOWED = [
"216d6f09-6cb6-4f41-9cdf-6cf0736d1160",
"96f66412-844a-469f-b87b-a29e87bc2266",
"7910bcb7-b286-4cad-920c-d4866d777436",
"33bdcd3a-276b-4291-ba4f-36292d2dd83a",
"b273cd72-3454-4026-a40a-e2979fbb9dc8",
"aa664172-c9d6-4992-a695-3dc732daf3f9",
"1219ab75-de3b-4063-9a3b-c1e3ea1ba3a7",
"371be45b-299e-4392-a2dd-7863785fe2d2",
"9f9443c3-3243-4547-8506-d175705a5ff1",
"dfad440d-004a-4629-9342-08dfbf4778e7",
"07d702f7-51ac-402c-9d80-138d21e81cef",
"cf559fdf-734e-4bc4-b9bf-b1346511e1ad",
"3889eb2e-b1ae-479a-a03e-9f5cfcbcb98c",
"2e8a314e-0bd2-4778-89ad-44b8458e2b11",
"2c28d5c9-4e6e-499c-aa27-cd383c0171bb",
"17f4abef-363a-4deb-a379-c65575d3f846",
"c276addd-49c6-4401-a625-085a04f01f11",
"a479399e-c68a-4275-9ae0-705fc27dc35e",
"fda3bf1b-51fa-49f6-8a4a-7f2a9af41a43",
"499d83b8-542a-49e9-a902-f61ca0480a40",
"be0f5abc-22b1-4f66-9dc5-3d3fbdf2d590",
"68e08812-d333-408a-8834-6b539c41240e",
"0ef74b4a-4ee9-42b4-8486-f12870036f86",
"15bc1f94-4e09-4785-b2f0-b6ace27696f4",
"4f0ae2b4-5df0-4039-93b2-f428622525e0",
"9badc77a-ec36-4b5e-ad9d-abdc2ae5d9b5",
"bfb807cf-1da4-4bf0-b47a-952a8b5c6bb2",
"0b6f76ef-cc6d-465f-9f44-85c4e5c8c21b",
"daf5d045-0f92-4cf8-ba00-362f28b019a4",
"e8795391-bbab-4a40-8c77-09c27002754c",
"6753903c-5a7e-46ae-8af5-09ce381f05d1",
"84580072-39ec-4ff1-af0a-5db69d8853da",
"0f63be10-c282-46bf-84ab-ef919f063a96",
"aac1cdeb-1255-481d-a4ca-d519d5610b65",
"2ed654cd-af0f-4488-a7f8-5e0a46ff0970",
"ee29a44f-b783-4d00-ade4-f7a1082294c9",
"94ff1a9d-9fdb-4e7a-9628-043dc119f34c",
"cf845b23-ce37-4a52-b559-b7c45f55c7f1",
"c742da56-51ad-44fb-82bf-10da6a529f9a",
"ef8397e0-a2a0-4f3e-b612-7cbdfbd10154",
"56cf9a2e-5058-4015-83c5-733c1ece0a16",
"0eb6606a-7ce2-414d-9fb2-e94f3060e0b1",
"afdc867b-3abf-4ecb-b79a-a9829c142125",
"cbf08d13-bd01-4ed7-9953-74facab90285",
"5c643aa5-a037-4e47-a26b-62c0017604d1",
"e153fb8d-3691-48c1-93c0-2df1bbf9274a",
"eeaa6222-7ed6-4bf4-862e-253c8846cd54",
"2fda00c7-a050-4b8c-95d7-0e2aa7ec0e51",
"d37b5e04-4041-437c-b621-961eb054e472",
"93012f25-c160-4f1f-aaae-3823c2bb0a48",
"3dd39110-4e80-46cc-ace2-5116d7cbeccf",
"262291f2-05ef-4507-851a-38de85e181c2",
"f48d6eef-0e95-46d6-8a99-c9c3b7e2a874",
"959d526e-233c-4011-a43c-5394b74e8333",
"e38a890e-fb5e-4efa-a786-869ea800faf0",
"afd85e74-ffaa-4372-8b80-80f8b69c056a",
"8d9a9361-2c03-44c1-a711-ba8ac4758604",
"72ee34db-3e99-450c-bd88-c04726cf1f42",
"c4ac392c-d69c-4644-a556-1f3196426c33",
"7c552cae-6da6-4df4-b469-fdc8740faf0c",
"67724a85-d06a-4167-a63d-9756db8d4f79",
"6855e42a-8450-4069-aef6-ec1fa495aef1",
"2519e28f-d91d-4f79-9720-2ee92097f32d",
"9545f631-9d16-4370-8b25-bf6d0ddcb309",
"c8dab254-19c5-43b7-97b9-a72ebc4c8f0f",
"1b4ded01-8698-4563-86d9-1ab639d48edd",
"bbc0e4b2-969f-48c9-85c3-dd640c7f531d",
"a2d7d4cb-c71c-4363-be41-fe80eb1c4d84",
"d0f07b31-0ea6-46b9-b256-8cc129fe98fc",
"bfd66c0b-82a6-4078-a654-aadd2bb3ab1b",
"eb7aabf3-b392-4241-b63a-d1a37962f296",
"8561d4b2-3839-4068-a008-5bb126acf4d6",
"dc2d6e09-1e9b-4f38-bddc-f6e53beff473",
"ecc27590-4cac-498d-ad71-32e572801019",
"5b64dc9c-258d-45f8-9ae0-d32fd16ebff1",
"b9739d97-254b-477d-9915-d65322b36a43",
"d0d3ab64-40f5-4719-bcf4-3e7a5a09d0c7",
"259c278b-eb3d-4c8c-b309-1014cf3ff387",
"80109dc2-ea40-4828-aa8e-aa0f18137d72",
"306b9d8d-a925-4dbd-b07c-431e94480b1b",
"d9c494c6-5248-4712-a762-f709029f78cd",
"e1d8f1e6-8d8c-4e2d-8a8f-2af48616e94b",
"2194035e-5d09-4a60-a65a-9d8eadb43dc1",
"584d858e-f71e-4315-8062-1e533a9d3507",
"f40ed34c-2530-4baf-982d-f750166d972f",
"87a45c44-b3f6-4da2-804f-a232cb68bce4",
"7b955fc6-a77e-4478-8fed-71b396255b25",
"05a44e33-e5f6-497e-9cd5-c90c40b5d576",
"3cb32803-5727-418d-b482-1a643a80246c",
"7db5b113-7514-4c27-b66d-b241831c8585",
"60e479d5-9fa2-4aef-81d4-5f396c2eae57",
"3a1343a2-d367-4c8b-a2d6-b96b98be5306",
"d914e1f0-446b-4d7b-99db-a074f8a8b241",
"3cf5bbcd-6dca-40a9-8d43-ff096764a572",
"e0b24f7e-202b-4d1a-ad98-d81314bed692",
"863eb9e1-e8d7-4df4-acc1-26dcf83bf8fc",
"93dd5d83-4274-4012-ad99-853666a056f9",
"2960e5e4-b1ff-4c3f-b8ed-8abe4a0887c6",
"fc10048f-458c-4e1e-8de7-0da99fc1e6f9",
"02af08e2-0d99-48e4-8a3e-2df1bf70a7db",
"ad79ad32-145a-44e0-b581-979a823ec40a",
"1d407586-b4d6-461c-8e34-d67304e76b6c",
"d5f3f89d-bb1b-4c7e-8497-1941c3fffa73",
"96f44c7f-6603-4cf7-891f-b43ca98aa2da",
"7a8e8b5a-d10c-414d-bc81-69d6969c2846",
"b584b71c-f8aa-40e6-952e-86b254c044d4",
"e0393dec-9e26-4fd3-bf4b-2f442eb25fe2",
"1ad9492b-cae8-4411-a574-3941bd5cdc7f",
"753cf0d9-7adf-43a5-9c95-d475666c7646",
"07c6dfdb-85fd-454f-9050-40aa9253cae3",
"1cf0fb8b-8664-41c9-bf81-d5de6e167aa5",
"7ee3ea1e-3cea-431a-81b8-069046074c33",
"bb18c279-7ee0-4985-97aa-099803951225",
"4faa8072-1eeb-4df5-bb80-c305690ea96a",
"02727d45-d25a-4194-a3f8-5fcb011f0f83",
"cf660947-af5f-454e-8321-b67bb2921edb",
"c0a30286-89c9-4456-80ad-6c28f769fa06",
"7e831f1c-424f-4ac8-94ec-ae5a1a88d7bb",
"16dad829-ff7b-4024-8bef-959f5e3df717",
"c146c9ed-e818-4c6a-87f7-375ca02908de",
"16b67f20-6b7a-4bec-8368-fd95facdc768",
"c4437951-b12a-43df-8222-0d3439b4ec30",
"f69e47b1-77ee-4b8a-9111-6a251cf9b74a",
"c7a871a8-afd8-4f9c-ad5b-3e4ac7d7d71b",
"a416f074-3392-408f-bdbf-333b269e822b",
"9446208d-29ec-4688-ae51-4c9387f10c40",
"fa7814b9-bc48-4e86-8a0c-825abb0b2de1",
"77b4a9b6-0637-4cb9-b6c0-0a9fc6e84fd5",
"02bc7a79-fd3f-40ea-8f21-665995ffb322",
"fcd94721-10bb-48f6-b247-125c2c2c83c0",
"c283bcb6-e600-4e57-9d60-31a9bac0a8ed",
"c9c18c23-0679-4529-a35a-f943b314cddf",
"bc3470a6-f68a-4fd4-8f3c-422df5b2bc3b",
"0ab0d80f-98e7-4c55-84b2-b4ddc5071c6b",
"a6290577-a023-49b6-a8d5-fa71e20e4d68",
"ac4935c0-bf1d-4e96-8995-9596cd507ede",
"387d85c6-d92a-4ee3-91b2-7bb17db209a6",
"bac2ac4c-2099-4250-a993-c215a7dcfdb7",
"3382bceb-8b53-48fe-8a1e-11d364990161",
"ecb25bff-0c9f-419c-8c63-d77d99dbe99e",
"25bd0026-fe28-402e-97a1-ea6499944bea",
"f066d137-642d-4b9f-893d-02db3113fa24",
"5bf8875e-5552-443c-9e80-d9a42d02ff20",
"60029bdc-6015-43fd-ac85-9ffae4f85a36",
"1afc47e2-c906-4fc3-956b-03d8ccda19b3",
"0435627a-f6e1-49d8-b00b-16aae26d16a1",
"a07acdc6-88fc-47d0-97a6-c1343377a71d",
"e0a6f837-6444-4233-9fb8-061118641788",
"5f4ccff8-bc1f-4f57-a6a4-6937ae54599b",
"79644673-feb1-411b-934b-ae653f198423",
"b31b6030-bf14-4cdd-8a5b-f5a40377e722",
"a0956563-fac0-481b-a44c-cd78234ad310",
"f79d8d90-1bfc-46f5-b74a-b06b30295b6f",
"78665cee-9718-4be3-9672-d86b4329b9e9",
"ce19ea06-d09a-4782-848b-495fcdaedf3c",
"4fcb0a1e-4a53-4aac-a917-ff74840b3dd7",
"f97c9a37-cf94-4930-b983-e5c924e315a3",
"2e573da0-6894-47ec-a72b-24830314b24c",
"5a6cfbe6-584c-40ad-b3cd-717cb2642cfb",
"fc61e9af-6eb4-4470-a3e7-4b10cfdc3ade",
"c8a689df-efe1-4253-ac3f-7d52c762b9fe",
"6fa36025-7a5d-4020-9f3a-a8050be85f29",
"8f362402-d3af-424a-8f46-de0643a7a49d",
"98b965b2-1e11-40f2-9e08-f783b6165406",
"518e023d-5a0e-4930-a927-af9e974e01a9",
"5800f989-b176-4b6b-9ae7-e68ab1f6a3a1",
"8726fbe7-4b65-4c53-b544-d81b10a33f3c",
"4ac19e33-09d4-435b-a2cc-b65b35ca9176",
"ee817015-1dfb-4d38-9104-0965ffd69357",
"6f7f3a00-4714-49ee-8691-59f0e8757e1c",
"60326d67-b489-4629-9686-2ecef164b9fc",
"3666ed9a-a7ea-4b07-a2a4-eaa80e132e8e",
"3c2f4d9d-e3bf-4475-8e87-8dd865d9d549",
"036c5ab7-559e-4073-babe-5be1f0f216b7",
"1081eb02-3c3b-4f25-b2b5-47944b7df8c9",
"9397770f-498e-4587-8e0b-98c711b8ffd6",
"07b8cc96-bcd5-4eee-b690-d3a354f77a52",
"5941e7bf-0f38-46b9-8ef4-d7a968b173f0",
"e698ad95-c8d6-465e-b00b-96ef25dd580b",
"0435627a-f6e1-49d8-b00b-16aae26d16ae",
"91786f60-ae8c-40af-af42-36f7ab4c229d",
"c535d870-dffa-4e11-9c44-d6599fee14a6",
"561ee513-fbd0-498e-9991-73cdab17fc84",
"ffc4d103-fdb7-4be5-9f12-52b35b5684e8",
"3e587cc7-d115-4e78-b5e1-a3e169244e33",
"7e9a75b5-e25d-4c48-8d56-9e5716c50e73",
"95d26f54-532c-433c-aa3d-7793313e6600",
"d6656535-abf3-4bbe-9ca8-faccac779373",
"7d508802-cece-4b8e-ba40-579f33918109",
"f04e4c84-630c-4aa0-acb0-313bca77467b",
"3400cf90-70a5-4ddb-a28b-d0be464fe870",
"f55c0a20-7fe8-4954-a986-8a9cd096a469",
"7010a8df-9cf0-4216-92ac-98918ae7b8cc",
"18615dd4-5a32-4eef-bfa1-ef523fcda0c9",
"72bc0f7c-0365-4a07-a5fc-55ec52b23c2b",
"2020c7a8-2570-4d4c-aa5e-11794dea2a4d",
"7733223a-a7b0-46a8-8987-fc1eca42ca74",
"dbf7334f-49d4-45cf-87d9-a2ace54e4471",
"9d59e4ad-6e9b-44a4-9960-82cae1b553c5",
"6c0954a3-d1c6-4b60-b87a-e11af32e43cf",
"5a311020-d535-43be-bb3f-731cf22d284e",
"275b0e46-bb86-4b37-aca8-84cff139e6dc",
"311b7031-b258-4cb5-9c96-5f44cb04af3f",
"381cc6bf-f383-4b15-98f1-2035ee13f065",
"9b84df50-6d6e-4696-a1c8-d0b370b847fc",
"dc0135b8-562e-415d-ac13-39ed99b29529",
"e7320902-a6d1-42a0-b4a0-ee4629cbf028",
"f6adf8ef-e5cd-4354-8f1b-ad1fac230709",
"3d3c2678-13c6-41bb-8663-17aea0487dbf",
"bfe9c0e9-bc98-409d-ae4c-1f4e0d658fd2",
"28f63e02-261e-409a-8981-761ab14e840c",
"08852692-922a-41ff-ac0e-1633e184dd7e",
"2bde7aa4-fd06-4963-b293-41d06ebf8cfb",
"3cf80b2a-840b-4dd1-aa2a-80da6fc52e84",
"b63080f9-e105-4733-9c51-16170a66f1b7",
"1863bed1-a83c-4b49-a81c-f0fe11b02ef0",
"9b66894b-1527-4a60-ae49-a67914bbfe6a",
"36b26b25-0283-429b-8300-8911b15d37c1",
"23cb6859-8d23-4951-b363-375fd0b0eee9",
"6cc6268f-56bf-484e-9c5c-24a4d764d290",
"9c084959-ac5b-4772-aaa0-40c5f9fd57b1",
"eadba78a-b4ac-434b-97f7-089760fff4e7",
"6dd63e97-376c-401f-ac02-920da8e2cd91",
"99adaec8-0968-4738-b1c7-ac923a3a1c4e",
"c30ec4e0-a889-4509-9942-c4ccc8e5b914",
"9ba45a7e-20e5-41f5-a02d-8c171c743429",
"cc02141f-1664-4260-acd0-cff255b1082e",
"4dbb429c-1dc6-477e-bc9b-b4a2ee23f032",
"e49c7f90-b453-44f6-a54c-2910b4051a8b",
"482d8d3e-3d14-4feb-80e4-946cabb06e99",
"38b385c2-0dc7-490a-8635-5f8accd6d66f",
"2b5121fc-b592-4741-932c-679d169c6b9e",
"f8937b26-55bf-4fbc-99f2-741a1a4c06d4",
"16405d08-3242-46d1-a870-e53e85b062ce",
"3ea20e19-4823-4606-8f08-852dfe983d0e",
"149e8b3a-13a4-41f8-afcf-bbeb38769d80",
"9245e200-3471-4914-a54b-d63336a88cd3",
"6fc27451-2593-498a-980f-7f2889dbe824",
"f34071f0-9056-47b6-8e2d-bacbc644345e",
"185a6e1b-ba8e-4a54-9401-20be1db964dc",
"ba7ee27c-bfb5-463c-8ef5-be1ccb56bc2c",
"995ee625-f2b7-40ad-be44-1add4ebe2a95",
"17be82ce-abe5-4718-9c8b-9763f2013980",
"92c4ef68-8433-4bb1-9082-fe728131fcc2",
"8c28b2c0-61b5-4c9d-aeaf-2a3618e9a4a0",
"3eafac51-f79b-4e7b-952b-208a2c2c2897",
"b959e717-8c3a-4e45-ac82-1b0ee92fd7cf",
"c8fbbedf-798d-4914-b75c-3312d83173ef",
"55ac5112-5c04-4c7a-8ec0-7e7996131de6",
"1a91a6a3-d96b-4aa7-8aca-d2bb7b205804",
"1378378f-0331-4a72-aaae-8e92e990df50",
"2bd21316-3b5a-453c-8967-81aab535ff3b",
"f1756bef-9439-4044-ba56-7e3496dce5e6",
"cfe49c20-3198-4fd7-b0b9-87595680b0b8",
"dec49800-f737-497a-a869-73412627589a",
"5f1d0f04-2268-4d21-b0b9-fcc931d1f44f",
"2607fca0-0ee6-4f05-936a-96027d13c1e0",
"2fda197f-805e-4899-83ed-5798d11bc2cf",
"cfff45ba-68eb-4952-bf9e-46db3cd1a8aa",
"0cc996de-d5d9-4385-85f0-04dc54907cad",
"b6fc958b-eae4-4050-8a92-5b9af207a9a9",
"a9376dba-7dd7-48b4-92ee-36303c3de313",
"85ba68c2-1452-4101-b61f-5c282aebfc3f",
"aafe7056-a70c-4da3-b8ba-6b71e0c1f2a3",
"70622fde-d844-48a8-b85a-64b55f1bf6af",
"546e2b47-0cf1-4253-8343-283a197f78bb",
"1ae0911f-b75b-4874-9047-a7a8eeee7ee8",
"b6154204-9b78-48c6-87c0-d3d39452e6d1",
"0435627a-f6e1-49d8-b00b-16aae26d16a2",
"0fec3ae5-c979-4bd6-8116-0efa063aa2fc",
"bf2e2da6-91c3-4e66-b7a6-31b886f9ec49",
"041f4867-caa9-407f-8409-b923ce16043c",
"b8651960-0337-4a1b-b823-5ad8a613cfe3",
"e7680eb7-43dc-47d1-9b9a-b7177f9b2ec6",
"901e94a8-cb30-441e-879a-861b851f584b",
"1fdfcc88-ba1d-47a3-ba41-f65f1ade8856",
"f025f3bc-2aed-4eb6-9d18-9045abeefdb5",
"f9bd6e3b-f372-4cee-bfe7-2d81323d55ef",
"af2e6adc-bfd4-4ee8-a506-226bb02eb490",
"8d60285c-f6b1-430b-8f60-f7b0651cd0ce",
"7f7281b7-4569-4813-b6e9-e544faa6e21b",
"667ef43d-b4de-4921-86c6-04cd4a21bc17",
"1ffb0f18-52b7-4c41-b840-03bee66dd72b",
"ad6c533d-cf31-4ef8-acda-736d0588d8df",
"a7edf1b8-0847-4315-a60f-5c4423b4bee7",
"fbf5f85b-20c9-4b85-8bf0-bfbe4a6dddcb",
"13d746e2-3b7c-4b80-83e1-396e067002a1",
"70c6378d-8a4b-4479-b886-b7a4d2f84773",
"aee10c5d-6059-4f45-a305-2a6ffd32c57f",
"85c291bc-f18c-4f7c-8210-eb214f6cfd49",
"85caf974-3093-459d-8a06-19cfd9989eae",
"8a94cfb5-6d36-4394-9114-ebcabb8e3638",
"a0d11f40-2b20-40b4-b7a1-42efd20c85ca",
"4bceabaf-9bb0-408c-9429-3093475e5179",
"bb779ce1-4faa-4c73-b10e-7bf67ac36e78",
"c7fcd94a-7a6d-49dc-b573-67f0aea68248",
"f67f9cd8-145d-4af2-953b-0ab9240607d3",
"8f4c9194-7ee6-4994-a342-7587788de5e5",
"7a41eb30-2706-4899-8507-c34bdd97ca4c",
"c5528d0e-fa77-4646-bfba-fd80d3780ac2",
"f356f55e-8c1f-4a0f-969a-321b08bcccfc",
"069162fa-da45-47bb-b39a-6178e742274e",
"1fdf7f0c-0593-4331-87d2-41c89498073f",
"4c685bfd-01bd-4a92-8ca7-41c50d2db230",
"0435627a-f6e1-49d8-b00b-16aae26d16a3",
"16ccd675-57f2-4f24-8615-3108023f11c3",
"18ad5013-9b9c-42de-9410-ef0a8bc55ecb",
"3919077b-9cd6-489f-9667-04a3abe87c85",
"a70069b3-0763-460c-aaf7-c076844dde21",
"b4f48827-9c80-45ce-a679-2c5824c2e6ce",
"d7b0b722-fca4-4a05-a0c6-b6dc882d3ef9",
"e54f2f2a-ba38-4523-bdac-595b0bf90628",
"81cdec96-a925-4dbd-b399-8863deb5d108",
"ab31d003-4fe1-42d2-b1cc-158ac9ea2a01",
"31bcbc75-cf6f-4995-a1af-6dd0db1d3409",
"e64842d2-0509-433c-97ba-140e490c1927",
"57391c28-340f-491f-89b7-2298bb940e9c",
"ef02a53f-97f1-4662-823a-8f003ef28cc1",
"ba6e70ed-4c46-4cdb-aba0-1e4ad0796ba5",
"6452f445-aa1f-4538-aac0-7d51fab1ed36",
"90afa601-9d86-4370-ba53-cb025e97d23c",
"05f444ca-1352-4e81-b1e9-97e88ab00194",
"3b7c3946-76f0-4bca-9be9-4348f1a916be",
"8182cf8d-2f05-40ff-ae2c-442378d4fa70",
"a5b152e5-03c9-42ee-bc20-6266ca28c97c",
"16a7f9fc-122d-467f-9d13-3107cd305473",
"d590fec3-9a78-46bb-a7a3-94795f3ebfb3",
"f71717ba-1381-40c2-944a-98e32607a3d2",
"ceca5ca8-50ea-4aaf-97b3-6822a692d2c3",
"16a825ef-9942-48b2-8e76-cb9846e738b1",
"320fe6ac-fcd0-46db-8f83-d841af7f8ab3",
"bc3b16dd-9277-439d-aec7-81a250f4d092",
"4e7c777b-095e-4000-8f04-64f5f0bc6db3",
"b1f53715-a1d0-4e89-b9b6-fdc74e8e6887",
"5ccd927c-7238-4525-bbc5-e9ef923d1d1a",
"8f5b70ba-f71e-4324-a2db-b9ad69a08e2e",
"7b025575-22c9-4fe6-ac6a-6286538af792",
"d0a1b021-3629-4bf5-a82d-1a27000f6f65",
"6fb354fc-d10d-4bf2-b4ca-11ec3c11ae8e",
"313612fc-6245-4ac9-a870-05174aa07615",
"f9776e6a-4300-4a6b-bbf4-64cd1a8a5af6",
"712b5d15-38eb-43a0-86fe-565767452d92",
"ea03b06a-6094-462d-b03a-0994193b4627",
"5c8e7434-6bf1-4473-856e-cd6a9972a6a1",
"473d235a-016c-4cad-9623-9a98ed77befe",
"b9ab6f20-534a-4e3f-b81b-93826ca3a9ca",
"ec06b1e8-e7cd-4d50-8c6a-25a804dd9685",
"f9a6a571-71ac-4192-a862-98d3a0a78c37",
"758dc4a8-f60f-428a-a77d-02ee4d74f1fe",
"b69e286e-edec-4599-9b7c-b68378eb734e",
"975d8833-9288-4377-857d-881d04b19f59",
"a16285dd-bf5b-4a2b-93c8-860d7d00fb86",
"03746064-b911-4679-92bf-2f3a4a50cf89",
"8d9809a4-f87a-4e6b-b65a-9d520170aabe",
"c6c95ab3-c04c-4280-8163-321039dfcfa1",
"ff57ce1b-f7a8-406f-8fc1-1918a19b94cc",
"1ea7d184-8fda-4614-8646-908262274577",
"4b3e9f39-0025-4c62-9851-0c57bb2b7b70",
"9cf0a7a2-9067-40b8-9c9b-5695353366ef",
"c5da4067-078e-41b5-8ebd-483675341fdc",
"a7f55d30-eb29-4f20-900a-9875fa640d61",
"816233da-dff9-462a-85e2-092893518d36",
"08023175-c051-4d23-8f4c-68852954e4ed",
"429b98f1-6518-499c-9798-b3d92fefe3a7",
"6043979a-0b2e-4953-bdf7-a8509a59804b",
"e9bb8979-f51d-4b21-a075-dced5b880d25",
"eb68e680-28fc-462a-8347-8c2525679a82",
"1ee56d47-f71c-4668-8e2f-b194e915eac6",
"cdc0fda9-5883-4495-bdab-635579b95b6b",
"129603cc-d8ea-472e-ad7a-696f83373a72",
"b5842ba1-ae19-4f6b-8a15-59f72742977c",
"24294612-8ad6-43a5-b706-92879feca370",
"caf11a8e-7747-45f8-9e87-9d0b0c2b9a6b",
"d68bc00d-e8e2-4e49-b1f1-b74cb18a581f",
"5d6f5c00-2e5f-4cee-b6ab-dfb115c27ba2",
"f54a7cf1-fc87-4efd-b67b-47f8b9826f32",
"ba3f6f4c-aacf-4da6-8f1a-d76fd7ac13bd",
"efb7efcb-e774-43ed-943e-8cbd6fdefae2",
"876452f2-1c48-4105-834b-f16ec46eeff7",
"0435627a-f6e1-49d8-b00b-16aae26d16a5",
"dc253ac9-e975-4793-86bf-f6ecaf69125b",
"5a4002a2-9ce5-4e46-89d6-d0307124cb93",
"1285ffbb-80cd-4b5c-a874-6a94159b0942",
"77801401-f31b-4ead-b563-37fb36fb4201",
"e5af5ec2-3187-40a6-83a8-f60abd26505b",
"fb06b153-204a-463d-be9e-053e4cbd8d51",
"a78889e5-a5e3-41f8-9894-f127ada52d44",
"9f3f5aa7-468a-4d74-9398-2a8594308501",
"1903b6e3-9cbd-4387-bc0c-420f2bbbfa09",
"fd3559f6-8acf-4cad-8646-9cfe1cd39540",
"d718a08a-283f-4a16-b34a-81fd849a3ca9",
"7901ad92-ce30-42c8-919a-dd7cc8ea64e7",
"1147183d-74db-4f6a-a201-1681dfea0a79",
"82e2d94c-fcd3-4e6f-97a9-0feb4d748c57",
"33f42860-bea9-48e5-922a-f4e7e8095290",
"4c7b99ef-ff23-41e9-a2f7-a930cc56eac7",
"8a324278-dd6c-49d0-9e69-efa04c9878cb",
"ba5eae2a-55c7-49f4-8e0b-0231d3b3cc6d",
"c7b42ca0-fc17-4965-9527-ba4d8d898592",
"6ea9999f-000c-42e4-9cbb-36fc9c51ebe2",
"c961c67a-8629-4e68-a5c5-4dd72832f878",
"d2011940-ca54-4413-ac6e-4c937e4ed056",
"86069181-380d-484a-a24c-f8893e25c08d",
"c88a9a2f-a65e-4851-bc40-c342a58076c6",
"85918153-d259-470f-8860-0139b5214b75",
"71f8cd50-68e2-4e3b-b76e-03a03a15a39a",
"19c63fc8-d33c-4a71-be8f-02cad5ad11fa",
"6ab5a706-58a3-4336-91dd-a4d3c14e3c28",
"5cbf9fea-6081-4efe-9cf9-a394002a9f52",
"7b24559b-80c6-4bcf-b594-36995ad3b1c3",
"1098cbdc-8d4e-4f86-8a6e-5842b8685f53",
"c079e0ff-2193-4a30-8195-65d4bd8c2012",
"fd0e2047-d846-41e4-be43-1aca795ba405",
"022d1d5a-d66d-4dbe-86f0-e84546ed107a",
"9059781e-6000-434f-a96d-2b5d847c2f74",
"45223df8-6640-4581-bd07-ce356e91575d",
"d035183a-26f4-465c-af53-f3c39e0484bc",
"c53e6a72-b4ae-4e59-bb79-046173497636",
"1e665909-41f3-40d4-aced-2d6d3376c1d2",
"e43f115b-7863-4ae6-9524-78a6b7097472",
"a9b86b41-4a13-4610-b35d-089e8a0ed91a",
"46cbcbd5-3ef1-4400-bb1d-088ef2199d14",
"a1419862-bd00-40e9-9cb5-bf1976dfbc0b",
"bad6d554-0c83-4659-83cf-dbf8ffc0c91f",
"219d02da-be6d-49f8-83b4-c79692af24f2",
"2d468790-487f-4cd3-af22-907e386ed292",
"013ffad6-d4a5-4dac-ac74-f6d2f690ded9",
"534e6db9-2715-4064-b5d2-a85faacd65cd",
"41b41ed7-5ee6-4fd1-a2be-d9830f2e83a0",
"debcb902-938d-4690-b769-024082194cd7",
"59e58513-1a89-42a9-a82f-00f4145c916a",
"7dc16ff5-20fe-40e6-9fd9-691c42961919",
"3f941a08-d9e6-4a1b-91a1-464abb7c8d9a",
"bb1b9ccd-d5fe-405c-bfe5-6fa52e6cce21",
"bed4c29d-27ba-437c-bce6-afb8c932866a",
"d8979dec-8a4f-443f-b574-dbd5fb475d62",
"c8d18958-c9c6-4af8-a72f-036426def8c7",
"31eb946e-2732-409f-a9cc-abe2caabac26",
"2866da31-7124-4c8e-9eb1-1f6d87b0892a",
"0435627a-f6e1-49d8-b00b-16aae26d16a4",
"dfa52da9-09fa-4c65-8e60-1dcd983065be",
"4002753b-e952-48bf-a384-8e2c02576247",
"e5ab80dd-99ce-40d3-8fe1-b05518927969",
"6cc9b52a-266d-420b-801a-db9bf32748a5",
"6e8fae05-72dd-4d25-ac94-bbaa595f93d0",
"b065d345-a6b4-4fee-98fb-4c0f3dc1a7b3",
"d03c7e29-276c-4db7-bc29-171c7b4a0f12",
"1def1d58-cc4b-40e3-8bbc-ca56d64b47d3",
"9928f953-2b8c-4b72-82b4-4c40c13ac09d",
"dbb87b69-3757-4906-abec-d8936a155605",
"d095d4bc-ccce-4ce1-b2dc-abffd7522b5a",
"64cbd236-faff-460b-b422-2db0259758f0",
"60067427-0bd1-4bf1-9b85-d1d97afe0460",
"6e014bf8-dc9c-474a-b7eb-3ed6a8f479a9",
"60b6b1d3-d885-4a65-8e68-cab71b5e1012",
"b10081e4-8297-424a-a8e2-fa045fc11247",
"fc9124ae-15d9-40fd-b078-5c69abf1f68e",
"39de3592-6d5c-4711-a7be-b2251cd94be2",
"5784721b-a957-4664-8463-785ef1127144",
"0933f8a7-73d1-4c76-a4df-cff28402fb77",
"1419c6b8-64bf-4cd5-a3e9-fff320cf886a",
"513671f8-c599-463d-8c48-fb9150ba21ba",
"b094d916-a052-422a-a156-44e0f2f3f071",
"3f44ef5e-8a1a-4282-9299-917c0a33ed68",
"bc6122c1-2f35-482c-905a-7c8af73a97e5",
"d31d4fd6-17b2-45d4-8c58-fc111881e030",
"4f505988-6705-4370-adaa-3269d1f48400",
"ebdfe7d1-beb8-4e3f-98a4-0d2af0c52968",
"79289439-882c-46fa-a655-634fa99032a6",
"587e3b7e-4141-42c1-bee1-967e55fc5876",
"4695c161-c38b-476d-8a38-3ed8ba74d769",
"aafd859c-ddc2-46ce-8ac4-85f9c3baceeb",
"f2e1c38c-ee1a-48e3-982e-d0341a93b463",
"e9f1eba6-8750-450d-8791-5295368b7b92",
"44131aed-7e70-4896-82c1-bb04e645a416",
"34f0109c-1a6b-4ae8-ac20-3018882eaf99",
"9101cfa3-91c9-41fd-b3bc-bffc709abf2c",
"10d232ec-ea06-479f-9ba6-a6a800c3b87a",
]
