export const parseCountry = (country?: string): undefined | string => {
  // for now, we only authorize 'GB' countries, so when another value is
  // detected, we force set it to 'GB'
  if (typeof country === 'undefined') return undefined
  if (!country) return null
  if (country === 'ENG') return 'GB'
  return country
}

export const currencify = (
  value: number | string,
  currencyCode: string = 'EUR'
): string => {
  let currency = '€'
  switch (currencyCode) {
    case 'EUR':
      currency = '€'
      break
    case 'GBP':
      currency = '£'
      break
    case 'CAD':
    case 'USD':
    default:
      currency = '$'
      break
  }

  if (value === null) return '-'
  if (typeof value === 'string') {
    return `${currency}${parseFloat(value).toFixed(2)}`
  }
  return `${currency}${value.toFixed(2)}`
}
