import { useEffect, useState } from 'react'

export enum DeviceType {
  tablet = 'tablet',
  largeMobile = 'largeMobile',
  mobile = 'mobile',
  smallMobile = 'smallMobile'
}

const getDeviceType = (windowWidth: number) => {
  if (windowWidth < 1104) {
    if (windowWidth < 350) {
      return DeviceType.smallMobile
    } else if (windowWidth < 750) {
      return DeviceType.mobile
    }
    return DeviceType.largeMobile
  }
  return DeviceType.tablet
}

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(getDeviceType(window.innerWidth))

  useEffect(() => {
    const onResize = () => setDeviceType(getDeviceType(window.innerWidth))

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  })

  return deviceType
}
