import React from 'react'

const Phone = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      role="img"
      className="a8x1wuo _1fragem14 _1fragem30 _1fragem9e _1fragem9d"
      focusable="false"
      aria-hidden="true"
      width="16"
      height="16"
      stroke="#737373"
      fill="none"
      {...props}
    >
      <path d="M3.5 12V2a.6.6 0 0 1 .6-.6h5.8a.6.6 0 0 1 .6.6v10a.6.6 0 0 1-.6.6H4.1a.6.6 0 0 1-.6-.6Z"></path>
      <circle cx="7" cy="9.8" r="0.4"></circle>
      <path d="M7.002 9.798h-.005v.005h.005v-.005Z"></path>
    </svg>
  )
}

export default Phone
