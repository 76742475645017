import styled from 'styled-components'
import Modal from 'styled-react-modal'

import { tablet } from '../queries/media-queries'

export const StyledModal = Modal.styled`
   width: 32.7rem;
      height: 36.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position:relative;
  border: 1px solid #E8E9ED;
  box-shadow: 0px 20px 58px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @media ${tablet} {
      width: 68rem;
      height: 47rem;
  }
`

export const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background: rgba(41, 43, 54, 0.8);
`
