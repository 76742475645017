import React from 'react'

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { NameProps } from './buttons'

const baseLinkCss = css`
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;
  text-decoration: none;

  font-weight: 500;

  color: ${props => props.theme.colorblackmainmoona};
`

export const ExternalLink = styled.a.attrs<NameProps>(({ name }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  name: name,
}))<NameProps>`
  ${baseLinkCss}
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colorblackmainmoona};
`

export const ExternalLinkSelf = styled.a.attrs<NameProps>(({ name }) => ({
  target: '_self',
  name: name,
}))<NameProps>`
  ${baseLinkCss}
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colorblackmainmoona};
`

export const InternalLink = styled(Link).attrs<NameProps>(({ name }) => ({
  name: name,
}))<NameProps>`
  text-decoration: none;
  color: inherit;
`

export const InternalBaseLink = styled(InternalLink)`
  ${baseLinkCss}
`

export const ForgotPasswordLink = styled(InternalLink)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  margin-top: 2rem;
  align-self: center;
  text-decoration: underline;
`

export const InlineLink = styled(InternalBaseLink)`
  display: inline-block;
  margin-left: 0.5rem;
`

export const ExternalInlineLink = styled(ExternalLink)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: ${props => props.theme.colorblackmainmoona};
`

export const ExternalInlineLinkSelf = styled(ExternalLinkSelf)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: ${props => props.theme.colorblackmainmoona};
`

const BaseNewTabLink: React.FC<{ to: string; name: string }> = ({
  to,
  name,
  ...props
}) => (
  <InternalBaseLink
    name={name}
    {...props}
    to={`${process.env.PUBLIC_URL}${to}`}
  />
)

export const NewTabLink = styled(BaseNewTabLink).attrs(({ name }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  name: name,
}))<NameProps>`
  display: inline-block;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: ${props => props.theme.colorblackmainmoona};
`
export const CheckoutLink = styled('a')`
  font-size: 1.4rem;
  color: ${props => props.theme.checkoutColors.primary};
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`
