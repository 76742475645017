export const GET_CHECKOUT = 'GET_CHECKOUT'
export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT'
export const UPDATE_GET_DISCOUNT = 'UPDATE_GET_DISCOUNT'
export const ADD_SHIPPING_SLOT = 'ADD_SHIPPING_SLOT'
export const START_SAVE_FORM = 'START_SAVE_FORM'
export const START_REDIRECT_LOADING = 'START_REDIRECT_LOADING'

export interface CheckoutInformationState {
  email: string
  emailMarketingConsent: boolean
  smsMarketingConsent: boolean
  smsMarketingPhone: string
  deliveryMethod: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  country: string
  countryCode: string
  province: string
  provinceCode: string
  zip: string
  phone: string
  requiresShipping?: boolean
  saveInformationForNextTime: boolean
  cleeverDiscount: CleeverDiscount
}

export interface CheckoutShippingState {
  shippingMethod: string
  shippingTitle: string
  shippingSlot?: ShippingSlot
  cleeverShippingPrice?: number
}

export interface CheckoutPaymentState {
  getDiscount: boolean
  rememberMe: boolean
  paymentMethod: string
  billingAddressMethod: string
  firstNameBilling: string
  lastNameBilling: string
  address1Billing?: string
  address2Billing?: string
  cityBilling?: string
  countryBilling?: string
  countryCodeBilling?: string
  zipBilling?: string
  phoneBilling?: string
  provinceBilling: string
  provinceCodeBilling: string
}

export interface CleeverDiscount {
  title: string
  titleChecked: string
  discount: number
  description: string
  privacy: string
}

export interface ShippingSlot {
  shippingPriceReference: number
  shippingMethodTitle: string
  shippingCarrierTitle: string
  shippingReference: string
  shippingLabel: string
  discount: number
  minAmountToDisplay: number
  title: string
  description: string
}

export type CheckoutState = CheckoutInformationState &
  CheckoutShippingState &
  CheckoutPaymentState & {
    saveForm: boolean
    redirectLoading: boolean
  }

export interface UpdateCheckout {
  type: typeof UPDATE_CHECKOUT
  payload:
    | CheckoutInformationState
    | CheckoutShippingState
    | CheckoutPaymentState
}
export interface UpdateGetDiscount {
  type: typeof UPDATE_GET_DISCOUNT
  payload: boolean
}
export interface StartSaveForm {
  type: typeof START_SAVE_FORM
}

export interface StartRedirectLoading {
  type: typeof START_REDIRECT_LOADING
}

export type CheckoutActionTypes =
  | UpdateCheckout
  | UpdateGetDiscount
  | StartSaveForm
  | StartRedirectLoading
