import { FC } from 'react'

import styled from 'styled-components'

import Card from '../../components/Card'
import AppBox, { AppBoxProps } from '../../components/Primitives/AppBox'
import { tablet } from '../queries/media-queries'

import { NameProps } from './buttons'
import login from './login.svg'
import { baseFooterTextCss } from './Typography'

export const ONE_PAGER_DESKTOP_MAX_WIDTH = '500px'

export const Container = styled.div`
  width: 100%;
`

export const ColumnContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`
export const RowContainer = styled(Container)`
  display: flex;
`

export const CenterColumnContainer = styled(ColumnContainer)`
  align-items: center;
  justify-content: center;
`

export const FlexContentContainer = styled(Container)`
  font-size: 1.2rem;
  line-height: 2.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.colorgreymain};
  margin-top: 2rem;
  margin-bottom: 4.4rem;
`
export const DefaultContentContainer = styled(Container)`
  font-size: 1.2rem;
  line-height: 2.1rem;
  text-align: center;
  color: ${props => props.theme.colorgreymain};
  margin-top: 4.4rem;
  margin-bottom: 4.4rem;
  @media ${tablet} {
    margin-top: 2rem;
  }
`

export const CenterContentContainer = styled(DefaultContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const Section: FC<AppBoxProps> = styled(AppBox).attrs(props => ({
  as: 'section',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  ...props,
}))``

export const WideSection = styled.section`
  width: 100%;
`

export const NameableDiv = styled.div.attrs<NameProps>(({ name }) => ({
  name: name,
}))<NameProps>``

export const NameableSpan = styled.span.attrs<NameProps>(({ name }) => ({
  name: name,
}))<NameProps>``

export const MarginContainer = styled.div<{
  mx?: string
  my?: string
  mt?: string
  mb?: string
  center?: boolean
  selfcenter?: boolean
  column?: boolean
  hflex?: boolean
}>`
  margin-left: ${props => (props.mx ? `${props.mx}` : 0)};
  margin-right: ${props => (props.my ? `${props.my}` : 0)};
  margin-top: ${props => (props.mt ? `${props.mt}` : 0)};
  margin-bottom: ${props => (props.mb ? `${props.mb}` : 0)};
  ${({ hflex }) =>
    hflex &&
    `
  display: flex;
  align-items: center;
  `}

  ${({ center }) =>
    center &&
    `
    display: flex;
    justify-content: center;
    width:100%;

  `}
  ${({ selfcenter }) =>
    selfcenter &&
    `
    align-self: center;
    width:100%;

  `}
  ${({ column }) =>
    column &&
    `
    display: flex;
    flex-direction:column;
  `}
`
export const BorderSection = styled(Section)`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e8e9ed;
  border-radius: 16px;
  padding-top: 0.5rem;
  padding-bottom: 4rem;
  @media ${tablet} {
    padding-top: 4rem;
    height: 24.3rem;
  }
`

export const GreySection = styled(Section)`
  width: 100%;
  /* height: 100vh;
  height: calc(var(--vh, 1vh) * 100); */
  height: 100vh;
  @supports not (-ms-high-contrast: none) {
    height: calc(var(--vh, 1vh) * 100);
  }

  background: ${props => props.theme.colorgreylight1};
  @media ${tablet} {
    height: 24.3rem;
  }
`

export const FooterSection = styled.footer`
  ${baseFooterTextCss}
  text-align:center;
`

export const HeroSection = styled(Container)``

export const LoginSection = styled(Container)`
  background: url(${login}) ${props => props.theme.colorgreylight1};
  background-repeat: no-repeat;
  background-position: top 19.4rem center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  @media ${tablet} {
    justify-content: center;
  }
  @media all and (-ms-high-contrast: none) {
    height: 100vh;
  }
`
export const GreyHeroSection = styled(HeroSection)`
  border-radius: 5px;
  background: ${props => props.theme.colorgreylight1};
  z-index: -10;
  isolation: isolate;
  min-height: 100vh;
`
export const WhiteHeroSection = styled(HeroSection)`
  background: white;
  z-index: -10;
  isolation: isolate;
  min-height: 100vh;
`
export const BoxHeroSection = styled(AppBox).attrs(props => ({
  width: '100%',
  zIndex: -10,
  isolation: 'isolate',
  minHeight: '100vh',
  ...props,
}))``

export const OnePagerPage = styled(AppBox).attrs((props: AppBoxProps) => ({
  width: '100%',
  zIndex: -10,
  isolation: 'isolate',
  minHeight: '100vh',
  borderRadius: '5px',
  bg: props.backgroundColor ?? props.bg ?? ['white', 'colorgreylight1'],
  ...props,
}))`` as FC<AppBoxProps>

export const OnePagerBody = styled(Card).attrs(props => ({
  border: ['none', '1px solid #e8e9ed'],
  boxShadow: ['none', '1px 20px 20px 0px rgba(0, 0, 0, 0.04)'],
  borderRadius: props.borderRadius ?? [0, '16px'],
  px: props.px ?? ['2rem', '3.8rem'],
  py: props.py ?? [0, '4rem'],
  maxWidth: [null, ONE_PAGER_DESKTOP_MAX_WIDTH],
  mx: 'auto',
  display: props.display ?? 'flex',
  flexDirection: props.flexDirection ?? 'column',
  ...props,
}))``

export const MobileGreyHeroSection = styled(HeroSection)`
  background: ${props => props.theme.colorgreylight1};
`

export const Main = styled.main`
  position: relative;
`
export const CenteredMain = styled(Main)`
  width: 68rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ContentWrapper = styled(Section)`
  width: 100%;
  @media ${tablet} {
    padding: 0 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3.8rem 5.6rem;
    justify-content: center;
  }
`
export const InputWrapper: FC<AppBoxProps> = styled(AppBox).attrs(props => ({
  width: '100%',
  ...props,
}))``
