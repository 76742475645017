import { Field, Form } from 'formik'
import styled, { css } from 'styled-components'

import { INTEGRATED_INPUT_CSS } from '../css/input'

import checked from './checked.svg'
import { baseTitleCss } from './Typography'

const defaultInputBorder = css`
  border: 1px solid #dee0e7;
`
const activeInputBorder = css`
  border: 1px solid ${props => props.theme.colorgreymain};
`

const baseErrorInputCss = css`
  border: 1px solid ${props => props.theme.colorreddanger};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
`
export const baseInputCss = css`
  text-align: left;
  width: 100%;
  height: 4rem;
  padding-left: 1.4rem;
  @media screen and (max-width: 350px) {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    & .StripeElement {
      padding: 0.8rem 0;
    }
  }
  background: #ffffff;
  outline: none;
  ${defaultInputBorder}
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  &:focus,
  &:active {
    ${activeInputBorder}
  }
  &.error:active {
    ${baseErrorInputCss}
  }

  &.error {
    ${baseErrorInputCss}
  }

  &:disabled {
    background: #f2f3f3;
    color: #bdbec0;
    opacity: 1;
  }

  &::placeholder {
    font-family: 'Open Sans', sans-serif, Arial;
    font-size: 1.4rem;
    line-height: 2.4rem;
    @-moz-document url-prefix() {
      line-height: 3.4rem;
    }

    color: ${props => props.theme.colorblackmainmoona};
    opacity: 0.3;
  }

  &::-ms-input-placeholder {
    font-family: 'Open Sans', sans-serif, Arial !important;
    font-size: 1.4rem !important;
    line-height: 2.4rem !important;
    @-moz-document url-prefix() {
      line-height: 3.4rem !important;
    }

    color: ${props => props.theme.colorblackmainmoona} !important;
    opacity: 0.3 !important;
  }

  &::-ms-clear {
    display: none;
  }
`

export const focusInputCss = css`
  border: 1px solid ${props => props.theme.colorpurplemoona}
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
`

export const activeInputCss = css`
  position: absolute;
  display: block;
  z-index: -1;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;

  border: 4px solid #ecddff;
  border-radius: 8px;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
`
export const Input = styled(Field)<{ isIntegrated?: boolean }>`
  ${baseInputCss}
  font-family: "Open Sans", sans-serif, Arial;
  font-style: normal;
  font-weight: normal;
  color: ${props => props.theme.colorblackmainmoona};
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    ${focusInputCss}
  }

  &:active + span:after {
    content: '';
    ${activeInputCss}
  }
  ${props => props.isIntegrated && INTEGRATED_INPUT_CSS}
`

export const Label = styled.label<{ isIntegrated?: boolean }>`
  ${baseTitleCss}
  font-weight: ${props => (props.isIntegrated ? 400 : 500)};
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-bottom: 0.4rem;
  color: ${props => (props.isIntegrated ? 'black' : props.theme.colorgreymain)};
  ${props =>
    props.isIntegrated &&
    css`
      margin-bottom: 0;
      font-size: 1.5rem;
    `}
`

export const InlineLabel = styled(Label)`
  display: inline;
  outline: none;
  font-size: 1.2rem;
  line-height: 2.1rem;
  font-weight: normal;
  padding-left: 3rem;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: -0.5rem;
  }
`

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    width: 100%;
    position: relative;
    padding-left: 0px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border: 4px solid ${props => props.theme.colorpurplemoona};
    border-radius: 100%;
    background: #fff;
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    right: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    top: 50%;
    opacity: 1;
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
`

export const Checkbox = styled(Field).attrs({
  type: 'checkbox',
})`
  position: absolute;
  left: 0;
  opacity: 0.01;
  &:not(:checked) + label::before,
  &:checked + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 1.1rem;
    width: 1.6rem;
    height: 1.6rem;
    background: #fff;
    border-radius: 0.2em;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
      0 0 0 rgba(203, 34, 237, 0.2);
    transition: all 0.275s;
  }

  &:not(:checked) + label::before {
    ${defaultInputBorder}
  }
  &:not(:checked) + label:hover:before,
  &:not(:checked) + label:focus:before,
  &:focus + label:before {
    background: #ffffff;
    border: 1px solid ${props => props.theme.colorpurplemoona}
    box-sizing: border-box;

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  position: relative;

  &:focus ~ span:after {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;

    border: 3px solid #ecddff;
    border-radius: 8px;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
    top: 3px;
    left: 10px;
    width: 21px;
    height: 21px;
  }

  &:checked + label:before {
    ${activeInputBorder}
  }
  &:not(:checked) + label::after,
  &:checked + label::after {
    content: '';
    speak: never;
    position: absolute;
    left: 0;
    top: 6px;
    width: 16px;
    height: 16px;

    background-image: url(${checked});
    background-repeat: no-repeat;
  }
  &:not(:checked) + label::after {
    opacity: 0;
  }
  &:checked + label::after {
    opacity: 1;
  }
  display: inline-block;
`

export const StyledForm = styled(Form)`
  height: 100%;
  width: 100%;
`
