export const SET_PRICE = 'SET_PRICE'
export const SET_SUCCESS = 'SET_SUCCESS'
export const SET_FAILURE = 'SET_FAILURE'
export const START_SUBMITTING = 'START_SUBMITTING'
export const STOP_SUBMITTING = 'STOP_SUBMITTING'
export const SET_HAS_DISCOUNT = 'SET_HAS_DISCOUNT'

export interface Payment {
  price: number
  finalPrice: number
  hasDiscount: boolean
  discount: number
  success: boolean
  submitting: boolean
}

export interface SetPriceAction {
  type: typeof SET_PRICE
  payload: {
    price: number
    finalPrice: number
    discount: number
  }
}
export interface SetSuccessAction {
  type: typeof SET_SUCCESS
}
export interface SetFailureAction {
  type: typeof SET_FAILURE
}
export interface StartSubmittingAction {
  type: typeof START_SUBMITTING
}
export interface StopSubmittingAction {
  type: typeof STOP_SUBMITTING
}
export interface SetHasDiscount {
  type: typeof SET_HAS_DISCOUNT
  payload: {
    hasDiscount: boolean
  }
}

export type PaymentActionTypes =
  | SetPriceAction
  | SetSuccessAction
  | SetFailureAction
  | StartSubmittingAction
  | StopSubmittingAction
  | SetHasDiscount

export interface PaymentState extends Payment {}
