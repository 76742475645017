import React from 'react'

import styled from 'styled-components'

import { Container, NameableSpan } from 'common/style/components/Section'

import { InlineLink, NewTabLink } from '../../style/components/links'

import { IsJsonString } from './validators'

const ErrorNewTabLink = styled(NewTabLink)`
  font-size: 1.4rem;
  margin-left: 0.5rem;
`

export const ErrorContainer = styled(Container)`
  margin-top: 0.4rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colorreddanger};
`

const TextError: React.FC = ({ children }) => {
  const isJson = IsJsonString(children as string)
  if (!isJson) {
    return <ErrorContainer>{children}</ErrorContainer>
  }

  const { label, links, name }: ErrorBodyProps = JSON.parse(children as string)
  return (
    <ErrorContainer>
      <ErrorBody label={label} links={links} name={name} />
    </ErrorContainer>
  )
}

export interface ErrorBodyProps {
  label: string
  links?: { label: string; target: string; newtab: boolean }[]
  name: string
}
export const ErrorBody: React.FC<ErrorBodyProps> = ({ label, links, name }) => {
  return (
    <NameableSpan name={name}>
      {label + ' '}{' '}
      {links?.map((link, i) => {
        if (link.newtab) {
          return (
            <ErrorNewTabLink to={link.target} key={i} name={`texterror-${i}`}>
              {' ' + link.label}
            </ErrorNewTabLink>
          )
        }
        return (
          <InlineLink to={link.target} key={i} name={`texterror-${i}`}>
            {link.label}
          </InlineLink>
        )
      })}
    </NameableSpan>
  )
}
export default TextError
