export const UPDATE_CHECKOUT_SHOPIFY = 'UPDATE_CHECKOUT_SHOPIFY'

export interface UpdateCheckoutShopify {
  type: typeof UPDATE_CHECKOUT_SHOPIFY
  payload: CheckoutShopifyState
}

export type CheckoutShopifyActionTypes = UpdateCheckoutShopify

export type CheckoutShopifyState = {
  id: string
  email: string
  webUrl: string
  orderStatusUrl: string
  customAttributes: { key: string; value: string }[]
  discountApplications: {
    nodes: DiscountApplication[]
  }
  availableShippingRates: AvailableShippingRates
  shippingAddress?: {
    address1: string
    address2: string
    city: string
    company: string
    country: string
    countryCodeV2: string
    firstName: string
    lastName: string
    phone: string
    province: string
    provinceCode: string
    zip: string
  }
  shippingLine: ShippingLine
  lineItems: LineItems
  lineItemsSubtotalPrice: Price
  paymentDue: Price
  subtotalPrice: Price
  totalDuties: Price
  totalPrice: Price
  totalTax: Price
}

export type AvailableShippingRates = {
  ready: boolean
  shippingRates: ShippingRate[]
}

export type DiscountAllocation = {
  discountApplication: DiscountApplication
  allocatedAmount: Price
}

export type DiscountApplication = {
  code?: string
  title?: string
  allocationMethod: string
  targetSelection: string
  targetType: string
  value: {
    __typename: string
    amount: string
    percentage: number
  }
}

export type Item = {
  id: string
  quantity: number
  title: string
  discountAllocations: DiscountAllocation[]
  unitPrice: Price
  variant: ItemVariant
  customAttributes: { key: string; value: string }[]
}

export type ItemImage = {
  id: string
  altText: string
  url: string
  height: number
  width: number
}

export type ItemVariant = {
  id: string
  price: Price
  image: ItemImage
  requiresShipping: boolean
  sku: string
  title: string
  unitPrice: Price
  weight: number
  weightUnit: string
  selectedOptions: { name: string; value: string }[]
  product: Product
  availableForSale: boolean
  currentlyNotInStock: boolean
}

export type LineItems = {
  nodes: Item[]
}

export type Price = {
  amount: string
  currencyCode: string
}

export type Product = {
  // https://shopify.dev/api/storefront/2023-04/objects/Product
}

export type ShippingLine = {
  handle: string
  title: string
  price: Price
}

export type ShippingRate = {
  handle: string
  title: string
  price: Price
}

export type ReturnItem = {
  image: Omit<ItemImage, 'height' | 'width'>
  price: string
} & Omit<Item, 'unitPrice'>
