import React from 'react'

const InfoShopify = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      className="_1fragem1a _1fragem3g _1fragembp _1fragembo"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path d="M12 11h1v7h-2v-5a1 1 0 0 1 0-2h1zm0 13C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM10.5 7.5c0-.828.666-1.5 1.5-1.5.828 0 1.5.666 1.5 1.5 0 .828-.666 1.5-1.5 1.5-.828 0-1.5-.666-1.5-1.5z"></path>
    </svg>
  )
}

export default InfoShopify
