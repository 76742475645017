import React, { FC } from 'react'

import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

import Flex from 'common/components/Primitives/Flex'
import { CheckoutFormGroup } from 'common/style/pages/checkout'

import { CheckboxSkeleton, InputSkeleton } from '../Form/InputSkeleton'
import { H3Skeleton } from '../Typography/TitlesSkeleton'

const FlexSkeleton = styled(Flex)`
  gap: 14px;
  & > span {
    width: 50%;
  }
`

const InformationSkeleton: FC = () => (
  <>
    <CheckoutFormGroup>
      <FlexSkeleton style={{ alignItems: 'end' }}>
        <H3Skeleton style={{ width: '150px' }} />
        <Skeleton />
      </FlexSkeleton>
      <InputSkeleton />
      <CheckboxSkeleton width="65%" />
    </CheckoutFormGroup>
    <CheckoutFormGroup>
      <H3Skeleton style={{ width: '150px' }} />
      <InputSkeleton />
      <InputSkeleton />
    </CheckoutFormGroup>
    <CheckoutFormGroup>
      <H3Skeleton style={{ width: '150px' }} />
      <InputSkeleton />
      <FlexSkeleton>
        <InputSkeleton />
        <InputSkeleton />
      </FlexSkeleton>
      <InputSkeleton />
      <InputSkeleton />
      <FlexSkeleton>
        <InputSkeleton />
        <InputSkeleton />
      </FlexSkeleton>
      <InputSkeleton />
      <CheckboxSkeleton width="45%" />
    </CheckoutFormGroup>
  </>
)

export default InformationSkeleton
