import React, { ChangeEvent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CheckoutCheckbox } from 'common/components/Form/Checkout'
import CheckoutNumberInput from 'common/components/Form/Checkout/CheckoutNumberInput'
import Flex from 'common/components/Primitives/Flex'

import 'react-phone-number-input/style.css'

type Props = {
  formik: any
}

const MessageStyled = styled.p`
  font-size: 12px;
  color: #737373;
  line-height: 18px;

  a {
    color: #000;
    text-decoration: none;
  }
`

const SmsMarketingBloc = ({ formik }: Props) => {
  const [t] = useTranslation('app')
  const informationLabels: any = t('checkout.form.information', {
    returnObjects: true,
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('smsMarketingConsent', e.target.checked)
  }

  const handleChangePhoneMarketing = (value: string) => {
    formik.setFieldValue('smsMarketingPhone', value)
  }

  return (
    <Flex direction="column">
      <CheckoutCheckbox
        name="smsMarketingConsent"
        label={informationLabels.smsMarketingConsent.label}
        onChange={handleChange}
        checked={formik.values.smsMarketingConsent}
      />
      {formik.values.smsMarketingConsent && (
        <div
          style={{
            paddingLeft: '27px',
            paddingTop: '14px',
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
          }}
        >
          <CheckoutNumberInput
            name="smsMarketingPhone"
            label="Mobile phone number"
            value={formik.values.smsMarketingPhone}
            onChange={handleChangePhoneMarketing}
            errors={formik.errors}
            defaultCountry={formik.values.countryCode}
            defaultValue={formik.values.phone}
            formik={formik}
          />
          <MessageStyled
            dangerouslySetInnerHTML={{
              __html: informationLabels.smsMarketingConsent.details,
            }}
          ></MessageStyled>
        </div>
      )}
    </Flex>
  )
}

export default SmsMarketingBloc
