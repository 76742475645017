import {
  FormState,
  FormActionTypes,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_EMAIL,
  SET_PHONE_NUMBER,
  SET_BRAND
} from './types'

export const initialFormState: FormState = {
  firstName: '',
  lastName: '',
  email: '',
  phonenumber: '',
  brand: ''
}

export default function payment(
  state = initialFormState,
  action: FormActionTypes
): FormState {
  switch (action.type) {
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload.firstName
      }
    case SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload.lastName
      }
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload.email
      }
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phonenumber: action.payload.phonenumber
      }
    case SET_BRAND:
      return {
        ...state,
        brand: action.payload.brand
      }

    default:
      return state
  }
}
