import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ForgotPasswordLink } from 'common/style/components/links'
import { tablet } from 'common/style/queries/media-queries'
import { sendAmplitudeData } from 'common/utilities/amplitude'
import { AppState } from 'state/features/types'

import Header from '../../common/components/Header/Header'
import {
  MoonaCenterTopToastContainer,
  topSuccess,
} from '../../common/components/Toast/Toast'
import { ButtonDefaultLink } from '../../common/style/components/buttons'
import { Image } from '../../common/style/components/image'
import {
  CenteredMain,
  OnePagerBody,
  OnePagerPage,
} from '../../common/style/components/Section'
import { MdH1, OnePagerTitle } from '../../common/style/components/Typography'
import { resetForgotPassword } from '../../state/features/auth/actions'

import { LoginForm } from './components/LoginForm'

export const LoginCenteredMain = styled(CenteredMain)`
  width: 33.5rem;
  padding: 3.6rem 2.4rem 2.6rem 2.4rem;

  background-color: #fff;
  border: 1px solid #e8e9ed;
  border-radius: 16px;

  @media ${tablet} {
    padding: 6.4rem 17.6rem 6.5rem 17.6rem;
    width: 100%;
  }
`

export interface FormBundle {
  email: { label: string; placeholder: string; validation: string }
  password: { label: string; placeholder: string; validation: string }
  button: { label: string }
  validation: { label: string; links: { label: string; target: string }[] }
}
interface PageBundle {
  title: { mobile: string; desktop: string }
  form: FormBundle
  cta: { to: string; label: string }
  cta2?: { to: string; label: string }
}

export const LoginImage = styled(Image)`
  margin-bottom: 3.6rem;
  margin-top: 3.8rem;
  @media ${tablet} {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
`
export const LoginTitle = styled(MdH1)`
  width: 100%;
  margin-top: 0rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.7rem;
  color: ${props => props.theme.colorblackmainmoona02};
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media all and (-ms-high-contrast: none) {
    height: 54.8rem;
  }
  @media ${tablet} {
  }
`
const Login: React.FC = () => {
  const [t] = useTranslation('app')

  const { loginfailure } = useSelector((state: AppState) => {
    return state.auth
  })

  const {
    params: {
      merchantId,
      merchantStripeId,
      mode,
      ecommerce,
      merchantSite,
      orderId,
      ecommerceSessionId,
      islogged,
      cancelUrl,
    },
  } = useSelector((state: AppState) => {
    return state.query
  })

  const {
    title,
    form: { email, password, button, validation },
    cta,
    cta2,
  }: PageBundle = t('login', { returnObjects: true, cancelUrl })
  const { isMobile } = useSelector((state: AppState) => state.session)
  const titleLabel = isMobile ? title.mobile : title.desktop
  const [isProcessing, setProcessing] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const { forgotPasswordSuccess } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  useEffect(() => {
    if (loginfailure) {
      setProcessing(false)
    }
  }, [loginfailure])

  useEffect(() => {
    if (forgotPasswordSuccess) {
      topSuccess(t('login.resetPasswordSuccessToast'), 'success-password-reset')
      dispatch(resetForgotPassword())
    }
  }, [forgotPasswordSuccess, dispatch, t])

  useEffect(() => {
    sendAmplitudeData('View - Login', {
      mode,
      merchantID: merchantId,
      merchantStripeID: merchantStripeId,
      merchantSite: merchantSite,
      orderID: orderId,
      moonaSessionID: ecommerceSessionId,
      isLogged: islogged,
    })
  }, [
    mode,
    merchantId,
    merchantStripeId,
    merchantSite,
    orderId,
    ecommerceSessionId,
    islogged,
  ])

  return (
    <OnePagerPage>
      <Header showLogo={false} showBreadcrumb={false} />
      <OnePagerBody>
        <OnePagerTitle fontSize="2rem" alignSelf="center">
          {titleLabel}
        </OnePagerTitle>
        <LoginForm
          email={email}
          password={password}
          button={button}
          loginfailure={loginfailure}
          validation={validation}
          isProcessing={isProcessing}
          setProcessing={setProcessing}
          isSubmitted={isSubmitted}
          setSubmitted={setSubmitted}
        />
        <ForgotPasswordLink to={cta.to} name="forgotten-password">
          {cta.label}
        </ForgotPasswordLink>
        {mode === 'redirection' && cancelUrl && cancelUrl !== '' && (
          <ButtonDefaultLink
            type="button"
            onClick={() => {
              if (
                process.env.REACT_APP_STAGING === 'true' &&
                ecommerce === 'FORM'
              ) {
                window.location.replace('/')
              } else {
                window.open(cta2.to, '_self')
              }
            }}
            name="back-offer"
          >
            {cta2.label}
          </ButtonDefaultLink>
        )}
      </OnePagerBody>
      <MoonaCenterTopToastContainer />
    </OnePagerPage>
  )
}

export default Login
