import { CheckoutShopifyState, ReturnItem } from './types'

export const summarySelector = ({ checkoutShopify, checkout }) => {
  const { shippingSlot } = checkout

  function getShippingPrice() {
    if (checkoutShopify.shippingLine?.price?.amount) {
      const freeShippingDiscount =
        checkoutShopify.discountApplications?.nodes.find(
          item =>
            item.value.percentage === 100 && item.targetType === 'SHIPPING_LINE'
        )

      if (freeShippingDiscount) {
        return 0
      }

      if (shippingSlot?.discount) {
        const shippingPriceDiscounted = parseFloat(
          (
            parseFloat(checkoutShopify.shippingLine?.price?.amount ?? '0') -
            shippingSlot?.discount / 100
          ).toFixed(2)
        )
        if (shippingPriceDiscounted >= 0) {
          return shippingPriceDiscounted
        }
        return 0
      }
      return parseFloat(checkoutShopify.shippingLine?.price?.amount ?? '0')
    }
    return null
  }

  // TODO : Double check error with floating decimals
  // basePrice === string ???
  function getCalculatedPrice(basePrice) {
    if (basePrice) {
      if (checkoutShopify.shippingLine?.price?.amount) {
        const freeShippingDiscount =
          checkoutShopify.discountApplications?.nodes.find(
            item =>
              item.value.percentage === 100 &&
              item.targetType === 'SHIPPING_LINE'
          )

        if (!freeShippingDiscount) {
          return parseFloat(
            (
              basePrice -
              parseFloat(checkoutShopify.shippingLine?.price?.amount ?? '0') +
              getShippingPrice()
            ).toFixed(2)
          )
        }
      }
      return parseFloat(basePrice)
    }

    return null
  }

  const partialSummary = {
    subtotal: parseFloat(checkoutShopify.subtotalPrice?.amount ?? 0),
    shipping: getShippingPrice(),
    taxe: parseFloat(checkoutShopify.totalTax?.amount ?? 0),
    discount: checkoutShopify.discountApplications?.nodes ?? [],
    total: getCalculatedPrice(checkoutShopify.totalPrice?.amount) ?? 0,
    duties: parseFloat(checkoutShopify.totalDuties?.amount ?? 0),
    lineItemsSubtotal: parseFloat(
      checkoutShopify.lineItemsSubtotalPrice?.amount ?? 0
    ),
    paymentDue: getCalculatedPrice(checkoutShopify.paymentDue?.amount) ?? 0,
    ceilingError: false,
  }

  let discountCodesTotalPrice =
    partialSummary.paymentDue - partialSummary.lineItemsSubtotal

  if (partialSummary.shipping) {
    discountCodesTotalPrice -= partialSummary.shipping
  }

  if (checkoutShopify.discountApplications?.nodes.length > 0) {
    const discountAllItems = checkoutShopify.discountApplications?.nodes.find(
      discount =>
        discount.targetSelection === 'ALL' && discount.value.percentage
    )
    if (discountAllItems) {
      const lineItemsSubtotalPrice = parseFloat(
        checkoutShopify.lineItemsSubtotalPrice?.amount
      )

      const baseSubtotalPrice =
        Math.trunc(
          (lineItemsSubtotalPrice -
            lineItemsSubtotalPrice *
              (discountAllItems.value.percentage / 100)) *
            100
        ) / 100

      const subtotalCeilDecimal =
        Math.ceil(
          (lineItemsSubtotalPrice -
            lineItemsSubtotalPrice *
              (discountAllItems.value.percentage / 100)) *
            100
        ) / 100

      if (subtotalCeilDecimal !== baseSubtotalPrice) {
        partialSummary.ceilingError = true
        partialSummary.paymentDue += 0.01
        discountCodesTotalPrice += 0.01
      } else {
        partialSummary.ceilingError = false
      }
    }
  }

  return {
    duties: partialSummary.duties.toFixed(2),
    subtotal: partialSummary.subtotal.toFixed(2),
    shipping:
      partialSummary.shipping !== null
        ? partialSummary.shipping.toFixed(2)
        : null,
    taxe: partialSummary.taxe.toFixed(2),
    discounts: partialSummary.discount,
    total: partialSummary.total.toFixed(2),
    discountCodesTotalPrice: discountCodesTotalPrice.toFixed(2),
    lineItemsSubtotal: partialSummary.lineItemsSubtotal.toFixed(2),
    paymentDue: partialSummary.paymentDue.toFixed(2),
    ceilingError: partialSummary.ceilingError,
  }
}

export const lineItemsSelector = ({
  checkoutShopify,
}: {
  checkoutShopify: CheckoutShopifyState
}): ReturnItem[] => {
  return [
    ...checkoutShopify.lineItems.nodes.map(item => ({
      id: item?.id ?? '',
      title: item?.title ?? '',
      image: {
        id: item?.variant?.image?.id ?? '',
        url: item?.variant?.image?.url ?? '',
        altText: item?.variant?.image?.altText ?? '',
      },
      quantity: item?.quantity ?? 0,
      price: item?.variant?.price?.amount,
      discountAllocations: item?.discountAllocations ?? [],
      variant: item.variant,
      customAttributes: item?.customAttributes ?? [],
    })),
  ]
}
