import amplitude from 'amplitude-js'

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE)
}

export const getAmplitudeUserDeviceId = () => {
  return amplitude.getInstance().options.deviceId
}

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId)
}

export const clearAmplitudeUserId = () => {
  amplitude.getInstance().setUserId(null)
}

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties)
}

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
}

export const setIdentifyAmplitude = (typeName, typeProperties) => {
  var identify = new amplitude.Identify().set(typeName, typeProperties)
  amplitude.getInstance().identify(identify)
}

export const getAmplitudeEventOptions = () => {
  return {
    platform: amplitude.getInstance().options?.platform ?? '',
    os_name: amplitude.getInstance()._ua?.os?.name ?? '',
    device_model: amplitude.getInstance()._ua?.device?.model ?? '',
    // TODO : retrieve missing fields
    // country: '',
    // region: '',
    // city: '',
  }
}
