import { DeviceType } from 'common/style/queries/useDeviceType'

import { AppActions } from '../types'

import { SET_DEVICE_TYPE } from './types'

export const setDeviceType = (deviceType: DeviceType): AppActions => {
  const isMediumMobile = deviceType === DeviceType.mobile
  const isSmallMobile = deviceType === DeviceType.smallMobile
  const isLargeMobile = deviceType === DeviceType.largeMobile
  const isMobile = isSmallMobile || isMediumMobile || isLargeMobile

  return {
    type: SET_DEVICE_TYPE,
    payload: {
      isMobile,
      isSmallMobile,
      isLargeMobile,
      isMediumMobile,
    },
  }
}
