import React, { FC, ReactNode } from 'react'

import { darken } from 'polished'
import styled from 'styled-components'

import { tablet } from 'common/style/queries/media-queries'

type ButtonProps = {
  children: ReactNode
  onClick: () => void
  color?: string
  type?: 'button' | 'submit' | 'reset'
} & any

const StyledButton = styled('button')`
  /* padding: 21px 26px; */
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  box-shadow: none;
  border: none;
  outline: none;
  position: relative;
  min-width: 182px;
  min-height: 57px;

  &:disabled {
    background-color: #d9d9d9 !important;
    &:hover {
      cursor: initial;
    }
  }

  &.simple-link {
    background: none;
    color: ${props => props.theme.checkoutColors.primary};
  }

  &.btn-primary {
    background-color: ${props => props.theme.checkoutColors.primaryButton};

    &:hover {
      background-color: ${props =>
        darken(0.05, props.theme.checkoutColors.primaryButton)};
    }
  }

  &:hover {
    cursor: pointer;
  }

  @media ${tablet} {
    font-size: 14px;
  }
`

const Button: FC<ButtonProps> = ({
  children,
  type = 'button',
  onClick,
  color = 'primary',
  ...rest
}) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      className={`btn btn-${color}`}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}

export default Button
