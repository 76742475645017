import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { usePlacesWidget } from 'react-google-autocomplete'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import {
  CheckoutInput,
  CheckoutSelectInput,
} from 'common/components/Form/Checkout'
import MoonaIcon from 'common/components/Icon/MoonaIcon'
import Flex from 'common/components/Primitives/Flex'
import { UpdateCheckout } from 'state/features/checkout/action'
import { AppState } from 'state/features/types'

import AddressSelector from './AddressSelector'
import { allCountries } from './allCountries'
import SmsMarketingBloc from './SmsMarketingBloc'
import AutocompleteMenu from '../AutocompleteMenu/AutocompleteMenu'
import apiInstance from 'services/api'
import { shippingCountries } from 'assets/countries'
import { set } from 'lodash'

type AddressFormProps = {
  formik: any
  billing?: boolean
  isFirstStep?: boolean
}

type MarketOption = {
  value: string
  label: string
  primary: boolean
  provinces?: {
    code: string
    name: string
  }[]
}

const AddressForm: FC<AddressFormProps> = ({
  formik,
  billing = false,
  isFirstStep = false,
}) => {
  const [t] = useTranslation('app')
  const labels: any = t('checkout.form.information', {
    returnObjects: true,
  })
  const [addressValueSelect, setAddressValueSelect] = useState(null)
  const [countrySelect, setCountrySelect] = useState(null)
  const [marketList, setMarketList] = useState<MarketOption[]>([])
  const [showGoogleAutocomplete, setShowGoogleAutocomplete] = useState(false)
  const {
    checkout: checkoutState,
    storeSettings: {
      countries,
      markets,
      smsMarketing,
      addressAutoCompletionEnabled,
    },
    checkoutSession: {
      customer,
      store: { country: storeCountry },
    },
  } = useSelector((state: AppState) => state)
  const dispatch = useDispatch()

  const addressRef = React.useRef(null)

  // const countrieFromStoreSettings = useMemo(() => {
  //   return countries.find(country => country.code === countrySelect.value)
  // }, [countrySelect])

  const handleChangeCountry = (e: any) => {
    const { value } = e.target

    const countrySelected = marketList.find(market => market.value === value)

    if (countrySelected) {
      setCountrySelect(countrySelected)

      if (
        countrySelected.value !==
        formik.values[`countryCode${billing ? 'Billing' : ''}`]
      ) {
        formik.setValues({
          ...formik.values,
          [`country${billing ? 'Billing' : ''}`]: countrySelected.label,
          [`countryCode${billing ? 'Billing' : ''}`]: countrySelected.value,
          [`firstName${billing ? 'Billing' : ''}`]:
            formik.values[`firstName${billing ? 'Billing' : ''}`],
          [`lastName${billing ? 'Billing' : ''}`]:
            formik.values[`lastName${billing ? 'Billing' : ''}`],
          // clear address fields
          [`address1${billing ? 'Billing' : ''}`]: '',
          [`address2${billing ? 'Billing' : ''}`]: '',
          [`city${billing ? 'Billing' : ''}`]: '',
          [`province${billing ? 'Billing' : ''}`]: '',
          [`provinceCode${billing ? 'Billing' : ''}`]: '',
          [`zip${billing ? 'Billing' : ''}`]: '',
        })
      }

      const currentCountry = countries.find(
        country => country.code === countrySelected.value
      )

      if (currentCountry.provinces.length === 0) {
        formik.setFieldValue(`province${billing ? 'Billing' : ''}`, null)

        formik.setFieldValue(`provinceCode${billing ? 'Billing' : ''}`, null)
      }
    }
  }

  const handleChangeProvince = useCallback(
    (e: any) => {
      const { value } = e.target
      const provinceSelected = countrySelect.provinces.find(
        province => province.code === value
      )

      // TODO : Gérer le cas d'erreur quand le state est obligatoire
      if (provinceSelected) {
        formik.setFieldValue(
          `province${billing ? 'Billing' : ''}`,
          provinceSelected.name
        )
        formik.setFieldValue(
          `provinceCode${billing ? 'Billing' : ''}`,
          provinceSelected.code
        )
      } else {
        formik.setFieldValue(`province${billing ? 'Billing' : ''}`, '')
        formik.setFieldValue(`provinceCode${billing ? 'Billing' : ''}`, '')
      }
    },
    [countrySelect]
  )

  const updateFieldsFromGooglePlace = (place: any) => {
    const addressComponents = place.address_components

    const streetNumber = addressComponents.find((component: any) =>
      component.types.includes('street_number')
    )

    const street = addressComponents.find((component: any) =>
      component.types.includes('route')
    )
    if (streetNumber && street) {
      formik.setFieldValue(
        `address1${billing ? 'Billing' : ''}`,
        `${streetNumber.long_name} ${street.long_name}`
      )
    } else if (street) {
      formik.setFieldValue(
        `address1${billing ? 'Billing' : ''}`,
        street.long_name
      )
    } else if (streetNumber) {
      formik.setFieldValue(
        `address1${billing ? 'Billing' : ''}`,
        streetNumber.long_name
      )
    }

    const city = addressComponents.find(
      (component: any) =>
        component.types.includes('postal_town') ||
        component.types.includes('locality')
    )
    if (city) {
      formik.setFieldValue(`city${billing ? 'Billing' : ''}`, city.long_name)
    }

    const zip = addressComponents.find((component: any) =>
      component.types.includes('postal_code')
    )
    if (zip) {
      formik.setFieldValue(`zip${billing ? 'Billing' : ''}`, zip.long_name)
    }

    const province = addressComponents.find((component: any) =>
      component.types.includes('administrative_area_level_1')
    )
    if (province) {
      formik.setFieldValue(
        `province${billing ? 'Billing' : ''}`,
        province.long_name
      )
      formik.setFieldValue(
        `provinceCode${billing ? 'Billing' : ''}`,
        province.short_name
      )
    }

    setShowGoogleAutocomplete(false)
  }

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: 'AIzaSyB0Z59SS7fE94EkDyJZA0Alpq3i-jvywk4',
      debounce: 300,
      libraries: ['places'],
      language: 'en',
    })

  const handleClickOnPlace = (placeId: string) => {
    placesService?.getDetails(
      {
        placeId,
      },
      placeDetails => updateFieldsFromGooglePlace(placeDetails)
    )
  }

  const getAvailableShippingCountries = async () => {
    try {
      const response = await apiInstance.post('', {
        query: `{
          shop {
              shipsToCountries
          }
        }`,
        variables: {},
      })

      if (response.data.data.shop.shipsToCountries) {
        const shipsToCountries = response.data.data.shop.shipsToCountries
        const availableCountries = shippingCountries.filter(country =>
          shipsToCountries.includes(country.code)
        )

        const formattedCountries = availableCountries.map(country => {
          const findCountryFromStoreSettings = countries.find(
            countryFromStoreSettings =>
              countryFromStoreSettings.code === country.code
          )
          return {
            value: country.code,
            label: country.name,
            primary: country.primary,
            provinces:
              findCountryFromStoreSettings?.provinces?.map(province => ({
                code: province.code,
                name: province.name,
              })) ?? [],
          }
        })
        setMarketList(formattedCountries)
      } else {
        setMarketList([])
      }
    } catch (error) {
      console.error(error)
      setMarketList([])
    }
  }

  useEffect(() => {
    if (!billing) {
      getAvailableShippingCountries()
    }
  }, [])

  useEffect(() => {
    if (marketList && marketList.length > 0) {
      if (customer?.addresses.length > 0) {
        const defaultAddress = customer?.addresses.find(
          address => address.default
        )
        setAddressValueSelect(defaultAddress.id)
      }

      const checkoutStateCountry = billing
        ? checkoutState.countryCodeBilling
        : checkoutState.countryCode
      let countrySelected = marketList.find(
        market => market.value === checkoutStateCountry
      )
      countrySelected =
        countrySelected && countrySelected.value !== ''
          ? countrySelected
          : marketList.find(market => market.value === storeCountry) ??
            marketList[0]
      setCountrySelect(countrySelected)
      formik.setFieldValue(
        `country${billing ? 'Billing' : ''}`,
        countrySelected.label
      )
      formik.setFieldValue(
        `countryCode${billing ? 'Billing' : ''}`,
        countrySelected.value
      )
      dispatch(
        UpdateCheckout({
          [`country${billing ? 'Billing' : ''}`]: countrySelected.label,
          [`countryCode${billing ? 'Billing' : ''}`]: countrySelected.value,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketList])

  // Handle market list sort + primary
  useEffect(() => {
    if (markets.length > 0) {
      const sortedMarkets = [...markets].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
      const marketOptions: MarketOption[] = sortedMarkets.map(market => ({
        value: market.code,
        label: market.name,
        primary: market.primary,
        ...(market.provinces &&
          market.code !== 'GB' && { provinces: market.provinces }),
      }))
      const primaryMarkets = marketOptions.filter(
        market => market.primary === true
      )

      let marketsToDisplay: MarketOption[]

      // Display the primary first -> shipping country (without primary) --> separator -> rest of the world
      if (billing) {
        marketsToDisplay = allCountries.slice()

        const separator: MarketOption = {
          value: '',
          label: '---',
          primary: false,
        }
        marketsToDisplay.unshift(separator)

        const noPrimaryMarkets = marketOptions.filter(
          market => market.primary === false
        )
        if (noPrimaryMarkets.length > 0) {
          marketsToDisplay = noPrimaryMarkets.concat(marketsToDisplay)
        }
        if (primaryMarkets.length > 0) {
          marketsToDisplay = primaryMarkets.concat(marketsToDisplay)
        }
      }
      // Display the primary first -> separator -> shipping country (with primary)
      else {
        if (marketOptions.length > 1) {
          if (primaryMarkets.length > 0) {
            const separator: MarketOption = {
              value: '',
              label: '---',
              primary: false,
            }
            primaryMarkets.push(separator)
            marketsToDisplay = primaryMarkets.concat(marketOptions)
          } else {
            marketsToDisplay = marketOptions
          }
        } else {
          marketsToDisplay = marketOptions
        }
      }

      setMarketList(marketsToDisplay)
    }
  }, [markets])

  // update the formik value when the country select change for add a required yup verification or not
  useEffect(() => {
    if (countrySelect?.provinces?.length > 0) {
      if (
        formik.values[`requiresProvince${billing ? 'Billing' : ''}`] === false
      ) {
        formik.setFieldValue(
          `requiresProvince${billing ? 'Billing' : ''}`,
          true
        )
      }
    } else {
      // reset the province value if the country doesn't have any province
      if (
        formik.values[`provinceCode${billing ? 'Billing' : ''}`] !== null ||
        formik.values[`province${billing ? 'Billing' : ''}`] !== null
      ) {
        formik.setFieldValue(`provinceCode${billing ? 'Billing' : ''}`, null)
        formik.setFieldValue(`province${billing ? 'Billing' : ''}`, null)
      }

      if (
        formik.values[`requiresProvince${billing ? 'Billing' : ''}`] === true
      ) {
        formik.setFieldValue(
          `requiresProvince${billing ? 'Billing' : ''}`,
          false
        )
      }
    }
  }, [countrySelect])

  return (
    <>
      {customer?.addresses.length > 0 && (
        <AddressSelector
          addresses={customer?.addresses ?? []}
          addressValueSelect={addressValueSelect}
          setAddressValueSelect={setAddressValueSelect}
          billing={billing}
          formik={formik}
        />
      )}
      <CheckoutSelectInput
        name={`countryCode${billing ? 'Billing' : ''}`}
        label={labels.country.label}
        onChange={handleChangeCountry}
        value={formik.values[`countryCode${billing ? 'Billing' : ''}`]}
        errors={formik.errors}
        style={{
          background: billing ? '#fff' : 'none',
        }}
        options={[
          ...marketList.map(market => ({
            value: market.value,
            label: market.label,
          })),
        ]}
      />
      {countrySelect?.provinces?.length > 0 && (
        <CheckoutSelectInput
          name={`provinceCode${billing ? 'Billing' : ''}`}
          label={
            countrySelect?.value === 'US'
              ? labels.state.titleLabel
              : labels.province.titleLabel
          }
          onChange={handleChangeProvince}
          value={formik.values[`provinceCode${billing ? 'Billing' : ''}`] || ''}
          errors={formik.errors}
          style={{
            background: billing ? '#fff' : 'none',
          }}
          options={[
            {
              value: '',
              label:
                countrySelect?.value === 'US'
                  ? labels.state.contentLabel
                  : labels.province.contentLabel,
            },
            ...countrySelect?.provinces?.map(province => ({
              value: province.code,
              label: province.name,
            })),
          ]}
        />
      )}
      <Flex style={{ gap: billing ? '17px' : '12px' }}>
        <CheckoutInput
          name={`firstName${billing ? 'Billing' : ''}`}
          label={labels.firstname.label}
          onChange={formik.handleChange}
          value={formik.values[`firstName${billing ? 'Billing' : ''}`]}
          errors={formik.errors}
        />
        <CheckoutInput
          name={`lastName${billing ? 'Billing' : ''}`}
          label={labels.lastname.label}
          onChange={formik.handleChange}
          value={formik.values[`lastName${billing ? 'Billing' : ''}`]}
          errors={formik.errors}
        />
      </Flex>
      <CheckoutInput
        ref={addressRef}
        name={`address1${billing ? 'Billing' : ''}`}
        label={labels.address1.label}
        onChange={event => {
          formik.handleChange(event)
          if (addressAutoCompletionEnabled) {
            if (
              addressRef.current &&
              !addressRef.current.matches('*:-webkit-autofill')
            ) {
              getPlacePredictions({
                input: event.target.value,
                types: ['address'],
                componentRestrictions: {
                  country: billing
                    ? (formik.values.countryCodeBilling as string)
                    : (formik.values.countryCode as string),
                },
              })
              setShowGoogleAutocomplete(true)
            }
          }
        }}
        value={formik.values[`address1${billing ? 'Billing' : ''}`]}
        errors={formik.errors}
      />
      {addressRef.current !== null &&
        formik.values[`address1${billing ? 'Billing' : ''}`].length > 0 &&
        addressAutoCompletionEnabled && (
          <AutocompleteMenu
            element={addressRef.current}
            open={showGoogleAutocomplete}
            setOpen={setShowGoogleAutocomplete}
            data={placePredictions}
            handleClickOnPlace={handleClickOnPlace}
          />
        )}
      {formik.values[`address1${billing ? 'Billing' : ''}`] !== '' &&
        !/\d/.test(formik.values[`address1${billing ? 'Billing' : ''}`]) && (
          <Flex align="center" spacing="5px">
            <MoonaIcon
              icon="Info"
              style={{
                width: '18px',
                height: '18px',
              }}
            />
            <p>Add a house number if you have one</p>
          </Flex>
        )}
      <Flex style={{ gap: 14 }}>
        <CheckoutInput
          name={`city${billing ? 'Billing' : ''}`}
          label={labels.city.label}
          onChange={formik.handleChange}
          value={formik.values[`city${billing ? 'Billing' : ''}`]}
          errors={formik.errors}
        />
        <CheckoutInput
          name={`zip${billing ? 'Billing' : ''}`}
          label={
            countrySelect?.value === 'US'
              ? labels.zipcode.label
              : labels.postcode.label
          }
          onChange={formik.handleChange}
          value={formik.values[`zip${billing ? 'Billing' : ''}`]}
          errors={formik.errors}
        />
      </Flex>
      <CheckoutInput
        name={`phone${billing ? 'Billing' : ''}`}
        label={labels.phone.label}
        onChange={formik.handleChange}
        value={formik.values[`phone${billing ? 'Billing' : ''}`]}
        errors={formik.errors}
        regexp={new RegExp('^[0-9s()+-]*$')}
        helperText="In case we need to contact you about your order"
      />
      {smsMarketing && isFirstStep && <SmsMarketingBloc formik={formik} />}

      {/* {(addressValueSelect === 'new' || !(customer?.addresses?.length > 0)) && (
        <CheckoutCheckbox
          name="saveInformationForNextTime"
          label={labels.saveInformationForNextTime.label}
          onChange={formik.handleChange}
          checked={formik.values.saveInformationForNextTime}
        />
      )} */}
    </>
  )
}

export default AddressForm
