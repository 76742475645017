import { API, graphqlOperation } from 'aws-amplify'

import { usersByEmail } from 'graphql/queries'

import {
  getUserInfos,
  getUserAndEcommerceSession,
} from '../../../graphql/queries'

import { check as checkResponse } from './utils'

export const isRegisteredEmail = async email => {
  const data = await API.graphql(
    graphqlOperation(usersByEmail, { email: email.toLowerCase() })
  )

  return data.data.usersByEmail.items && data.data.usersByEmail.items.length > 0
}

export const getUserInfosByEmail = async (email, check = true) => {
  let data
  if (check) {
    data = checkResponse(
      await API.graphql(
        graphqlOperation(getUserInfos, { email: email.toLowerCase() })
      ),
      'getUserInfos'
    )
  } else {
    const result = await API.graphql(
      graphqlOperation(getUserInfos, { email: email.toLowerCase() })
    )
    data = JSON.parse(result.data.getUserInfos)
  }

  return data.statusCode === 200 && data.body ? data.body : null
}

export const getUserAndEcommerceSessionById = async sessionid => {
  const result = await API.graphql(
    graphqlOperation(getUserAndEcommerceSession, { sessionid: sessionid })
  )
  const data = JSON.parse(result.data.getUserAndEcommerceSession)
  return data.statusCode === 200 && data.body ? data.body : null
}
