import * as Yup from 'yup'

import { isRegisteredEmail } from 'state/features/users/api'

export interface BaseBundleMessage {
  label: string
  name: string
  links?: { label: string; target: string }[]
}

function ValidateEmail(mail: string): boolean {
  const pattern =
    process.env.REACT_APP_BUILD_ENV !== 'prod'
      ? new RegExp(
          /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+[\\.][a-zA-Z0-9-.]+$/
        )
      : new RegExp(
          /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*?@[a-zA-Z0-9-]+[\\.][a-zA-Z0-9-.]+$/
        )

  // : new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)

  if (pattern.test(mail)) {
    return true
  }

  return false
}

function isEmpty(text: string): boolean {
  if (text === '') {
    return true
  }
  return false
}

export function validateUKpostcode(postcode: string) {
  postcode = postcode.replace(/\s/g, '')
  var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i
  return regex.test(postcode)
}

export function validatePostalCode(postcode: string, msg: BaseBundleMessage) {
  let error = ''
  if (!postcode) {
    error = JSON.stringify(msg)
  }
  postcode = postcode.replace(/\s/g, '')
  const regex = /^([a-zA-Z0-9 ]+)$/
  if (!regex.test(postcode)) {
    error = JSON.stringify(msg)
  }
  return error
}

function ValidateText(text: string): boolean {
  if (text === '') {
    return false
  }
  const re = /^[ \-'a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g
  if (re.test(text)) {
    return true
  }
  return false
}

export function IsJsonString(str: string): boolean {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function validateText(value: string, msg: BaseBundleMessage): string {
  let error = ''
  if (isEmpty(value)) {
    error = JSON.stringify(msg)
  } else if (!ValidateText(value)) {
    error = JSON.stringify(msg)
  }
  return error
}

interface BundleMail {
  label: string
  placeholder: string
  invalidformat: BaseBundleMessage
  inuse: BaseBundleMessage
}
export const validateEmail = (
  value: string,
  bundle: BundleMail,
  isFieldActive: boolean,
  currentError: string
) => {
  if (!isFieldActive) {
    return currentError
  }

  const { invalidformat, inuse } = bundle
  if (!ValidateEmail(value)) {
    return JSON.stringify(invalidformat)
  }

  return isRegisteredEmail(value).then(existing => {
    if (existing) {
      return JSON.stringify(inuse)
    } else {
      return ''
    }
  })
}

export function validatePhone(value: string, msg: BaseBundleMessage) {
  let error = ''
  if (value === 'invalid_phone_number' || value === '') {
    error = JSON.stringify(msg)
  }
  return error
}

export const yupPasswordValidationRule = (msg: BaseBundleMessage) => ({
  password: Yup.string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"'()*+,/:;<>?[\] `{|}~@\-#$%^&+=])(?=.{8,})/,
      JSON.stringify(msg)
    ),
})
