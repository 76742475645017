export const UPDATE_SHOPPER = 'UPDATE_SHOPPER'

// 0 => No subscription
// 1 => trialing
// 2 => active

export interface ShopperState {
  id: string
  sbdt: 0 | 1 | 2 // discount code
  sbfs: 0 | 1 | 2 // freeshipping subscription
  sbm: 0 | 1 | 2 // Moona subscription
  sbmbg: 0 | 1 | 2 // MBG subscription
  stripeId: string
}

export interface UpdateShopper {
  type: typeof UPDATE_SHOPPER
  payload: ShopperState
}

export type ShopperActionTypes = UpdateShopper
