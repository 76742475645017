export const shippingCountries = [
  { name: 'Afghanistan', code: 'AF', primary: false },
  { name: 'Åland Islands', code: 'AX', primary: false },
  { name: 'Albania', code: 'AL', primary: false },
  { name: 'Algeria', code: 'DZ', primary: false },
  { name: 'American Samoa', code: 'AS', primary: false },
  { name: 'AndorrA', code: 'AD', primary: false },
  { name: 'Angola', code: 'AO', primary: false },
  { name: 'Anguilla', code: 'AI', primary: false },
  { name: 'Antarctica', code: 'AQ', primary: false },
  { name: 'Antigua and Barbuda', code: 'AG', primary: false },
  { name: 'Argentina', code: 'AR', primary: false },
  { name: 'Armenia', code: 'AM', primary: false },
  { name: 'Aruba', code: 'AW', primary: false },
  { name: 'Australia', code: 'AU', primary: false },
  { name: 'Austria', code: 'AT', primary: false },
  { name: 'Azerbaijan', code: 'AZ', primary: false },
  { name: 'Bahamas', code: 'BS', primary: false },
  { name: 'Bahrain', code: 'BH', primary: false },
  { name: 'Bangladesh', code: 'BD', primary: false },
  { name: 'Barbados', code: 'BB', primary: false },
  { name: 'Belarus', code: 'BY', primary: false },
  { name: 'Belgium', code: 'BE', primary: false },
  { name: 'Belize', code: 'BZ', primary: false },
  { name: 'Benin', code: 'BJ', primary: false },
  { name: 'Bermuda', code: 'BM', primary: false },
  { name: 'Bhutan', code: 'BT', primary: false },
  { name: 'Bolivia', code: 'BO', primary: false },
  { name: 'Bosnia and Herzegovina', code: 'BA', primary: false },
  { name: 'Botswana', code: 'BW', primary: false },
  { name: 'Bouvet Island', code: 'BV', primary: false },
  { name: 'Brazil', code: 'BR', primary: false },
  { name: 'British Indian Ocean Territory', code: 'IO', primary: false },
  { name: 'Brunei Darussalam', code: 'BN', primary: false },
  { name: 'Bulgaria', code: 'BG', primary: false },
  { name: 'Burkina Faso', code: 'BF', primary: false },
  { name: 'Burundi', code: 'BI', primary: false },
  { name: 'Cambodia', code: 'KH', primary: false },
  { name: 'Cameroon', code: 'CM', primary: false },
  { name: 'Canada', code: 'CA', primary: false },
  { name: 'Cape Verde', code: 'CV', primary: false },
  { name: 'Cayman Islands', code: 'KY', primary: false },
  { name: 'Central African Republic', code: 'CF', primary: false },
  { name: 'Chad', code: 'TD', primary: false },
  { name: 'Chile', code: 'CL', primary: false },
  { name: 'China', code: 'CN', primary: false },
  { name: 'Christmas Island', code: 'CX', primary: false },
  { name: 'Cocos (Keeling) Islands', code: 'CC', primary: false },
  { name: 'Colombia', code: 'CO', primary: false },
  { name: 'Comoros', code: 'KM', primary: false },
  { name: 'Congo', code: 'CG', primary: false },
  { name: 'Congo, The Democratic Republic of the', code: 'CD', primary: false },
  { name: 'Cook Islands', code: 'CK', primary: false },
  { name: 'Costa Rica', code: 'CR', primary: false },
  { name: "Cote D'Ivoire", code: 'CI', primary: false },
  { name: 'Croatia', code: 'HR', primary: false },
  { name: 'Cuba', code: 'CU', primary: false },
  { name: 'Cyprus', code: 'CY', primary: false },
  { name: 'Czech Republic', code: 'CZ', primary: false },
  { name: 'Denmark', code: 'DK', primary: false },
  { name: 'Djibouti', code: 'DJ', primary: false },
  { name: 'Dominica', code: 'DM', primary: false },
  { name: 'Dominican Republic', code: 'DO', primary: false },
  { name: 'Ecuador', code: 'EC', primary: false },
  { name: 'Egypt', code: 'EG', primary: false },
  { name: 'El Salvador', code: 'SV', primary: false },
  { name: 'Equatorial Guinea', code: 'GQ', primary: false },
  { name: 'Eritrea', code: 'ER', primary: false },
  { name: 'Estonia', code: 'EE', primary: false },
  { name: 'Ethiopia', code: 'ET', primary: false },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', primary: false },
  { name: 'Faroe Islands', code: 'FO', primary: false },
  { name: 'Fiji', code: 'FJ', primary: false },
  { name: 'Finland', code: 'FI', primary: false },
  { name: 'France', code: 'FR', primary: false },
  { name: 'French Guiana', code: 'GF', primary: false },
  { name: 'French Polynesia', code: 'PF', primary: false },
  { name: 'French Southern Territories', code: 'TF', primary: false },
  { name: 'Gabon', code: 'GA', primary: false },
  { name: 'Gambia', code: 'GM', primary: false },
  { name: 'Georgia', code: 'GE', primary: false },
  { name: 'Germany', code: 'DE', primary: false },
  { name: 'Ghana', code: 'GH', primary: false },
  { name: 'Gibraltar', code: 'GI', primary: false },
  { name: 'Greece', code: 'GR', primary: false },
  { name: 'Greenland', code: 'GL', primary: false },
  { name: 'Grenada', code: 'GD', primary: false },
  { name: 'Guadeloupe', code: 'GP', primary: false },
  { name: 'Guam', code: 'GU', primary: false },
  { name: 'Guatemala', code: 'GT', primary: false },
  { name: 'Guernsey', code: 'GG', primary: false },
  { name: 'Guinea', code: 'GN', primary: false },
  { name: 'Guinea-Bissau', code: 'GW', primary: false },
  { name: 'Guyana', code: 'GY', primary: false },
  { name: 'Haiti', code: 'HT', primary: false },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', primary: false },
  { name: 'Holy See (Vatican City State)', code: 'VA', primary: false },
  { name: 'Honduras', code: 'HN', primary: false },
  { name: 'Hong Kong', code: 'HK', primary: false },
  { name: 'Hungary', code: 'HU', primary: false },
  { name: 'Iceland', code: 'IS', primary: false },
  { name: 'India', code: 'IN', primary: false },
  { name: 'Indonesia', code: 'ID', primary: false },
  { name: 'Iran, Islamic Republic Of', code: 'IR', primary: false },
  { name: 'Iraq', code: 'IQ', primary: false },
  { name: 'Ireland', code: 'IE', primary: false },
  { name: 'Isle of Man', code: 'IM', primary: false },
  { name: 'Israel', code: 'IL', primary: false },
  { name: 'Italy', code: 'IT', primary: false },
  { name: 'Jamaica', code: 'JM', primary: false },
  { name: 'Japan', code: 'JP', primary: false },
  { name: 'Jersey', code: 'JE', primary: false },
  { name: 'Jordan', code: 'JO', primary: false },
  { name: 'Kazakhstan', code: 'KZ', primary: false },
  { name: 'Kenya', code: 'KE', primary: false },
  { name: 'Kiribati', code: 'KI', primary: false },
  {
    name: "Korea, Democratic People'S Republic of",
    code: 'KP',
    primary: false,
  },
  { name: 'Korea, Republic of', code: 'KR', primary: false },
  { name: 'Kuwait', code: 'KW', primary: false },
  { name: 'Kyrgyzstan', code: 'KG', primary: false },
  { name: "Lao People'S Democratic Republic", code: 'LA', primary: false },
  { name: 'Latvia', code: 'LV', primary: false },
  { name: 'Lebanon', code: 'LB', primary: false },
  { name: 'Lesotho', code: 'LS', primary: false },
  { name: 'Liberia', code: 'LR', primary: false },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', primary: false },
  { name: 'Liechtenstein', code: 'LI', primary: false },
  { name: 'Lithuania', code: 'LT', primary: false },
  { name: 'Luxembourg', code: 'LU', primary: false },
  { name: 'Macao', code: 'MO', primary: false },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    primary: false,
  },
  { name: 'Madagascar', code: 'MG', primary: false },
  { name: 'Malawi', code: 'MW', primary: false },
  { name: 'Malaysia', code: 'MY', primary: false },
  { name: 'Maldives', code: 'MV', primary: false },
  { name: 'Mali', code: 'ML', primary: false },
  { name: 'Malta', code: 'MT', primary: false },
  { name: 'Marshall Islands', code: 'MH', primary: false },
  { name: 'Martinique', code: 'MQ', primary: false },
  { name: 'Mauritania', code: 'MR', primary: false },
  { name: 'Mauritius', code: 'MU', primary: false },
  { name: 'Mayotte', code: 'YT', primary: false },
  { name: 'Mexico', code: 'MX', primary: false },
  { name: 'Micronesia, Federated States of', code: 'FM', primary: false },
  { name: 'Moldova, Republic of', code: 'MD', primary: false },
  { name: 'Monaco', code: 'MC', primary: false },
  { name: 'Mongolia', code: 'MN', primary: false },
  { name: 'Montserrat', code: 'MS', primary: false },
  { name: 'Morocco', code: 'MA', primary: false },
  { name: 'Mozambique', code: 'MZ', primary: false },
  { name: 'Myanmar', code: 'MM', primary: false },
  { name: 'Namibia', code: 'NA', primary: false },
  { name: 'Nauru', code: 'NR', primary: false },
  { name: 'Nepal', code: 'NP', primary: false },
  { name: 'Netherlands', code: 'NL', primary: false },
  { name: 'Netherlands Antilles', code: 'AN', primary: false },
  { name: 'New Caledonia', code: 'NC', primary: false },
  { name: 'New Zealand', code: 'NZ', primary: false },
  { name: 'Nicaragua', code: 'NI', primary: false },
  { name: 'Niger', code: 'NE', primary: false },
  { name: 'Nigeria', code: 'NG', primary: false },
  { name: 'Niue', code: 'NU', primary: false },
  { name: 'Norfolk Island', code: 'NF', primary: false },
  { name: 'Northern Mariana Islands', code: 'MP', primary: false },
  { name: 'Norway', code: 'NO', primary: false },
  { name: 'Oman', code: 'OM', primary: false },
  { name: 'Pakistan', code: 'PK', primary: false },
  { name: 'Palau', code: 'PW', primary: false },
  { name: 'Palestinian Territory, Occupied', code: 'PS', primary: false },
  { name: 'Panama', code: 'PA', primary: false },
  { name: 'Papua New Guinea', code: 'PG', primary: false },
  { name: 'Paraguay', code: 'PY', primary: false },
  { name: 'Peru', code: 'PE', primary: false },
  { name: 'Philippines', code: 'PH', primary: false },
  { name: 'Pitcairn', code: 'PN', primary: false },
  { name: 'Poland', code: 'PL', primary: false },
  { name: 'Portugal', code: 'PT', primary: false },
  { name: 'Puerto Rico', code: 'PR', primary: false },
  { name: 'Qatar', code: 'QA', primary: false },
  { name: 'Reunion', code: 'RE', primary: false },
  { name: 'Romania', code: 'RO', primary: false },
  { name: 'Russian Federation', code: 'RU', primary: false },
  { name: 'RWANDA', code: 'RW', primary: false },
  { name: 'Saint Helena', code: 'SH', primary: false },
  { name: 'Saint Kitts and Nevis', code: 'KN', primary: false },
  { name: 'Saint Lucia', code: 'LC', primary: false },
  { name: 'Saint Pierre and Miquelon', code: 'PM', primary: false },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', primary: false },
  { name: 'Samoa', code: 'WS', primary: false },
  { name: 'San Marino', code: 'SM', primary: false },
  { name: 'Sao Tome and Principe', code: 'ST', primary: false },
  { name: 'Saudi Arabia', code: 'SA', primary: false },
  { name: 'Senegal', code: 'SN', primary: false },
  { name: 'Serbia and Montenegro', code: 'CS', primary: false },
  { name: 'Seychelles', code: 'SC', primary: false },
  { name: 'Sierra Leone', code: 'SL', primary: false },
  { name: 'Singapore', code: 'SG', primary: false },
  { name: 'Slovakia', code: 'SK', primary: false },
  { name: 'Slovenia', code: 'SI', primary: false },
  { name: 'Solomon Islands', code: 'SB', primary: false },
  { name: 'Somalia', code: 'SO', primary: false },
  { name: 'South Africa', code: 'ZA', primary: false },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    primary: false,
  },
  { name: 'Spain', code: 'ES', primary: false },
  { name: 'Sri Lanka', code: 'LK', primary: false },
  { name: 'Sudan', code: 'SD', primary: false },
  { name: 'Suriname', code: 'SR', primary: false },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', primary: false },
  { name: 'Swaziland', code: 'SZ', primary: false },
  { name: 'Sweden', code: 'SE', primary: false },
  { name: 'Switzerland', code: 'CH', primary: false },
  { name: 'Syrian Arab Republic', code: 'SY', primary: false },
  { name: 'Taiwan, Province of China', code: 'TW', primary: false },
  { name: 'Tajikistan', code: 'TJ', primary: false },
  { name: 'Tanzania, United Republic of', code: 'TZ', primary: false },
  { name: 'Thailand', code: 'TH', primary: false },
  { name: 'Timor-Leste', code: 'TL', primary: false },
  { name: 'Togo', code: 'TG', primary: false },
  { name: 'Tokelau', code: 'TK', primary: false },
  { name: 'Tonga', code: 'TO', primary: false },
  { name: 'Trinidad and Tobago', code: 'TT', primary: false },
  { name: 'Tunisia', code: 'TN', primary: false },
  { name: 'Turkey', code: 'TR', primary: false },
  { name: 'Turkmenistan', code: 'TM', primary: false },
  { name: 'Turks and Caicos Islands', code: 'TC', primary: false },
  { name: 'Tuvalu', code: 'TV', primary: false },
  { name: 'Uganda', code: 'UG', primary: false },
  { name: 'Ukraine', code: 'UA', primary: false },
  { name: 'United Arab Emirates', code: 'AE', primary: false },
  { name: 'United Kingdom', code: 'GB', primary: true },
  { name: 'United States', code: 'US', primary: false },
  { name: 'United States Minor Outlying Islands', code: 'UM', primary: false },
  { name: 'Uruguay', code: 'UY', primary: false },
  { name: 'Uzbekistan', code: 'UZ', primary: false },
  { name: 'Vanuatu', code: 'VU', primary: false },
  { name: 'Venezuela', code: 'VE', primary: false },
  { name: 'Viet Nam', code: 'VN', primary: false },
  { name: 'Virgin Islands, British', code: 'VG', primary: false },
  { name: 'Virgin Islands, U.S.', code: 'VI', primary: false },
  { name: 'Wallis and Futuna', code: 'WF', primary: false },
  { name: 'Western Sahara', code: 'EH', primary: false },
  { name: 'Yemen', code: 'YE', primary: false },
  { name: 'Zambia', code: 'ZM', primary: false },
  { name: 'Zimbabwe', code: 'ZW', primary: false },
]
