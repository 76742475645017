import React, { ComponentProps, FC } from 'react'

import styled, { css } from 'styled-components'

import AppBox, {
  AppBoxProps,
  PropsOf,
} from '../../components/Primitives/AppBox'
import { tablet } from '../queries/media-queries'

import { Image } from './image'
import lock from './lock.svg'
import paymentcheck from './paymentcheck.svg'
import { Spinner } from './spinner'

export interface NameProps {
  readonly name: string
}

const baseButtonCss = css`
  background: none;
  border: 0;
  font-family: 'Circular Std';
  color: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-appearance: button;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: inherit;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export const BaseButton = styled.button.attrs<NameProps>(({ name }) => ({
  type: 'button',
  name: name,
}))<NameProps>`
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;

  outline: none;
`

export const ButtonLink = styled(BaseButton)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: ${props => props.theme.colorpurplemoona};
`

export const ButtonDefaultLink = styled(BaseButton)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  font-family: inherit;
  text-decoration: underline;
  color: ${props => props.theme.colorblackmainmoona};
`

export const primaryButtonCss = css`
  ${baseButtonCss}
  background: ${props => props.theme.colorpurplemoona};
  white-space: nowrap;

  border-radius: 0.8rem;
  font-family: 'Open Sans', sans-serif, Arial;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.4rem;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  align-self: center;

  &:hover {
    background: ${props => props.theme.colorpurpledark};
  }

  &:focus:before {
    content: '';

    position: absolute;
    display: block;
    z-index: -1;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;

    border-radius: 8px;

    color: #ffffff;
    background: ${props => props.theme.colorpurplemoona};
    border: 4px solid ${props => props.theme.colorpurplelight};
  }

  &:active:before {
    content: '';

    position: absolute;
    display: block;
    z-index: -1;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;

    border-radius: 8px;

    color: #ffffff;
    background: ${props => props.theme.colorpurpledark};
    border: 4px solid ${props => props.theme.colorpurplelight};
  }

  &:disabled {
    background: rgba(149, 64, 255, 0.5);
    cursor: not-allowed;
  }
`

export const pinkButtonCss = css`
  ${primaryButtonCss}
  background-color: ${props => props.theme.colorsuccess};
  width: 100%;
  padding: 1.2rem 8rem;

  &:hover {
    background: ${props => props.theme.colorsuccesshover};
  }

  &:focus:before {
    background: ${props => props.theme.colorsuccess};
    border: 4px solid ${props => props.theme.colorsuccessborder};
  }

  &:active:before {
    content: '';
    background: ${props => props.theme.colorsuccesshover};
    border: 4px solid ${props => props.theme.colorsuccessborder};
  }

  &:disabled {
    background: ${props => props.theme.colorsuccessdisable};
  }
`

export const ButtonPinkYCoupon = styled.button`
  ${pinkButtonCss}
  display: flex;
`

export const successButtonCss = css`
  ${primaryButtonCss}
  background-color: ${props => props.theme.colorsuccess};
  width: 100%;
  padding: 1.3rem 0;

  &:hover {
    background: ${props => props.theme.colorsuccesshover};
  }

  &:focus:before {
    background: ${props => props.theme.colorsuccess};
    border: 4px solid ${props => props.theme.colorsuccessborder};
  }

  &:active:before {
    content: '';
    background: ${props => props.theme.colorsuccesshover};
    border: 4px solid ${props => props.theme.colorsuccessborder};
  }

  &:disabled {
    background: ${props => props.theme.colorsuccessdisable};
  }
`

interface PrimaryButtonProps
  extends Omit<AppBoxProps, keyof PropsOf<'button'>>,
    PropsOf<'button'> {
  success?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}

export const PrimaryButton: FC<PrimaryButtonProps> = styled(AppBox).attrs<
  PrimaryButtonProps
>(({ name, type, ...props }) => ({
  as: 'button',
  type: type || 'button',
  name: name,
  px: props.px ?? '8rem',
  py: props.py ?? '1.2rem',
  ...props,
}))<PrimaryButtonProps>`
  ${primaryButtonCss}
`

interface NeutralButtonProps
  extends Omit<AppBoxProps, keyof PropsOf<'button'>>,
    PropsOf<'button'> {}

export const NeutralButton: FC<NeutralButtonProps> = styled(AppBox).attrs<
  NeutralButtonProps
>({
  as: 'button',
})<NeutralButtonProps>`
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

NeutralButton.defaultProps = {
  borderRadius: '0.8rem',
  bg: 'colorgreylink',
  border: 'none',
  py: '1.2rem',
  px: '4rem',
  fontFamily: 'inherit',
  fontWeight: 700,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
}

export const ButtonRedLink = styled(PrimaryButton)`
  text-decoration: underline;
  justify-content: ${props => props.justifyContent ?? 'right'};
  background-color: transparent;
  color: ${props => props.color ?? props.theme.colorblackmainmoona02};
  font-size: 1.4rem;
  font-weight: 400;
  width: 100%;
  padding: 0;
  padding-right: 10px;
  display: -webkit-box;
  text-align: ${props => props.justifyContent ?? '-webkit-right'};
  text-align: ${props => props.justifyContent ?? 'right'};
  -webkit-box-pack: ${props => props.justifyContent ?? 'end'};

  &:hover {
    background: unset;
  }

  &:focus:before {
    display: none;
  }

  &:active:before {
    display: none;
  }
`

export const ButtonTowerLondonLink = styled(PrimaryButton)`
  text-decoration: underline;
  justify-content: ${props => props.justifyContent ?? 'right'};
  background-color: transparent;
  color: ${props => props.color ?? props.theme.colorblackmainmoona02};
  font-size: 2.1rem;
  opacity: 0.5;
  font-weight: 700;
  width: 100%;
  padding: 0;
  display: -webkit-box;
  text-align: ${props => props.justifyContent ?? '-webkit-right'};
  text-align: ${props => props.justifyContent ?? 'right'};
  -webkit-box-pack: ${props => props.justifyContent ?? 'end'};

  &:hover {
    background: unset;
  }

  &:focus:before {
    display: none;
  }

  &:active:before {
    display: none;
  }
`

export const ButtonWhiteLink = styled(PrimaryButton)`
  background-color: white;
  color: ${props => props.color ?? props.theme.colorblackmainmoona02};
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  padding: 0;

  &:hover {
    background: unset;
  }
`

export const SuccessButton = styled.button`
  ${successButtonCss}
  display: flex;
`

export const IconSucessButton = styled(({ className, ...props }) => (
  <SuccessButton className={className} {...props}>
    <Image src={paymentcheck} />
  </SuccessButton>
)).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  width: 28.5rem;
  @media ${tablet} {
    width: 100%;
  }
`

interface SubmittablePrimaryButtonProps {
  className?: string
  isSubmitting: boolean
  isSubmitted: boolean
  label: string
  name: string
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
}
const BaseSubmittablePrimaryButton: React.FC<SubmittablePrimaryButtonProps> = ({
  className,
  name,
  isSubmitting,
  isSubmitted,
  label,
  type = 'button',
  disabled = false,
  ...props
}) => {
  const body = isSubmitting ? <Spinner /> : label
  if (isSubmitted) {
    return <IconSucessButton />
  }
  return (
    <PrimaryButton
      name={name}
      className={`${className}`}
      {...props}
      success={'#31DC9E'}
      type={type}
      disabled={disabled}
    >
      {body}
    </PrimaryButton>
  )
}

export const SubmittablePrimaryButton = styled(
  BaseSubmittablePrimaryButton
).attrs(({ type, disabled }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  type: type || 'button',
  disabled: disabled || false,
}))`
  display: inline-block;
  position: relative;
  display: flex;
  width: 100%;
  height: 4.8rem;
`

const BaseSubmittablePayButton: React.FC<SubmittablePrimaryButtonProps> = ({
  className,
  isSubmitting,
  isSubmitted,
  label,
  type = 'button',
  disabled = false,

  name,
  ...props
}) => {
  const body = isSubmitting ? <Spinner /> : label
  if (isSubmitted) {
    return <IconSucessButton />
  }
  return (
    <PrimaryButton
      name={name}
      className={`${className}`}
      {...props}
      success={'#31DC9E'}
      type={type}
      disabled={disabled}
    >
      {/* { && !disabled && <Lock src={lock} />} */}
      {/* {!isSubmitting && !disabled && <Lock src={lock} />} */}
      {!isSubmitting && <Lock src={lock} />}
      {body}
    </PrimaryButton>
  )
}
const Lock = styled(Image)`
  position: absolute;
  left: 0.85rem;
  top: 0.5rem;
`
export const SubmittablePayButton: FC<
  SubmittablePrimaryButtonProps &
    AppBoxProps &
    ComponentProps<'button'> &
    Omit<PrimaryButtonProps, keyof AppBoxProps>
> = styled(BaseSubmittablePayButton).attrs(({ type, disabled }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  type: type || 'button',
  disabled: disabled || false,
}))`
  position: relative;
  display: flex;
  width: 100%;
  height: 4.8rem;
`
