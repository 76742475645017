import React from 'react'

import Skeleton, { SkeletonProps } from 'react-loading-skeleton'

export const H3Skeleton = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Skeleton> &
    Readonly<SkeletonProps> &
    Readonly<{ children?: React.ReactNode }>
) => <Skeleton height="2rem" {...props} />

export const H1Skeleton = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Skeleton> &
    Readonly<SkeletonProps> &
    Readonly<{ children?: React.ReactNode }>
) => <Skeleton height="3rem" {...props} />
