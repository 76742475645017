import styled from 'styled-components'

export const AutocompleteMenuOption = styled.li`
  list-style: none;
  padding-inline-start: 0;
  margin-inline-start: 0;
  width: 100%;
  padding: 14px;

  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }

  strong {
    color: #000;
    font-weight: 500;
  }

  color: #737373;
  font-size: 14px;
  font-weight: 400;
`
