import { AppActions } from '../types'

import {
  ADD_CARD,
  AddCard,
  CHANGE_CARD,
  ChangeCard,
  CLEAR_USER,
  DELETE_FIRST_USER,
  GET_MAIL,
  GET_MAIL_SUCCESS,
  IS_MOONA_USER,
  LOAD_USER,
  PROCESS_MAIN_ORDER_REQUEST,
  PROCESS_ORDER_REQUEST,
  PROCESS_SUBSCRIBE_TO_MONEY_BACK_GUARANTEE_REQUEST,
  ProcessMainOrderRequestAction,
  ProcessSubscribeToMoneyBackGuaranteeRequestioAction,
  SET_EMAIL,
  SET_EMAIL_MOONAID_SECRET,
  SET_FIRST_PWD,
  SET_HAS_SUBSCRIPTION,
  SET_HAS_SUBSCRIPTION_YCOUPON,
  SET_HAS_SUBSCRIPTION_MBG,
  SET_HAS_SUBSCRIPTION_DISCOUNT_POST_PURCHASE,
  SET_MOONA_ID,
  SET_ONLY_EMAIL,
  SET_PWD_SUCCESS,
  SET_STRIPE_ID,
  SET_USER_SECRET,
  SetHasSubscription,
  SetHasSubscriptionYCoupon,
  SetHasSubscriptionMBG,
  SetHasSubscriptionDiscountPostPurchase,
  SetMoonaId,
  SetOnlyEmail,
  SetStripeId,
  START_LOADING,
  STOP_LOADING,
  UPDATE_PWD,
  User,
  USER_ERROR,
} from './types'

export const createUserRequest = ({
  email,
  firstname,
  lastname,
  phone,
  price,
  merchantStripeId,
  orderId,
  ecommerceSessionId,
  postcode,
  city,
  country,
  address,
  cartId,
  deviceId,
  merchantId,
  ecommerce,
  notifyUrl,
  payload,
}: {
  email: string
  firstname: string
  lastname: string
  phone: string
  price: number
  merchantStripeId: string
  orderId: string
  ecommerceSessionId: string
  postcode: string
  city: string
  country: string
  address: string
  notifyUrl: string
  ecommerce: string
  deviceId: string
  merchantId: string
  cartId?: string | null
  payload?: string | null
}): AppActions => ({
  type: PROCESS_ORDER_REQUEST,
  payload: {
    email,
    firstname,
    lastname,
    phone,
    price,
    merchantStripeId,
    orderId,
    ecommerceSessionId,
    postcode,
    city,
    country,
    address,
    cartId,
    deviceId,
    merchantId,
    ecommerce,
    notifyUrl,
    payload,
  },
})

export const processMainOrderRequest = (
  payload: ProcessMainOrderRequestAction['payload']
): AppActions => ({
  type: PROCESS_MAIN_ORDER_REQUEST,
  payload,
})

export const processSubscribeToMoneyBackGuaranteeRequestioAction = (
  payload: ProcessSubscribeToMoneyBackGuaranteeRequestioAction['payload']
): AppActions => ({
  type: PROCESS_SUBSCRIBE_TO_MONEY_BACK_GUARANTEE_REQUEST,
  payload,
})

export const loadUser = ({
  firstName,
  lastName,
  email,
  phone,
  cards,
  moonaId,
  stripeId,
  paymentId,
}: User): AppActions => ({
  type: LOAD_USER,
  payload: {
    firstName,
    lastName,
    email,
    phone,
    cards,
    moonaId,
    stripeId,
    paymentId,
  },
})
export const clearUser = (): AppActions => ({
  type: CLEAR_USER,
})

export const userError = ({ error }: { error: string }): AppActions => ({
  type: USER_ERROR,
  payload: {
    error,
  },
})

export const setFirstPwd = ({
  email,
  password,
}: {
  email: string
  password: string
}): AppActions => ({
  type: SET_FIRST_PWD,
  payload: {
    email,
    password,
  },
})

export const updatePwd = ({
  code,
  username,
  password,
}: {
  code: string
  username: string
  password: string
}): AppActions => ({
  type: UPDATE_PWD,
  payload: {
    username,
    code,
    password,
  },
})
export const setUserSecret = ({
  userSecret,
}: {
  userSecret: string
}): AppActions => ({
  type: SET_USER_SECRET,
  payload: {
    userSecret,
  },
})

export const deleteFirstUserAction = ({
  moonaId,
}: {
  moonaId: string
}): AppActions => {
  return {
    type: DELETE_FIRST_USER,
    payload: {
      moonaId,
    },
  }
}

export const setUserMailAndId = ({
  email,
  moonaId,
}: {
  email: string
  moonaId: string
}): AppActions => {
  return {
    type: SET_EMAIL_MOONAID_SECRET,
    payload: {
      email,
      moonaId,
    },
  }
}

export const setMoonaEmail = ({ email }: { email: string }): AppActions => {
  return {
    type: SET_EMAIL,
    payload: {
      email,
    },
  }
}

export const setPwdSuccess = ({
  password,
}: {
  password: string
}): AppActions => {
  return {
    type: SET_PWD_SUCCESS,
    payload: {
      password,
    },
  }
}

export const fetchMailById = (id: string): AppActions => ({
  type: GET_MAIL,
  payload: {
    id,
  },
})

export const isMoonaUserByEmail = (email: string): AppActions => ({
  type: IS_MOONA_USER,
  payload: {
    email,
  },
})

export const fetchMailSuccess = ({ email }: { email: string }): AppActions => ({
  type: GET_MAIL_SUCCESS,
  payload: { email },
})

export const addCard = ({
  last4,
  brand,
  id,
}: AddCard['payload']): AppActions => ({
  type: ADD_CARD,
  payload: { last4, brand, id },
})

export const changeCard = ({ id }: ChangeCard['payload']): AppActions => ({
  type: CHANGE_CARD,
  payload: { id },
})

export const startLoading = (): AppActions => ({
  type: START_LOADING,
})

export const stopLoading = (): AppActions => ({
  type: STOP_LOADING,
})

export const setOnlyEmail = ({
  email,
}: SetOnlyEmail['payload']): AppActions => {
  return {
    type: SET_ONLY_EMAIL,
    payload: {
      email,
    },
  }
}

export const setMoonaId = ({ moonaId }: SetMoonaId['payload']): AppActions => {
  return {
    type: SET_MOONA_ID,
    payload: {
      moonaId,
    },
  }
}

export const setStripeId = ({
  stripeId,
}: SetStripeId['payload']): AppActions => {
  return {
    type: SET_STRIPE_ID,
    payload: {
      stripeId,
    },
  }
}

export const setHasSubscription = ({
  hasSubscription,
}: SetHasSubscription['payload']): AppActions => {
  return {
    type: SET_HAS_SUBSCRIPTION,
    payload: {
      hasSubscription,
    },
  }
}

export const setHasSubscriptionYCoupon = ({
  hasSubscriptionYCoupon
}: SetHasSubscriptionYCoupon['payload']): AppActions => {
  return {
    type: SET_HAS_SUBSCRIPTION_YCOUPON,
    payload: {
      hasSubscriptionYCoupon
    }
  }
}

export const setHasSubscriptionMBG = ({
  hasSubscriptionMBG,
}: SetHasSubscriptionMBG['payload']): AppActions => {
  return {
    type: SET_HAS_SUBSCRIPTION_MBG,
    payload: {
      hasSubscriptionMBG,
    },
  }
}

export const setHasSubscriptionDiscountPostPurchase = ({
  hasSubscriptionDiscountPostPurchase,
}: SetHasSubscriptionDiscountPostPurchase['payload']): AppActions => {
  return {
    type: SET_HAS_SUBSCRIPTION_DISCOUNT_POST_PURCHASE,
    payload: {
      hasSubscriptionDiscountPostPurchase,
    },
  }
}
